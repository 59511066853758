import { css } from '@emotion/react'
import styled from '@emotion/styled'

import {
  Name as BaseAttributeName,
  Value as BaseAttributeValue,
  TooltipIndicator as BaseTooltipIndicator,
  TooltipWrapper as BaseTooltipWrapper,
} from '@emico/product-specifications-table-pimcore'

import theme from '../theme'

const rowStyling = css`
  display: flex;
  padding: ${theme.spacing.x1};
  border-bottom: 1px solid ${theme.colors.superLightGray};

  &:first-of-type {
    border-top: 1px solid ${theme.colors.superLightGray};
  }
`

const AttributeName = styled(BaseAttributeName.original)`
  ${rowStyling};
  border-right: 1px solid ${theme.colors.superLightGray};
`

const AttributeValue = styled(BaseAttributeValue.original)`
  ${rowStyling};
`

export const TooltipWrapper = styled(BaseTooltipWrapper.original)`
  width: ${theme.spacing.x3}px;
`

const TooltipIncidator = styled(BaseTooltipIndicator.original)`
  color: ${theme.colors.gray};
`

/* Override attribute name */
BaseAttributeName.override(AttributeName as typeof BaseAttributeName.original)
/* Override attribute value */
BaseAttributeValue.override(
  AttributeValue as typeof BaseAttributeValue.original,
)
/* Override attribute tooltip wrapper */
BaseTooltipWrapper.override(
  TooltipWrapper as typeof BaseTooltipWrapper.original,
)
/* Override attribute tooltip */
BaseTooltipIndicator.override(
  TooltipIncidator as typeof BaseTooltipIndicator.original,
)
