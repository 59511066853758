import defaultsToVars, { defaultsToCss } from './cssVarsUtils'

// https://csstriggers.com/
const propertiesDefaults = {
  common:
    'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
  colors: 'background-color, border-color, color, fill, stroke',
  dimensions: 'width, height',
  position: 'left, right, top, bottom',
  background: 'background-color, background-image, background-position',
}

const properties = defaultsToVars(propertiesDefaults, 'transition-properties')

const timingFunctionsDefaults = {
  easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
  easeOut: 'cubic-bezier(0, 0, 0.2, 1)',
  easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
  cubicBezierSmooth: 'cubic-bezier(0.19, 1, 0.22, 1)',
}

const timingFunctions = defaultsToVars(timingFunctionsDefaults)

const durations = {
  fast: '100ms',
  normal: '200ms',
  slow: '600ms',
}

const transitions = {
  properties,
  timingFunctions,
  durations,
}

export const transitionCss = [
  ...defaultsToCss(propertiesDefaults, 'transition-properties'),
  ...defaultsToCss(timingFunctionsDefaults, 'transition'),
]

export const reducedMotionProps = [
  ...defaultsToCss<typeof propertiesDefaults>(
    {
      common: 'transform, opacity',
      background: 'none',
      position: propertiesDefaults.position,
      dimensions: propertiesDefaults.dimensions,
      colors: propertiesDefaults.colors,
    },
    'transition-properties',
  ),
  ...defaultsToCss<typeof timingFunctionsDefaults>(
    {
      easeIn: 'easeInOut',
      easeInOut: 'easeInOut',
      easeOut: 'easeInOut',
      cubicBezierSmooth: 'cubicBezierSmooth',
    },
    'transition',
  ),
]

export default transitions
