export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  JSON: { input: string; output: string }
}

/** Defines the bundle products to add to the cart. */
export interface AddBundleProductsToCartInput {
  /** The ID of the cart. */
  cart_id: Scalars['String']['input']
  /** An array of bundle products to add. */
  cart_items: Array<InputMaybe<BundleProductCartItemInput>>
}

/** Contains details about the cart after adding bundle products. */
export interface AddBundleProductsToCartOutput {
  __typename?: 'AddBundleProductsToCartOutput'
  /** The cart after adding products. */
  cart: Cart
}

export interface AddCommentToPostInput {
  author_email: Scalars['String']['input']
  author_nickname: Scalars['String']['input']
  parent_id: Scalars['Int']['input']
  post_id: Scalars['Int']['input']
  text: Scalars['String']['input']
}

export interface AddCommentToPostOutput {
  __typename?: 'AddCommentToPostOutput'
  comments: Maybe<Array<Maybe<BlogComment>>>
  totalCount: Maybe<Scalars['Int']['output']>
  totalPages: Maybe<Scalars['Int']['output']>
}

/** Defines the configurable products to add to the cart. */
export interface AddConfigurableProductsToCartInput {
  /** The ID of the cart. */
  cart_id: Scalars['String']['input']
  /** An array of configurable products to add. */
  cart_items: Array<InputMaybe<ConfigurableProductCartItemInput>>
}

/** Contains details about the cart after adding configurable products. */
export interface AddConfigurableProductsToCartOutput {
  __typename?: 'AddConfigurableProductsToCartOutput'
  /** The cart after adding products. */
  cart: Cart
}

export interface AddDownloadableProductsToCartInput {
  /** The ID of the cart. */
  cart_id: Scalars['String']['input']
  /** An array of downloadable products to add. */
  cart_items: Array<InputMaybe<DownloadableProductCartItemInput>>
}

/** Contains details about the cart after adding downloadable products. */
export interface AddDownloadableProductsToCartOutput {
  __typename?: 'AddDownloadableProductsToCartOutput'
  /** The cart after adding products. */
  cart: Cart
}

/** Defines an item to add to the gift registry. */
export interface AddGiftRegistryItemInput {
  /** An array of options the customer has entered. */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>
  /** A brief note about the item. */
  note?: InputMaybe<Scalars['String']['input']>
  /** For complex product types, the SKU of the parent product. */
  parent_sku?: InputMaybe<Scalars['String']['input']>
  /** The quantity of the product to add. */
  quantity: Scalars['Float']['input']
  /** An array of strings corresponding to options the customer has selected. */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The SKU of the product to add to the gift registry. */
  sku: Scalars['String']['input']
}

/** Defines a new registrant. */
export interface AddGiftRegistryRegistrantInput {
  /** Additional attributes specified as a code-value pair. */
  dynamic_attributes?: InputMaybe<
    Array<InputMaybe<GiftRegistryDynamicAttributeInput>>
  >
  /** The email address of the registrant. */
  email: Scalars['String']['input']
  /** The first name of the registrant. */
  firstname: Scalars['String']['input']
  /** The last name of the registrant. */
  lastname: Scalars['String']['input']
}

/** Contains the results of a request to add registrants. */
export interface AddGiftRegistryRegistrantsOutput {
  __typename?: 'AddGiftRegistryRegistrantsOutput'
  /** The gift registry after adding registrants. */
  giftRegistry: Maybe<GiftRegistry>
}

/** Contains details about the cart after adding products to it. */
export interface AddProductsToCartOutput {
  __typename?: 'AddProductsToCartOutput'
  /** The cart after products have been added. */
  cart: Cart
  /** Contains errors encountered while adding an item to the cart. */
  userErrors: Array<Maybe<CartUserInputError>>
}

/** Contains products to add to an existing compare list. */
export interface AddProductsToCompareListInput {
  /** An array of product IDs to add to the compare list. */
  products: Array<InputMaybe<Scalars['ID']['input']>>
  /** The unique identifier of the compare list to modify. */
  uid: Scalars['ID']['input']
}

/** Output of the request to add products to a requisition list. */
export interface AddProductsToRequisitionListOutput {
  __typename?: 'AddProductsToRequisitionListOutput'
  /** The requisition list after adding products. */
  requisitionList: Maybe<RequisitionList>
}

/** Contains the customer's wish list and any errors encountered. */
export interface AddProductsToWishlistOutput {
  __typename?: 'AddProductsToWishlistOutput'
  /** An array of errors encountered while adding products to a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>
  /** Contains the wish list with all items that were successfully added. */
  wishlist: Wishlist
}

/** Contains the comment to be added to a purchase order. */
export interface AddPurchaseOrderCommentInput {
  /** Comment text. */
  comment: Scalars['String']['input']
  /** The unique ID of a purchase order. */
  purchase_order_uid: Scalars['ID']['input']
}

/** Contains the successfully added comment. */
export interface AddPurchaseOrderCommentOutput {
  __typename?: 'AddPurchaseOrderCommentOutput'
  /** The purchase order comment. */
  comment: PurchaseOrderComment
}

/** Defines the purchase order and cart to act on. */
export interface AddPurchaseOrderItemsToCartInput {
  /** The ID to assign to the cart. */
  cart_id: Scalars['String']['input']
  /** Purchase order unique ID. */
  purchase_order_uid: Scalars['ID']['input']
  /** Replace existing cart or merge items. */
  replace_existing_cart_items: Scalars['Boolean']['input']
}

/** Contains details about why an attempt to add items to the requistion list failed. */
export interface AddRequisitionListItemToCartUserError {
  __typename?: 'AddRequisitionListItemToCartUserError'
  /** A description of the error. */
  message: Scalars['String']['output']
  /** The type of error that occurred. */
  type: AddRequisitionListItemToCartUserErrorType
}

export enum AddRequisitionListItemToCartUserErrorType {
  LOW_QUANTITY = 'LOW_QUANTITY',
  OPTIONS_UPDATED = 'OPTIONS_UPDATED',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  UNAVAILABLE_SKU = 'UNAVAILABLE_SKU',
}

/** Output of the request to add items in a requisition list to the cart. */
export interface AddRequisitionListItemsToCartOutput {
  __typename?: 'AddRequisitionListItemsToCartOutput'
  /** Details about why the attempt to add items to the requistion list was not successful. */
  addRequisitionListItemsToCartUserErrors: Array<
    Maybe<AddRequisitionListItemToCartUserError>
  >
  /** The cart after adding requisition list items. */
  cart: Maybe<Cart>
  /** Indicates whether the attempt to add items to the requisition list was successful. */
  status: Scalars['Boolean']['output']
}

/** Defines a return comment. */
export interface AddReturnCommentInput {
  /** The text added to the return request. */
  comment_text: Scalars['String']['input']
  /** The unique ID for a `Return` object. */
  return_uid: Scalars['ID']['input']
}

/** Contains details about the return request. */
export interface AddReturnCommentOutput {
  __typename?: 'AddReturnCommentOutput'
  /** The modified return. */
  return: Maybe<Return>
}

/** Defines tracking information to be added to the return. */
export interface AddReturnTrackingInput {
  /** The unique ID for a `ReturnShippingCarrier` object. */
  carrier_uid: Scalars['ID']['input']
  /** The unique ID for a `Returns` object. */
  return_uid: Scalars['ID']['input']
  /** The shipping tracking number for this return request. */
  tracking_number: Scalars['String']['input']
}

/** Contains the response after adding tracking information. */
export interface AddReturnTrackingOutput {
  __typename?: 'AddReturnTrackingOutput'
  /** Details about the modified return. */
  return: Maybe<Return>
  /** Details about shipping for a return. */
  returnShippingTracking: Maybe<ReturnShippingTracking>
}

/** Defines the simple and group products to add to the cart. */
export interface AddSimpleProductsToCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** An array of simple and group items to add. */
  cart_items: Array<InputMaybe<SimpleProductCartItemInput>>
}

/** Contains details about the cart after adding simple or group products. */
export interface AddSimpleProductsToCartOutput {
  __typename?: 'AddSimpleProductsToCartOutput'
  /** The cart after adding products. */
  cart: Cart
}

/** Defines the virtual products to add to the cart. */
export interface AddVirtualProductsToCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** An array of virtual products to add. */
  cart_items: Array<InputMaybe<VirtualProductCartItemInput>>
}

/** Contains details about the cart after adding virtual products. */
export interface AddVirtualProductsToCartOutput {
  __typename?: 'AddVirtualProductsToCartOutput'
  /** The cart after adding products. */
  cart: Cart
}

/** Contains the resultant wish list and any error information. */
export interface AddWishlistItemsToCartOutput {
  __typename?: 'AddWishlistItemsToCartOutput'
  /** An array of errors encountered while adding products to the customer's cart. */
  addWishlistItemsToCartUserErrors: Array<Maybe<WishlistCartUserInputError>>
  /** Indicates whether the attempt to add items to the customer's cart was successful. */
  status: Scalars['Boolean']['output']
  /** Contains the wish list with all items that were successfully added. */
  wishlist: Wishlist
}

export interface AdditionalDataField {
  field: Scalars['String']['input']
  value: Scalars['String']['input']
}

/** Contains information for each filterable option (such as price, category `UID`, and custom attributes). */
export interface Aggregation {
  __typename?: 'Aggregation'
  /** Attribute code of the aggregation group. */
  attributeCode: Scalars['String']['output']
  /** The number of options in the aggregation group. */
  count: Maybe<Scalars['Int']['output']>
  /** The aggregation display name. */
  label: Maybe<Scalars['String']['output']>
  /** Array of options for the aggregation. */
  options: Maybe<Array<Maybe<AggregationOption>>>
  /** The relative position of the attribute in a layered navigation block. */
  position: Maybe<Scalars['Int']['output']>
}

/** An implementation of `AggregationOptionInterface`. */
export interface AggregationOption extends AggregationOptionInterface {
  __typename?: 'AggregationOption'
  /** The number of items that match the aggregation option. */
  count: Maybe<Scalars['Int']['output']>
  /** The display label for an aggregation option. */
  label: Maybe<Scalars['String']['output']>
  /** The internal ID that represents the value of the option. */
  value: Scalars['String']['output']
}

/** Defines aggregation option fields. */
export interface AggregationOptionInterface {
  /** The number of items that match the aggregation option. */
  count: Maybe<Scalars['Int']['output']>
  /** The display label for an aggregation option. */
  label: Maybe<Scalars['String']['output']>
  /** The internal ID that represents the value of the option. */
  value: Scalars['String']['output']
}

/** Filter category aggregations in layered navigation. */
export interface AggregationsCategoryFilterInput {
  /** Indicates whether to include only direct subcategories or all children categories at all levels. */
  includeDirectChildrenOnly?: InputMaybe<Scalars['Boolean']['input']>
}

/** An input object that specifies the filters used in product aggregations. */
export interface AggregationsFilterInput {
  /** Filter category aggregations in layered navigation. */
  category?: InputMaybe<AggregationsCategoryFilterInput>
}

export interface ApplePayConfig extends PaymentConfigItem {
  __typename?: 'ApplePayConfig'
  /** The styles for the ApplePay Smart Button configuration */
  buttonStyles: Maybe<ButtonStyles>
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>
  /** The payment source for the payment method */
  paymentSource: Maybe<Scalars['String']['output']>
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>
}

/** Apple Pay inputs */
export interface ApplePayMethodInput {
  /** The payment source for the payment method */
  payment_source?: InputMaybe<Scalars['String']['input']>
  /** The payment services order ID */
  payments_order_id?: InputMaybe<Scalars['String']['input']>
  /** PayPal order ID */
  paypal_order_id?: InputMaybe<Scalars['String']['input']>
}

/** Contains the applied coupon code. */
export interface AppliedCoupon {
  __typename?: 'AppliedCoupon'
  /** The coupon code the shopper applied to the card. */
  code: Scalars['String']['output']
}

/** Contains an applied gift card with applied and remaining balance. */
export interface AppliedGiftCard {
  __typename?: 'AppliedGiftCard'
  /** The amount applied to the current cart. */
  appliedBalance: Maybe<Money>
  /** The gift card account code. */
  code: Maybe<Scalars['String']['output']>
  /** The remaining balance on the gift card. */
  currentBalance: Maybe<Money>
  /** The expiration date of the gift card. */
  expirationDate: Maybe<Scalars['String']['output']>
}

/** Contains the applied and current balances. */
export interface AppliedStoreCredit {
  __typename?: 'AppliedStoreCredit'
  /** The applied store credit balance to the current cart. */
  appliedBalance: Maybe<Money>
  /** The current balance remaining on store credit. */
  currentBalance: Maybe<Money>
  /** Indicates whether store credits are enabled. If the feature is disabled, then the current balance will not be returned. */
  enabled: Maybe<Scalars['Boolean']['output']>
}

/** Specifies the coupon code to apply to the cart. */
export interface ApplyCouponToCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** A valid coupon code. */
  coupon_code: Scalars['String']['input']
}

/** Contains details about the cart after applying a coupon. */
export interface ApplyCouponToCartOutput {
  __typename?: 'ApplyCouponToCartOutput'
  /** The cart after applying a coupon. */
  cart: Cart
}

/** The strategy to apply coupons to the cart. */
export enum ApplyCouponsStrategy {
  /** Append new coupons keeping the coupons that have been applied before. */
  APPEND = 'APPEND',
  /** Remove all the coupons from the cart and apply only new provided coupons. */
  REPLACE = 'REPLACE',
}

/** Apply coupons to the cart. */
export interface ApplyCouponsToCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** An array of valid coupon codes. */
  coupon_codes: Array<InputMaybe<Scalars['String']['input']>>
  /** `replace` to replace the existing coupon(s) or `append` to add the coupon to the coupon(s) list. */
  type?: InputMaybe<ApplyCouponsStrategy>
}

/** Defines the input required to run the `applyGiftCardToCart` mutation. */
export interface ApplyGiftCardToCartInput {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String']['input']
  /** The gift card code to be applied to the cart. */
  gift_card_code: Scalars['String']['input']
}

/** Defines the possible output for the `applyGiftCardToCart` mutation. */
export interface ApplyGiftCardToCartOutput {
  __typename?: 'ApplyGiftCardToCartOutput'
  /** Describes the contents of the specified shopping cart. */
  cart: Cart
}

/** Contains the customer cart. */
export interface ApplyRewardPointsToCartOutput {
  __typename?: 'ApplyRewardPointsToCartOutput'
  /** The customer cart after reward points are applied. */
  cart: Cart
}

/** Defines the input required to run the `applyStoreCreditToCart` mutation. */
export interface ApplyStoreCreditToCartInput {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String']['input']
}

/** Defines the possible output for the `applyStoreCreditToCart` mutation. */
export interface ApplyStoreCreditToCartOutput {
  __typename?: 'ApplyStoreCreditToCartOutput'
  /** The contents of the specified shopping cart. */
  cart: Cart
}

/** Contains the results of the request to assign a compare list. */
export interface AssignCompareListToCustomerOutput {
  __typename?: 'AssignCompareListToCustomerOutput'
  /** The contents of the customer's compare list. */
  compareList: Maybe<CompareList>
  /** Indicates whether the compare list was successfully assigned to the customer. */
  result: Scalars['Boolean']['output']
}

/** Contains details about the attribute, including the code and type. */
export interface Attribute {
  __typename?: 'Attribute'
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attributeCode: Maybe<Scalars['String']['output']>
  /** Attribute options list. */
  attributeOptions: Maybe<Array<Maybe<AttributeOption>>>
  /** The data type of the attribute. */
  attributeType: Maybe<Scalars['String']['output']>
  /** The type of entity that defines the attribute. */
  entityType: Maybe<Scalars['String']['output']>
  /** The frontend input type of the attribute. */
  inputType: Maybe<Scalars['String']['output']>
  /** Details about the storefront properties configured for the attribute. */
  storefrontProperties: Maybe<StorefrontProperties>
}

/** List of all entity types. Populated by the modules introducing EAV entities. */
export enum AttributeEntityTypeEnum {
  CATALOG_CATEGORY = 'CATALOG_CATEGORY',
  CATALOG_PRODUCT = 'CATALOG_PRODUCT',
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_ADDRESS = 'CUSTOMER_ADDRESS',
  RMA_ITEM = 'RMA_ITEM',
}

/** An input object that specifies the filters used for attributes. */
export interface AttributeFilterInput {
  /** Whether a product or category attribute can be compared against another or not. */
  is_comparable?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute can be filtered or not. */
  is_filterable?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute can be filtered in search or not. */
  is_filterable_in_search?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute can use HTML on front or not. */
  is_html_allowed_on_front?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute can be searched or not. */
  is_searchable?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a customer or customer address attribute is used for customer segment or not. */
  is_used_for_customer_segment?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute can be used for price rules or not. */
  is_used_for_price_rules?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute is used for promo rules or not. */
  is_used_for_promo_rules?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute is visible in advanced search or not. */
  is_visible_in_advanced_search?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute is visible on front or not. */
  is_visible_on_front?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute has WYSIWYG enabled or not. */
  is_wysiwyg_enabled?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute is used in product listing or not. */
  used_in_product_listing?: InputMaybe<Scalars['Boolean']['input']>
}

/** EAV attribute frontend input types. */
export enum AttributeFrontendInputEnum {
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  FILE = 'FILE',
  GALLERY = 'GALLERY',
  HIDDEN = 'HIDDEN',
  IMAGE = 'IMAGE',
  MEDIA_IMAGE = 'MEDIA_IMAGE',
  MULTILINE = 'MULTILINE',
  MULTISELECT = 'MULTISELECT',
  PRICE = 'PRICE',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  UNDEFINED = 'UNDEFINED',
  WEIGHT = 'WEIGHT',
}

/** Defines the attribute characteristics to search for the `attribute_code` and `entity_type` to search. */
export interface AttributeInput {
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: InputMaybe<Scalars['String']['input']>
  /** The type of entity that defines the attribute. */
  entity_type?: InputMaybe<Scalars['String']['input']>
}

/** Specifies selected option for a select or multiselect attribute value. */
export interface AttributeInputSelectedOption {
  /** The attribute option value. */
  value: Scalars['String']['input']
}

/** Base EAV implementation of CustomAttributeMetadataInterface. */
export interface AttributeMetadata extends CustomAttributeMetadataInterface {
  __typename?: 'AttributeMetadata'
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  code: Scalars['ID']['output']
  /** Default attribute value. */
  defaultValue: Maybe<Scalars['String']['output']>
  /** The type of entity that defines the attribute. */
  entityType: AttributeEntityTypeEnum
  /** The frontend class of the attribute. */
  frontendClass: Maybe<Scalars['String']['output']>
  /** The frontend input type of the attribute. */
  frontendInput: Maybe<AttributeFrontendInputEnum>
  /** Whether the attribute value is required. */
  isRequired: Scalars['Boolean']['output']
  /** Whether the attribute value must be unique. */
  isUnique: Scalars['Boolean']['output']
  /** The label assigned to the attribute. */
  label: Maybe<Scalars['String']['output']>
  /** Attribute options. */
  options: Array<Maybe<CustomAttributeOptionInterface>>
}

/** Attribute metadata retrieval error. */
export interface AttributeMetadataError {
  __typename?: 'AttributeMetadataError'
  /** Attribute metadata retrieval error message. */
  message: Scalars['String']['output']
  /** Attribute metadata retrieval error type. */
  type: AttributeMetadataErrorType
}

/** Attribute metadata retrieval error types. */
export enum AttributeMetadataErrorType {
  /** The requested attribute was not found. */
  ATTRIBUTE_NOT_FOUND = 'ATTRIBUTE_NOT_FOUND',
  /** The requested entity was not found. */
  ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
  /** The filter cannot be applied as it does not belong to the entity */
  FILTER_NOT_FOUND = 'FILTER_NOT_FOUND',
  /** Not categorized error, see the error message. */
  UNDEFINED = 'UNDEFINED',
}

/** Defines an attribute option. */
export interface AttributeOption {
  __typename?: 'AttributeOption'
  /** The label assigned to the attribute option. */
  label: Maybe<Scalars['String']['output']>
  /** The attribute option value. */
  value: Maybe<Scalars['String']['output']>
}

/** Base EAV implementation of CustomAttributeOptionInterface. */
export interface AttributeOptionMetadata
  extends CustomAttributeOptionInterface {
  __typename?: 'AttributeOptionMetadata'
  /** Is the option value default. */
  isDefault: Scalars['Boolean']['output']
  /** The label assigned to the attribute option. */
  label: Scalars['String']['output']
  /** The attribute option value. */
  value: Scalars['String']['output']
}

export interface AttributeSelectedOption
  extends AttributeSelectedOptionInterface {
  __typename?: 'AttributeSelectedOption'
  /** The attribute selected option label. */
  label: Scalars['String']['output']
  /** The attribute selected option value. */
  value: Scalars['String']['output']
}

export interface AttributeSelectedOptionInterface {
  /** The attribute selected option label. */
  label: Scalars['String']['output']
  /** The attribute selected option value. */
  value: Scalars['String']['output']
}

export interface AttributeSelectedOptions extends AttributeValueInterface {
  __typename?: 'AttributeSelectedOptions'
  /** The attribute code. */
  code: Scalars['ID']['output']
  selectedOptions: Array<Maybe<AttributeSelectedOptionInterface>>
}

export interface AttributeValue extends AttributeValueInterface {
  __typename?: 'AttributeValue'
  /** The attribute code. */
  code: Scalars['ID']['output']
  /** The attribute value. */
  value: Scalars['String']['output']
}

/** Specifies the value for attribute. */
export interface AttributeValueInput {
  /** The code of the attribute. */
  attribute_code: Scalars['String']['input']
  /** An array containing selected options for a select or multiselect attribute. */
  selected_options?: InputMaybe<Array<InputMaybe<AttributeInputSelectedOption>>>
  /** The value assigned to the attribute. */
  value?: InputMaybe<Scalars['String']['input']>
}

export interface AttributeValueInterface {
  /** The attribute code. */
  code: Scalars['ID']['output']
}

/** Metadata of EAV attributes associated to form */
export interface AttributesFormOutput {
  __typename?: 'AttributesFormOutput'
  /** Errors of retrieving certain attributes metadata. */
  errors: Array<Maybe<AttributeMetadataError>>
  /** Requested attributes metadata. */
  items: Array<Maybe<CustomAttributeMetadataInterface>>
}

/** Metadata of EAV attributes. */
export interface AttributesMetadataOutput {
  __typename?: 'AttributesMetadataOutput'
  /** Errors of retrieving certain attributes metadata. */
  errors: Array<Maybe<AttributeMetadataError>>
  /** Requested attributes metadata. */
  items: Array<Maybe<CustomAttributeMetadataInterface>>
}

export interface AutocompleteAddressDetails {
  __typename?: 'AutocompleteAddressDetails'
  /** Number and possible addition of the building in the postal address. */
  building: Scalars['String']['output']
  /** Building number of the postal address. Or null if not available. */
  buildingNumber: Maybe<Scalars['Int']['output']>
  /** Building number addition of the postal address, if available. */
  buildingNumberAddition: Maybe<Scalars['String']['output']>
  /** Country name of the of postal address in English. */
  country: Scalars['String']['output']
  /** Name of primary locality used in postal address. */
  locality: Scalars['String']['output']
  /** Postcode used in postal address. */
  postcode: Scalars['String']['output']
  /** Name of primary street used in postal address. */
  street: Scalars['String']['output']
}

export interface AutocompleteAddressDetailsCountry {
  __typename?: 'AutocompleteAddressDetailsCountry'
  /** The ISO 3166-1 Alpha-3 code for the country */
  iso3Code: Scalars['String']['output']
  /** The country name */
  name: Scalars['String']['output']
}

export interface AutocompleteAddressDetailsLocation {
  __typename?: 'AutocompleteAddressDetailsLocation'
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
  /** Precision of location. One of "Address", "Street", "PostalCode", "Locality" */
  precision: Scalars['String']['output']
}

export interface AutocompleteAddressDetailsResult {
  __typename?: 'AutocompleteAddressDetailsResult'
  address: AutocompleteAddressDetails
  country: AutocompleteAddressDetailsCountry
  details: Maybe<Scalars['JSON']['output']>
  isPoBox: Scalars['Boolean']['output']
  language: Scalars['String']['output']
  location: Maybe<AutocompleteAddressDetailsLocation>
  mailLines: Array<Scalars['String']['output']>
}

export enum AutocompleteAddressLanguages {
  DE = 'DE',
  DE_DE = 'DE_DE',
  EN = 'EN',
  EN_GB = 'EN_GB',
  EN_US = 'EN_US',
  NL = 'NL',
  NL_NL = 'NL_NL',
}

export interface AutocompleteAddressResult {
  __typename?: 'AutocompleteAddressResult'
  /** List of matches for the specified context and term. */
  matches: Array<AutocompleteAddressResultMatch>
  /**
   * New context that is required for further autocomplete requests. Null if no context update
   * is required.
   */
  newContext: Maybe<Scalars['String']['output']>
}

export interface AutocompleteAddressResultMatch {
  __typename?: 'AutocompleteAddressResultMatch'
  /**
   * If the user selects this match, use this as the context parameter in subsequent autocomplete
   * call. Contexts may expire and should not be stored.
   */
  context: Scalars['String']['output']
  /**
   * Additional information relevant to this match, helps with disambiguation between similar labels.
   * For example, a postal code associated with a matched street.
   */
  description: Scalars['String']['output']
  /**
   * List of [start, end] character offsets to highlight in the label in order to indicate what
   * was matched.
   */
  highlights: Array<Array<Scalars['Int']['output']>>
  /** Label describing this match. For example, a street or municipality name. */
  label: Scalars['String']['output']
  /**
   * Match precision, used to know what type of address information is available. E.g. "Locality",
   * "PostalCode", "Street", "PartialAddress", "Address"
   */
  precision: Scalars['String']['output']
  /**
   * The value represents all matched address information. If the user selects this match the current
   * term input must be replaced with this value.
   */
  value: Scalars['String']['output']
}

/** Defines the code and symbol of a currency that can be used for purchase orders. */
export interface AvailableCurrency {
  __typename?: 'AvailableCurrency'
  /** 3-letter currency code, for example USD. */
  code: CurrencyEnum
  /** Currency symbol, for example $. */
  symbol: Scalars['String']['output']
}

/** Describes a payment method that the shopper can use to pay for the order. */
export interface AvailablePaymentMethod {
  __typename?: 'AvailablePaymentMethod'
  /** The payment method code. */
  code: Scalars['String']['output']
  /** If the payment method is an online integration */
  isDeferred: Scalars['Boolean']['output']
  /** Available issuers for this payment method */
  mollieAvailableIssuers: Maybe<Array<Maybe<MollieIssuer>>>
  /** Available terminals for this payment method */
  mollieAvailableTerminals: Maybe<Array<Maybe<MollieTerminalOutput>>>
  /** Retrieve meta information for this payment method (image) */
  mollieMeta: MolliePaymentMethodMeta
  /** The payment method title. */
  title: Scalars['String']['output']
}

/** Contains details about the possible shipping methods and carriers. */
export interface AvailableShippingMethod {
  __typename?: 'AvailableShippingMethod'
  /** The cost of shipping using this shipping method. */
  amount: Money
  /** Indicates whether this shipping method can be applied to the cart. */
  available: Scalars['Boolean']['output']
  /** @deprecated The field should not be used on the storefront. */
  baseAmount: Maybe<Money>
  /** A string that identifies a commercial carrier or an offline shipping method. */
  carrierCode: Scalars['String']['output']
  /** The label for the carrier code. */
  carrierTitle: Scalars['String']['output']
  /** Describes an error condition. */
  errorMessage: Maybe<Scalars['String']['output']>
  /** A shipping method code associated with a carrier. The value could be null if no method is available. */
  methodCode: Maybe<Scalars['String']['output']>
  /** The label for the shipping method code. The value could be null if no method is available. */
  methodTitle: Maybe<Scalars['String']['output']>
  /** The cost of shipping using this shipping method, excluding tax. */
  priceExclTax: Money
  /** The cost of shipping using this shipping method, including tax. */
  priceInclTax: Money
}

export enum BatchMutationStatus {
  FAILURE = 'FAILURE',
  MIXED_RESULTS = 'MIXED_RESULTS',
  SUCCESS = 'SUCCESS',
}

/** Defines the billing address. */
export interface BillingAddressInput {
  /** Defines a billing address. */
  address?: InputMaybe<CartAddressInput>
  /** An ID from the customer's address book that uniquely identifies the address to be used for billing. */
  customer_address_id?: InputMaybe<Scalars['Int']['input']>
  /** Indicates whether to set the billing address to be the same as the existing shipping address on the cart. */
  same_as_shipping?: InputMaybe<Scalars['Boolean']['input']>
  /** Indicates whether to set the shipping address to be the same as this billing address. */
  use_for_shipping?: InputMaybe<Scalars['Boolean']['input']>
}

/** Contains details about the billing address. */
export interface BillingCartAddress extends CartAddressInterface {
  __typename?: 'BillingCartAddress'
  /** The city specified for the billing or shipping address. */
  city: Scalars['String']['output']
  /** The company specified for the billing or shipping address. */
  company: Maybe<Scalars['String']['output']>
  /** An object containing the country label and code. */
  country: CartAddressCountry
  /** The custom attribute values of the billing or shipping address. */
  customAttributes: Array<Maybe<AttributeValueInterface>>
  /** @deprecated The field is used only in shipping address. */
  customerNotes: Maybe<Scalars['String']['output']>
  /** The customer's fax number. */
  fax: Maybe<Scalars['String']['output']>
  /** The first name of the customer or guest. */
  firstname: Scalars['String']['output']
  /** The last name of the customer or guest. */
  lastname: Scalars['String']['output']
  /** The middle name of the person associated with the billing/shipping address. */
  middlename: Maybe<Scalars['String']['output']>
  /** The ZIP or postal code of the billing or shipping address. */
  postcode: Maybe<Scalars['String']['output']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']['output']>
  /** An object containing the region label and code. */
  region: Maybe<CartAddressRegion>
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']['output']>>
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']['output']>
  /** The telephone number for the billing or shipping address. */
  telephone: Maybe<Scalars['String']['output']>
  /** The unique id of the customer address. */
  uid: Scalars['String']['output']
  /** The VAT company number for billing or shipping address. */
  vatId: Maybe<Scalars['String']['output']>
}

export interface BlogAuthor extends RoutableInterface {
  __typename?: 'BlogAuthor'
  /** Id of the Blog Post Author */
  authorId: Maybe<Scalars['Int']['output']>
  /** Blog Author Author url */
  authorUrl: Maybe<Scalars['String']['output']>
  content: Maybe<Scalars['String']['output']>
  /** Blog Author Created */
  creationTime: Maybe<Scalars['String']['output']>
  customLayout: Maybe<Scalars['String']['output']>
  customLayoutUpdateXml: Maybe<Scalars['String']['output']>
  customTheme: Maybe<Scalars['String']['output']>
  customThemeFrom: Maybe<Scalars['String']['output']>
  customThemeTo: Maybe<Scalars['String']['output']>
  facebookPageUrl: Maybe<Scalars['String']['output']>
  featuredImage: Maybe<Scalars['String']['output']>
  filteredContent: Maybe<Scalars['String']['output']>
  /** URL key of Blog Author */
  identifier: Maybe<Scalars['String']['output']>
  instagramPageUrl: Maybe<Scalars['String']['output']>
  /** Blog Author Is active */
  isActive: Maybe<Scalars['Int']['output']>
  layoutUpdateXml: Maybe<Scalars['String']['output']>
  linkedinPageUrl: Maybe<Scalars['String']['output']>
  /** Blog Author Meta description */
  metaDescription: Maybe<Scalars['String']['output']>
  /** Blog Author Meta title */
  metaTitle: Maybe<Scalars['String']['output']>
  /** Blog Author Name */
  name: Maybe<Scalars['String']['output']>
  pageLayout: Maybe<Scalars['String']['output']>
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  role: Maybe<Scalars['String']['output']>
  shortContent: Maybe<Scalars['String']['output']>
  shortFilteredContent: Maybe<Scalars['String']['output']>
  /** Blog Author title */
  title: Maybe<Scalars['String']['output']>
  twitterPageUrl: Maybe<Scalars['String']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /** Blog Author Url */
  url: Maybe<Scalars['String']['output']>
}

export interface BlogCategoriesOutput {
  __typename?: 'BlogCategoriesOutput'
  items: Maybe<Array<Maybe<BlogCategory>>>
  totalCount: Maybe<Scalars['Int']['output']>
}

export interface BlogCategory extends RoutableInterface {
  __typename?: 'BlogCategory'
  /** Breadcrumbs, parent categories info. */
  breadcrumbs: Maybe<Array<Maybe<Breadcrumb>>>
  /** Blog Category Canonical URL */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** Id of the Blog Post Category */
  categoryId: Maybe<Scalars['Int']['output']>
  /** Category Level */
  categoryLevel: Maybe<Scalars['Int']['output']>
  /** URL of the Blog Post Category */
  categoryUrl: Maybe<Scalars['String']['output']>
  /** Relative URL of the Blog Post Category */
  categoryUrlPath: Maybe<Scalars['String']['output']>
  /** Blog Category content */
  content: Maybe<Scalars['String']['output']>
  /** Blog Category Content heading */
  contentHeading: Maybe<Scalars['String']['output']>
  /** Blog Category Custom layout */
  customLayout: Maybe<Scalars['String']['output']>
  /** Blog Category Custom layout update xml */
  customLayoutUpdateXml: Maybe<Scalars['String']['output']>
  /** Blog Category Custom theme */
  customTheme: Maybe<Scalars['String']['output']>
  /** Blog Category Custom theme from */
  customThemeFrom: Maybe<Scalars['String']['output']>
  /** Blog Category Custom theme to */
  customThemeTo: Maybe<Scalars['String']['output']>
  /** Blog Category Display mode */
  displayMode: Maybe<Scalars['Int']['output']>
  /** URL key of Blog Category */
  identifier: Maybe<Scalars['String']['output']>
  /** Blog Category Include in menu */
  includeInMenu: Maybe<Scalars['Int']['output']>
  /** Blog Category Is active */
  isActive: Maybe<Scalars['Int']['output']>
  /** Blog Category Layout update xml */
  layoutUpdateXml: Maybe<Scalars['String']['output']>
  /** Blog Category Meta description */
  metaDescription: Maybe<Scalars['String']['output']>
  /** Blog Category Meta keywords */
  metaKeywords: Maybe<Scalars['String']['output']>
  /** Blog Category Meta title */
  metaTitle: Maybe<Scalars['String']['output']>
  /** Blog Category Page_layout */
  pageLayout: Maybe<Scalars['String']['output']>
  /** Id of the Blog Post Parent Category */
  parentCategoryId: Maybe<Scalars['Int']['output']>
  /** Blog Category Path */
  path: Maybe<Scalars['String']['output']>
  /** Blog Category Position */
  position: Maybe<Scalars['Int']['output']>
  /** Blog Category Posts Count */
  postsCount: Maybe<Scalars['Int']['output']>
  /** Blog Category Posts sort by */
  postsSortBy: Maybe<Scalars['Int']['output']>
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** Blog Category title */
  title: Maybe<Scalars['String']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
}

export interface BlogComment {
  __typename?: 'BlogComment'
  /** Blog  Comment Admin id */
  adminId: Maybe<Scalars['Int']['output']>
  /** Blog Comment Author email */
  authorEmail: Maybe<Scalars['String']['output']>
  /** Blog Comment Author nickname */
  authorNickname: Maybe<Scalars['String']['output']>
  /** Blog Comment Author type */
  authorType: Maybe<Scalars['Int']['output']>
  /** Blog  Comment Comment id */
  commentId: Maybe<Scalars['Int']['output']>
  /** Blog Comment Creation time */
  creationTime: Maybe<Scalars['String']['output']>
  /** Blog  Comment Customer id */
  customerId: Maybe<Scalars['Int']['output']>
  /** Blog  Comment Parent id */
  parentId: Maybe<Scalars['Int']['output']>
  /** Blog  Comment Comment id */
  postId: Maybe<Scalars['Int']['output']>
  /** Blog Comment Replies */
  replies: Maybe<Array<Maybe<BlogComment>>>
  /** Blog Comment Is active */
  status: Maybe<Scalars['Int']['output']>
  /** Blog Comment Text */
  text: Maybe<Scalars['String']['output']>
  /** Blog Comment Update time */
  updateTime: Maybe<Scalars['String']['output']>
}

export interface BlogCommentsFilterInput {
  /** The keyword required to perform a logical OR comparison */
  or?: InputMaybe<BlogCommentsFilterInput>
  post_id?: InputMaybe<FilterTypeInput>
}

export interface BlogCommentsOutput extends RoutableInterface {
  __typename?: 'BlogCommentsOutput'
  items: Maybe<Array<Maybe<BlogComment>>>
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  totalCount: Maybe<Scalars['Int']['output']>
  totalPages: Maybe<Scalars['Int']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
}

export interface BlogPost extends RoutableInterface {
  __typename?: 'BlogPost'
  /** Blog Post Author */
  author: Maybe<BlogAuthor>
  /** Blog Post Author Id */
  authorId: Maybe<Scalars['Int']['output']>
  /** Blog Post Canonical URL */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** An array of related categories */
  categories: Maybe<Array<Maybe<BlogCategory>>>
  /** Blog Post Category Id */
  categoryId: Maybe<Scalars['Int']['output']>
  /** Blog Post content */
  content: Maybe<Scalars['String']['output']>
  /** Blog Post Content heading */
  contentHeading: Maybe<Scalars['String']['output']>
  /** Blog Post Creation time */
  creationTime: Maybe<Scalars['String']['output']>
  /** Blog Post Custom layout */
  customLayout: Maybe<Scalars['String']['output']>
  /** Blog Post Custom layout update xml */
  customLayoutUpdateXml: Maybe<Scalars['String']['output']>
  /** Blog Post Custom theme */
  customTheme: Maybe<Scalars['String']['output']>
  /** Blog Post Custom theme from */
  customThemeFrom: Maybe<Scalars['String']['output']>
  /** Blog Post Custom theme to */
  customThemeTo: Maybe<Scalars['String']['output']>
  /** Blog Post End time */
  endTime: Maybe<Scalars['String']['output']>
  /** Blog Post Featured image */
  featuredImage: Maybe<Scalars['String']['output']>
  /** Blog Post Featured image alt text */
  featuredImgAlt: Maybe<Scalars['String']['output']>
  /** Blog Post Featured List image */
  featuredListImage: Maybe<Scalars['String']['output']>
  /** Blog Post Featured List image alt text */
  featuredListImgAlt: Maybe<Scalars['String']['output']>
  /** Blog Post filtered content */
  filteredContent: Maybe<Scalars['String']['output']>
  /** Blog Post first image */
  firstImage: Maybe<Scalars['String']['output']>
  /** URL key of Blog Post */
  identifier: Maybe<Scalars['String']['output']>
  /** Blog Post Include in recent */
  includeInRecent: Maybe<Scalars['Int']['output']>
  /** Blog Post Is active */
  isActive: Maybe<Scalars['Int']['output']>
  /** Blog Post Is recent posts skip */
  isRecentPostsSkip: Maybe<Scalars['Int']['output']>
  /** Blog Post Layout update xml */
  layoutUpdateXml: Maybe<Scalars['String']['output']>
  /** Blog Post Media gallery */
  mediaGallery: Maybe<Scalars['String']['output']>
  /** Blog Post Meta description */
  metaDescription: Maybe<Scalars['String']['output']>
  /** Blog Post Meta keywords */
  metaKeywords: Maybe<Scalars['String']['output']>
  /** Blog Post Meta title */
  metaTitle: Maybe<Scalars['String']['output']>
  /** Blog Post Og description */
  ogDescription: Maybe<Scalars['String']['output']>
  /** Blog Post Og image */
  ogImage: Maybe<Scalars['String']['output']>
  /** Blog Post Og title */
  ogTitle: Maybe<Scalars['String']['output']>
  /** Blog Post Og type */
  ogType: Maybe<Scalars['String']['output']>
  /** Blog Post Page_layout */
  pageLayout: Maybe<Scalars['String']['output']>
  /** Blog Post Position */
  position: Maybe<Scalars['Int']['output']>
  /** Id of the Blog Post */
  postId: Maybe<Scalars['Int']['output']>
  /** Blog Post URL */
  postUrl: Maybe<Scalars['String']['output']>
  /** Blog Post Publish time */
  publishTime: Maybe<Scalars['String']['output']>
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** Blog Related posts */
  relatedPosts: Maybe<Array<Maybe<BlogPost>>>
  /** Blog Related products */
  relatedProducts: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Id of the Related Product (This attribute used only in filter) */
  relatedproductId: Maybe<Scalars['Int']['output']>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** Blog Post Search Term */
  search: Maybe<Scalars['String']['output']>
  /** Blog Post Secret */
  secret: Maybe<Scalars['String']['output']>
  /** Blog Post Short content */
  shortContent: Maybe<Scalars['String']['output']>
  /** Blog Post short filtered content */
  shortFilteredContent: Maybe<Scalars['String']['output']>
  /** Blog Post Author Id */
  tagId: Maybe<Scalars['Int']['output']>
  /** An array of related tags */
  tags: Maybe<Array<Maybe<BlogTag>>>
  /** Blog Post title */
  title: Maybe<Scalars['String']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /** Blog Post Update time */
  updateTime: Maybe<Scalars['String']['output']>
  /** Blog Post Views count */
  viewsCount: Maybe<Scalars['Int']['output']>
}

export interface BlogPostsFilterInput {
  author_id?: InputMaybe<FilterTypeInput>
  category_id?: InputMaybe<FilterTypeInput>
  content?: InputMaybe<FilterTypeInput>
  /** The keyword required to perform a logical OR comparison */
  or?: InputMaybe<BlogPostsFilterInput>
  post_id?: InputMaybe<FilterTypeInput>
  publish_time?: InputMaybe<FilterTypeInput>
  relatedproduct_id?: InputMaybe<FilterTypeInput>
  search?: InputMaybe<FilterTypeInput>
  tag_id?: InputMaybe<FilterTypeInput>
  title?: InputMaybe<FilterTypeInput>
}

export interface BlogPostsOutput extends RoutableInterface {
  __typename?: 'BlogPostsOutput'
  /** An array of Blog posts */
  items: Maybe<Array<Maybe<BlogPost>>>
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  totalCount: Maybe<Scalars['Int']['output']>
  totalPages: Maybe<Scalars['Int']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
}

export interface BlogTag extends RoutableInterface {
  __typename?: 'BlogTag'
  /** Blog Tag content */
  content: Maybe<Scalars['String']['output']>
  /** Blog Tag Custom layout */
  customLayout: Maybe<Scalars['String']['output']>
  /** Blog Tag Custom layout update xml */
  customLayoutUpdateXml: Maybe<Scalars['String']['output']>
  /** Blog Tag Custom theme */
  customTheme: Maybe<Scalars['String']['output']>
  /** Blog Tag Custom theme from */
  customThemeFrom: Maybe<Scalars['String']['output']>
  /** Blog Tag Custom theme to */
  customThemeTo: Maybe<Scalars['String']['output']>
  /** URL key of Blog Tag */
  identifier: Maybe<Scalars['String']['output']>
  /** Blog Tag Is active */
  isActive: Maybe<Scalars['Int']['output']>
  /** Blog Tag Layout update xml */
  layoutUpdateXml: Maybe<Scalars['String']['output']>
  /** Blog Tag Meta description */
  metaDescription: Maybe<Scalars['String']['output']>
  /** Blog Tag Meta keywords */
  metaKeywords: Maybe<Scalars['String']['output']>
  /** Blog Tag Meta robots */
  metaRobots: Maybe<Scalars['String']['output']>
  /** Blog Tag Meta title */
  metaTitle: Maybe<Scalars['String']['output']>
  /** Blog Tag Page_layout */
  pageLayout: Maybe<Scalars['String']['output']>
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** Id of the Blog Post Tag */
  tagId: Maybe<Scalars['Int']['output']>
  /** URL of Blog Tag */
  tagUrl: Maybe<Scalars['String']['output']>
  /** Blog Tag title */
  title: Maybe<Scalars['String']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
}

export interface BlogTagsOutput {
  __typename?: 'BlogTagsOutput'
  items: Maybe<Array<Maybe<BlogTag>>>
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Contains details about an individual category that comprises a breadcrumb. */
export interface Breadcrumb {
  __typename?: 'Breadcrumb'
  /**
   * The ID of the category.
   * @deprecated Use `category_uid` instead.
   */
  categoryId: Maybe<Scalars['Int']['output']>
  /** The category level. */
  categoryLevel: Maybe<Scalars['Int']['output']>
  /** The display name of the category. */
  categoryName: Maybe<Scalars['String']['output']>
  /** The unique ID for a `Breadcrumb` object. */
  categoryUid: Scalars['ID']['output']
  /** The URL key of the category. */
  categoryUrlKey: Maybe<Scalars['String']['output']>
  /** The URL path of the category. */
  categoryUrlPath: Maybe<Scalars['String']['output']>
}

/** An implementation for bundle product cart items. */
export interface BundleCartItem extends CartItemInterface {
  __typename?: 'BundleCartItem'
  /** The list of available gift wrapping options for the cart item. */
  availableGiftWrapping: Array<Maybe<GiftWrapping>>
  /** An array containing the bundle options the shopper selected. */
  bundleOptions: Array<Maybe<SelectedBundleOption>>
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** Delivery date of the cart item (Y-m-d) */
  deliveryDate: Maybe<Scalars['String']['output']>
  /** Contains discount for quote line item. */
  discount: Maybe<Array<Maybe<Discount>>>
  /** Elektramat configuration packslip */
  elektramatPackslip: Maybe<Scalars['String']['output']>
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>
  /** The entered gift message for the cart item */
  giftMessage: Maybe<GiftMessage>
  /** The selected gift wrapping for the cart item. */
  giftWrapping: Maybe<GiftWrapping>
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output']
  /** True if requested quantity is less than available stock, false otherwise. */
  isAvailable: Scalars['Boolean']['output']
  /** An array of products that have been added to the cart as child of */
  items: Maybe<Array<Maybe<CartItemInterface>>>
  /** The buyer's quote line item note. */
  noteFromBuyer: Maybe<Array<Maybe<ItemNote>>>
  /** The seller's quote line item note. */
  noteFromSeller: Maybe<Array<Maybe<ItemNote>>>
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>
  /** Details about an item in the cart. */
  product: ProductInterface
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output']
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output']
}

/** Defines bundle product options for `CreditMemoItemInterface`. */
export interface BundleCreditMemoItem extends CreditMemoItemInterface {
  __typename?: 'BundleCreditMemoItem'
  /** A list of bundle options that are assigned to a bundle product that is part of a credit memo. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
}

/** Defines bundle product options for `InvoiceItemInterface`. */
export interface BundleInvoiceItem extends InvoiceItemInterface {
  __typename?: 'BundleInvoiceItem'
  /** A list of bundle options that are assigned to an invoiced bundle product. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID']['output']
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
}

/** Defines an individual item within a bundle product. */
export interface BundleItem {
  __typename?: 'BundleItem'
  /**
   * An ID assigned to each type of item in a bundle product.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** An array of additional options for this bundle item. */
  options: Maybe<Array<Maybe<BundleItemOption>>>
  /** A number indicating the sequence order of this item compared to the other bundle items. */
  position: Maybe<Scalars['Int']['output']>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** Indicates whether the item must be included in the bundle. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The SKU of the bundle product. */
  sku: Maybe<Scalars['String']['output']>
  /** The display name of the item. */
  title: Maybe<Scalars['String']['output']>
  /** The input type that the customer uses to select the item. Examples include radio button and checkbox. */
  type: Maybe<Scalars['String']['output']>
  /** The unique ID for a `BundleItem` object. */
  uid: Maybe<Scalars['ID']['output']>
}

/** Defines the characteristics that comprise a specific bundle item and its options. */
export interface BundleItemOption {
  __typename?: 'BundleItemOption'
  /** Indicates whether the customer can change the number of items for this option. */
  canChangeQuantity: Maybe<Scalars['Boolean']['output']>
  /**
   * The ID assigned to the bundled item option.
   * @deprecated Use `uid` instead
   */
  id: Maybe<Scalars['Int']['output']>
  /** Indicates whether this option is the default option. */
  isDefault: Maybe<Scalars['Boolean']['output']>
  /** The text that identifies the bundled item option. */
  label: Maybe<Scalars['String']['output']>
  /** When a bundle item contains multiple options, the relative position of this option compared to the other options. */
  position: Maybe<Scalars['Int']['output']>
  /** The price of the selected option. */
  price: Maybe<Scalars['Float']['output']>
  /** One of FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** Contains details about this product option. */
  product: Maybe<ProductInterface>
  /**
   * Indicates the quantity of this specific bundle item.
   * @deprecated Use `quantity` instead.
   */
  qty: Maybe<Scalars['Float']['output']>
  /** The quantity of this specific bundle item. */
  quantity: Maybe<Scalars['Float']['output']>
  /** The unique ID for a `BundleItemOption` object. */
  uid: Scalars['ID']['output']
}

/** Defines the input for a bundle option. */
export interface BundleOptionInput {
  /** The ID of the option. */
  id: Scalars['Int']['input']
  /** The number of the selected item to add to the cart. */
  quantity: Scalars['Float']['input']
  /** An array with the chosen value of the option. */
  value: Array<InputMaybe<Scalars['String']['input']>>
}

/** Defines bundle product options for `OrderItemInterface`. */
export interface BundleOrderItem extends OrderItemInterface {
  __typename?: 'BundleOrderItem'
  /** A list of bundle options that are assigned to the bundle product. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** Indicates whether the order item is eligible to be in a return request. */
  eligibleForReturn: Maybe<Scalars['Boolean']['output']>
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>
  /** The selected gift wrapping for the order item. */
  giftWrapping: Maybe<GiftWrapping>
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID']['output']
  /** The ProductInterface object, which contains details about the base product */
  product: Maybe<ProductInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']['output']>
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']['output']>
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']['output']>
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']['output']>
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']['output']>
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']['output']>
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The status of the order item. */
  status: Maybe<Scalars['String']['output']>
}

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export interface BundleProduct
  extends CustomizableProductInterface,
    PhysicalProductInterface,
    ProductInterface,
    RoutableInterface {
  __typename?: 'BundleProduct'
  /** @deprecated Use the `custom_attributes` field instead. */
  articleNumber: Maybe<Scalars['String']['output']>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  color: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  costPrice: Maybe<Scalars['String']['output']>
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** Product custom attributes. */
  customAttributesV2: Maybe<ProductCustomAttributes>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>
  /** Indicates whether the bundle product has a dynamic price. */
  dynamicPrice: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the bundle product has a dynamic SKU. */
  dynamicSku: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the bundle product has a dynamically calculated weight. */
  dynamicWeight: Maybe<Scalars['Boolean']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']['output']>
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>
  /** @deprecated Use the `custom_attributes` field instead. */
  isClonedForStore: Maybe<Scalars['String']['output']>
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']['output']>
  /** An array containing information about individual bundle items. */
  items: Maybe<Array<Maybe<BundleItem>>>
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  manufacturerId: Maybe<Scalars['String']['output']>
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['String']['output']>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minimumQuantityAllowed: Maybe<Scalars['String']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  phasedOut: Maybe<Scalars['String']['output']>
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>
  /** The price details of the main product */
  priceDetails: Maybe<PriceDetails>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>
  /** One of PRICE_RANGE or AS_LOW_AS. */
  priceView: Maybe<PriceViewEnum>
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  productgroep: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  quantityIncrements: Maybe<Scalars['String']['output']>
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float']['output']
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** Indicates whether to ship bundle items together or individually. */
  shipBundleItems: Maybe<ShipBundleItemsEnum>
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalCrossection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDegreeofprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalEarthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalIdentificationcores: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalInnerdepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductorcrosssection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductordiameter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalinsulationthickness: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofcores: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofmodules: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofpoles: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofrows: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalOuterdiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalRatedvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalTrippingcharacteristic: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWithattachmentoption: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBundlesperpallet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingCabletype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingDiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingFireretardancy: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorBoxquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorCablediameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorSolarpanelcompatibility: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerConnector: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerPayload: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerRfidcardreader: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerSuitableforoutdoormounting: Maybe<
    Scalars['Int']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWirelessconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemRoofpitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWindstandard: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMaxsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMinsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringCommunicationtype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSeriecompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSignalrange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDaktype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHeightatthetop: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHoogtebovenzijde: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalPaletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperbox: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperpalet: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalRooftype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcdcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAfci: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerCompatiblestorage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcswitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerInvertertype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximuminputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMicropanelpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMinearthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMonitoringoptions: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMppvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerNumberofmpptrackers: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerPaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperbox: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperpalet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerRatedacpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerSolarpanels: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStartupvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStringspermpptracker: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerUsablebatterycapacity: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemBatvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCommunicationport: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxbatcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxoutputpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemOutputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemStoragecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemUsablecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBacksheetcolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBuild: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCelltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFramecolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelGuaranteedpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelJunctionboxprotectiondegree: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelMaxsystemvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelModuleefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelNumberofcells: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletsize: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPanelspercontainer: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPowerguarantee: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelSolarpaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelThickness: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWattagepersquaremeter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWindandsnowload: Maybe<Scalars['String']['output']>
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean']['output']
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  usp: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  voorraadstatus: Maybe<Scalars['String']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>
}

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export interface BundleProductCustomAttributesV2Args {
  filters: InputMaybe<AttributeFilterInput>
}

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export interface BundleProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines a single bundle product. */
export interface BundleProductCartItemInput {
  /** A mandatory array of options for the bundle product, including each chosen option and specified quantity. */
  bundle_options: Array<InputMaybe<BundleOptionInput>>
  /** The ID and value of the option. */
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  /** The quantity and SKU of the bundle product. */
  data: CartItemInput
}

/** Contains details about bundle products added to a requisition list. */
export interface BundleRequisitionListItem
  extends RequisitionListItemInterface {
  __typename?: 'BundleRequisitionListItem'
  /** An array of selected options for a bundle product. */
  bundleOptions: Array<Maybe<SelectedBundleOption>>
  /** Selected custom options for an item in the requisition list. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** Details about a requisition list item. */
  product: ProductInterface
  /** The quantity of the product added to the requisition list. */
  quantity: Scalars['Float']['output']
  /** The unique ID of an item in a requisition list. */
  uid: Scalars['ID']['output']
}

/** Defines bundle product options for `ShipmentItemInterface`. */
export interface BundleShipmentItem extends ShipmentItemInterface {
  __typename?: 'BundleShipmentItem'
  /** A list of bundle options that are assigned to a shipped product. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item associated with the shipment item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of shipped items. */
  quantityShipped: Scalars['Float']['output']
}

/** Defines bundle product options for `WishlistItemInterface`. */
export interface BundleWishlistItem extends WishlistItemInterface {
  __typename?: 'BundleWishlistItem'
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output']
  /** An array containing information about the selected bundle items. */
  bundleOptions: Maybe<Array<Maybe<SelectedBundleOption>>>
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output']
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output']
}

export interface ButtonStyles {
  __typename?: 'ButtonStyles'
  /** The button color */
  color: Maybe<Scalars['String']['output']>
  /** The button height in pixels */
  height: Maybe<Scalars['Int']['output']>
  /** The button label */
  label: Maybe<Scalars['String']['output']>
  /** The button layout */
  layout: Maybe<Scalars['String']['output']>
  /** The button shape */
  shape: Maybe<Scalars['String']['output']>
  /** Indicates whether the tagline is displayed */
  tagline: Maybe<Scalars['Boolean']['output']>
  /** Defines if the button uses default height. If the value is false, the value of height is used */
  useDefaultHeight: Maybe<Scalars['Boolean']['output']>
}

/** Defines the order to cancel. */
export interface CancelOrderInput {
  /** Order ID. */
  order_id: Scalars['ID']['input']
  /** Cancellation reason. */
  reason: Scalars['String']['input']
}

/** Contains the updated customer order and error message if any. */
export interface CancelOrderOutput {
  __typename?: 'CancelOrderOutput'
  /** Error encountered while cancelling the order. */
  error: Maybe<Scalars['String']['output']>
  /** Updated customer order. */
  order: Maybe<CustomerOrder>
}

export interface CancellationReason {
  __typename?: 'CancellationReason'
  description: Scalars['String']['output']
}

export interface Card {
  __typename?: 'Card'
  /** Card bin details */
  binDetails: Maybe<CardBin>
  /** Expiration month of the card */
  cardExpiryMonth: Maybe<Scalars['String']['output']>
  /** Expiration year of the card */
  cardExpiryYear: Maybe<Scalars['String']['output']>
  /** Last four digits of the card */
  lastDigits: Maybe<Scalars['String']['output']>
  /** Name on the card */
  name: Maybe<Scalars['String']['output']>
}

export interface CardBin {
  __typename?: 'CardBin'
  /** Card bin number */
  bin: Maybe<Scalars['String']['output']>
}

/** Contains the contents and other details about a guest or customer cart. */
export interface Cart {
  __typename?: 'Cart'
  /** @deprecated Use `applied_coupons` instead. */
  appliedCoupon: Maybe<AppliedCoupon>
  /** An array of `AppliedCoupon` objects. Each object contains the `code` text attribute, which specifies the coupon code. */
  appliedCoupons: Maybe<Array<Maybe<AppliedCoupon>>>
  /** An array of gift card items applied to the cart. */
  appliedGiftCards: Maybe<Array<Maybe<AppliedGiftCard>>>
  /** The amount of reward points applied to the cart. */
  appliedRewardPoints: Maybe<RewardPointsAmount>
  /** Store credit information applied to the cart. */
  appliedStoreCredit: Maybe<AppliedStoreCredit>
  /** The list of available gift wrapping options for the cart. */
  availableGiftWrappings: Array<Maybe<GiftWrapping>>
  /** An array of available payment methods. */
  availablePaymentMethods: Maybe<Array<Maybe<AvailablePaymentMethod>>>
  /** Backorder data of the cart (Y-m-d) */
  backorderDate: Maybe<Scalars['String']['output']>
  /** The billing address assigned to the cart. */
  billingAddress: Maybe<BillingCartAddress>
  /** Delivery date of the cart (Y-m-d) */
  deliveryDate: Maybe<Scalars['String']['output']>
  /** Delivery time of the cart (hh:mm) */
  deliveryTime: Maybe<Scalars['String']['output']>
  /** Delivery type of the cart  */
  deliveryType: Maybe<DeliveryType>
  /** The email address of the guest or customer. */
  email: Maybe<Scalars['String']['output']>
  /** Forklift available on location */
  forkliftAvailable: Maybe<Scalars['Boolean']['output']>
  /** The entered gift message for the cart */
  giftMessage: Maybe<GiftMessage>
  /** Indicates whether the shopper requested gift receipt for the cart. */
  giftReceiptIncluded: Scalars['Boolean']['output']
  /** The selected gift wrapping for the cart. */
  giftWrapping: Maybe<GiftWrapping>
  /** The unique ID for a `Cart` object. */
  id: Scalars['ID']['output']
  /** Indicates whether the cart contains only virtual products. */
  isVirtual: Scalars['Boolean']['output']
  /**
   * An array of products that have been added to the cart.
   * @deprecated Use `itemsV2` instead.
   */
  items: Maybe<Array<Maybe<CartItemInterface>>>
  itemsV2: Maybe<CartItems>
  /** Available issuers for the selected payment method */
  mollieAvailableIssuers: Maybe<Array<Maybe<MollieIssuer>>>
  /** Pricing details for the quote. */
  prices: Maybe<CartPrices>
  /** Indicates whether the shopper requested a printed card for the cart. */
  printedCardIncluded: Scalars['Boolean']['output']
  /** Order reference of the cart */
  reference: Maybe<Scalars['String']['output']>
  /** Indicates which payment method was applied to the cart. */
  selectedPaymentMethod: Maybe<SelectedPaymentMethod>
  /** An array of shipping addresses assigned to the cart. */
  shippingAddresses: Array<Maybe<ShippingCartAddress>>
  /** The total number of items in the cart. */
  totalQuantity: Scalars['Float']['output']
}

/** Contains the contents and other details about a guest or customer cart. */
export interface CartItemsV2Args {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort: InputMaybe<QuoteItemsSortInput>
}

/** Contains details the country in a billing or shipping address. */
export interface CartAddressCountry {
  __typename?: 'CartAddressCountry'
  /** The country code. */
  code: Scalars['String']['output']
  /** The display label for the country. */
  label: Scalars['String']['output']
}

/** Defines the billing or shipping address to be applied to the cart. */
export interface CartAddressInput {
  /** The city specified for the billing or shipping address. */
  city: Scalars['String']['input']
  /** The company specified for the billing or shipping address. */
  company?: InputMaybe<Scalars['String']['input']>
  /** The country code and label for the billing or shipping address. */
  country_code: Scalars['String']['input']
  /** The custom attribute values of the billing or shipping address. */
  custom_attributes?: InputMaybe<Array<InputMaybe<AttributeValueInput>>>
  /** The customer's fax number. */
  fax?: InputMaybe<Scalars['String']['input']>
  /** The first name of the customer or guest. */
  firstname: Scalars['String']['input']
  /** The last name of the customer or guest. */
  lastname: Scalars['String']['input']
  /** The middle name of the person associated with the billing/shipping address. */
  middlename?: InputMaybe<Scalars['String']['input']>
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: InputMaybe<Scalars['String']['input']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>
  /** A string that defines the state or province of the billing or shipping address. */
  region?: InputMaybe<Scalars['String']['input']>
  /** An integer that defines the state or province of the billing or shipping address. */
  region_id?: InputMaybe<Scalars['Int']['input']>
  /** Determines whether to save the address in the customer's address book. The default value is true. */
  save_in_address_book?: InputMaybe<Scalars['Boolean']['input']>
  /** An array containing the street for the billing or shipping address. */
  street: Array<InputMaybe<Scalars['String']['input']>>
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']['input']>
  /** The telephone number for the billing or shipping address. */
  telephone?: InputMaybe<Scalars['String']['input']>
  /** The VAT company number for billing or shipping address. */
  vat_id?: InputMaybe<Scalars['String']['input']>
}

export interface CartAddressInterface {
  /** The city specified for the billing or shipping address. */
  city: Scalars['String']['output']
  /** The company specified for the billing or shipping address. */
  company: Maybe<Scalars['String']['output']>
  /** An object containing the country label and code. */
  country: CartAddressCountry
  /** The custom attribute values of the billing or shipping address. */
  customAttributes: Array<Maybe<AttributeValueInterface>>
  /** The customer's fax number. */
  fax: Maybe<Scalars['String']['output']>
  /** The first name of the customer or guest. */
  firstname: Scalars['String']['output']
  /** The last name of the customer or guest. */
  lastname: Scalars['String']['output']
  /** The middle name of the person associated with the billing/shipping address. */
  middlename: Maybe<Scalars['String']['output']>
  /** The ZIP or postal code of the billing or shipping address. */
  postcode: Maybe<Scalars['String']['output']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']['output']>
  /** An object containing the region label and code. */
  region: Maybe<CartAddressRegion>
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']['output']>>
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']['output']>
  /** The telephone number for the billing or shipping address. */
  telephone: Maybe<Scalars['String']['output']>
  /** The unique id of the customer address. */
  uid: Scalars['String']['output']
  /** The VAT company number for billing or shipping address. */
  vatId: Maybe<Scalars['String']['output']>
}

/** Contains details about the region in a billing or shipping address. */
export interface CartAddressRegion {
  __typename?: 'CartAddressRegion'
  /** The state or province code. */
  code: Maybe<Scalars['String']['output']>
  /** The display label for the region. */
  label: Maybe<Scalars['String']['output']>
  /** The unique ID for a pre-defined region. */
  regionId: Maybe<Scalars['Int']['output']>
}

/** Contains information about discounts applied to the cart. */
export interface CartDiscount {
  __typename?: 'CartDiscount'
  /** The amount of the discount applied to the item. */
  amount: Money
  /** The description of the discount. */
  label: Array<Maybe<Scalars['String']['output']>>
}

export enum CartDiscountType {
  ITEM = 'ITEM',
  SHIPPING = 'SHIPPING',
}

export interface CartItemError {
  __typename?: 'CartItemError'
  /** An error code that describes the error encountered */
  code: CartItemErrorType
  /** A localized error message */
  message: Scalars['String']['output']
}

export enum CartItemErrorType {
  ITEM_INCREMENTS = 'ITEM_INCREMENTS',
  ITEM_QTY = 'ITEM_QTY',
  UNDEFINED = 'UNDEFINED',
}

/** Defines an item to be added to the cart. */
export interface CartItemInput {
  /** An array of entered options for the base product, such as personalization text. */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>
  /** For a child product, the SKU of its parent product. */
  parent_sku?: InputMaybe<Scalars['String']['input']>
  /** The amount or number of an item to add. */
  quantity: Scalars['Float']['input']
  /** The selected options for the base product, such as color or size, using the unique ID for an object such as `CustomizableRadioOption`, `CustomizableDropDownOption`, or `ConfigurableProductOptionsValues`. */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The SKU of the product. */
  sku: Scalars['String']['input']
}

/** An implementation for elektramat configurator cart items. */
export interface CartItemInterface {
  /** Delivery date of the cart item (Y-m-d) */
  deliveryDate: Maybe<Scalars['String']['output']>
  /** Contains discount for quote line item. */
  discount: Maybe<Array<Maybe<Discount>>>
  /** Elektramat configuration packslip */
  elektramatPackslip: Maybe<Scalars['String']['output']>
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output']
  /** True if requested quantity is less than available stock, false otherwise. */
  isAvailable: Scalars['Boolean']['output']
  /** The buyer's quote line item note. */
  noteFromBuyer: Maybe<Array<Maybe<ItemNote>>>
  /** The seller's quote line item note. */
  noteFromSeller: Maybe<Array<Maybe<ItemNote>>>
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>
  /** Details about an item in the cart. */
  product: ProductInterface
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output']
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output']
}

/** Contains details about the price of the item, including taxes and discounts. */
export interface CartItemPrices {
  __typename?: 'CartItemPrices'
  /** An array of discounts to be applied to the cart item. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** An array of FPTs applied to the cart item. */
  fixedProductTaxes: Maybe<Array<Maybe<FixedProductTax>>>
  /** The price of the item before any discounts were applied. The price that might include tax, depending on the configured display settings for cart. */
  price: Money
  /** The price of the item before any discounts were applied. The price that might include tax, depending on the configured display settings for cart. */
  priceIncludingTax: Money
  /** The value of the price multiplied by the quantity of the item. */
  rowTotal: Money
  /** The value of `row_total` plus the tax applied to the item. */
  rowTotalIncludingTax: Money
  /** The total of all discounts applied to the item. */
  totalItemDiscount: Maybe<Money>
}

/** Deprecated: The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
export interface CartItemQuantity {
  __typename?: 'CartItemQuantity'
  /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
  cartItemId: Scalars['Int']['output']
  /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
  quantity: Scalars['Float']['output']
}

/** Contains details about the price of a selected customizable value. */
export interface CartItemSelectedOptionValuePrice {
  __typename?: 'CartItemSelectedOptionValuePrice'
  /** Indicates whether the price type is fixed, percent, or dynamic. */
  type: PriceTypeEnum
  /** A string that describes the unit of the value. */
  units: Scalars['String']['output']
  /** A price value. */
  value: Scalars['Float']['output']
}

/** A single item to be updated. */
export interface CartItemUpdateInput {
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: InputMaybe<Scalars['Int']['input']>
  /** The unique ID for a `CartItemInterface` object. */
  cart_item_uid?: InputMaybe<Scalars['ID']['input']>
  /** An array that defines customizable options for the product. */
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  /** Gift message details for the cart item */
  gift_message?: InputMaybe<GiftMessageInput>
  /** The unique ID for a `GiftWrapping` object to be used for the cart item. */
  gift_wrapping_id?: InputMaybe<Scalars['ID']['input']>
  /** The new quantity of the item. */
  quantity?: InputMaybe<Scalars['Float']['input']>
}

export interface CartItems {
  __typename?: 'CartItems'
  /** An array of products that have been added to the cart. */
  items: Array<Maybe<CartItemInterface>>
  /** Metadata for pagination rendering. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The number of returned cart items. */
  totalCount: Scalars['Int']['output']
}

export interface CartOrderDateResult {
  __typename?: 'CartOrderDateResult'
  /** Date of the delivery day (Y-m-d) */
  firstDeliveryDate: Maybe<Scalars['String']['output']>
}

/** Contains details about the final price of items in the cart, including discount and tax information. */
export interface CartPrices {
  __typename?: 'CartPrices'
  /** An array containing the names and amounts of taxes applied to each item in the cart. */
  appliedTaxes: Maybe<Array<Maybe<CartTaxItem>>>
  /** Battery fee */
  batteryFee: Maybe<Money>
  /** Timeslot delivery costs */
  deliveryFee: Maybe<Money>
  /** @deprecated Use discounts instead. */
  discount: Maybe<CartDiscount>
  /** An array containing cart rule discounts, store credit and gift cards applied to the cart. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The list of prices for the selected gift options. */
  giftOptions: Maybe<GiftOptionsPrices>
  /** The total, including discounts, taxes, shipping, and other fees. */
  grandTotal: Maybe<Money>
  molliePaymentFee: Maybe<MolliePaymentFee>
  /** Order processing fee */
  orderProcessingFee: Maybe<Money>
  /** Delivery cost for partial delivery */
  partialDeliveryFee: Maybe<Money>
  /** The subtotal without any applied taxes. */
  subtotalExcludingTax: Maybe<Money>
  /** The subtotal including any applied taxes. */
  subtotalIncludingTax: Maybe<Money>
  /** The subtotal with any discounts applied, but not taxes. */
  subtotalWithDiscountExcludingTax: Maybe<Money>
}

/** Contains tax information about an item in the cart. */
export interface CartTaxItem {
  __typename?: 'CartTaxItem'
  /** The amount of tax applied to the item. */
  amount: Money
  /** The description of the tax. */
  label: Scalars['String']['output']
}

/** An error encountered while adding an item to the the cart. */
export interface CartUserInputError {
  __typename?: 'CartUserInputError'
  /** A cart-specific error code. */
  code: CartUserInputErrorType
  /** A localized error message. */
  message: Scalars['String']['output']
}

export enum CartUserInputErrorType {
  INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
  NOT_SALABLE = 'NOT_SALABLE',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  UNDEFINED = 'UNDEFINED',
}

export enum CatalogAttributeApplyToEnum {
  BUNDLE = 'BUNDLE',
  CATEGORY = 'CATEGORY',
  CONFIGURABLE = 'CONFIGURABLE',
  DOWNLOADABLE = 'DOWNLOADABLE',
  GROUPED = 'GROUPED',
  SIMPLE = 'SIMPLE',
  VIRTUAL = 'VIRTUAL',
}

/** Swatch attribute metadata. */
export interface CatalogAttributeMetadata
  extends CustomAttributeMetadataInterface {
  __typename?: 'CatalogAttributeMetadata'
  /** To which catalog types an attribute can be applied. */
  applyTo: Maybe<Array<Maybe<CatalogAttributeApplyToEnum>>>
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  code: Scalars['ID']['output']
  /** Default attribute value. */
  defaultValue: Maybe<Scalars['String']['output']>
  /** The type of entity that defines the attribute. */
  entityType: AttributeEntityTypeEnum
  /** The frontend class of the attribute. */
  frontendClass: Maybe<Scalars['String']['output']>
  /** The frontend input type of the attribute. */
  frontendInput: Maybe<AttributeFrontendInputEnum>
  /** Whether a product or category attribute can be compared against another or not. */
  isComparable: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute can be filtered or not. */
  isFilterable: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute can be filtered in search or not. */
  isFilterableInSearch: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute can use HTML on front or not. */
  isHtmlAllowedOnFront: Maybe<Scalars['Boolean']['output']>
  /** Whether the attribute value is required. */
  isRequired: Scalars['Boolean']['output']
  /** Whether a product or category attribute can be searched or not. */
  isSearchable: Maybe<Scalars['Boolean']['output']>
  /** Whether the attribute value must be unique. */
  isUnique: Scalars['Boolean']['output']
  /** Whether a product or category attribute can be used for price rules or not. */
  isUsedForPriceRules: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute is used for promo rules or not. */
  isUsedForPromoRules: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute is visible in advanced search or not. */
  isVisibleInAdvancedSearch: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute is visible on front or not. */
  isVisibleOnFront: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute has WYSIWYG enabled or not. */
  isWysiwygEnabled: Maybe<Scalars['Boolean']['output']>
  /** The label assigned to the attribute. */
  label: Maybe<Scalars['String']['output']>
  /** Attribute options. */
  options: Array<Maybe<CustomAttributeOptionInterface>>
  /** Input type of the swatch attribute option. */
  swatchInputType: Maybe<SwatchInputTypeEnum>
  /** Whether update product preview image or not. */
  updateProductPreviewImage: Maybe<Scalars['Boolean']['output']>
  /** Whether use product image for swatch or not. */
  useProductImageForSwatch: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute is used in product listing or not. */
  usedInProductListing: Maybe<Scalars['Boolean']['output']>
}

/** Defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export interface CategoryFilterInput {
  /** Filter by the unique category ID for a `CategoryInterface` object. */
  category_uid?: InputMaybe<FilterEqualTypeInput>
  /** Deprecated: use 'category_uid' to filter uniquely identifiers of categories. */
  ids?: InputMaybe<FilterEqualTypeInput>
  /** Filter by the display name of the category. */
  name?: InputMaybe<FilterMatchTypeInput>
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_category_uid?: InputMaybe<FilterEqualTypeInput>
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_id?: InputMaybe<FilterEqualTypeInput>
  /** Filter by the part of the URL that identifies the category. */
  url_key?: InputMaybe<FilterEqualTypeInput>
  /** Filter by the URL path for the category. */
  url_path?: InputMaybe<FilterEqualTypeInput>
}

/** Contains the full set of attributes that can be returned in a category search. */
export interface CategoryInterface {
  automaticSorting: Maybe<Scalars['String']['output']>
  availableSortBy: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** An array of breadcrumb items. */
  breadcrumbs: Maybe<Array<Maybe<Breadcrumb>>>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  childrenCount: Maybe<Scalars['String']['output']>
  /** Contains a category CMS block. */
  cmsBlock: Maybe<CmsBlock>
  /**
   * The timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  customLayoutUpdateFile: Maybe<Scalars['String']['output']>
  /** The attribute to use for sorting. */
  defaultSortBy: Maybe<Scalars['String']['output']>
  /** An optional description of the category. */
  description: Maybe<Scalars['String']['output']>
  descriptionTop: Maybe<Scalars['String']['output']>
  displayMode: Maybe<Scalars['String']['output']>
  filterPriceRange: Maybe<Scalars['Float']['output']>
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use `uid` instead.
   */
  id: Maybe<Scalars['Int']['output']>
  image: Maybe<Scalars['String']['output']>
  includeInMenu: Maybe<Scalars['Int']['output']>
  isAnchor: Maybe<Scalars['Int']['output']>
  landingPage: Maybe<Scalars['Int']['output']>
  /** The depth of the category within the tree. */
  level: Maybe<Scalars['Int']['output']>
  metaDescription: Maybe<Scalars['String']['output']>
  metaKeywords: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** The display name of the category. */
  name: Maybe<Scalars['String']['output']>
  /** The full category path. */
  path: Maybe<Scalars['String']['output']>
  /** The category path within the store. */
  pathInStore: Maybe<Scalars['String']['output']>
  pimcoreId: Maybe<Scalars['String']['output']>
  /** The position of the category relative to other categories at the same level in tree. */
  position: Maybe<Scalars['Int']['output']>
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  productCount: Maybe<Scalars['Int']['output']>
  /** The list of products assigned to the category. */
  products: Maybe<CategoryProducts>
  /** Indicates whether the category is staged for a future campaign. */
  staged: Scalars['Boolean']['output']
  tweakwiseCrosssellGroupCode: Maybe<Scalars['String']['output']>
  tweakwiseCrosssellTemplate: Maybe<Scalars['Int']['output']>
  tweakwiseFeaturedTemplate: Maybe<Scalars['Int']['output']>
  tweakwiseFilterValuesWhitelist: Maybe<Scalars['String']['output']>
  tweakwiseFilterWhitelist: Maybe<Scalars['String']['output']>
  tweakwiseShoppingcartCrosssellGroupCode: Maybe<Scalars['String']['output']>
  tweakwiseShoppingcartCrosssellTemplate: Maybe<Scalars['Int']['output']>
  tweakwiseUpsellGroupCode: Maybe<Scalars['String']['output']>
  tweakwiseUpsellTemplate: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * The timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** The URL key assigned to the category. */
  urlKey: Maybe<Scalars['String']['output']>
  /** The URL path assigned to the category. */
  urlPath: Maybe<Scalars['String']['output']>
  /** The part of the category URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
}

/** Contains the full set of attributes that can be returned in a category search. */
export interface CategoryInterfaceProductsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort: InputMaybe<ProductAttributeSortInput>
}

/** Contains details about the products assigned to a category. */
export interface CategoryProducts {
  __typename?: 'CategoryProducts'
  /** An array of products that are assigned to the category. */
  items: Maybe<Array<Maybe<ProductInterface>>>
  /** Pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Contains a collection of `CategoryTree` objects and pagination information. */
export interface CategoryResult {
  __typename?: 'CategoryResult'
  /** A list of categories that match the filter criteria. */
  items: Maybe<Array<Maybe<CategoryTree>>>
  /** An object that includes the `page_info` and `currentPage` values specified in the query. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The total number of categories that match the criteria. */
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Contains the hierarchy of categories. */
export interface CategoryTree extends CategoryInterface, RoutableInterface {
  __typename?: 'CategoryTree'
  automaticSorting: Maybe<Scalars['String']['output']>
  availableSortBy: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** An array of breadcrumb items. */
  breadcrumbs: Maybe<Array<Maybe<Breadcrumb>>>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** A tree of child categories. */
  children: Maybe<Array<Maybe<CategoryTree>>>
  childrenCount: Maybe<Scalars['String']['output']>
  /** Contains a category CMS block. */
  cmsBlock: Maybe<CmsBlock>
  /**
   * The timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  customLayoutUpdateFile: Maybe<Scalars['String']['output']>
  /** The attribute to use for sorting. */
  defaultSortBy: Maybe<Scalars['String']['output']>
  /** An optional description of the category. */
  description: Maybe<Scalars['String']['output']>
  descriptionTop: Maybe<Scalars['String']['output']>
  displayMode: Maybe<Scalars['String']['output']>
  filterPriceRange: Maybe<Scalars['Float']['output']>
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use `uid` instead.
   */
  id: Maybe<Scalars['Int']['output']>
  image: Maybe<Scalars['String']['output']>
  includeInMenu: Maybe<Scalars['Int']['output']>
  isAnchor: Maybe<Scalars['Int']['output']>
  landingPage: Maybe<Scalars['Int']['output']>
  /** The depth of the category within the tree. */
  level: Maybe<Scalars['Int']['output']>
  metaDescription: Maybe<Scalars['String']['output']>
  metaKeywords: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** The display name of the category. */
  name: Maybe<Scalars['String']['output']>
  /** The full category path. */
  path: Maybe<Scalars['String']['output']>
  /** The category path within the store. */
  pathInStore: Maybe<Scalars['String']['output']>
  pimcoreId: Maybe<Scalars['String']['output']>
  /** The position of the category relative to other categories at the same level in tree. */
  position: Maybe<Scalars['Int']['output']>
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  productCount: Maybe<Scalars['Int']['output']>
  /** The list of products assigned to the category. */
  products: Maybe<CategoryProducts>
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** Indicates whether the category is staged for a future campaign. */
  staged: Scalars['Boolean']['output']
  tweakwiseCrosssellGroupCode: Maybe<Scalars['String']['output']>
  tweakwiseCrosssellTemplate: Maybe<Scalars['Int']['output']>
  tweakwiseFeaturedTemplate: Maybe<Scalars['Int']['output']>
  tweakwiseFilterValuesWhitelist: Maybe<Scalars['String']['output']>
  tweakwiseFilterWhitelist: Maybe<Scalars['String']['output']>
  tweakwiseShoppingcartCrosssellGroupCode: Maybe<Scalars['String']['output']>
  tweakwiseShoppingcartCrosssellTemplate: Maybe<Scalars['Int']['output']>
  tweakwiseUpsellGroupCode: Maybe<Scalars['String']['output']>
  tweakwiseUpsellTemplate: Maybe<Scalars['Int']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * The timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** The URL key assigned to the category. */
  urlKey: Maybe<Scalars['String']['output']>
  /** The URL path assigned to the category. */
  urlPath: Maybe<Scalars['String']['output']>
  /** The part of the category URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
}

/** Contains the hierarchy of categories. */
export interface CategoryTreeProductsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort: InputMaybe<ProductAttributeSortInput>
}

/** Defines details about an individual checkout agreement. */
export interface CheckoutAgreement {
  __typename?: 'CheckoutAgreement'
  /** The ID for a checkout agreement. */
  agreementId: Scalars['Int']['output']
  /** The checkbox text for the checkout agreement. */
  checkboxText: Scalars['String']['output']
  /** Required. The text of the agreement. */
  content: Scalars['String']['output']
  /** The height of the text box where the Terms and Conditions statement appears during checkout. */
  contentHeight: Maybe<Scalars['String']['output']>
  /** Indicates whether the `content` text is in HTML format. */
  isHtml: Scalars['Boolean']['output']
  /** Indicates whether agreements are accepted automatically or manually. */
  mode: CheckoutAgreementMode
  /** The name given to the condition. */
  name: Scalars['String']['output']
}

/** Indicates how agreements are accepted. */
export enum CheckoutAgreementMode {
  /** Conditions are automatically accepted upon checkout. */
  AUTO = 'AUTO',
  /** Shoppers must manually accept the conditions to place an order. */
  MANUAL = 'MANUAL',
}

/** An error encountered while adding an item to the cart. */
export interface CheckoutUserInputError {
  __typename?: 'CheckoutUserInputError'
  /** An error code that is specific to Checkout. */
  code: CheckoutUserInputErrorCodes
  /** A localized error message. */
  message: Scalars['String']['output']
  /** The path to the input field that caused an error. See the GraphQL specification about path errors for details: http://spec.graphql.org/draft/#sec-Errors */
  path: Array<Maybe<Scalars['String']['output']>>
}

export enum CheckoutUserInputErrorCodes {
  INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
  NOT_SALABLE = 'NOT_SALABLE',
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  REORDER_NOT_AVAILABLE = 'REORDER_NOT_AVAILABLE',
  UNDEFINED = 'UNDEFINED',
}

/** Contains details about errors encountered when a customer clear cart. */
export interface ClearCartError {
  __typename?: 'ClearCartError'
  /** A localized error message */
  message: Scalars['String']['output']
  /** A cart-specific error type. */
  type: ClearCartErrorType
}

export enum ClearCartErrorType {
  INACTIVE = 'INACTIVE',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORISED = 'UNAUTHORISED',
  UNDEFINED = 'UNDEFINED',
}

/** Assigns a specific `cart_id` to the empty cart. */
export interface ClearCartInput {
  /** The unique ID of a `Cart` object. */
  uid: Scalars['ID']['input']
}

/** Output of the request to clear the customer cart. */
export interface ClearCartOutput {
  __typename?: 'ClearCartOutput'
  /** The cart after clear cart items. */
  cart: Maybe<Cart>
  /** An array of errors encountered while clearing the cart item */
  errors: Maybe<Array<Maybe<ClearCartError>>>
}

/** Output of the request to clear the customer cart. */
export interface ClearCustomerCartOutput {
  __typename?: 'ClearCustomerCartOutput'
  /** The cart after clearing items. */
  cart: Maybe<Cart>
  /** Indicates whether cart was cleared. */
  status: Scalars['Boolean']['output']
}

export type CloseNegotiableQuoteError =
  | InternalError
  | NegotiableQuoteInvalidStateError
  | NoSuchEntityUidError

/** Contains details about a failed close operation on a negotiable quote. */
export interface CloseNegotiableQuoteOperationFailure {
  __typename?: 'CloseNegotiableQuoteOperationFailure'
  /** An array of errors encountered while attempting close the negotiable quote. */
  errors: Array<Maybe<CloseNegotiableQuoteError>>
  /** The unique ID of a `NegotiableQuote` object. */
  quoteUid: Scalars['ID']['output']
}

export type CloseNegotiableQuoteOperationResult =
  | CloseNegotiableQuoteOperationFailure
  | NegotiableQuoteUidOperationSuccess

/** Defines the negotiable quotes to mark as closed. */
export interface CloseNegotiableQuotesInput {
  /** A list of unique IDs from `NegotiableQuote` objects. */
  quote_uids: Array<InputMaybe<Scalars['ID']['input']>>
}

/** Contains the closed negotiable quotes and other negotiable quotes the company user can view. */
export interface CloseNegotiableQuotesOutput {
  __typename?: 'CloseNegotiableQuotesOutput'
  /**
   * An array containing the negotiable quotes that were just closed.
   * @deprecated Use `operation_results` instead.
   */
  closedQuotes: Maybe<Array<Maybe<NegotiableQuote>>>
  /** A list of negotiable quotes that can be viewed by the logged-in customer */
  negotiableQuotes: Maybe<NegotiableQuotesOutput>
  /** An array of closed negotiable quote UIDs and details about any errors. */
  operationResults: Array<Maybe<CloseNegotiableQuoteOperationResult>>
  /** The status of the request to close one or more negotiable quotes. */
  resultStatus: BatchMutationStatus
}

/** Contains the closed negotiable quotes and other negotiable quotes the company user can view. */
export interface CloseNegotiableQuotesOutputNegotiableQuotesArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter: InputMaybe<NegotiableQuoteFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort: InputMaybe<NegotiableQuoteSortInput>
}

/** Contains details about a specific CMS block. */
export interface CmsBlock {
  __typename?: 'CmsBlock'
  /** The content of the CMS block in raw HTML. */
  content: Maybe<Scalars['String']['output']>
  /** The CMS block identifier. */
  identifier: Maybe<Scalars['String']['output']>
  /** The title assigned to the CMS block. */
  title: Maybe<Scalars['String']['output']>
}

/** Contains an array CMS block items. */
export interface CmsBlocks {
  __typename?: 'CmsBlocks'
  /** An array of CMS blocks. */
  items: Maybe<Array<Maybe<CmsBlock>>>
}

/** Contains details about a CMS page. */
export interface CmsPage extends RoutableInterface {
  __typename?: 'CmsPage'
  /** The content of the CMS page in raw HTML. */
  content: Maybe<Scalars['String']['output']>
  /** The heading that displays at the top of the CMS page. */
  contentHeading: Maybe<Scalars['String']['output']>
  /** The ID of a CMS page. */
  identifier: Maybe<Scalars['String']['output']>
  /** A brief description of the page for search results listings. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A brief description of the page for search results listings. */
  metaKeywords: Maybe<Scalars['String']['output']>
  /** A page title that is indexed by search engines and appears in search results listings. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** The design layout of the page, indicating the number of columns and navigation features used on the page. */
  pageLayout: Maybe<Scalars['String']['output']>
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** The name that appears in the breadcrumb trail navigation and in the browser title bar and tab. */
  title: Maybe<Scalars['String']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /** The URL key of the CMS page, which is often based on the `content_heading`. */
  urlKey: Maybe<Scalars['String']['output']>
}

export interface ColorSwatchData extends SwatchDataInterface {
  __typename?: 'ColorSwatchData'
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']['output']>
}

/** Contains the output schema for a company. */
export interface Company {
  __typename?: 'Company'
  /** The list of all resources defined within the company. */
  aclResources: Maybe<Array<Maybe<CompanyAclResource>>>
  /** An object containing information about the company administrator. */
  companyAdmin: Maybe<Customer>
  /** Company credit balances and limits. */
  credit: CompanyCredit
  /** Details about the history of company credit operations. */
  creditHistory: CompanyCreditHistory
  /** The email address of the company contact. */
  email: Maybe<Scalars['String']['output']>
  /** The unique ID of a `Company` object. */
  id: Scalars['ID']['output']
  /** The address where the company is registered to conduct business. */
  legalAddress: Maybe<CompanyLegalAddress>
  /** The full legal name of the company. */
  legalName: Maybe<Scalars['String']['output']>
  /** The name of the company. */
  name: Maybe<Scalars['String']['output']>
  /** The list of payment methods available to a company. */
  paymentMethods: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The resale number that is assigned to the company for tax reporting purposes. */
  resellerId: Maybe<Scalars['String']['output']>
  /** A company role filtered by the unique ID of a `CompanyRole` object. */
  role: Maybe<CompanyRole>
  /** An object that contains a list of company roles. */
  roles: CompanyRoles
  /** An object containing information about the company sales representative. */
  salesRepresentative: Maybe<CompanySalesRepresentative>
  /** The company structure of teams and customers in depth-first order. */
  structure: Maybe<CompanyStructure>
  /** The company team data filtered by the unique ID for a `CompanyTeam` object. */
  team: Maybe<CompanyTeam>
  /** A company user filtered by the unique ID of a `Customer` object. */
  user: Maybe<Customer>
  /** An object that contains a list of company users based on activity status. */
  users: Maybe<CompanyUsers>
  /** The value-added tax number that is assigned to the company by some jurisdictions for tax reporting purposes. */
  vatTaxId: Maybe<Scalars['String']['output']>
}

/** Contains the output schema for a company. */
export interface CompanyCreditHistoryArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter: InputMaybe<CompanyCreditHistoryFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Contains the output schema for a company. */
export interface CompanyRoleArgs {
  id: Scalars['ID']['input']
}

/** Contains the output schema for a company. */
export interface CompanyRolesArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Contains the output schema for a company. */
export interface CompanyStructureArgs {
  depth?: InputMaybe<Scalars['Int']['input']>
  rootId: InputMaybe<Scalars['ID']['input']>
}

/** Contains the output schema for a company. */
export interface CompanyTeamArgs {
  id: Scalars['ID']['input']
}

/** Contains the output schema for a company. */
export interface CompanyUserArgs {
  id: Scalars['ID']['input']
}

/** Contains the output schema for a company. */
export interface CompanyUsersArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter: InputMaybe<CompanyUsersFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Contains details about the access control list settings of a resource. */
export interface CompanyAclResource {
  __typename?: 'CompanyAclResource'
  /** An array of sub-resources. */
  children: Maybe<Array<Maybe<CompanyAclResource>>>
  /** The unique ID for a `CompanyAclResource` object. */
  id: Scalars['ID']['output']
  /** The sort order of an ACL resource. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The label assigned to the ACL resource. */
  text: Maybe<Scalars['String']['output']>
}

/** Contains details about the company administrator. */
export interface CompanyAdmin {
  __typename?: 'CompanyAdmin'
  /** The email address of the company administrator. */
  email: Maybe<Scalars['String']['output']>
  /** The company administrator's first name. */
  firstname: Maybe<Scalars['String']['output']>
  /** The company administrator's gender (Male - 1, Female - 2, Not Specified - 3). */
  gender: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `CompanyAdmin` object. */
  id: Scalars['ID']['output']
  /** The job title of the company administrator. */
  jobTitle: Maybe<Scalars['String']['output']>
  /** The company administrator's last name. */
  lastname: Maybe<Scalars['String']['output']>
}

/** Defines the input schema for creating a company administrator. */
export interface CompanyAdminInput {
  /** The email address of the company administrator. */
  email: Scalars['String']['input']
  /** The company administrator's first name. */
  firstname: Scalars['String']['input']
  /** The company administrator's gender (Male - 1, Female - 2, Not Specified - 3). */
  gender?: InputMaybe<Scalars['Int']['input']>
  /** The job title of the company administrator. */
  job_title?: InputMaybe<Scalars['String']['input']>
  /** The company administrator's last name. */
  lastname: Scalars['String']['input']
}

/** Defines the input schema for creating a new company. */
export interface CompanyCreateInput {
  /** Defines the company administrator. */
  company_admin: CompanyAdminInput
  /** The email address of the company contact. */
  company_email: Scalars['String']['input']
  /** The name of the company to create. */
  company_name: Scalars['String']['input']
  /** Defines legal address data of the company. */
  legal_address: CompanyLegalAddressCreateInput
  /** The full legal name of the company. */
  legal_name?: InputMaybe<Scalars['String']['input']>
  /** The resale number that is assigned to the company for tax reporting purposes. */
  reseller_id?: InputMaybe<Scalars['String']['input']>
  /** The value-added tax number that is assigned to the company by some jurisdictions for tax reporting purposes. */
  vat_tax_id?: InputMaybe<Scalars['String']['input']>
}

/** Contains company credit balances and limits. */
export interface CompanyCredit {
  __typename?: 'CompanyCredit'
  /** The sum of the credit limit and the outstanding balance. If the company has exceeded the credit limit, the amount is as a negative value. */
  availableCredit: Money
  /** The amount of credit extended to the company. */
  creditLimit: Money
  /** The amount reimbursed, less the total due from all orders placed using the Payment on Account payment method. The amount can be a positive or negative value. */
  outstandingBalance: Money
}

/** Contains details about prior company credit operations. */
export interface CompanyCreditHistory {
  __typename?: 'CompanyCreditHistory'
  /** An array of company credit operations. */
  items: Array<Maybe<CompanyCreditOperation>>
  /** Metadata for pagination rendering. */
  pageInfo: SearchResultPageInfo
  /** The number of the company credit operations matching the specified filter. */
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Defines a filter for narrowing the results of a credit history search. */
export interface CompanyCreditHistoryFilterInput {
  /** The purchase order number associated with the company credit operation. */
  custom_reference_number?: InputMaybe<Scalars['String']['input']>
  /** The type of the company credit operation. */
  operation_type?: InputMaybe<CompanyCreditOperationType>
  /** The name of the person submitting the company credit operation. */
  updated_by?: InputMaybe<Scalars['String']['input']>
}

/** Contains details about a single company credit operation. */
export interface CompanyCreditOperation {
  __typename?: 'CompanyCreditOperation'
  /** The amount of the company credit operation. */
  amount: Maybe<Money>
  /** The credit balance as a result of the operation. */
  balance: CompanyCredit
  /** The purchase order number associated with the company credit operation. */
  customReferenceNumber: Maybe<Scalars['String']['output']>
  /** The date the operation occurred. */
  date: Scalars['String']['output']
  /** The type of the company credit operation. */
  type: CompanyCreditOperationType
  /** The company user that submitted the company credit operation. */
  updatedBy: CompanyCreditOperationUser
}

export enum CompanyCreditOperationType {
  ALLOCATION = 'ALLOCATION',
  PURCHASE = 'PURCHASE',
  REFUND = 'REFUND',
  REIMBURSEMENT = 'REIMBURSEMENT',
  REVERT = 'REVERT',
  UPDATE = 'UPDATE',
}

/** Defines the administrator or company user that submitted a company credit operation. */
export interface CompanyCreditOperationUser {
  __typename?: 'CompanyCreditOperationUser'
  /** The name of the company user submitting the company credit operation. */
  name: Scalars['String']['output']
  /** The type of the company user submitting the company credit operation. */
  type: CompanyCreditOperationUserType
}

export enum CompanyCreditOperationUserType {
  ADMIN = 'ADMIN',
  CUSTOMER = 'CUSTOMER',
}

/** Contains details about the address where the company is registered to conduct business. */
export interface CompanyLegalAddress {
  __typename?: 'CompanyLegalAddress'
  /** The city where the company is registered to conduct business. */
  city: Maybe<Scalars['String']['output']>
  /** The country code of the company's legal address. */
  countryCode: Maybe<CountryCodeEnum>
  /** The company's postal code. */
  postcode: Maybe<Scalars['String']['output']>
  /** An object containing region data for the company. */
  region: Maybe<CustomerAddressRegion>
  /** An array of strings that define the company's street address. */
  street: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The company's phone number. */
  telephone: Maybe<Scalars['String']['output']>
}

/** Defines the input schema for defining a company's legal address. */
export interface CompanyLegalAddressCreateInput {
  /** The city where the company is registered to conduct business. */
  city: Scalars['String']['input']
  /** The company's country ID. Use the `countries` query to get this value. */
  country_id: CountryCodeEnum
  /** The postal code of the company. */
  postcode: Scalars['String']['input']
  /** An object containing the region name and/or region ID where the company is registered to conduct business. */
  region: CustomerAddressRegionInput
  /** An array of strings that define the street address where the company is registered to conduct business. */
  street: Array<InputMaybe<Scalars['String']['input']>>
  /** The primary phone number of the company. */
  telephone: Scalars['String']['input']
}

/** Defines the input schema for updating a company's legal address. */
export interface CompanyLegalAddressUpdateInput {
  /** The city where the company is registered to conduct business. */
  city?: InputMaybe<Scalars['String']['input']>
  /** The unique ID for a `Country` object. */
  country_id?: InputMaybe<CountryCodeEnum>
  /** The postal code of the company. */
  postcode?: InputMaybe<Scalars['String']['input']>
  /** An object containing the region name and/or region ID where the company is registered to conduct business. */
  region?: InputMaybe<CustomerAddressRegionInput>
  /** An array of strings that define the street address where the company is registered to conduct business. */
  street?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The primary phone number of the company. */
  telephone?: InputMaybe<Scalars['String']['input']>
}

/** Contails details about a single role. */
export interface CompanyRole {
  __typename?: 'CompanyRole'
  /** The unique ID for a `CompanyRole` object. */
  id: Scalars['ID']['output']
  /** The name assigned to the role. */
  name: Maybe<Scalars['String']['output']>
  /** A list of permission resources defined for a role. */
  permissions: Maybe<Array<Maybe<CompanyAclResource>>>
  /** The total number of users assigned the specified role. */
  usersCount: Maybe<Scalars['Int']['output']>
}

/** Defines the input schema for creating a company role. */
export interface CompanyRoleCreateInput {
  /** The name of the role to create. */
  name: Scalars['String']['input']
  /** A list of resources the role can access. */
  permissions: Array<InputMaybe<Scalars['String']['input']>>
}

/** Defines the input schema for updating a company role. */
export interface CompanyRoleUpdateInput {
  /** The unique ID for a `CompanyRole` object. */
  id: Scalars['ID']['input']
  /** The name of the role to update. */
  name?: InputMaybe<Scalars['String']['input']>
  /** A list of resources the role can access. */
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Contains an array of roles. */
export interface CompanyRoles {
  __typename?: 'CompanyRoles'
  /** A list of company roles that match the specified filter criteria. */
  items: Array<Maybe<CompanyRole>>
  /** Pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The total number of objects matching the specified filter. */
  totalCount: Scalars['Int']['output']
}

/** Contains details about a company sales representative. */
export interface CompanySalesRepresentative {
  __typename?: 'CompanySalesRepresentative'
  /** The email address of the company sales representative. */
  email: Maybe<Scalars['String']['output']>
  /** The company sales representative's first name. */
  firstname: Maybe<Scalars['String']['output']>
  /** The company sales representative's last name. */
  lastname: Maybe<Scalars['String']['output']>
}

/** Contains an array of the individual nodes that comprise the company structure. */
export interface CompanyStructure {
  __typename?: 'CompanyStructure'
  /** An array of elements in a company structure. */
  items: Maybe<Array<Maybe<CompanyStructureItem>>>
}

export type CompanyStructureEntity = CompanyTeam | Customer

/** Defines an individual node in the company structure. */
export interface CompanyStructureItem {
  __typename?: 'CompanyStructureItem'
  /** A union of `CompanyTeam` and `Customer` objects. */
  entity: Maybe<CompanyStructureEntity>
  /** The unique ID for a `CompanyStructureItem` object. */
  id: Scalars['ID']['output']
  /** The ID of the parent item in the company hierarchy. */
  parentId: Maybe<Scalars['ID']['output']>
}

/** Defines the input schema for updating the company structure. */
export interface CompanyStructureUpdateInput {
  /** The ID of a company that will be the new parent. */
  parent_tree_id: Scalars['ID']['input']
  /** The ID of the company team that is being moved to another parent. */
  tree_id: Scalars['ID']['input']
}

/** Describes a company team. */
export interface CompanyTeam {
  __typename?: 'CompanyTeam'
  /** An optional description of the team. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CompanyTeam` object. */
  id: Scalars['ID']['output']
  /** The display name of the team. */
  name: Maybe<Scalars['String']['output']>
  /** ID of the company structure */
  structureId: Scalars['ID']['output']
}

/** Defines the input schema for creating a company team. */
export interface CompanyTeamCreateInput {
  /** An optional description of the team. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The display name of the team. */
  name: Scalars['String']['input']
  /** The ID of a node within a company's structure. This ID will be the parent of the created team. */
  target_id?: InputMaybe<Scalars['ID']['input']>
}

/** Defines the input schema for updating a company team. */
export interface CompanyTeamUpdateInput {
  /** An optional description of the team. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The unique ID of the `CompanyTeam` object to update. */
  id: Scalars['ID']['input']
  /** The display name of the team. */
  name?: InputMaybe<Scalars['String']['input']>
}

/** Defines the input schema for updating a company. */
export interface CompanyUpdateInput {
  /** The email address of the company contact. */
  company_email?: InputMaybe<Scalars['String']['input']>
  /** The name of the company to update. */
  company_name?: InputMaybe<Scalars['String']['input']>
  /** The legal address data of the company. */
  legal_address?: InputMaybe<CompanyLegalAddressUpdateInput>
  /** The full legal name of the company. */
  legal_name?: InputMaybe<Scalars['String']['input']>
  /** The resale number that is assigned to the company for tax reporting purposes. */
  reseller_id?: InputMaybe<Scalars['String']['input']>
  /** The value-added tax number that is assigned to the company by some jurisdictions for tax reporting purposes. */
  vat_tax_id?: InputMaybe<Scalars['String']['input']>
}

/** Defines the input schema for creating a company user. */
export interface CompanyUserCreateInput {
  /** The company user's email address */
  email: Scalars['String']['input']
  /** The company user's first name. */
  firstname: Scalars['String']['input']
  /** The company user's job title or function. */
  job_title: Scalars['String']['input']
  /** The company user's last name. */
  lastname: Scalars['String']['input']
  /** The unique ID for a `CompanyRole` object. */
  role_id: Scalars['ID']['input']
  /** Indicates whether the company user is ACTIVE or INACTIVE. */
  status: CompanyUserStatusEnum
  /** The ID of a node within a company's structure. This ID will be the parent of the created company user. */
  target_id?: InputMaybe<Scalars['ID']['input']>
  /** The company user's phone number. */
  telephone: Scalars['String']['input']
}

/** Defines the list of company user status values. */
export enum CompanyUserStatusEnum {
  /** Only active users. */
  ACTIVE = 'ACTIVE',
  /** Only inactive users. */
  INACTIVE = 'INACTIVE',
}

/** Defines the input schema for updating a company user. */
export interface CompanyUserUpdateInput {
  /** The company user's email address. */
  email?: InputMaybe<Scalars['String']['input']>
  /** The company user's first name. */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** The unique ID of a `Customer` object. */
  id: Scalars['ID']['input']
  /** The company user's job title or function. */
  job_title?: InputMaybe<Scalars['String']['input']>
  /** The company user's last name. */
  lastname?: InputMaybe<Scalars['String']['input']>
  /** The unique ID for a `CompanyRole` object. */
  role_id?: InputMaybe<Scalars['ID']['input']>
  /** Indicates whether the company user is ACTIVE or INACTIVE. */
  status?: InputMaybe<CompanyUserStatusEnum>
  /** The company user's phone number. */
  telephone?: InputMaybe<Scalars['String']['input']>
}

/** Contains details about company users. */
export interface CompanyUsers {
  __typename?: 'CompanyUsers'
  /** An array of `CompanyUser` objects that match the specified filter criteria. */
  items: Array<Maybe<Customer>>
  /** Pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The number of objects returned. */
  totalCount: Scalars['Int']['output']
}

/** Defines the filter for returning a list of company users. */
export interface CompanyUsersFilterInput {
  /** The activity status to filter on. */
  status?: InputMaybe<CompanyUserStatusEnum>
}

/** Contains an attribute code that is used for product comparisons. */
export interface ComparableAttribute {
  __typename?: 'ComparableAttribute'
  /** An attribute code that is enabled for product comparisons. */
  code: Scalars['String']['output']
  /** The label of the attribute code. */
  label: Scalars['String']['output']
}

/** Defines an object used to iterate through items for product comparisons. */
export interface ComparableItem {
  __typename?: 'ComparableItem'
  /** An array of product attributes that can be used to compare products. */
  attributes: Array<Maybe<ProductAttribute>>
  /** Details about a product in a compare list. */
  product: ProductInterface
  /** The unique ID of an item in a compare list. */
  uid: Scalars['ID']['output']
}

/** Contains iterable information such as the array of items, the count, and attributes that represent the compare list. */
export interface CompareList {
  __typename?: 'CompareList'
  /** An array of attributes that can be used for comparing products. */
  attributes: Maybe<Array<Maybe<ComparableAttribute>>>
  /** The number of items in the compare list. */
  itemCount: Scalars['Int']['output']
  /** An array of products to compare. */
  items: Maybe<Array<Maybe<ComparableItem>>>
  /** The unique ID assigned to the compare list. */
  uid: Scalars['ID']['output']
}

export interface ComplexTextValue {
  __typename?: 'ComplexTextValue'
  /** Text that can contain HTML tags. */
  html: Scalars['String']['output']
}

/** Contains details about a configurable product attribute option. */
export interface ConfigurableAttributeOption {
  __typename?: 'ConfigurableAttributeOption'
  /** The ID assigned to the attribute. */
  code: Maybe<Scalars['String']['output']>
  /** A string that describes the configurable attribute option. */
  label: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ConfigurableAttributeOption` object. */
  uid: Scalars['ID']['output']
  /** A unique index number assigned to the configurable product option. */
  valueIndex: Maybe<Scalars['Int']['output']>
}

/** An implementation for configurable product cart items. */
export interface ConfigurableCartItem extends CartItemInterface {
  __typename?: 'ConfigurableCartItem'
  /** The list of available gift wrapping options for the cart item. */
  availableGiftWrapping: Array<Maybe<GiftWrapping>>
  /** An array containing the configuranle options the shopper selected. */
  configurableOptions: Array<Maybe<SelectedConfigurableOption>>
  /** Product details of the cart item. */
  configuredVariant: ProductInterface
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** Delivery date of the cart item (Y-m-d) */
  deliveryDate: Maybe<Scalars['String']['output']>
  /** Contains discount for quote line item. */
  discount: Maybe<Array<Maybe<Discount>>>
  /** Elektramat configuration packslip */
  elektramatPackslip: Maybe<Scalars['String']['output']>
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>
  /** The entered gift message for the cart item */
  giftMessage: Maybe<GiftMessage>
  /** The selected gift wrapping for the cart item. */
  giftWrapping: Maybe<GiftWrapping>
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output']
  /** True if requested quantity is less than available stock, false otherwise. */
  isAvailable: Scalars['Boolean']['output']
  /** The buyer's quote line item note. */
  noteFromBuyer: Maybe<Array<Maybe<ItemNote>>>
  /** The seller's quote line item note. */
  noteFromSeller: Maybe<Array<Maybe<ItemNote>>>
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>
  /** Details about an item in the cart. */
  product: ProductInterface
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output']
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output']
}

/** Describes configurable options that have been selected and can be selected as a result of the previous selections. */
export interface ConfigurableOptionAvailableForSelection {
  __typename?: 'ConfigurableOptionAvailableForSelection'
  /** An attribute code that uniquely identifies a configurable option. */
  attributeCode: Scalars['String']['output']
  /** An array of selectable option value IDs. */
  optionValueUids: Array<Maybe<Scalars['ID']['output']>>
}

/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProduct
  extends CustomizableProductInterface,
    PhysicalProductInterface,
    ProductInterface,
    RoutableInterface {
  __typename?: 'ConfigurableProduct'
  /** @deprecated Use the `custom_attributes` field instead. */
  articleNumber: Maybe<Scalars['String']['output']>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  color: Maybe<Scalars['Int']['output']>
  /** An array of options for the configurable product. */
  configurableOptions: Maybe<Array<Maybe<ConfigurableProductOptions>>>
  /** An array of media gallery items and other details about selected configurable product options as well as details about remaining selectable options. */
  configurableProductOptionsSelection: Maybe<ConfigurableProductOptionsSelection>
  /** @deprecated Use the `custom_attributes` field instead. */
  costPrice: Maybe<Scalars['String']['output']>
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** Product custom attributes. */
  customAttributesV2: Maybe<ProductCustomAttributes>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']['output']>
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>
  /** @deprecated Use the `custom_attributes` field instead. */
  isClonedForStore: Maybe<Scalars['String']['output']>
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']['output']>
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  manufacturerId: Maybe<Scalars['String']['output']>
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['String']['output']>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minimumQuantityAllowed: Maybe<Scalars['String']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  phasedOut: Maybe<Scalars['String']['output']>
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  productgroep: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  quantityIncrements: Maybe<Scalars['String']['output']>
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float']['output']
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalCrossection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDegreeofprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalEarthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalIdentificationcores: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalInnerdepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductorcrosssection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductordiameter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalinsulationthickness: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofcores: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofmodules: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofpoles: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofrows: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalOuterdiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalRatedvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalTrippingcharacteristic: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWithattachmentoption: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBundlesperpallet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingCabletype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingDiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingFireretardancy: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorBoxquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorCablediameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorSolarpanelcompatibility: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerConnector: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerPayload: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerRfidcardreader: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerSuitableforoutdoormounting: Maybe<
    Scalars['Int']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWirelessconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemRoofpitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWindstandard: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMaxsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMinsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringCommunicationtype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSeriecompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSignalrange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDaktype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHeightatthetop: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHoogtebovenzijde: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalPaletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperbox: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperpalet: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalRooftype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcdcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAfci: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerCompatiblestorage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcswitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerInvertertype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximuminputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMicropanelpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMinearthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMonitoringoptions: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMppvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerNumberofmpptrackers: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerPaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperbox: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperpalet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerRatedacpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerSolarpanels: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStartupvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStringspermpptracker: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerUsablebatterycapacity: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemBatvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCommunicationport: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxbatcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxoutputpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemOutputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemStoragecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemUsablecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBacksheetcolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBuild: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCelltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFramecolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelGuaranteedpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelJunctionboxprotectiondegree: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelMaxsystemvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelModuleefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelNumberofcells: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletsize: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPanelspercontainer: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPowerguarantee: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelSolarpaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelThickness: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWattagepersquaremeter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWindandsnowload: Maybe<Scalars['String']['output']>
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean']['output']
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  usp: Maybe<Scalars['String']['output']>
  /** An array of simple product variants. */
  variants: Maybe<Array<Maybe<ConfigurableVariant>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  voorraadstatus: Maybe<Scalars['String']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>
}

/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProductConfigurableProductOptionsSelectionArgs {
  configurableOptionValueUids: InputMaybe<Array<Scalars['ID']['input']>>
}

/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProductCustomAttributesV2Args {
  filters: InputMaybe<AttributeFilterInput>
}

/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export interface ConfigurableProductCartItemInput {
  /** The ID and value of the option. */
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  /** The quantity and SKU of the configurable product. */
  data: CartItemInput
  /** The SKU of the parent configurable product. */
  parent_sku?: InputMaybe<Scalars['String']['input']>
  /** Deprecated. Use `CartItemInput.sku` instead. */
  variant_sku?: InputMaybe<Scalars['String']['input']>
}

/** Contains details about configurable product options. */
export interface ConfigurableProductOption {
  __typename?: 'ConfigurableProductOption'
  /** An attribute code that uniquely identifies a configurable option. */
  attributeCode: Scalars['String']['output']
  /** The display name of the option. */
  label: Scalars['String']['output']
  /** The unique ID of the configurable option. */
  uid: Scalars['ID']['output']
  /** An array of values that are applicable for this option. */
  values: Maybe<Array<Maybe<ConfigurableProductOptionValue>>>
}

/** Defines a value for a configurable product option. */
export interface ConfigurableProductOptionValue {
  __typename?: 'ConfigurableProductOptionValue'
  /** Indicates whether the product is available with this selected option. */
  isAvailable: Scalars['Boolean']['output']
  /** Indicates whether the value is the default. */
  isUseDefault: Scalars['Boolean']['output']
  /** The display name of the value. */
  label: Scalars['String']['output']
  /** The URL assigned to the thumbnail of the swatch image. */
  swatch: Maybe<SwatchDataInterface>
  /** The unique ID of the value. */
  uid: Scalars['ID']['output']
}

/** Defines configurable attributes for the specified product. */
export interface ConfigurableProductOptions {
  __typename?: 'ConfigurableProductOptions'
  /** A string that identifies the attribute. */
  attributeCode: Maybe<Scalars['String']['output']>
  /**
   * The ID assigned to the attribute.
   * @deprecated Use `attribute_uid` instead.
   */
  attributeId: Maybe<Scalars['String']['output']>
  /**
   * The ID assigned to the attribute.
   * @deprecated Use `attribute_uid` instead.
   */
  attributeIdV2: Maybe<Scalars['Int']['output']>
  /** The unique ID for an `Attribute` object. */
  attributeUid: Scalars['ID']['output']
  /**
   * The configurable option ID number assigned by the system.
   * @deprecated Use `uid` instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** A displayed string that describes the configurable product option. */
  label: Maybe<Scalars['String']['output']>
  /** A number that indicates the order in which the attribute is displayed. */
  position: Maybe<Scalars['Int']['output']>
  /**
   * This is the same as a product's `id` field.
   * @deprecated `product_id` is not needed and can be obtained from its parent.
   */
  productId: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `ConfigurableProductOptions` object. */
  uid: Scalars['ID']['output']
  /** Indicates whether the option is the default. */
  useDefault: Maybe<Scalars['Boolean']['output']>
  /** An array that defines the `value_index` codes assigned to the configurable product. */
  values: Maybe<Array<Maybe<ConfigurableProductOptionsValues>>>
}

/** Contains metadata corresponding to the selected configurable options. */
export interface ConfigurableProductOptionsSelection {
  __typename?: 'ConfigurableProductOptionsSelection'
  /** An array of all possible configurable options. */
  configurableOptions: Maybe<Array<Maybe<ConfigurableProductOption>>>
  /** Product images and videos corresponding to the specified configurable options selection. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /** The configurable options available for further selection based on the current selection. */
  optionsAvailableForSelection: Maybe<
    Array<Maybe<ConfigurableOptionAvailableForSelection>>
  >
  /** A variant represented by the specified configurable options selection. The value is expected to be null until selections are made for each configurable option. */
  variant: Maybe<SimpleProduct>
}

/** Contains the index number assigned to a configurable product option. */
export interface ConfigurableProductOptionsValues {
  __typename?: 'ConfigurableProductOptionsValues'
  /** The label of the product on the default store. */
  defaultLabel: Maybe<Scalars['String']['output']>
  /** The label of the product. */
  label: Maybe<Scalars['String']['output']>
  /** The label of the product on the current store. */
  storeLabel: Maybe<Scalars['String']['output']>
  /** Swatch data for a configurable product option. */
  swatchData: Maybe<SwatchDataInterface>
  /** The unique ID for a `ConfigurableProductOptionsValues` object. */
  uid: Maybe<Scalars['ID']['output']>
  /** Indicates whether to use the default_label. */
  useDefaultValue: Maybe<Scalars['Boolean']['output']>
  /**
   * A unique index number assigned to the configurable product option.
   * @deprecated Use `uid` instead.
   */
  valueIndex: Maybe<Scalars['Int']['output']>
}

/** Contains details about configurable products added to a requisition list. */
export interface ConfigurableRequisitionListItem
  extends RequisitionListItemInterface {
  __typename?: 'ConfigurableRequisitionListItem'
  /** Selected configurable options for an item in the requisition list. */
  configurableOptions: Maybe<Array<Maybe<SelectedConfigurableOption>>>
  /** Selected custom options for an item in the requisition list. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** Details about a requisition list item. */
  product: ProductInterface
  /** The quantity of the product added to the requisition list. */
  quantity: Scalars['Float']['output']
  /** The unique ID of an item in a requisition list. */
  uid: Scalars['ID']['output']
}

/** Contains all the simple product variants of a configurable product. */
export interface ConfigurableVariant {
  __typename?: 'ConfigurableVariant'
  /** An array of configurable attribute options. */
  attributes: Maybe<Array<Maybe<ConfigurableAttributeOption>>>
  /** An array of linked simple products. */
  product: Maybe<SimpleProduct>
}

/** A configurable product wish list item. */
export interface ConfigurableWishlistItem extends WishlistItemInterface {
  __typename?: 'ConfigurableWishlistItem'
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output']
  /**
   * The SKU of the simple product corresponding to a set of selected configurable options.
   * @deprecated Use `ConfigurableWishlistItem.configured_variant.sku` instead.
   */
  childSku: Scalars['String']['output']
  /** An array of selected configurable options. */
  configurableOptions: Maybe<Array<Maybe<SelectedConfigurableOption>>>
  /** Product details of the selected variant. The value is null if some options are not configured. */
  configuredVariant: Maybe<ProductInterface>
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output']
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output']
}

/** Contains details about a customer email address to confirm. */
export interface ConfirmEmailInput {
  /** The key to confirm the email address. */
  confirmation_key: Scalars['String']['input']
  /** The email address to be confirmed. */
  email: Scalars['String']['input']
}

/** List of account confirmation statuses. */
export enum ConfirmationStatusEnum {
  /** Account confirmation not required */
  ACCOUNT_CONFIRMATION_NOT_REQUIRED = 'ACCOUNT_CONFIRMATION_NOT_REQUIRED',
  /** Account confirmed */
  ACCOUNT_CONFIRMED = 'ACCOUNT_CONFIRMED',
}

export interface ContactUsInput {
  /** The shopper's comment to the merchant. */
  comment: Scalars['String']['input']
  /** The email address of the shopper. */
  email: Scalars['String']['input']
  /** The full name of the shopper. */
  name: Scalars['String']['input']
  /** The shopper's telephone number. */
  telephone?: InputMaybe<Scalars['String']['input']>
}

/** Contains the status of the request. */
export interface ContactUsOutput {
  __typename?: 'ContactUsOutput'
  /** Indicates whether the request was successful. */
  status: Scalars['Boolean']['output']
}

/** An input object that defines the items in a requisition list to be copied. */
export interface CopyItemsBetweenRequisitionListsInput {
  /** An array of IDs representing products copied from one requisition list to another. */
  requisitionListItemUids: Array<InputMaybe<Scalars['ID']['input']>>
}

/** Output of the request to copy items to the destination requisition list. */
export interface CopyItemsFromRequisitionListsOutput {
  __typename?: 'CopyItemsFromRequisitionListsOutput'
  /** The destination requisition list after the items were copied. */
  requisitionList: Maybe<RequisitionList>
}

/** Contains the source and target wish lists after copying products. */
export interface CopyProductsBetweenWishlistsOutput {
  __typename?: 'CopyProductsBetweenWishlistsOutput'
  /** The destination wish list containing the copied products. */
  destinationWishlist: Wishlist
  /** The wish list that the products were copied from. */
  sourceWishlist: Wishlist
  /** An array of errors encountered while copying products in a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>
}

export interface Country {
  __typename?: 'Country'
  /** An array of regions within a particular country. */
  availableRegions: Maybe<Array<Maybe<Region>>>
  /** The name of the country in English. */
  fullNameEnglish: Maybe<Scalars['String']['output']>
  /** The name of the country in the current locale. */
  fullNameLocale: Maybe<Scalars['String']['output']>
  /** The unique ID for a `Country` object. */
  id: Maybe<Scalars['String']['output']>
  /** The three-letter abbreviation of the country, such as USA. */
  threeLetterAbbreviation: Maybe<Scalars['String']['output']>
  /** The two-letter abbreviation of the country, such as US. */
  twoLetterAbbreviation: Maybe<Scalars['String']['output']>
}

/** The list of country codes. */
export enum CountryCodeEnum {
  /** Andorra */
  AD = 'AD',
  /** United Arab Emirates */
  AE = 'AE',
  /** Afghanistan */
  AF = 'AF',
  /** Antigua & Barbuda */
  AG = 'AG',
  /** Anguilla */
  AI = 'AI',
  /** Albania */
  AL = 'AL',
  /** Armenia */
  AM = 'AM',
  /** Netherlands Antilles */
  AN = 'AN',
  /** Angola */
  AO = 'AO',
  /** Antarctica */
  AQ = 'AQ',
  /** Argentina */
  AR = 'AR',
  /** American Samoa */
  AS = 'AS',
  /** Austria */
  AT = 'AT',
  /** Australia */
  AU = 'AU',
  /** Aruba */
  AW = 'AW',
  /** Åland Islands */
  AX = 'AX',
  /** Azerbaijan */
  AZ = 'AZ',
  /** Bosnia & Herzegovina */
  BA = 'BA',
  /** Barbados */
  BB = 'BB',
  /** Bangladesh */
  BD = 'BD',
  /** Belgium */
  BE = 'BE',
  /** Burkina Faso */
  BF = 'BF',
  /** Bulgaria */
  BG = 'BG',
  /** Bahrain */
  BH = 'BH',
  /** Burundi */
  BI = 'BI',
  /** Benin */
  BJ = 'BJ',
  /** St. Barthélemy */
  BL = 'BL',
  /** Bermuda */
  BM = 'BM',
  /** Brunei */
  BN = 'BN',
  /** Bolivia */
  BO = 'BO',
  /** Brazil */
  BR = 'BR',
  /** Bahamas */
  BS = 'BS',
  /** Bhutan */
  BT = 'BT',
  /** Bouvet Island */
  BV = 'BV',
  /** Botswana */
  BW = 'BW',
  /** Belarus */
  BY = 'BY',
  /** Belize */
  BZ = 'BZ',
  /** Canada */
  CA = 'CA',
  /** Cocos (Keeling) Islands */
  CC = 'CC',
  /** Congo-Kinshasa */
  CD = 'CD',
  /** Central African Republic */
  CF = 'CF',
  /** Congo-Brazzaville */
  CG = 'CG',
  /** Switzerland */
  CH = 'CH',
  /** Côte d’Ivoire */
  CI = 'CI',
  /** Cook Islands */
  CK = 'CK',
  /** Chile */
  CL = 'CL',
  /** Cameroon */
  CM = 'CM',
  /** China */
  CN = 'CN',
  /** Colombia */
  CO = 'CO',
  /** Costa Rica */
  CR = 'CR',
  /** Cuba */
  CU = 'CU',
  /** Cape Verde */
  CV = 'CV',
  /** Christmas Island */
  CX = 'CX',
  /** Cyprus */
  CY = 'CY',
  /** Czech Republic */
  CZ = 'CZ',
  /** Germany */
  DE = 'DE',
  /** Djibouti */
  DJ = 'DJ',
  /** Denmark */
  DK = 'DK',
  /** Dominica */
  DM = 'DM',
  /** Dominican Republic */
  DO = 'DO',
  /** Algeria */
  DZ = 'DZ',
  /** Ecuador */
  EC = 'EC',
  /** Estonia */
  EE = 'EE',
  /** Egypt */
  EG = 'EG',
  /** Western Sahara */
  EH = 'EH',
  /** Eritrea */
  ER = 'ER',
  /** Spain */
  ES = 'ES',
  /** Ethiopia */
  ET = 'ET',
  /** Finland */
  FI = 'FI',
  /** Fiji */
  FJ = 'FJ',
  /** Falkland Islands */
  FK = 'FK',
  /** Micronesia */
  FM = 'FM',
  /** Faroe Islands */
  FO = 'FO',
  /** France */
  FR = 'FR',
  /** Gabon */
  GA = 'GA',
  /** United Kingdom */
  GB = 'GB',
  /** Grenada */
  GD = 'GD',
  /** Georgia */
  GE = 'GE',
  /** French Guiana */
  GF = 'GF',
  /** Guernsey */
  GG = 'GG',
  /** Ghana */
  GH = 'GH',
  /** Gibraltar */
  GI = 'GI',
  /** Greenland */
  GL = 'GL',
  /** Gambia */
  GM = 'GM',
  /** Guinea */
  GN = 'GN',
  /** Guadeloupe */
  GP = 'GP',
  /** Equatorial Guinea */
  GQ = 'GQ',
  /** Greece */
  GR = 'GR',
  /** South Georgia & South Sandwich Islands */
  GS = 'GS',
  /** Guatemala */
  GT = 'GT',
  /** Guam */
  GU = 'GU',
  /** Guinea-Bissau */
  GW = 'GW',
  /** Guyana */
  GY = 'GY',
  /** Hong Kong SAR China */
  HK = 'HK',
  /** Heard &amp; McDonald Islands */
  HM = 'HM',
  /** Honduras */
  HN = 'HN',
  /** Croatia */
  HR = 'HR',
  /** Haiti */
  HT = 'HT',
  /** Hungary */
  HU = 'HU',
  /** Indonesia */
  ID = 'ID',
  /** Ireland */
  IE = 'IE',
  /** Israel */
  IL = 'IL',
  /** Isle of Man */
  IM = 'IM',
  /** India */
  IN = 'IN',
  /** British Indian Ocean Territory */
  IO = 'IO',
  /** Iraq */
  IQ = 'IQ',
  /** Iran */
  IR = 'IR',
  /** Iceland */
  IS = 'IS',
  /** Italy */
  IT = 'IT',
  /** Jersey */
  JE = 'JE',
  /** Jamaica */
  JM = 'JM',
  /** Jordan */
  JO = 'JO',
  /** Japan */
  JP = 'JP',
  /** Kenya */
  KE = 'KE',
  /** Kyrgyzstan */
  KG = 'KG',
  /** Cambodia */
  KH = 'KH',
  /** Kiribati */
  KI = 'KI',
  /** Comoros */
  KM = 'KM',
  /** St. Kitts & Nevis */
  KN = 'KN',
  /** North Korea */
  KP = 'KP',
  /** South Korea */
  KR = 'KR',
  /** Kuwait */
  KW = 'KW',
  /** Cayman Islands */
  KY = 'KY',
  /** Kazakhstan */
  KZ = 'KZ',
  /** Laos */
  LA = 'LA',
  /** Lebanon */
  LB = 'LB',
  /** St. Lucia */
  LC = 'LC',
  /** Liechtenstein */
  LI = 'LI',
  /** Sri Lanka */
  LK = 'LK',
  /** Liberia */
  LR = 'LR',
  /** Lesotho */
  LS = 'LS',
  /** Lithuania */
  LT = 'LT',
  /** Luxembourg */
  LU = 'LU',
  /** Latvia */
  LV = 'LV',
  /** Libya */
  LY = 'LY',
  /** Morocco */
  MA = 'MA',
  /** Monaco */
  MC = 'MC',
  /** Moldova */
  MD = 'MD',
  /** Montenegro */
  ME = 'ME',
  /** St. Martin */
  MF = 'MF',
  /** Madagascar */
  MG = 'MG',
  /** Marshall Islands */
  MH = 'MH',
  /** Macedonia */
  MK = 'MK',
  /** Mali */
  ML = 'ML',
  /** Myanmar (Burma) */
  MM = 'MM',
  /** Mongolia */
  MN = 'MN',
  /** Macau SAR China */
  MO = 'MO',
  /** Northern Mariana Islands */
  MP = 'MP',
  /** Martinique */
  MQ = 'MQ',
  /** Mauritania */
  MR = 'MR',
  /** Montserrat */
  MS = 'MS',
  /** Malta */
  MT = 'MT',
  /** Mauritius */
  MU = 'MU',
  /** Maldives */
  MV = 'MV',
  /** Malawi */
  MW = 'MW',
  /** Mexico */
  MX = 'MX',
  /** Malaysia */
  MY = 'MY',
  /** Mozambique */
  MZ = 'MZ',
  /** Namibia */
  NA = 'NA',
  /** New Caledonia */
  NC = 'NC',
  /** Niger */
  NE = 'NE',
  /** Norfolk Island */
  NF = 'NF',
  /** Nigeria */
  NG = 'NG',
  /** Nicaragua */
  NI = 'NI',
  /** Netherlands */
  NL = 'NL',
  /** Norway */
  NO = 'NO',
  /** Nepal */
  NP = 'NP',
  /** Nauru */
  NR = 'NR',
  /** Niue */
  NU = 'NU',
  /** New Zealand */
  NZ = 'NZ',
  /** Oman */
  OM = 'OM',
  /** Panama */
  PA = 'PA',
  /** Peru */
  PE = 'PE',
  /** French Polynesia */
  PF = 'PF',
  /** Papua New Guinea */
  PG = 'PG',
  /** Philippines */
  PH = 'PH',
  /** Pakistan */
  PK = 'PK',
  /** Poland */
  PL = 'PL',
  /** St. Pierre & Miquelon */
  PM = 'PM',
  /** Pitcairn Islands */
  PN = 'PN',
  /** Palestinian Territories */
  PS = 'PS',
  /** Portugal */
  PT = 'PT',
  /** Palau */
  PW = 'PW',
  /** Paraguay */
  PY = 'PY',
  /** Qatar */
  QA = 'QA',
  /** Réunion */
  RE = 'RE',
  /** Romania */
  RO = 'RO',
  /** Serbia */
  RS = 'RS',
  /** Russia */
  RU = 'RU',
  /** Rwanda */
  RW = 'RW',
  /** Saudi Arabia */
  SA = 'SA',
  /** Solomon Islands */
  SB = 'SB',
  /** Seychelles */
  SC = 'SC',
  /** Sudan */
  SD = 'SD',
  /** Sweden */
  SE = 'SE',
  /** Singapore */
  SG = 'SG',
  /** St. Helena */
  SH = 'SH',
  /** Slovenia */
  SI = 'SI',
  /** Svalbard & Jan Mayen */
  SJ = 'SJ',
  /** Slovakia */
  SK = 'SK',
  /** Sierra Leone */
  SL = 'SL',
  /** San Marino */
  SM = 'SM',
  /** Senegal */
  SN = 'SN',
  /** Somalia */
  SO = 'SO',
  /** Suriname */
  SR = 'SR',
  /** São Tomé & Príncipe */
  ST = 'ST',
  /** El Salvador */
  SV = 'SV',
  /** Syria */
  SY = 'SY',
  /** Eswatini */
  SZ = 'SZ',
  /** Turks & Caicos Islands */
  TC = 'TC',
  /** Chad */
  TD = 'TD',
  /** French Southern Territories */
  TF = 'TF',
  /** Togo */
  TG = 'TG',
  /** Thailand */
  TH = 'TH',
  /** Tajikistan */
  TJ = 'TJ',
  /** Tokelau */
  TK = 'TK',
  /** Timor-Leste */
  TL = 'TL',
  /** Turkmenistan */
  TM = 'TM',
  /** Tunisia */
  TN = 'TN',
  /** Tonga */
  TO = 'TO',
  /** Turkey */
  TR = 'TR',
  /** Trinidad & Tobago */
  TT = 'TT',
  /** Tuvalu */
  TV = 'TV',
  /** Taiwan */
  TW = 'TW',
  /** Tanzania */
  TZ = 'TZ',
  /** Ukraine */
  UA = 'UA',
  /** Uganda */
  UG = 'UG',
  /** U.S. Outlying Islands */
  UM = 'UM',
  /** United States */
  US = 'US',
  /** Uruguay */
  UY = 'UY',
  /** Uzbekistan */
  UZ = 'UZ',
  /** Vatican City */
  VA = 'VA',
  /** St. Vincent & Grenadines */
  VC = 'VC',
  /** Venezuela */
  VE = 'VE',
  /** British Virgin Islands */
  VG = 'VG',
  /** U.S. Virgin Islands */
  VI = 'VI',
  /** Vietnam */
  VN = 'VN',
  /** Vanuatu */
  VU = 'VU',
  /** Wallis & Futuna */
  WF = 'WF',
  /** Samoa */
  WS = 'WS',
  /** Yemen */
  YE = 'YE',
  /** Mayotte */
  YT = 'YT',
  /** South Africa */
  ZA = 'ZA',
  /** Zambia */
  ZM = 'ZM',
  /** Zimbabwe */
  ZW = 'ZW',
}

/** Contains the response to the request to create a company. */
export interface CreateCompanyOutput {
  __typename?: 'CreateCompanyOutput'
  /** The new company instance. */
  company: Company
}

/** Contains the response to the request to create a company role. */
export interface CreateCompanyRoleOutput {
  __typename?: 'CreateCompanyRoleOutput'
  /** The new company role instance. */
  role: CompanyRole
}

/** Contains the response to the request to create a company team. */
export interface CreateCompanyTeamOutput {
  __typename?: 'CreateCompanyTeamOutput'
  /** The new company team instance. */
  team: CompanyTeam
}

/** Contains the response to the request to create a company user. */
export interface CreateCompanyUserOutput {
  __typename?: 'CreateCompanyUserOutput'
  /** The new company user instance. */
  user: Customer
}

/** Contains an array of product IDs to use for creating a compare list. */
export interface CreateCompareListInput {
  /** An array of product IDs to add to the compare list. */
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export interface CreateConfigurationInput {
  /** Configuration Name */
  name?: InputMaybe<Scalars['String']['input']>
  /** Configuration Url */
  url?: InputMaybe<Scalars['String']['input']>
}

/** Assigns a specific `cart_id` to the empty cart. */
export interface CreateEmptyCartInput {
  /** The ID to assign to the cart. */
  cart_id?: InputMaybe<Scalars['String']['input']>
}

/** Defines a new gift registry. */
export interface CreateGiftRegistryInput {
  /** Additional attributes specified as a code-value pair. */
  dynamic_attributes?: InputMaybe<
    Array<InputMaybe<GiftRegistryDynamicAttributeInput>>
  >
  /** The name of the event. */
  event_name: Scalars['String']['input']
  /** The ID of the selected event type. */
  gift_registry_type_uid: Scalars['ID']['input']
  /** A message describing the event. */
  message: Scalars['String']['input']
  /** Indicates whether the registry is PRIVATE or PUBLIC. */
  privacy_settings: GiftRegistryPrivacySettings
  /** The list of people who receive notifications about the registry. */
  registrants: Array<InputMaybe<AddGiftRegistryRegistrantInput>>
  /** The shipping address for all gift registry items. */
  shipping_address?: InputMaybe<GiftRegistryShippingAddressInput>
  /** Indicates whether the registry is ACTIVE or INACTIVE. */
  status: GiftRegistryStatus
}

/** Contains the results of a request to create a gift registry. */
export interface CreateGiftRegistryOutput {
  __typename?: 'CreateGiftRegistryOutput'
  /** The newly-created gift registry. */
  giftRegistry: Maybe<GiftRegistry>
}

export interface CreateGuestCartInput {
  /** Optional client-generated ID */
  cart_uid?: InputMaybe<Scalars['ID']['input']>
}

export interface CreateGuestCartOutput {
  __typename?: 'CreateGuestCartOutput'
  /** The newly created cart. */
  cart: Maybe<Cart>
}

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export interface CreatePayflowProTokenOutput {
  __typename?: 'CreatePayflowProTokenOutput'
  /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
  responseMessage: Scalars['String']['output']
  /** A non-zero value if any errors occurred. */
  result: Scalars['Int']['output']
  /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
  resultCode: Scalars['Int']['output']
  /** A secure token generated by PayPal. */
  secureToken: Scalars['String']['output']
  /** A secure token ID generated by PayPal. */
  secureTokenId: Scalars['String']['output']
}

/** Contains payment order details that are used while processing the payment order */
export interface CreatePaymentOrderInput {
  /** The customer cart ID */
  cartId: Scalars['String']['input']
  /** Defines the origin location for that payment request */
  location: PaymentLocation
  /** The code for the payment method used in the order */
  methodCode: Scalars['String']['input']
  /** The identifiable payment source for the payment method */
  paymentSource: Scalars['String']['input']
  /** Indicates whether the payment information should be vaulted */
  vaultIntent?: InputMaybe<Scalars['Boolean']['input']>
}

/** Contains payment order details that are used while processing the payment order */
export interface CreatePaymentOrderOutput {
  __typename?: 'CreatePaymentOrderOutput'
  /** The amount of the payment order */
  amount: Maybe<Scalars['Float']['output']>
  /** The currency of the payment order */
  currencyCode: Maybe<Scalars['String']['output']>
  /** PayPal order ID */
  id: Maybe<Scalars['String']['output']>
  /** The order ID generated by Payment Services */
  mpOrderId: Maybe<Scalars['String']['output']>
  /** The status of the payment order */
  status: Maybe<Scalars['String']['output']>
}

/** Defines a new product review. */
export interface CreateProductReviewInput {
  /** The customer's nickname. Defaults to the customer name, if logged in. */
  nickname: Scalars['String']['input']
  /** The ratings details by category. For example, Price: 5 stars, Quality: 4 stars, etc. */
  ratings: Array<InputMaybe<ProductReviewRatingInput>>
  /** The SKU of the reviewed product. */
  sku: Scalars['String']['input']
  /** The summary (title) of the review. */
  summary: Scalars['String']['input']
  /** The review text. */
  text: Scalars['String']['input']
}

/** Contains the completed product review. */
export interface CreateProductReviewOutput {
  __typename?: 'CreateProductReviewOutput'
  /** Product review details. */
  review: ProductReview
}

/** Specifies the amount and currency to evaluate. */
export interface CreatePurchaseOrderApprovalRuleConditionAmountInput {
  /** Purchase order approval rule condition amount currency. */
  currency: CurrencyEnum
  /** Purchase order approval rule condition amount value. */
  value: Scalars['Float']['input']
}

/** Defines a set of conditions that apply to a rule. */
export interface CreatePurchaseOrderApprovalRuleConditionInput {
  /** The amount to be compared in a purchase order approval rule. This field is mutually exclusive with condition quantity. */
  amount?: InputMaybe<CreatePurchaseOrderApprovalRuleConditionAmountInput>
  /** The type of approval rule. */
  attribute: PurchaseOrderApprovalRuleType
  /** Defines how to evaluate an amount or quantity in a purchase order. */
  operator: PurchaseOrderApprovalRuleConditionOperator
  /** The quantity to be compared in a purchase order approval rule. This field is mutually exclusive with condition amount. */
  quantity?: InputMaybe<Scalars['Int']['input']>
}

/** An input object that identifies and describes a new requisition list. */
export interface CreateRequisitionListInput {
  /** An optional description of the requisition list. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name assigned to the requisition list. */
  name: Scalars['String']['input']
}

/** Output of the request to create a requisition list. */
export interface CreateRequisitionListOutput {
  __typename?: 'CreateRequisitionListOutput'
  /** The created requisition list. */
  requisitionList: Maybe<RequisitionList>
}

/** Defines the name and visibility of a new wish list. */
export interface CreateWishlistInput {
  /** The name of the new wish list. */
  name: Scalars['String']['input']
  /** Indicates whether the wish list is public or private. */
  visibility: WishlistVisibilityEnum
}

/** Contains the wish list. */
export interface CreateWishlistOutput {
  __typename?: 'CreateWishlistOutput'
  /** The newly-created wish list */
  wishlist: Wishlist
}

/** Required fields for Payflow Pro and Payments Pro credit card payments. */
export interface CreditCardDetailsInput {
  /** The credit card expiration month. */
  cc_exp_month: Scalars['Int']['input']
  /** The credit card expiration year. */
  cc_exp_year: Scalars['Int']['input']
  /** The last 4 digits of the credit card. */
  cc_last_4: Scalars['Int']['input']
  /** The credit card type. */
  cc_type: Scalars['String']['input']
}

/** Contains credit memo details. */
export interface CreditMemo {
  __typename?: 'CreditMemo'
  /** Comments on the credit memo. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>
  /** The unique ID for a `CreditMemo` object. */
  id: Scalars['ID']['output']
  /** An array containing details about refunded items. */
  items: Maybe<Array<Maybe<CreditMemoItemInterface>>>
  /** The sequential credit memo number. */
  number: Scalars['String']['output']
  /** Details about the total refunded amount. */
  total: Maybe<CreditMemoTotal>
}

export interface CreditMemoItem extends CreditMemoItemInterface {
  __typename?: 'CreditMemoItem'
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
}

/** Credit memo item details. */
export interface CreditMemoItemInterface {
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
}

/** Contains credit memo price details. */
export interface CreditMemoTotal {
  __typename?: 'CreditMemoTotal'
  /** An adjustment manually applied to the order. */
  adjustment: Money
  /** The final base grand total amount in the base currency. */
  baseGrandTotal: Money
  /** The applied discounts to the credit memo. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The final total amount, including shipping, discounts, and taxes. */
  grandTotal: Money
  /** Details about the shipping and handling costs for the credit memo. */
  shippingHandling: Maybe<ShippingHandling>
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
  subtotal: Money
  /** The credit memo tax details. */
  taxes: Maybe<Array<Maybe<TaxItem>>>
  /** The shipping amount for the credit memo. */
  totalShipping: Money
  /** The amount of tax applied to the credit memo. */
  totalTax: Money
}

export interface Currency {
  __typename?: 'Currency'
  /** An array of three-letter currency codes accepted by the store, such as USD and EUR. */
  availableCurrencyCodes: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The base currency set for the store, such as USD. */
  baseCurrencyCode: Maybe<Scalars['String']['output']>
  /** The symbol for the specified base currency, such as $. */
  baseCurrencySymbol: Maybe<Scalars['String']['output']>
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  defaultDisplayCurrecyCode: Maybe<Scalars['String']['output']>
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  defaultDisplayCurrecySymbol: Maybe<Scalars['String']['output']>
  /** The currency that is displayed by default, such as USD. */
  defaultDisplayCurrencyCode: Maybe<Scalars['String']['output']>
  /** The currency symbol that is displayed by default, such as $. */
  defaultDisplayCurrencySymbol: Maybe<Scalars['String']['output']>
  /** An array of exchange rates for currencies defined in the store. */
  exchangeRates: Maybe<Array<Maybe<ExchangeRate>>>
}

/** The list of available currency codes. */
export enum CurrencyEnum {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZM = 'AZM',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BUK = 'BUK',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHE = 'CHE',
  CHF = 'CHF',
  CHW = 'CHW',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EEK = 'EEK',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEK = 'GEK',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GQE = 'GQE',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LSM = 'LSM',
  LTL = 'LTL',
  LVL = 'LVL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIC = 'NIC',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RHD = 'RHD',
  ROL = 'ROL',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SKK = 'SKK',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  STD = 'STD',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMM = 'TMM',
  TND = 'TND',
  TOP = 'TOP',
  TRL = 'TRL',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VEB = 'VEB',
  VEF = 'VEF',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XCD = 'XCD',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  YTL = 'YTL',
  ZAR = 'ZAR',
  ZMK = 'ZMK',
  ZWD = 'ZWD',
}

/** Defines an array of custom attributes. */
export interface CustomAttributeMetadata {
  __typename?: 'CustomAttributeMetadata'
  /** An array of attributes. */
  items: Maybe<Array<Maybe<Attribute>>>
}

/** An interface containing fields that define the EAV attribute. */
export interface CustomAttributeMetadataInterface {
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  code: Scalars['ID']['output']
  /** Default attribute value. */
  defaultValue: Maybe<Scalars['String']['output']>
  /** The type of entity that defines the attribute. */
  entityType: AttributeEntityTypeEnum
  /** The frontend class of the attribute. */
  frontendClass: Maybe<Scalars['String']['output']>
  /** The frontend input type of the attribute. */
  frontendInput: Maybe<AttributeFrontendInputEnum>
  /** Whether the attribute value is required. */
  isRequired: Scalars['Boolean']['output']
  /** Whether the attribute value must be unique. */
  isUnique: Scalars['Boolean']['output']
  /** The label assigned to the attribute. */
  label: Maybe<Scalars['String']['output']>
  /** Attribute options. */
  options: Array<Maybe<CustomAttributeOptionInterface>>
}

export interface CustomAttributeOptionInterface {
  /** Is the option value default. */
  isDefault: Scalars['Boolean']['output']
  /** The label assigned to the attribute option. */
  label: Scalars['String']['output']
  /** The attribute option value. */
  value: Scalars['String']['output']
}

/** Defines the customer name, addresses, and other details. */
export interface Customer {
  __typename?: 'Customer'
  /** An array containing the customer's shipping and billing addresses. */
  addresses: Maybe<Array<Maybe<CustomerAddress>>>
  /** Indicates whether the customer has enabled remote shopping assistance. */
  allowRemoteShoppingAssistance: Scalars['Boolean']['output']
  /** The contents of the customer's compare list. */
  compareList: Maybe<CompareList>
  /** The customer's confirmation status. */
  confirmationStatus: ConfirmationStatusEnum
  /** Timestamp indicating when the account was created. */
  createdAt: Maybe<Scalars['String']['output']>
  /** Customer's custom attributes. */
  customAttributes: Maybe<Array<Maybe<AttributeValueInterface>>>
  /** The customer's date of birth. */
  dateOfBirth: Maybe<Scalars['String']['output']>
  /** The ID assigned to the billing address. */
  defaultBilling: Maybe<Scalars['String']['output']>
  /** The ID assigned to the shipping address. */
  defaultShipping: Maybe<Scalars['String']['output']>
  /**
   * The customer's date of birth.
   * @deprecated Use `date_of_birth` instead.
   */
  dob: Maybe<Scalars['String']['output']>
  /** The customer's email address. Required. */
  email: Maybe<Scalars['String']['output']>
  /** The customer's first name. */
  firstname: Maybe<Scalars['String']['output']>
  /** The customer's gender (Male - 1, Female - 2). */
  gender: Maybe<Scalars['Int']['output']>
  /** Details about all of the customer's gift registries. */
  giftRegistries: Maybe<Array<Maybe<GiftRegistry>>>
  /** Details about a specific gift registry. */
  giftRegistry: Maybe<GiftRegistry>
  /** @deprecated Customer group should not be exposed in the storefront scenarios. */
  groupId: Maybe<Scalars['Int']['output']>
  /**
   * The ID assigned to the customer.
   * @deprecated `id` is not needed as part of `Customer`, because on the server side, it can be identified based on the customer token used for authentication. There is no need to know customer ID on the client side.
   */
  id: Maybe<Scalars['Int']['output']>
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  isSubscribed: Maybe<Scalars['Boolean']['output']>
  /** The job title of a company user. */
  jobTitle: Maybe<Scalars['String']['output']>
  /** The customer's family name. */
  lastname: Maybe<Scalars['String']['output']>
  /** The customer's middle name. */
  middlename: Maybe<Scalars['String']['output']>
  orders: Maybe<CustomerOrders>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']['output']>
  /** Purchase order details. */
  purchaseOrder: Maybe<PurchaseOrder>
  /** Details about a single purchase order approval rule. */
  purchaseOrderApprovalRule: Maybe<PurchaseOrderApprovalRule>
  /** Purchase order approval rule metadata that can be used for rule edit form rendering. */
  purchaseOrderApprovalRuleMetadata: Maybe<PurchaseOrderApprovalRuleMetadata>
  /** A list of purchase order approval rules visible to the customer. */
  purchaseOrderApprovalRules: Maybe<PurchaseOrderApprovalRules>
  /** A list of purchase orders visible to the customer. */
  purchaseOrders: Maybe<PurchaseOrders>
  /** Indicates whether purchase order functionality is enabled for the current customer. Global and company-level settings are factored into the result. */
  purchaseOrdersEnabled: Scalars['Boolean']['output']
  /** An object that contains the customer's requisition lists. */
  requisitionLists: Maybe<RequisitionLists>
  /** Details about the specified return request from the unique ID for a `Return` object. */
  return: Maybe<Return>
  /** Information about the customer's return requests. */
  returns: Maybe<Returns>
  /** Contains the customer's product reviews. */
  reviews: ProductReviews
  /** Customer reward points details. */
  rewardPoints: Maybe<RewardPoints>
  /** The role name and permissions assigned to the company user. */
  role: Maybe<CompanyRole>
  /** Indicates whether the company user is ACTIVE or INACTIVE. */
  status: Maybe<CompanyUserStatusEnum>
  /** Store credit information applied for the logged in customer. */
  storeCredit: Maybe<CustomerStoreCredit>
  /** ID of the company structure */
  structureId: Scalars['ID']['output']
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']['output']>
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  taxvat: Maybe<Scalars['String']['output']>
  /** The team the company user is assigned to. */
  team: Maybe<CompanyTeam>
  /** The phone number of the company user. */
  telephone: Maybe<Scalars['String']['output']>
  /**
   * Return a customer's wish lists.
   * @deprecated Use `Customer.wishlists` or `Customer.wishlist_v2` instead.
   */
  wishlist: Wishlist
  /** Retrieve the wish list identified by the unique ID for a `Wishlist` object. */
  wishlistV2: Maybe<Wishlist>
  /** An array of wishlists. In Magento Open Source, customers are limited to one wish list. The number of wish lists is configurable for Adobe Commerce. */
  wishlists: Array<Maybe<Wishlist>>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerCustomAttributesArgs {
  attributeCodes: InputMaybe<Array<Scalars['ID']['input']>>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerGiftRegistryArgs {
  giftRegistryUid: Scalars['ID']['input']
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerOrdersArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter: InputMaybe<CustomerOrdersFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  scope: InputMaybe<ScopeTypeEnum>
  sort: InputMaybe<CustomerOrderSortInput>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerPurchaseOrderArgs {
  uid: Scalars['ID']['input']
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerPurchaseOrderApprovalRuleArgs {
  uid: Scalars['ID']['input']
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerPurchaseOrderApprovalRulesArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerPurchaseOrdersArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter: InputMaybe<PurchaseOrdersFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerRequisitionListsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter: InputMaybe<RequisitionListFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerReturnArgs {
  uid: Scalars['ID']['input']
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerReturnsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerWishlistV2Args {
  id: Scalars['ID']['input']
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerWishlistsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Contains detailed information about a customer's billing or shipping address. */
export interface CustomerAddress {
  __typename?: 'CustomerAddress'
  /** The customer's city or town. */
  city: Maybe<Scalars['String']['output']>
  /** The customer's company. */
  company: Maybe<Scalars['String']['output']>
  /** The customer's country. */
  countryCode: Maybe<CountryCodeEnum>
  /**
   * The customer's country.
   * @deprecated Use `country_code` instead.
   */
  countryId: Maybe<Scalars['String']['output']>
  /** @deprecated Use custom_attributesV2 instead. */
  customAttributes: Maybe<Array<Maybe<CustomerAddressAttribute>>>
  /** Custom attributes assigned to the customer address. */
  customAttributesV2: Array<Maybe<AttributeValueInterface>>
  /**
   * The customer ID
   * @deprecated `customer_id` is not needed as part of `CustomerAddress`. The `id` is a unique identifier for the addresses.
   */
  customerId: Maybe<Scalars['Int']['output']>
  /** Indicates whether the address is the customer's default billing address. */
  defaultBilling: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the address is the customer's default shipping address. */
  defaultShipping: Maybe<Scalars['Boolean']['output']>
  /** Contains any extension attributes for the address. */
  extensionAttributes: Maybe<Array<Maybe<CustomerAddressAttribute>>>
  /** The customer's fax number. */
  fax: Maybe<Scalars['String']['output']>
  /** The first name of the person associated with the shipping/billing address. */
  firstname: Maybe<Scalars['String']['output']>
  /** The ID of a `CustomerAddress` object. */
  id: Maybe<Scalars['Int']['output']>
  /** The family name of the person associated with the shipping/billing address. */
  lastname: Maybe<Scalars['String']['output']>
  /** The middle name of the person associated with the shipping/billing address. */
  middlename: Maybe<Scalars['String']['output']>
  /** The customer's ZIP or postal code. */
  postcode: Maybe<Scalars['String']['output']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']['output']>
  /** An object containing the region name, region code, and region ID. */
  region: Maybe<CustomerAddressRegion>
  /** The unique ID for a pre-defined region. */
  regionId: Maybe<Scalars['Int']['output']>
  /** An array of strings that define the street number and name. */
  street: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']['output']>
  /** The customer's telephone number. */
  telephone: Maybe<Scalars['String']['output']>
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  vatId: Maybe<Scalars['String']['output']>
}

/** Contains detailed information about a customer's billing or shipping address. */
export interface CustomerAddressCustomAttributesV2Args {
  attributeCodes: InputMaybe<Array<Scalars['ID']['input']>>
}

/** Specifies the attribute code and value of a customer address attribute. */
export interface CustomerAddressAttribute {
  __typename?: 'CustomerAddressAttribute'
  /** The name assigned to the customer address attribute. */
  attributeCode: Maybe<Scalars['String']['output']>
  /** The value assigned to the customer address attribute. */
  value: Maybe<Scalars['String']['output']>
}

/** Specifies the attribute code and value of a customer attribute. */
export interface CustomerAddressAttributeInput {
  /** The name assigned to the attribute. */
  attribute_code: Scalars['String']['input']
  /** The value assigned to the attribute. */
  value: Scalars['String']['input']
}

/** Contains details about a billing or shipping address. */
export interface CustomerAddressInput {
  /** The customer's city or town. */
  city?: InputMaybe<Scalars['String']['input']>
  /** The customer's company. */
  company?: InputMaybe<Scalars['String']['input']>
  /** The two-letter code representing the customer's country. */
  country_code?: InputMaybe<CountryCodeEnum>
  /** Deprecated: use `country_code` instead. */
  country_id?: InputMaybe<CountryCodeEnum>
  /** Deprecated. Use custom_attributesV2 instead. */
  custom_attributes?: InputMaybe<
    Array<InputMaybe<CustomerAddressAttributeInput>>
  >
  /** Custom attributes assigned to the customer address. */
  custom_attributesV2?: InputMaybe<Array<InputMaybe<AttributeValueInput>>>
  /** Indicates whether the address is the default billing address. */
  default_billing?: InputMaybe<Scalars['Boolean']['input']>
  /** Indicates whether the address is the default shipping address. */
  default_shipping?: InputMaybe<Scalars['Boolean']['input']>
  /** The customer's fax number. */
  fax?: InputMaybe<Scalars['String']['input']>
  /** The first name of the person associated with the billing/shipping address. */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** The family name of the person associated with the billing/shipping address. */
  lastname?: InputMaybe<Scalars['String']['input']>
  /** The middle name of the person associated with the billing/shipping address. */
  middlename?: InputMaybe<Scalars['String']['input']>
  /** The customer's ZIP or postal code. */
  postcode?: InputMaybe<Scalars['String']['input']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>
  /** An object containing the region name, region code, and region ID. */
  region?: InputMaybe<CustomerAddressRegionInput>
  /** An array of strings that define the street number and name. */
  street?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']['input']>
  /** The customer's telephone number. */
  telephone?: InputMaybe<Scalars['String']['input']>
  /** The customer's Tax/VAT number (for corporate customers). */
  vat_id?: InputMaybe<Scalars['String']['input']>
}

/** Defines the customer's state or province. */
export interface CustomerAddressRegion {
  __typename?: 'CustomerAddressRegion'
  /** The state or province name. */
  region: Maybe<Scalars['String']['output']>
  /** The address region code. */
  regionCode: Maybe<Scalars['String']['output']>
  /** The unique ID for a pre-defined region. */
  regionId: Maybe<Scalars['Int']['output']>
}

/** Defines the customer's state or province. */
export interface CustomerAddressRegionInput {
  /** The state or province name. */
  region?: InputMaybe<Scalars['String']['input']>
  /** The address region code. */
  region_code?: InputMaybe<Scalars['String']['input']>
  /** The unique ID for a pre-defined region. */
  region_id?: InputMaybe<Scalars['Int']['input']>
}

/** Customer attribute metadata. */
export interface CustomerAttributeMetadata
  extends CustomAttributeMetadataInterface {
  __typename?: 'CustomerAttributeMetadata'
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  code: Scalars['ID']['output']
  /** Default attribute value. */
  defaultValue: Maybe<Scalars['String']['output']>
  /** The type of entity that defines the attribute. */
  entityType: AttributeEntityTypeEnum
  /** The frontend class of the attribute. */
  frontendClass: Maybe<Scalars['String']['output']>
  /** The frontend input type of the attribute. */
  frontendInput: Maybe<AttributeFrontendInputEnum>
  /** The template used for the input of the attribute (e.g., 'date'). */
  inputFilter: Maybe<InputFilterEnum>
  /** Whether the attribute value is required. */
  isRequired: Scalars['Boolean']['output']
  /** Whether the attribute value must be unique. */
  isUnique: Scalars['Boolean']['output']
  /** The label assigned to the attribute. */
  label: Maybe<Scalars['String']['output']>
  /** The number of lines of the attribute value. */
  multilineCount: Maybe<Scalars['Int']['output']>
  /** Attribute options. */
  options: Array<Maybe<CustomAttributeOptionInterface>>
  /** The position of the attribute in the form. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The validation rules of the attribute value. */
  validateRules: Maybe<Array<Maybe<ValidationRule>>>
}

export interface CustomerConfiguration {
  __typename?: 'CustomerConfiguration'
  /** Created At */
  createdAt: Maybe<Scalars['String']['output']>
  /** Configuration Id */
  entityId: Maybe<Scalars['Int']['output']>
  /** Configuration Name */
  name: Maybe<Scalars['String']['output']>
  /** Updated At */
  updatedAt: Maybe<Scalars['String']['output']>
  /** Configuration Url */
  url: Maybe<Scalars['String']['output']>
}

/** An input object for creating a customer. */
export interface CustomerCreateInput {
  /** Indicates whether the customer has enabled remote shopping assistance. */
  allow_remote_shopping_assistance?: InputMaybe<Scalars['Boolean']['input']>
  /** The customer's custom attributes. */
  custom_attributes?: InputMaybe<Array<InputMaybe<AttributeValueInput>>>
  /** The customer's date of birth. */
  date_of_birth?: InputMaybe<Scalars['String']['input']>
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: InputMaybe<Scalars['String']['input']>
  /** The customer's email address. */
  email: Scalars['String']['input']
  /** The customer's first name. */
  firstname: Scalars['String']['input']
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: InputMaybe<Scalars['Int']['input']>
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: InputMaybe<Scalars['Boolean']['input']>
  /** The customer's family name. */
  lastname: Scalars['String']['input']
  /** The customer's middle name. */
  middlename?: InputMaybe<Scalars['String']['input']>
  /** The customer's password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']['input']>
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: InputMaybe<Scalars['String']['input']>
}

export interface CustomerCreditResult {
  __typename?: 'CustomerCreditResult'
  /** Customer spend credit. */
  availableCredit: Maybe<Scalars['Float']['output']>
  /** Customer credit. */
  credit: Maybe<Scalars['Float']['output']>
}

/** Contains details about a single downloadable product. */
export interface CustomerDownloadableProduct {
  __typename?: 'CustomerDownloadableProduct'
  /** The date and time the purchase was made. */
  date: Maybe<Scalars['String']['output']>
  /** The fully qualified URL to the download file. */
  downloadUrl: Maybe<Scalars['String']['output']>
  /** The unique ID assigned to the item. */
  orderIncrementId: Maybe<Scalars['String']['output']>
  /** The remaining number of times the customer can download the product. */
  remainingDownloads: Maybe<Scalars['String']['output']>
  /** Indicates when the product becomes available for download. Options are `Pending` and `Invoiced`. */
  status: Maybe<Scalars['String']['output']>
}

/** Contains a list of downloadable products. */
export interface CustomerDownloadableProducts {
  __typename?: 'CustomerDownloadableProducts'
  /** An array of purchased downloadable items. */
  items: Maybe<Array<Maybe<CustomerDownloadableProduct>>>
}

/** An input object that assigns or updates customer attributes. */
export interface CustomerInput {
  /** The customer's date of birth. */
  date_of_birth?: InputMaybe<Scalars['String']['input']>
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: InputMaybe<Scalars['String']['input']>
  /** The customer's email address. Required when creating a customer. */
  email?: InputMaybe<Scalars['String']['input']>
  /** The customer's first name. */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: InputMaybe<Scalars['Int']['input']>
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: InputMaybe<Scalars['Boolean']['input']>
  /** The customer's family name. */
  lastname?: InputMaybe<Scalars['String']['input']>
  /** The customer's middle name. */
  middlename?: InputMaybe<Scalars['String']['input']>
  /** The customer's password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']['input']>
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: InputMaybe<Scalars['String']['input']>
}

/** Contains details about each of the customer's orders. */
export interface CustomerOrder {
  __typename?: 'CustomerOrder'
  /** Coupons applied to the order. */
  appliedCoupons: Array<Maybe<AppliedCoupon>>
  /** The billing address for the order. */
  billingAddress: Maybe<OrderAddress>
  /** The shipping carrier for the order delivery. */
  carrier: Maybe<Scalars['String']['output']>
  /** Comments about the order. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>
  /** @deprecated Use the `order_date` field instead. */
  createdAt: Maybe<Scalars['String']['output']>
  /** A list of credit memos. */
  creditMemos: Maybe<Array<Maybe<CreditMemo>>>
  /** Order customer email. */
  email: Maybe<Scalars['String']['output']>
  /** The entered gift message for the order */
  giftMessage: Maybe<GiftMessage>
  /** Indicates whether the customer requested a gift receipt for the order. */
  giftReceiptIncluded: Scalars['Boolean']['output']
  /** The selected gift wrapping for the order. */
  giftWrapping: Maybe<GiftWrapping>
  /** @deprecated Use the `totals.grand_total` field instead. */
  grandTotal: Maybe<Scalars['Float']['output']>
  /** The unique ID for a `CustomerOrder` object. */
  id: Scalars['ID']['output']
  /** @deprecated Use the `id` field instead. */
  incrementId: Maybe<Scalars['String']['output']>
  /** A list of invoices for the order. */
  invoices: Array<Maybe<Invoice>>
  /** An array containing the items purchased in this order. */
  items: Maybe<Array<Maybe<OrderItemInterface>>>
  /** A list of order items eligible to be in a return request. */
  itemsEligibleForReturn: Maybe<Array<Maybe<OrderItemInterface>>>
  /** The order number. */
  number: Scalars['String']['output']
  /** The date the order was placed. */
  orderDate: Scalars['String']['output']
  /** @deprecated Use the `number` field instead. */
  orderNumber: Scalars['String']['output']
  /** Payment details for the order. */
  paymentMethods: Maybe<Array<Maybe<OrderPaymentMethod>>>
  /** Indicates whether the customer requested a printed card for the order. */
  printedCardIncluded: Scalars['Boolean']['output']
  /** Return requests associated with this order. */
  returns: Maybe<Returns>
  /** A list of shipments for the order. */
  shipments: Maybe<Array<Maybe<OrderShipment>>>
  /** The shipping address for the order. */
  shippingAddress: Maybe<OrderAddress>
  /** The delivery method for the order. */
  shippingMethod: Maybe<Scalars['String']['output']>
  /** The current status of the order. */
  status: Scalars['String']['output']
  /** The token that can be used to retrieve the order using order query. */
  token: Scalars['String']['output']
  /** Details about the calculated totals for this order. */
  total: Maybe<OrderTotal>
}

/** Contains details about each of the customer's orders. */
export interface CustomerOrderReturnsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** CustomerOrderSortInput specifies the field to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export interface CustomerOrderSortInput {
  /** This enumeration indicates whether to return results in ascending or descending order */
  sort_direction: SortEnum
  /** Specifies the field to use for sorting */
  sort_field: CustomerOrderSortableField
}

/** Specifies the field to use for sorting */
export enum CustomerOrderSortableField {
  /** Sorts customer orders by created_at field */
  CREATED_AT = 'CREATED_AT',
  /** Sorts customer orders by number */
  NUMBER = 'NUMBER',
}

/** The collection of orders that match the conditions defined in the filter. */
export interface CustomerOrders {
  __typename?: 'CustomerOrders'
  /** An array of customer orders. */
  items: Array<Maybe<CustomerOrder>>
  /** Contains pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The total count of customer orders. */
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Identifies the filter to use for filtering orders. */
export interface CustomerOrdersFilterInput {
  /** Filters by order number. */
  number?: InputMaybe<FilterStringTypeInput>
}

/** Contains details about a newly-created or updated customer. */
export interface CustomerOutput {
  __typename?: 'CustomerOutput'
  /** Customer details after creating or updating a customer. */
  customer: Customer
}

/** Contains payment tokens stored in the customer's vault. */
export interface CustomerPaymentTokens {
  __typename?: 'CustomerPaymentTokens'
  /** An array of payment tokens. */
  items: Array<Maybe<PaymentToken>>
}

/** Contains store credit information with balance and history. */
export interface CustomerStoreCredit {
  __typename?: 'CustomerStoreCredit'
  /** Contains the customer's store credit balance history. If the history or store credit feature is disabled, then a null value will be returned. */
  balanceHistory: Maybe<CustomerStoreCreditHistory>
  /** The current balance of store credit. */
  currentBalance: Maybe<Money>
  /** Indicates whether store credits are enabled. If the feature is disabled, then the balance will not be returned. */
  enabled: Maybe<Scalars['Boolean']['output']>
}

/** Contains store credit information with balance and history. */
export interface CustomerStoreCreditBalanceHistoryArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Lists changes to the amount of store credit available to the customer. */
export interface CustomerStoreCreditHistory {
  __typename?: 'CustomerStoreCreditHistory'
  /** An array containing information about changes to the store credit available to the customer. */
  items: Maybe<Array<Maybe<CustomerStoreCreditHistoryItem>>>
  /** Metadata for pagination rendering. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The number of items returned. */
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Contains store credit history information. */
export interface CustomerStoreCreditHistoryItem {
  __typename?: 'CustomerStoreCreditHistoryItem'
  /** The action that was made on the store credit. */
  action: Maybe<Scalars['String']['output']>
  /** The store credit available to the customer as a result of this action.  */
  actualBalance: Maybe<Money>
  /** The amount added to or subtracted from the store credit as a result of this action. */
  balanceChange: Maybe<Money>
  /** The date and time when the store credit change was made. */
  dateTimeChanged: Maybe<Scalars['String']['output']>
}

/** Contains a customer authorization token. */
export interface CustomerToken {
  __typename?: 'CustomerToken'
  /** The customer authorization token. */
  token: Maybe<Scalars['String']['output']>
}

/** An input object for updating a customer. */
export interface CustomerUpdateInput {
  /** Indicates whether the customer has enabled remote shopping assistance. */
  allow_remote_shopping_assistance?: InputMaybe<Scalars['Boolean']['input']>
  /** The customer's custom attributes. */
  custom_attributes?: InputMaybe<Array<InputMaybe<AttributeValueInput>>>
  /** The customer's date of birth. */
  date_of_birth?: InputMaybe<Scalars['String']['input']>
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: InputMaybe<Scalars['String']['input']>
  /** The customer's first name. */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: InputMaybe<Scalars['Int']['input']>
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: InputMaybe<Scalars['Boolean']['input']>
  /** The customer's family name. */
  lastname?: InputMaybe<Scalars['String']['input']>
  /** The customer's middle name. */
  middlename?: InputMaybe<Scalars['String']['input']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']['input']>
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: InputMaybe<Scalars['String']['input']>
}

/** Contains information about a text area that is defined as part of a customizable option. */
export interface CustomizableAreaOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableAreaOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An object that defines a text area. */
  value: Maybe<CustomizableAreaValue>
}

/** Defines the price and sku of a product whose page contains a customized text area. */
export interface CustomizableAreaValue {
  __typename?: 'CustomizableAreaValue'
  /** The maximum number of characters that can be entered for this customizable option. */
  maxCharacters: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableAreaValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains information about a set of checkbox values that are defined as part of a customizable option. */
export interface CustomizableCheckboxOption
  extends CustomizableOptionInterface {
  __typename?: 'CustomizableCheckboxOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An array that defines a set of checkbox values. */
  value: Maybe<Array<Maybe<CustomizableCheckboxValue>>>
}

/** Defines the price and sku of a product whose page contains a customized set of checkbox values. */
export interface CustomizableCheckboxValue {
  __typename?: 'CustomizableCheckboxValue'
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** The order in which the checkbox value is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableCheckboxValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains information about a date picker that is defined as part of a customizable option. */
export interface CustomizableDateOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableDateOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An object that defines a date field in a customizable option. */
  value: Maybe<CustomizableDateValue>
}

/** Defines the customizable date type. */
export enum CustomizableDateTypeEnum {
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  TIME = 'TIME',
}

/** Defines the price and sku of a product whose page contains a customized date picker. */
export interface CustomizableDateValue {
  __typename?: 'CustomizableDateValue'
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** DATE, DATE_TIME or TIME */
  type: Maybe<CustomizableDateTypeEnum>
  /** The unique ID for a `CustomizableDateValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains information about a drop down menu that is defined as part of a customizable option. */
export interface CustomizableDropDownOption
  extends CustomizableOptionInterface {
  __typename?: 'CustomizableDropDownOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An array that defines the set of options for a drop down menu. */
  value: Maybe<Array<Maybe<CustomizableDropDownValue>>>
}

/** Defines the price and sku of a product whose page contains a customized drop down menu. */
export interface CustomizableDropDownValue {
  __typename?: 'CustomizableDropDownValue'
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableDropDownValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains information about a text field that is defined as part of a customizable option. */
export interface CustomizableFieldOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableFieldOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An object that defines a text field. */
  value: Maybe<CustomizableFieldValue>
}

/** Defines the price and sku of a product whose page contains a customized text field. */
export interface CustomizableFieldValue {
  __typename?: 'CustomizableFieldValue'
  /** The maximum number of characters that can be entered for this customizable option. */
  maxCharacters: Maybe<Scalars['Int']['output']>
  /** The price of the custom value. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableFieldValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains information about a file picker that is defined as part of a customizable option. */
export interface CustomizableFileOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableFileOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An object that defines a file value. */
  value: Maybe<CustomizableFileValue>
}

/** Defines the price and sku of a product whose page contains a customized file picker. */
export interface CustomizableFileValue {
  __typename?: 'CustomizableFileValue'
  /** The file extension to accept. */
  fileExtension: Maybe<Scalars['String']['output']>
  /** The maximum width of an image. */
  imageSizeX: Maybe<Scalars['Int']['output']>
  /** The maximum height of an image. */
  imageSizeY: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableFileValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains information about a multiselect that is defined as part of a customizable option. */
export interface CustomizableMultipleOption
  extends CustomizableOptionInterface {
  __typename?: 'CustomizableMultipleOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An array that defines the set of options for a multiselect. */
  value: Maybe<Array<Maybe<CustomizableMultipleValue>>>
}

/** Defines the price and sku of a product whose page contains a customized multiselect. */
export interface CustomizableMultipleValue {
  __typename?: 'CustomizableMultipleValue'
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableMultipleValue` object. */
  uid: Scalars['ID']['output']
}

/** Defines a customizable option. */
export interface CustomizableOptionInput {
  /** The customizable option ID of the product. */
  id?: InputMaybe<Scalars['Int']['input']>
  /** The unique ID for a `CartItemInterface` object. */
  uid?: InputMaybe<Scalars['ID']['input']>
  /** The string value of the option. */
  value_string: Scalars['String']['input']
}

/** Contains basic information about a customizable option. It can be implemented by several types of configurable options. */
export interface CustomizableOptionInterface {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
}

/** Contains information about customizable product options. */
export interface CustomizableProductInterface {
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
}

/** Contains information about a set of radio buttons that are defined as part of a customizable option. */
export interface CustomizableRadioOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableRadioOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An array that defines a set of radio buttons. */
  value: Maybe<Array<Maybe<CustomizableRadioValue>>>
}

/** Defines the price and sku of a product whose page contains a customized set of radio buttons. */
export interface CustomizableRadioValue {
  __typename?: 'CustomizableRadioValue'
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** The order in which the radio button is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableRadioValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains the response to the request to delete the company role. */
export interface DeleteCompanyRoleOutput {
  __typename?: 'DeleteCompanyRoleOutput'
  /** SIndicates whether the company role has been deleted successfully. */
  success: Scalars['Boolean']['output']
}

/** Contains the status of the request to delete a company team. */
export interface DeleteCompanyTeamOutput {
  __typename?: 'DeleteCompanyTeamOutput'
  /** Indicates whether the delete operation succeeded. */
  success: Scalars['Boolean']['output']
}

/** Contains the response to the request to delete the company user. */
export interface DeleteCompanyUserOutput {
  __typename?: 'DeleteCompanyUserOutput'
  /** Indicates whether the company user has been deactivated successfully. */
  success: Scalars['Boolean']['output']
}

/** Contains the results of the request to delete a compare list. */
export interface DeleteCompareListOutput {
  __typename?: 'DeleteCompareListOutput'
  /** Indicates whether the compare list was successfully deleted. */
  result: Scalars['Boolean']['output']
}

export type DeleteNegotiableQuoteError =
  | InternalError
  | NegotiableQuoteInvalidStateError
  | NoSuchEntityUidError

/** Contains details about a failed delete operation on a negotiable quote. */
export interface DeleteNegotiableQuoteOperationFailure {
  __typename?: 'DeleteNegotiableQuoteOperationFailure'
  errors: Array<Maybe<DeleteNegotiableQuoteError>>
  /** The unique ID of a `NegotiableQuote` object. */
  quoteUid: Scalars['ID']['output']
}

export type DeleteNegotiableQuoteOperationResult =
  | DeleteNegotiableQuoteOperationFailure
  | NegotiableQuoteUidOperationSuccess

export interface DeleteNegotiableQuotesInput {
  /** A list of unique IDs for `NegotiableQuote` objects to delete. */
  quote_uids: Array<InputMaybe<Scalars['ID']['input']>>
}

/** Contains a list of undeleted negotiable quotes the company user can view. */
export interface DeleteNegotiableQuotesOutput {
  __typename?: 'DeleteNegotiableQuotesOutput'
  /** A list of negotiable quotes that the customer can view */
  negotiableQuotes: Maybe<NegotiableQuotesOutput>
  /** An array of deleted negotiable quote UIDs and details about any errors. */
  operationResults: Array<Maybe<DeleteNegotiableQuoteOperationResult>>
  /** The status of the request to delete one or more negotiable quotes. */
  resultStatus: BatchMutationStatus
}

/** Contains a list of undeleted negotiable quotes the company user can view. */
export interface DeleteNegotiableQuotesOutputNegotiableQuotesArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter: InputMaybe<NegotiableQuoteFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort: InputMaybe<NegotiableQuoteSortInput>
}

/** Indicates whether the request succeeded and returns the remaining customer payment tokens. */
export interface DeletePaymentTokenOutput {
  __typename?: 'DeletePaymentTokenOutput'
  /** A container for the customer's remaining payment tokens. */
  customerPaymentTokens: Maybe<CustomerPaymentTokens>
  /** Indicates whether the request succeeded. */
  result: Scalars['Boolean']['output']
}

/** Contains details about an error that occurred when deleting an approval rule . */
export interface DeletePurchaseOrderApprovalRuleError {
  __typename?: 'DeletePurchaseOrderApprovalRuleError'
  /** The text of the error message. */
  message: Maybe<Scalars['String']['output']>
  /** The error type. */
  type: Maybe<DeletePurchaseOrderApprovalRuleErrorType>
}

export enum DeletePurchaseOrderApprovalRuleErrorType {
  NOT_FOUND = 'NOT_FOUND',
  UNDEFINED = 'UNDEFINED',
}

/** Specifies the IDs of the approval rules to delete. */
export interface DeletePurchaseOrderApprovalRuleInput {
  /** An array of purchase order approval rule IDs. */
  approval_rule_uids: Array<InputMaybe<Scalars['ID']['input']>>
}

/** Contains any errors encountered while attempting to delete approval rules. */
export interface DeletePurchaseOrderApprovalRuleOutput {
  __typename?: 'DeletePurchaseOrderApprovalRuleOutput'
  /** An array of error messages encountered while performing the operation. */
  errors: Array<Maybe<DeletePurchaseOrderApprovalRuleError>>
}

/** Output of the request to remove items from the requisition list. */
export interface DeleteRequisitionListItemsOutput {
  __typename?: 'DeleteRequisitionListItemsOutput'
  /** The requisition list after removing items. */
  requisitionList: Maybe<RequisitionList>
}

/** Indicates whether the request to delete the requisition list was successful. */
export interface DeleteRequisitionListOutput {
  __typename?: 'DeleteRequisitionListOutput'
  /** The customer's requisition lists after deleting a requisition list. */
  requisitionLists: Maybe<RequisitionLists>
  /** Indicates whether the request to delete the requisition list was successful. */
  status: Scalars['Boolean']['output']
}

/** Contains the status of the request to delete a wish list and an array of the customer's remaining wish lists. */
export interface DeleteWishlistOutput {
  __typename?: 'DeleteWishlistOutput'
  /** Indicates whether the wish list was deleted. */
  status: Scalars['Boolean']['output']
  /** A list of undeleted wish lists. */
  wishlists: Array<Maybe<Wishlist>>
}

/** Delivery day for calendar */
export interface DeliveryDay {
  __typename?: 'DeliveryDay'
  /** Date of the delivery day (Y-m-d) */
  date: Maybe<Scalars['String']['output']>
  /** Delivery status of day */
  status: Maybe<DeliveryDayStatus>
}

export enum DeliveryDayStatus {
  AVAILABLE = 'AVAILABLE',
  AVAILABLE_PARTIAL = 'AVAILABLE_PARTIAL',
  UNAVAILABLE = 'UNAVAILABLE',
}

export interface DeliveryTimeFrame {
  __typename?: 'DeliveryTimeFrame'
  /** Delivery time frame can be disabled */
  canBeDisabled: Maybe<Scalars['Boolean']['output']>
  /** Delivery time frame cost */
  cost: Maybe<Money>
  /** Delivery time frame label */
  label: Maybe<Scalars['String']['output']>
  /** Delivery time frame value */
  timeFrame: Maybe<Scalars['String']['output']>
  /** Delivery time frame label */
  timeFrameLabel: Maybe<Scalars['String']['output']>
}

export enum DeliveryType {
  DROPSHIPMENT = 'DROPSHIPMENT',
  FULL = 'FULL',
  PARTIAL_DROPSHIPMENT = 'PARTIAL_DROPSHIPMENT',
}

/** Specifies the discount type and value for quote line item. */
export interface Discount {
  __typename?: 'Discount'
  /** The amount of the discount. */
  amount: Money
  /** The type of the entity the discount is applied to. */
  appliedTo: CartDiscountType
  /** The coupon related to the discount. */
  coupon: Maybe<AppliedCoupon>
  /** A description of the discount. */
  label: Scalars['String']['output']
  /** Quote line item discount type. Values: 1 = PERCENTAGE_DISCOUNT; 2 = AMOUNT_DISCOUNT; 3 = PROPOSED_TOTAL. */
  type: Maybe<Scalars['String']['output']>
  /** Quote line item discount value. */
  value: Maybe<Scalars['Float']['output']>
}

/** An implementation for downloadable product cart items. */
export interface DownloadableCartItem extends CartItemInterface {
  __typename?: 'DownloadableCartItem'
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** Delivery date of the cart item (Y-m-d) */
  deliveryDate: Maybe<Scalars['String']['output']>
  /** Contains discount for quote line item. */
  discount: Maybe<Array<Maybe<Discount>>>
  /** Elektramat configuration packslip */
  elektramatPackslip: Maybe<Scalars['String']['output']>
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output']
  /** True if requested quantity is less than available stock, false otherwise. */
  isAvailable: Scalars['Boolean']['output']
  /** An array containing information about the links for the downloadable product added to the cart. */
  links: Maybe<Array<Maybe<DownloadableProductLinks>>>
  /** The buyer's quote line item note. */
  noteFromBuyer: Maybe<Array<Maybe<ItemNote>>>
  /** The seller's quote line item note. */
  noteFromSeller: Maybe<Array<Maybe<ItemNote>>>
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>
  /** Details about an item in the cart. */
  product: ProductInterface
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output']
  /** An array containing information about samples of the selected downloadable product. */
  samples: Maybe<Array<Maybe<DownloadableProductSamples>>>
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output']
}

/** Defines downloadable product options for `CreditMemoItemInterface`. */
export interface DownloadableCreditMemoItem extends CreditMemoItemInterface {
  __typename?: 'DownloadableCreditMemoItem'
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** A list of downloadable links that are refunded from the downloadable product. */
  downloadableLinks: Maybe<Array<Maybe<DownloadableItemsLinks>>>
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
}

export enum DownloadableFileTypeEnum {
  /** @deprecated `sample_url` serves to get the downloadable sample */
  FILE = 'FILE',
  /** @deprecated `sample_url` serves to get the downloadable sample */
  URL = 'URL',
}

/** Defines downloadable product options for `InvoiceItemInterface`. */
export interface DownloadableInvoiceItem extends InvoiceItemInterface {
  __typename?: 'DownloadableInvoiceItem'
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** A list of downloadable links that are invoiced from the downloadable product. */
  downloadableLinks: Maybe<Array<Maybe<DownloadableItemsLinks>>>
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID']['output']
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
}

/** Defines characteristics of the links for downloadable product. */
export interface DownloadableItemsLinks {
  __typename?: 'DownloadableItemsLinks'
  /** A number indicating the sort order. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name of the link. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `DownloadableItemsLinks` object. */
  uid: Scalars['ID']['output']
}

/** Defines downloadable product options for `OrderItemInterface`. */
export interface DownloadableOrderItem extends OrderItemInterface {
  __typename?: 'DownloadableOrderItem'
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** A list of downloadable links that are ordered from the downloadable product. */
  downloadableLinks: Maybe<Array<Maybe<DownloadableItemsLinks>>>
  /** Indicates whether the order item is eligible to be in a return request. */
  eligibleForReturn: Maybe<Scalars['Boolean']['output']>
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>
  /** The selected gift wrapping for the order item. */
  giftWrapping: Maybe<GiftWrapping>
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID']['output']
  /** The ProductInterface object, which contains details about the base product */
  product: Maybe<ProductInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']['output']>
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']['output']>
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']['output']>
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']['output']>
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']['output']>
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']['output']>
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The status of the order item. */
  status: Maybe<Scalars['String']['output']>
}

/** Defines a product that the shopper downloads. */
export interface DownloadableProduct
  extends CustomizableProductInterface,
    ProductInterface,
    RoutableInterface {
  __typename?: 'DownloadableProduct'
  /** @deprecated Use the `custom_attributes` field instead. */
  articleNumber: Maybe<Scalars['String']['output']>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  color: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  costPrice: Maybe<Scalars['String']['output']>
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** Product custom attributes. */
  customAttributesV2: Maybe<ProductCustomAttributes>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>
  /** An array containing information about the links for this downloadable product. */
  downloadableProductLinks: Maybe<Array<Maybe<DownloadableProductLinks>>>
  /** An array containing information about samples of this downloadable product. */
  downloadableProductSamples: Maybe<Array<Maybe<DownloadableProductSamples>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']['output']>
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>
  /** @deprecated Use the `custom_attributes` field instead. */
  isClonedForStore: Maybe<Scalars['String']['output']>
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']['output']>
  /** A value of 1 indicates that each link in the array must be purchased separately. */
  linksPurchasedSeparately: Maybe<Scalars['Int']['output']>
  /** The heading above the list of downloadable products. */
  linksTitle: Maybe<Scalars['String']['output']>
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  manufacturerId: Maybe<Scalars['String']['output']>
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['String']['output']>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minimumQuantityAllowed: Maybe<Scalars['String']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  phasedOut: Maybe<Scalars['String']['output']>
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  productgroep: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  quantityIncrements: Maybe<Scalars['String']['output']>
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float']['output']
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalCrossection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDegreeofprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalEarthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalIdentificationcores: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalInnerdepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductorcrosssection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductordiameter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalinsulationthickness: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofcores: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofmodules: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofpoles: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofrows: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalOuterdiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalRatedvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalTrippingcharacteristic: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWithattachmentoption: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBundlesperpallet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingCabletype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingDiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingFireretardancy: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorBoxquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorCablediameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorSolarpanelcompatibility: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerConnector: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerPayload: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerRfidcardreader: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerSuitableforoutdoormounting: Maybe<
    Scalars['Int']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWirelessconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemRoofpitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWindstandard: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMaxsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMinsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringCommunicationtype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSeriecompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSignalrange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDaktype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHeightatthetop: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHoogtebovenzijde: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalPaletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperbox: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperpalet: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalRooftype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcdcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAfci: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerCompatiblestorage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcswitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerInvertertype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximuminputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMicropanelpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMinearthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMonitoringoptions: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMppvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerNumberofmpptrackers: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerPaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperbox: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperpalet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerRatedacpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerSolarpanels: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStartupvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStringspermpptracker: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerUsablebatterycapacity: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemBatvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCommunicationport: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxbatcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxoutputpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemOutputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemStoragecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemUsablecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBacksheetcolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBuild: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCelltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFramecolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelGuaranteedpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelJunctionboxprotectiondegree: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelMaxsystemvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelModuleefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelNumberofcells: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletsize: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPanelspercontainer: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPowerguarantee: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelSolarpaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelThickness: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWattagepersquaremeter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWindandsnowload: Maybe<Scalars['String']['output']>
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean']['output']
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  usp: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  voorraadstatus: Maybe<Scalars['String']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>
}

/** Defines a product that the shopper downloads. */
export interface DownloadableProductCustomAttributesV2Args {
  filters: InputMaybe<AttributeFilterInput>
}

/** Defines a product that the shopper downloads. */
export interface DownloadableProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines a single downloadable product. */
export interface DownloadableProductCartItemInput {
  /** The ID and value of the option. */
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  /** The quantity and SKU of the downloadable product. */
  data: CartItemInput
  /** An array of objects containing the link_id of the downloadable product link. */
  downloadable_product_links?: InputMaybe<
    Array<InputMaybe<DownloadableProductLinksInput>>
  >
}

/** Defines characteristics of a downloadable product. */
export interface DownloadableProductLinks {
  __typename?: 'DownloadableProductLinks'
  /** @deprecated This information should not be exposed on frontend. */
  id: Maybe<Scalars['Int']['output']>
  /** @deprecated This information should not be exposed on frontend. */
  isShareable: Maybe<Scalars['Boolean']['output']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  linkType: Maybe<DownloadableFileTypeEnum>
  /** @deprecated This information should not be exposed on frontend. */
  numberOfDownloads: Maybe<Scalars['Int']['output']>
  /** The price of the downloadable product. */
  price: Maybe<Scalars['Float']['output']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleFile: Maybe<Scalars['String']['output']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleType: Maybe<DownloadableFileTypeEnum>
  /** The full URL to the downloadable sample. */
  sampleUrl: Maybe<Scalars['String']['output']>
  /** A number indicating the sort order. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name of the link. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `DownloadableProductLinks` object. */
  uid: Scalars['ID']['output']
}

/** Contains the link ID for the downloadable product. */
export interface DownloadableProductLinksInput {
  /** The unique ID of the downloadable product link. */
  link_id: Scalars['Int']['input']
}

/** Defines characteristics of a downloadable product. */
export interface DownloadableProductSamples {
  __typename?: 'DownloadableProductSamples'
  /** @deprecated This information should not be exposed on frontend. */
  id: Maybe<Scalars['Int']['output']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleFile: Maybe<Scalars['String']['output']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleType: Maybe<DownloadableFileTypeEnum>
  /** The full URL to the downloadable sample. */
  sampleUrl: Maybe<Scalars['String']['output']>
  /** A number indicating the sort order. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name of the sample. */
  title: Maybe<Scalars['String']['output']>
}

/** Contains details about downloadable products added to a requisition list. */
export interface DownloadableRequisitionListItem
  extends RequisitionListItemInterface {
  __typename?: 'DownloadableRequisitionListItem'
  /** Selected custom options for an item in the requisition list. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** An array of links for downloadable products in the requisition list. */
  links: Maybe<Array<Maybe<DownloadableProductLinks>>>
  /** Details about a requisition list item. */
  product: ProductInterface
  /** The quantity of the product added to the requisition list. */
  quantity: Scalars['Float']['output']
  /** An array of links to downloadable product samples. */
  samples: Maybe<Array<Maybe<DownloadableProductSamples>>>
  /** The unique ID of an item in a requisition list. */
  uid: Scalars['ID']['output']
}

/** A downloadable product wish list item. */
export interface DownloadableWishlistItem extends WishlistItemInterface {
  __typename?: 'DownloadableWishlistItem'
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output']
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output']
  /** An array containing information about the selected links. */
  linksV2: Maybe<Array<Maybe<DownloadableProductLinks>>>
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output']
  /** An array containing information about the selected samples. */
  samples: Maybe<Array<Maybe<DownloadableProductSamples>>>
}

/** Contains a single dynamic block. */
export interface DynamicBlock {
  __typename?: 'DynamicBlock'
  /** The renderable HTML code of the dynamic block. */
  content: ComplexTextValue
  /** The unique ID of a `DynamicBlock` object. */
  uid: Scalars['ID']['output']
}

/** Indicates the locations the dynamic block can be placed. If this field is not specified, the query returns all locations. */
export enum DynamicBlockLocationEnum {
  CONTENT = 'CONTENT',
  FOOTER = 'FOOTER',
  HEADER = 'HEADER',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

/** Indicates the selected Dynamic Blocks Rotator inline widget. */
export enum DynamicBlockTypeEnum {
  CART_PRICE_RULE_RELATED = 'CART_PRICE_RULE_RELATED',
  CATALOG_PRICE_RULE_RELATED = 'CATALOG_PRICE_RULE_RELATED',
  SPECIFIED = 'SPECIFIED',
}

/** Contains an array of dynamic blocks. */
export interface DynamicBlocks {
  __typename?: 'DynamicBlocks'
  /** An array containing individual dynamic blocks. */
  items: Array<Maybe<DynamicBlock>>
  /** Metadata for pagination rendering. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The number of returned dynamic blocks. */
  totalCount: Scalars['Int']['output']
}

/** Defines the dynamic block filter. The filter can identify the block type, location and IDs to return. */
export interface DynamicBlocksFilterInput {
  /** An array of dynamic block UIDs to filter on. */
  dynamic_block_uids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** An array indicating the locations the dynamic block can be placed. */
  locations?: InputMaybe<Array<InputMaybe<DynamicBlockLocationEnum>>>
  /** A value indicating the type of dynamic block to filter on. */
  type: DynamicBlockTypeEnum
}

/** ElektramatConfiguration defines the Elektramat Configuration product */
export interface ElektramatConfiguration {
  __typename?: 'ElektramatConfiguration'
  buildSku: Maybe<Scalars['String']['output']>
  guid: Maybe<Scalars['String']['output']>
  image: Maybe<Scalars['String']['output']>
  name: Maybe<Scalars['String']['output']>
  packslip: Maybe<Scalars['String']['output']>
  subTotal: Maybe<Money>
  total: Maybe<Money>
}

/** Contains details about a custom text attribute that the buyer entered. */
export interface EnteredCustomAttributeInput {
  /** A string that identifies the entered custom attribute. */
  attribute_code: Scalars['String']['input']
  /** The text or other entered value. */
  value: Scalars['String']['input']
}

/** Defines a customer-entered option. */
export interface EnteredOptionInput {
  /** The unique ID for a `CustomizableOptionInterface` object, such as a `CustomizableFieldOption`, `CustomizableFileOption`, or `CustomizableAreaOption` object. */
  uid: Scalars['ID']['input']
  /** Text the customer entered. */
  value: Scalars['String']['input']
}

/** Contains the `uid`, `relative_url`, and `type` attributes. */
export interface EntityUrl {
  __typename?: 'EntityUrl'
  /** @deprecated Use `relative_url` instead. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface`, `CategoryInterface`, `CmsPage`, or similar object associated with the specified URL. This could be a product, category, or CMS page UID. */
  entityUid: Maybe<Scalars['ID']['output']>
  /**
   * The ID assigned to the object associated with the specified url. This could be a product ID, category ID, or page ID.
   * @deprecated Use `entity_uid` instead.
   */
  id: Maybe<Scalars['String']['output']>
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Maybe<Scalars['Int']['output']>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
}

export interface ErrorInterface {
  /** The returned error message. */
  message: Scalars['String']['output']
}

/** Contains details about an address. */
export interface EstimateAddressInput {
  /** The two-letter code representing the customer's country. */
  country_code: CountryCodeEnum
  /** The customer's ZIP or postal code. */
  postcode?: InputMaybe<Scalars['String']['input']>
  /** An object containing the region name, region code, and region ID. */
  region?: InputMaybe<CustomerAddressRegionInput>
}

export interface EstimateTotalsInput {
  /** Customer's address to estimate totals. */
  address: EstimateAddressInput
  /** The unique ID of the cart to query. */
  cart_id: Scalars['String']['input']
  /** Selected shipping method to estimate totals. */
  shipping_method?: InputMaybe<ShippingMethodInput>
}

/** Estimate totals output. */
export interface EstimateTotalsOutput {
  __typename?: 'EstimateTotalsOutput'
  /** Cart after totals estimation */
  cart: Maybe<Cart>
}

/** Lists the exchange rate. */
export interface ExchangeRate {
  __typename?: 'ExchangeRate'
  /** Specifies the store’s default currency to exchange to. */
  currencyTo: Maybe<Scalars['String']['output']>
  /** The exchange rate for the store’s default currency. */
  rate: Maybe<Scalars['Float']['output']>
}

/** Defines a filter that matches the input exactly. */
export interface FilterEqualTypeInput {
  /** Use this attribute to exactly match the specified string. For example, to filter on a specific category ID, specify a value such as `5`. */
  eq?: InputMaybe<Scalars['String']['input']>
  /** Use this attribute to filter on an array of values. For example, to filter on category IDs 4, 5, and 6, specify a value of `["4", "5", "6"]`. */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum FilterMatchTypeEnum {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
}

/** Defines a filter that performs a fuzzy search. */
export interface FilterMatchTypeInput {
  /** Use this attribute to fuzzy match the specified string. For example, to filter on a specific SKU, specify a value such as `24-MB01`. */
  match?: InputMaybe<Scalars['String']['input']>
  /** Filter match type for fine-tuned results. Possible values FULL or PARTIAL. If match_type is not provided, returned results will default to FULL match. */
  match_type?: InputMaybe<FilterMatchTypeEnum>
}

/** Defines a filter that matches a range of values, such as prices or dates. */
export interface FilterRangeTypeInput {
  /** Use this attribute to specify the lowest possible value in the range. */
  from?: InputMaybe<Scalars['String']['input']>
  /** Use this attribute to specify the highest possible value in the range. */
  to?: InputMaybe<Scalars['String']['input']>
}

/** Defines a filter for an input string. */
export interface FilterStringTypeInput {
  /** Filters items that are exactly the same as the specified string. */
  eq?: InputMaybe<Scalars['String']['input']>
  /** Filters items that are exactly the same as entries specified in an array of strings. */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Defines a filter that performs a fuzzy search using the specified string. */
  match?: InputMaybe<Scalars['String']['input']>
}

/** Defines the comparison operators that can be used in a filter. */
export interface FilterTypeInput {
  /** Equals. */
  eq?: InputMaybe<Scalars['String']['input']>
  finset?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** From. Must be used with the `to` field. */
  from?: InputMaybe<Scalars['String']['input']>
  /** Greater than. */
  gt?: InputMaybe<Scalars['String']['input']>
  /** Greater than or equal to. */
  gteq?: InputMaybe<Scalars['String']['input']>
  /** In. The value can contain a set of comma-separated values. */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Like. The specified value can contain % (percent signs) to allow matching of 0 or more characters. */
  like?: InputMaybe<Scalars['String']['input']>
  /** Less than. */
  lt?: InputMaybe<Scalars['String']['input']>
  /** Less than or equal to. */
  lteq?: InputMaybe<Scalars['String']['input']>
  /** More than or equal to. */
  moreq?: InputMaybe<Scalars['String']['input']>
  /** Not equal to. */
  neq?: InputMaybe<Scalars['String']['input']>
  /** Not in. The value can contain a set of comma-separated values. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Not null. */
  notnull?: InputMaybe<Scalars['String']['input']>
  /** Is null. */
  null?: InputMaybe<Scalars['String']['input']>
  /** To. Must be used with the `from` field. */
  to?: InputMaybe<Scalars['String']['input']>
}

/** A single FPT that can be applied to a product price. */
export interface FixedProductTax {
  __typename?: 'FixedProductTax'
  /** The amount of the Fixed Product Tax. */
  amount: Maybe<Money>
  /** The display label assigned to the Fixed Product Tax. */
  label: Maybe<Scalars['String']['output']>
}

/** Lists display settings for the Fixed Product Tax. */
export enum FixedProductTaxDisplaySettings {
  /** The displayed price does not include the FPT amount. The values of `ProductPrice.fixed_product_taxes` and the price including the FPT are displayed separately. This value corresponds to 'Excluding FPT, Including FPT description and final price.' */
  EXCLUDE_FPT_AND_INCLUDE_WITH_DETAILS = 'EXCLUDE_FPT_AND_INCLUDE_WITH_DETAILS',
  /** The displayed price does not include the FPT amount. The values from `ProductPrice.fixed_product_taxes` are not displayed. This value corresponds to 'Excluding FPT'. */
  EXCLUDE_FPT_WITHOUT_DETAILS = 'EXCLUDE_FPT_WITHOUT_DETAILS',
  /** The FPT feature is not enabled. You can omit `ProductPrice.fixed_product_taxes` from your query. */
  FPT_DISABLED = 'FPT_DISABLED',
  /** The displayed price includes the FPT amount without displaying the `ProductPrice.fixed_product_taxes` values. This value corresponds to 'Including FPT only'. */
  INCLUDE_FPT_WITHOUT_DETAILS = 'INCLUDE_FPT_WITHOUT_DETAILS',
  /** The displayed price includes the FPT amount while displaying the values of `ProductPrice.fixed_product_taxes` separately. This value corresponds to 'Including FPT and FPT description'. */
  INCLUDE_FPT_WITH_DETAILS = 'INCLUDE_FPT_WITH_DETAILS',
}

export interface GatewayOrderResultFormField {
  __typename?: 'GatewayOrderResultFormField'
  field: Scalars['String']['output']
  value: Scalars['String']['output']
}

/** Identifies which customer requires remote shopping assistance. */
export interface GenerateCustomerTokenAsAdminInput {
  /** The email address of the customer requesting remote shopping assistance. */
  customer_email: Scalars['String']['input']
}

/** Contains the generated customer token. */
export interface GenerateCustomerTokenAsAdminOutput {
  __typename?: 'GenerateCustomerTokenAsAdminOutput'
  /** The generated customer token. */
  customerToken: Scalars['String']['output']
}

/** Gets the payment SDK URLs and values */
export interface GetPaymentSdkOutput {
  __typename?: 'GetPaymentSdkOutput'
  /** The payment SDK parameters */
  sdkParams: Maybe<Array<Maybe<PaymentSdkParamsItem>>>
}

/** Contains details about the gift card account. */
export interface GiftCardAccount {
  __typename?: 'GiftCardAccount'
  /** The balance remaining on the gift card. */
  balance: Maybe<Money>
  /** The gift card account code. */
  code: Maybe<Scalars['String']['output']>
  /** The expiration date of the gift card. */
  expirationDate: Maybe<Scalars['String']['output']>
}

/** Contains the gift card code. */
export interface GiftCardAccountInput {
  /** The applied gift card code. */
  gift_card_code: Scalars['String']['input']
}

/** Contains the value of a gift card, the website that generated the card, and related information. */
export interface GiftCardAmounts {
  __typename?: 'GiftCardAmounts'
  /** An internal attribute ID. */
  attributeId: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `GiftCardAmounts` object. */
  uid: Scalars['ID']['output']
  /** The value of the gift card. */
  value: Maybe<Scalars['Float']['output']>
  /**
   * An ID that is assigned to each unique gift card amount.
   * @deprecated Use `uid` instead
   */
  valueId: Maybe<Scalars['Int']['output']>
  /** The ID of the website that generated the gift card. */
  websiteId: Maybe<Scalars['Int']['output']>
  /** The value of the gift card. */
  websiteValue: Maybe<Scalars['Float']['output']>
}

/** Contains details about a gift card that has been added to a cart. */
export interface GiftCardCartItem extends CartItemInterface {
  __typename?: 'GiftCardCartItem'
  /** The amount and currency of the gift card. */
  amount: Money
  /** An array of customizations applied to the gift card. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** Delivery date of the cart item (Y-m-d) */
  deliveryDate: Maybe<Scalars['String']['output']>
  /** Contains discount for quote line item. */
  discount: Maybe<Array<Maybe<Discount>>>
  /** Elektramat configuration packslip */
  elektramatPackslip: Maybe<Scalars['String']['output']>
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output']
  /** True if requested quantity is less than available stock, false otherwise. */
  isAvailable: Scalars['Boolean']['output']
  /** The message from the sender to the recipient. */
  message: Maybe<Scalars['String']['output']>
  /** The buyer's quote line item note. */
  noteFromBuyer: Maybe<Array<Maybe<ItemNote>>>
  /** The seller's quote line item note. */
  noteFromSeller: Maybe<Array<Maybe<ItemNote>>>
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>
  /** Details about an item in the cart. */
  product: ProductInterface
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output']
  /** The email address of the person receiving the gift card. */
  recipientEmail: Maybe<Scalars['String']['output']>
  /** The name of the person receiving the gift card. */
  recipientName: Scalars['String']['output']
  /** The email address of the sender. */
  senderEmail: Maybe<Scalars['String']['output']>
  /** The name of the sender. */
  senderName: Scalars['String']['output']
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output']
}

export interface GiftCardCreditMemoItem extends CreditMemoItemInterface {
  __typename?: 'GiftCardCreditMemoItem'
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** Selected gift card properties for a credit memo item. */
  giftCard: Maybe<GiftCardItem>
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
}

export interface GiftCardInvoiceItem extends InvoiceItemInterface {
  __typename?: 'GiftCardInvoiceItem'
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** Selected gift card properties for an invoice item. */
  giftCard: Maybe<GiftCardItem>
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID']['output']
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
}

/** Contains details about a gift card. */
export interface GiftCardItem {
  __typename?: 'GiftCardItem'
  /** The message from the sender to the recipient. */
  message: Maybe<Scalars['String']['output']>
  /** The email address of the receiver of a virtual gift card. */
  recipientEmail: Maybe<Scalars['String']['output']>
  /** The name of the receiver of a physical or virtual gift card. */
  recipientName: Maybe<Scalars['String']['output']>
  /** The email address of the sender of a virtual gift card. */
  senderEmail: Maybe<Scalars['String']['output']>
  /** The name of the sender of a physical or virtual gift card. */
  senderName: Maybe<Scalars['String']['output']>
}

/** Contains details about the sender, recipient, and amount of a gift card. */
export interface GiftCardOptions {
  __typename?: 'GiftCardOptions'
  /** The amount and currency of the gift card. */
  amount: Maybe<Money>
  /** The custom amount and currency of the gift card. */
  customGiftcardAmount: Maybe<Money>
  /** A message to the recipient. */
  message: Maybe<Scalars['String']['output']>
  /** The email address of the person receiving the gift card. */
  recipientEmail: Maybe<Scalars['String']['output']>
  /** The name of the person receiving the gift card. */
  recipientName: Maybe<Scalars['String']['output']>
  /** The email address of the person sending the gift card. */
  senderEmail: Maybe<Scalars['String']['output']>
  /** The name of the person sending the gift card. */
  senderName: Maybe<Scalars['String']['output']>
}

export interface GiftCardOrderItem extends OrderItemInterface {
  __typename?: 'GiftCardOrderItem'
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** Indicates whether the order item is eligible to be in a return request. */
  eligibleForReturn: Maybe<Scalars['Boolean']['output']>
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** Selected gift card properties for an order item. */
  giftCard: Maybe<GiftCardItem>
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>
  /** The selected gift wrapping for the order item. */
  giftWrapping: Maybe<GiftWrapping>
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID']['output']
  /** The ProductInterface object, which contains details about the base product */
  product: Maybe<ProductInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']['output']>
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']['output']>
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']['output']>
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']['output']>
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']['output']>
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']['output']>
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The status of the order item. */
  status: Maybe<Scalars['String']['output']>
}

/** Defines properties of a gift card. */
export interface GiftCardProduct
  extends CustomizableProductInterface,
    PhysicalProductInterface,
    ProductInterface,
    RoutableInterface {
  __typename?: 'GiftCardProduct'
  /** Indicates whether the customer can provide a message to accompany the gift card. */
  allowMessage: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether shoppers have the ability to set the value of the gift card. */
  allowOpenAmount: Maybe<Scalars['Boolean']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  articleNumber: Maybe<Scalars['String']['output']>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  color: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  costPrice: Maybe<Scalars['String']['output']>
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** Product custom attributes. */
  customAttributesV2: Maybe<ProductCustomAttributes>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']['output']>
  /** An array of customizable gift card options. */
  giftCardOptions: Array<Maybe<CustomizableOptionInterface>>
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>
  /** An array that contains information about the values and ID of a gift card. */
  giftcardAmounts: Maybe<Array<Maybe<GiftCardAmounts>>>
  /** An enumeration that specifies the type of gift card. */
  giftcardType: Maybe<GiftCardTypeEnum>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>
  /** @deprecated Use the `custom_attributes` field instead. */
  isClonedForStore: Maybe<Scalars['String']['output']>
  /** Indicates whether the customer can redeem the value on the card for cash. */
  isRedeemable: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']['output']>
  /** The number of days after purchase until the gift card expires. A null value means there is no limit. */
  lifetime: Maybe<Scalars['Int']['output']>
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  manufacturerId: Maybe<Scalars['String']['output']>
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['String']['output']>
  /** The maximum number of characters the gift message can contain. */
  messageMaxLength: Maybe<Scalars['Int']['output']>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minimumQuantityAllowed: Maybe<Scalars['String']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>
  /** The maximum acceptable value of an open amount gift card. */
  openAmountMax: Maybe<Scalars['Float']['output']>
  /** The minimum acceptable value of an open amount gift card. */
  openAmountMin: Maybe<Scalars['Float']['output']>
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  phasedOut: Maybe<Scalars['String']['output']>
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  productgroep: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  quantityIncrements: Maybe<Scalars['String']['output']>
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float']['output']
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalCrossection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDegreeofprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalEarthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalIdentificationcores: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalInnerdepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductorcrosssection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductordiameter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalinsulationthickness: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofcores: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofmodules: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofpoles: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofrows: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalOuterdiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalRatedvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalTrippingcharacteristic: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWithattachmentoption: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBundlesperpallet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingCabletype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingDiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingFireretardancy: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorBoxquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorCablediameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorSolarpanelcompatibility: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerConnector: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerPayload: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerRfidcardreader: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerSuitableforoutdoormounting: Maybe<
    Scalars['Int']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWirelessconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemRoofpitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWindstandard: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMaxsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMinsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringCommunicationtype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSeriecompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSignalrange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDaktype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHeightatthetop: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHoogtebovenzijde: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalPaletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperbox: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperpalet: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalRooftype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcdcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAfci: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerCompatiblestorage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcswitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerInvertertype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximuminputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMicropanelpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMinearthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMonitoringoptions: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMppvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerNumberofmpptrackers: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerPaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperbox: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperpalet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerRatedacpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerSolarpanels: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStartupvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStringspermpptracker: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerUsablebatterycapacity: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemBatvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCommunicationport: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxbatcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxoutputpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemOutputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemStoragecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemUsablecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBacksheetcolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBuild: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCelltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFramecolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelGuaranteedpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelJunctionboxprotectiondegree: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelMaxsystemvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelModuleefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelNumberofcells: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletsize: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPanelspercontainer: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPowerguarantee: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelSolarpaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelThickness: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWattagepersquaremeter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWindandsnowload: Maybe<Scalars['String']['output']>
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean']['output']
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  usp: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  voorraadstatus: Maybe<Scalars['String']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>
}

/** Defines properties of a gift card. */
export interface GiftCardProductCustomAttributesV2Args {
  filters: InputMaybe<AttributeFilterInput>
}

/** Defines properties of a gift card. */
export interface GiftCardProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Contains details about gift cards added to a requisition list. */
export interface GiftCardRequisitionListItem
  extends RequisitionListItemInterface {
  __typename?: 'GiftCardRequisitionListItem'
  /** Selected custom options for an item in the requisition list. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** An array that defines gift card properties. */
  giftCardOptions: GiftCardOptions
  /** Details about a requisition list item. */
  product: ProductInterface
  /** The amount added. */
  quantity: Scalars['Float']['output']
  /** The unique ID for the requisition list item. */
  uid: Scalars['ID']['output']
}

export interface GiftCardShipmentItem extends ShipmentItemInterface {
  __typename?: 'GiftCardShipmentItem'
  /** Selected gift card properties for a shipment item. */
  giftCard: Maybe<GiftCardItem>
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item associated with the shipment item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of shipped items. */
  quantityShipped: Scalars['Float']['output']
}

/** Specifies the gift card type. */
export enum GiftCardTypeEnum {
  COMBINED = 'COMBINED',
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL',
}

/** A single gift card added to a wish list. */
export interface GiftCardWishlistItem extends WishlistItemInterface {
  __typename?: 'GiftCardWishlistItem'
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output']
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>
  /** Details about a gift card. */
  giftCardOptions: GiftCardOptions
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output']
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output']
}

/** Contains the text of a gift message, its sender, and recipient */
export interface GiftMessage {
  __typename?: 'GiftMessage'
  /** Sender name */
  from: Scalars['String']['output']
  /** Gift message text */
  message: Scalars['String']['output']
  /** Recipient name */
  to: Scalars['String']['output']
}

/** Defines a gift message. */
export interface GiftMessageInput {
  /** The name of the sender. */
  from: Scalars['String']['input']
  /** The text of the gift message. */
  message: Scalars['String']['input']
  /** The name of the recepient. */
  to: Scalars['String']['input']
}

/** Contains prices for gift wrapping options. */
export interface GiftOptionsPrices {
  __typename?: 'GiftOptionsPrices'
  /** Price of the gift wrapping for all individual order items. */
  giftWrappingForItems: Maybe<Money>
  /** Price of the gift wrapping for the whole order. */
  giftWrappingForOrder: Maybe<Money>
  /** Price for the printed card. */
  printedCard: Maybe<Money>
}

/** Contains details about a gift registry. */
export interface GiftRegistry {
  __typename?: 'GiftRegistry'
  /** The date on which the gift registry was created. Only the registry owner can access this attribute. */
  createdAt: Scalars['String']['output']
  /** An array of attributes that define elements of the gift registry. Each attribute is specified as a code-value pair. */
  dynamicAttributes: Maybe<Array<Maybe<GiftRegistryDynamicAttribute>>>
  /** The name of the event. */
  eventName: Scalars['String']['output']
  /** An array of products added to the gift registry. */
  items: Maybe<Array<Maybe<GiftRegistryItemInterface>>>
  /** The message text the customer entered to describe the event. */
  message: Scalars['String']['output']
  /** The customer who created the gift registry. */
  ownerName: Scalars['String']['output']
  /** An enum that states whether the gift registry is PRIVATE or PUBLIC. Only the registry owner can access this attribute. */
  privacySettings: GiftRegistryPrivacySettings
  /** Contains details about each registrant for the event. */
  registrants: Maybe<Array<Maybe<GiftRegistryRegistrant>>>
  /** Contains the customer's shipping address. Only the registry owner can access this attribute. */
  shippingAddress: Maybe<CustomerAddress>
  /** An enum that states whether the gift registry is ACTIVE or INACTIVE. Only the registry owner can access this attribute. */
  status: GiftRegistryStatus
  /** The type of gift registry. */
  type: Maybe<GiftRegistryType>
  /** The unique ID assigned to the gift registry. */
  uid: Scalars['ID']['output']
}

export interface GiftRegistryDynamicAttribute
  extends GiftRegistryDynamicAttributeInterface {
  __typename?: 'GiftRegistryDynamicAttribute'
  /** The internal ID of the dynamic attribute. */
  code: Scalars['ID']['output']
  /** Indicates which group the dynamic attribute is a member of. */
  group: GiftRegistryDynamicAttributeGroup
  /** The display name of the dynamic attribute. */
  label: Scalars['String']['output']
  /** A corresponding value for the code. */
  value: Scalars['String']['output']
}

/** Defines the group type of a gift registry dynamic attribute. */
export enum GiftRegistryDynamicAttributeGroup {
  DETAILED_INFORMATION = 'DETAILED_INFORMATION',
  EVENT_INFORMATION = 'EVENT_INFORMATION',
  GENERAL_INFORMATION = 'GENERAL_INFORMATION',
  PRIVACY_SETTINGS = 'PRIVACY_SETTINGS',
  REGISTRANT = 'REGISTRANT',
  SHIPPING_ADDRESS = 'SHIPPING_ADDRESS',
}

/** Defines a dynamic attribute. */
export interface GiftRegistryDynamicAttributeInput {
  /** A unique key for an additional attribute of the event. */
  code: Scalars['ID']['input']
  /** A string that describes a dynamic attribute. */
  value: Scalars['String']['input']
}

export interface GiftRegistryDynamicAttributeInterface {
  /** The internal ID of the dynamic attribute. */
  code: Scalars['ID']['output']
  /** The display name of the dynamic attribute. */
  label: Scalars['String']['output']
  /** A corresponding value for the code. */
  value: Scalars['String']['output']
}

export interface GiftRegistryDynamicAttributeMetadata
  extends GiftRegistryDynamicAttributeMetadataInterface {
  __typename?: 'GiftRegistryDynamicAttributeMetadata'
  /** Indicates which group the dynamic attribute a member of. */
  attributeGroup: Scalars['String']['output']
  /** The internal ID of the dynamic attribute. */
  code: Scalars['ID']['output']
  /** The selected input type for this dynamic attribute. The value can be one of several static or custom types. */
  inputType: Scalars['String']['output']
  /** Indicates whether the dynamic attribute is required. */
  isRequired: Scalars['Boolean']['output']
  /** The display name of the dynamic attribute. */
  label: Scalars['String']['output']
  /** The order in which to display the dynamic attribute. */
  sortOrder: Maybe<Scalars['Int']['output']>
}

export interface GiftRegistryDynamicAttributeMetadataInterface {
  /** Indicates which group the dynamic attribute a member of. */
  attributeGroup: Scalars['String']['output']
  /** The internal ID of the dynamic attribute. */
  code: Scalars['ID']['output']
  /** The selected input type for this dynamic attribute. The value can be one of several static or custom types. */
  inputType: Scalars['String']['output']
  /** Indicates whether the dynamic attribute is required. */
  isRequired: Scalars['Boolean']['output']
  /** The display name of the dynamic attribute. */
  label: Scalars['String']['output']
  /** The order in which to display the dynamic attribute. */
  sortOrder: Maybe<Scalars['Int']['output']>
}

export interface GiftRegistryItem extends GiftRegistryItemInterface {
  __typename?: 'GiftRegistryItem'
  /** The date the product was added to the gift registry. */
  createdAt: Scalars['String']['output']
  /** A brief message about the gift registry item. */
  note: Maybe<Scalars['String']['output']>
  /** Details about the gift registry item. */
  product: Maybe<ProductInterface>
  /** The requested quantity of the product. */
  quantity: Scalars['Float']['output']
  /** The fulfilled quantity of the product. */
  quantityFulfilled: Scalars['Float']['output']
  /** The unique ID of a gift registry item. */
  uid: Scalars['ID']['output']
}

export interface GiftRegistryItemInterface {
  /** The date the product was added to the gift registry. */
  createdAt: Scalars['String']['output']
  /** A brief message about the gift registry item. */
  note: Maybe<Scalars['String']['output']>
  /** Details about the gift registry item. */
  product: Maybe<ProductInterface>
  /** The requested quantity of the product. */
  quantity: Scalars['Float']['output']
  /** The fulfilled quantity of the product. */
  quantityFulfilled: Scalars['Float']['output']
  /** The unique ID of a gift registry item. */
  uid: Scalars['ID']['output']
}

/** Contains the status and any errors that encountered with the customer's gift register item. */
export interface GiftRegistryItemUserErrorInterface {
  /** Indicates whether the attempt to move the cart items to the gift registry was successful. */
  status: Scalars['Boolean']['output']
  /** An array of errors encountered while moving items from the cart to the gift registry. */
  userErrors: Array<Maybe<GiftRegistryItemsUserError>>
}

/** Contains error information. */
export interface GiftRegistryItemUserErrors
  extends GiftRegistryItemUserErrorInterface {
  __typename?: 'GiftRegistryItemUserErrors'
  /** Indicates whether the attempt to move the cart items to the gift registry was successful. */
  status: Scalars['Boolean']['output']
  /** An array of errors encountered while moving items from the cart to the gift registry. */
  userErrors: Array<Maybe<GiftRegistryItemsUserError>>
}

/** Contains details about an error that occurred when processing a gift registry item. */
export interface GiftRegistryItemsUserError {
  __typename?: 'GiftRegistryItemsUserError'
  /** An error code that describes the error encountered. */
  code: GiftRegistryItemsUserErrorType
  /** The unique ID of the gift registry item containing an error. */
  giftRegistryItemUid: Maybe<Scalars['ID']['output']>
  /** The unique ID of the `GiftRegistry` object containing an error. */
  giftRegistryUid: Maybe<Scalars['ID']['output']>
  /** A localized error message. */
  message: Scalars['String']['output']
  /** The unique ID of the product containing an error. */
  productUid: Maybe<Scalars['ID']['output']>
}

/** Defines the error type. */
export enum GiftRegistryItemsUserErrorType {
  /** Used for exceptions like EntityNotFound. */
  NOT_FOUND = 'NOT_FOUND',
  /** Used for handling out of stock products. */
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  /** Used for other exceptions, such as database connection failures. */
  UNDEFINED = 'UNDEFINED',
}

/** Contains details about the gift registry. */
export interface GiftRegistryOutput extends GiftRegistryOutputInterface {
  __typename?: 'GiftRegistryOutput'
  /** The gift registry. */
  giftRegistry: Maybe<GiftRegistry>
}

/** Contains the customer's gift registry. */
export interface GiftRegistryOutputInterface {
  /** The gift registry. */
  giftRegistry: Maybe<GiftRegistry>
}

/** Defines the privacy setting of the gift registry. */
export enum GiftRegistryPrivacySettings {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

/** Contains details about a registrant. */
export interface GiftRegistryRegistrant {
  __typename?: 'GiftRegistryRegistrant'
  /** An array of dynamic attributes assigned to the registrant. */
  dynamicAttributes: Maybe<Array<Maybe<GiftRegistryRegistrantDynamicAttribute>>>
  /** The email address of the registrant. Only the registry owner can access this attribute. */
  email: Scalars['String']['output']
  /** The first name of the registrant. */
  firstname: Scalars['String']['output']
  /** The last name of the registrant. */
  lastname: Scalars['String']['output']
  /** The unique ID assigned to the registrant. */
  uid: Scalars['ID']['output']
}

export interface GiftRegistryRegistrantDynamicAttribute
  extends GiftRegistryDynamicAttributeInterface {
  __typename?: 'GiftRegistryRegistrantDynamicAttribute'
  /** The internal ID of the dynamic attribute. */
  code: Scalars['ID']['output']
  /** The display name of the dynamic attribute. */
  label: Scalars['String']['output']
  /** A corresponding value for the code. */
  value: Scalars['String']['output']
}

/** Contains the results of a gift registry search. */
export interface GiftRegistrySearchResult {
  __typename?: 'GiftRegistrySearchResult'
  /** The date of the event. */
  eventDate: Maybe<Scalars['String']['output']>
  /** The title given to the event. */
  eventTitle: Scalars['String']['output']
  /** The URL key of the gift registry. */
  giftRegistryUid: Scalars['ID']['output']
  /** The location of the event. */
  location: Maybe<Scalars['String']['output']>
  /** The name of the gift registry owner. */
  name: Scalars['String']['output']
  /** The type of event being held. */
  type: Maybe<Scalars['String']['output']>
}

/** Defines a shipping address for a gift registry. Specify either `address_data` or the `address_id`. If both are provided, validation will fail. */
export interface GiftRegistryShippingAddressInput {
  /** Defines the shipping address for this gift registry. */
  address_data?: InputMaybe<CustomerAddressInput>
  /** The ID assigned to this customer address. */
  address_id?: InputMaybe<Scalars['ID']['input']>
}

/** Defines the status of the gift registry. */
export enum GiftRegistryStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

/** Contains details about a gift registry type. */
export interface GiftRegistryType {
  __typename?: 'GiftRegistryType'
  /** An array of attributes that define elements of the gift registry. Each attribute is specified as a code-value pair. */
  dynamicAttributesMetadata: Maybe<
    Array<Maybe<GiftRegistryDynamicAttributeMetadataInterface>>
  >
  /** The label assigned to the gift registry type on the Admin. */
  label: Scalars['String']['output']
  /** The unique ID assigned to the gift registry type. */
  uid: Scalars['ID']['output']
}

/** Contains details about the selected or available gift wrapping options. */
export interface GiftWrapping {
  __typename?: 'GiftWrapping'
  /** The name of the gift wrapping design. */
  design: Scalars['String']['output']
  /**
   * The unique ID for a `GiftWrapping` object.
   * @deprecated Use `uid` instead
   */
  id: Scalars['ID']['output']
  /** The preview image for a gift wrapping option. */
  image: Maybe<GiftWrappingImage>
  /** The gift wrapping price. */
  price: Money
  /** The unique ID for a `GiftWrapping` object. */
  uid: Scalars['ID']['output']
}

/** Points to an image associated with a gift wrapping option. */
export interface GiftWrappingImage {
  __typename?: 'GiftWrappingImage'
  /** The gift wrapping preview image label. */
  label: Scalars['String']['output']
  /** The gift wrapping preview image URL. */
  url: Scalars['String']['output']
}

export interface GooglePayButtonStyles {
  __typename?: 'GooglePayButtonStyles'
  /** The button color */
  color: Maybe<Scalars['String']['output']>
  /** The button height in pixels */
  height: Maybe<Scalars['Int']['output']>
  /** The button type */
  type: Maybe<Scalars['String']['output']>
}

export interface GooglePayConfig extends PaymentConfigItem {
  __typename?: 'GooglePayConfig'
  /** The styles for the GooglePay Button configuration */
  buttonStyles: Maybe<GooglePayButtonStyles>
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>
  /** The payment source for the payment method */
  paymentSource: Maybe<Scalars['String']['output']>
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>
}

/** Google Pay inputs */
export interface GooglePayMethodInput {
  /** The payment source for the payment method */
  payment_source?: InputMaybe<Scalars['String']['input']>
  /** The payment services order ID */
  payments_order_id?: InputMaybe<Scalars['String']['input']>
  /** PayPal order ID */
  paypal_order_id?: InputMaybe<Scalars['String']['input']>
}

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export interface GroupedProduct
  extends PhysicalProductInterface,
    ProductInterface,
    RoutableInterface {
  __typename?: 'GroupedProduct'
  /** @deprecated Use the `custom_attributes` field instead. */
  articleNumber: Maybe<Scalars['String']['output']>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  color: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  costPrice: Maybe<Scalars['String']['output']>
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** Product custom attributes. */
  customAttributesV2: Maybe<ProductCustomAttributes>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']['output']>
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>
  /** @deprecated Use the `custom_attributes` field instead. */
  isClonedForStore: Maybe<Scalars['String']['output']>
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']['output']>
  /** An array containing grouped product items. */
  items: Maybe<Array<Maybe<GroupedProductItem>>>
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  manufacturerId: Maybe<Scalars['String']['output']>
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['String']['output']>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minimumQuantityAllowed: Maybe<Scalars['String']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  phasedOut: Maybe<Scalars['String']['output']>
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  productgroep: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  quantityIncrements: Maybe<Scalars['String']['output']>
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float']['output']
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalCrossection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDegreeofprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalEarthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalIdentificationcores: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalInnerdepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductorcrosssection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductordiameter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalinsulationthickness: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofcores: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofmodules: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofpoles: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofrows: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalOuterdiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalRatedvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalTrippingcharacteristic: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWithattachmentoption: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBundlesperpallet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingCabletype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingDiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingFireretardancy: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorBoxquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorCablediameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorSolarpanelcompatibility: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerConnector: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerPayload: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerRfidcardreader: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerSuitableforoutdoormounting: Maybe<
    Scalars['Int']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWirelessconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemRoofpitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWindstandard: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMaxsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMinsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringCommunicationtype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSeriecompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSignalrange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDaktype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHeightatthetop: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHoogtebovenzijde: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalPaletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperbox: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperpalet: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalRooftype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcdcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAfci: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerCompatiblestorage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcswitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerInvertertype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximuminputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMicropanelpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMinearthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMonitoringoptions: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMppvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerNumberofmpptrackers: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerPaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperbox: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperpalet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerRatedacpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerSolarpanels: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStartupvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStringspermpptracker: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerUsablebatterycapacity: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemBatvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCommunicationport: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxbatcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxoutputpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemOutputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemStoragecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemUsablecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBacksheetcolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBuild: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCelltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFramecolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelGuaranteedpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelJunctionboxprotectiondegree: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelMaxsystemvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelModuleefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelNumberofcells: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletsize: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPanelspercontainer: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPowerguarantee: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelSolarpaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelThickness: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWattagepersquaremeter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWindandsnowload: Maybe<Scalars['String']['output']>
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean']['output']
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  usp: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  voorraadstatus: Maybe<Scalars['String']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>
}

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export interface GroupedProductCustomAttributesV2Args {
  filters: InputMaybe<AttributeFilterInput>
}

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export interface GroupedProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Contains information about an individual grouped product item. */
export interface GroupedProductItem {
  __typename?: 'GroupedProductItem'
  /** The relative position of this item compared to the other group items. */
  position: Maybe<Scalars['Int']['output']>
  /** Details about this product option. */
  product: Maybe<ProductInterface>
  /** The quantity of this grouped product item. */
  qty: Maybe<Scalars['Float']['output']>
}

/** A grouped product wish list item. */
export interface GroupedProductWishlistItem extends WishlistItemInterface {
  __typename?: 'GroupedProductWishlistItem'
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output']
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output']
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output']
}

export interface HostedFieldsConfig extends PaymentConfigItem {
  __typename?: 'HostedFieldsConfig'
  /** Vault payment method code */
  ccVaultCode: Maybe<Scalars['String']['output']>
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>
  /** Card vault enabled */
  isVaultEnabled: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>
  /** The payment source for the payment method */
  paymentSource: Maybe<Scalars['String']['output']>
  /** Card and bin details required */
  requiresCardDetails: Maybe<Scalars['Boolean']['output']>
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>
  /** 3DS mode */
  threeDs: Maybe<Scalars['Boolean']['output']>
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>
}

/** Hosted Fields payment inputs */
export interface HostedFieldsInput {
  /** Card bin number */
  cardBin?: InputMaybe<Scalars['String']['input']>
  /** Expiration month of the card */
  cardExpiryMonth?: InputMaybe<Scalars['String']['input']>
  /** Expiration year of the card */
  cardExpiryYear?: InputMaybe<Scalars['String']['input']>
  /** Last four digits of the card */
  cardLast4?: InputMaybe<Scalars['String']['input']>
  /** Name on the card */
  holderName?: InputMaybe<Scalars['String']['input']>
  /** Indicates whether details about the shopper's credit/debit card should be tokenized for later usage. Required only if Vault is enabled for the Payment Services payment integration. */
  is_active_payment_token_enabler?: InputMaybe<Scalars['Boolean']['input']>
  /** The payment source for the payment method */
  payment_source?: InputMaybe<Scalars['String']['input']>
  /** The payment services order ID */
  payments_order_id?: InputMaybe<Scalars['String']['input']>
  /** PayPal order ID */
  paypal_order_id?: InputMaybe<Scalars['String']['input']>
}

/** Contains a set of relative URLs that PayPal uses in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payments Pro Hosted Solution payment method. */
export interface HostedProInput {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. For example, if the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']['input']
  /** The relative URL of the final confirmation page that PayPal redirects to upon payment success. For example, if the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']['input']
}

/** Contains the secure URL used for the Payments Pro Hosted Solution payment method. */
export interface HostedProUrl {
  __typename?: 'HostedProUrl'
  /** The secure URL generated by PayPal. */
  secureFormUrl: Maybe<Scalars['String']['output']>
}

/** Contains the required input to request the secure URL for Payments Pro Hosted Solution payment. */
export interface HostedProUrlInput {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String']['input']
}

/** Contains target path parameters. */
export interface HttpQueryParameter {
  __typename?: 'HttpQueryParameter'
  /** A parameter name. */
  name: Maybe<Scalars['String']['output']>
  /** A parameter value. */
  value: Maybe<Scalars['String']['output']>
}

export interface ImageSwatchData extends SwatchDataInterface {
  __typename?: 'ImageSwatchData'
  /** The URL assigned to the thumbnail of the swatch image. */
  thumbnail: Maybe<Scalars['String']['output']>
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']['output']>
}

/** List of templates/filters applied to customer attribute input. */
export enum InputFilterEnum {
  /** Forces attribute input to follow the date format. */
  DATE = 'DATE',
  /** Escape HTML Entities. */
  ESCAPEHTML = 'ESCAPEHTML',
  /** There are no templates or filters to be applied. */
  NONE = 'NONE',
  /** Strip HTML Tags. */
  STRIPTAGS = 'STRIPTAGS',
  /** Strip whitespace (or other characters) from the beginning and end of the input. */
  TRIM = 'TRIM',
}

/** Contains an error message when an internal error occurred. */
export interface InternalError extends ErrorInterface {
  __typename?: 'InternalError'
  /** The returned error message. */
  message: Scalars['String']['output']
}

/** Contains invoice details. */
export interface Invoice {
  __typename?: 'Invoice'
  /** Comments on the invoice. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>
  /** The unique ID for a `Invoice` object. */
  id: Scalars['ID']['output']
  /** Invoiced product details. */
  items: Maybe<Array<Maybe<InvoiceItemInterface>>>
  /** Sequential invoice number. */
  number: Scalars['String']['output']
  /** Invoice total amount details. */
  total: Maybe<InvoiceTotal>
}

export interface InvoiceItem extends InvoiceItemInterface {
  __typename?: 'InvoiceItem'
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID']['output']
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
}

/** Contains detailes about invoiced items. */
export interface InvoiceItemInterface {
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID']['output']
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
}

/** Contains price details from an invoice. */
export interface InvoiceTotal {
  __typename?: 'InvoiceTotal'
  /** The final base grand total amount in the base currency. */
  baseGrandTotal: Money
  /** The applied discounts to the invoice. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The final total amount, including shipping, discounts, and taxes. */
  grandTotal: Money
  /** Details about the shipping and handling costs for the invoice. */
  shippingHandling: Maybe<ShippingHandling>
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
  subtotal: Money
  /** The invoice tax details. */
  taxes: Maybe<Array<Maybe<TaxItem>>>
  /** The shipping amount for the invoice. */
  totalShipping: Money
  /** The amount of tax applied to the invoice. */
  totalTax: Money
}

/** Contains the response of a company admin email validation query. */
export interface IsCompanyAdminEmailAvailableOutput {
  __typename?: 'IsCompanyAdminEmailAvailableOutput'
  /** Indicates whether the specified email address can be used to create a company administrator. */
  isEmailAvailable: Scalars['Boolean']['output']
}

/** Contains the response of a company email validation query. */
export interface IsCompanyEmailAvailableOutput {
  __typename?: 'IsCompanyEmailAvailableOutput'
  /** Indicates whether the specified email address can be used to create a company. */
  isEmailAvailable: Scalars['Boolean']['output']
}

/** Contains the response of a role name validation query. */
export interface IsCompanyRoleNameAvailableOutput {
  __typename?: 'IsCompanyRoleNameAvailableOutput'
  /** Indicates whether the specified company role name is available. */
  isRoleNameAvailable: Scalars['Boolean']['output']
}

/** Contains the response of a company user email validation query. */
export interface IsCompanyUserEmailAvailableOutput {
  __typename?: 'IsCompanyUserEmailAvailableOutput'
  /** Indicates whether the specified email address can be used to create a company user. */
  isEmailAvailable: Scalars['Boolean']['output']
}

/** Contains the result of the `isEmailAvailable` query. */
export interface IsEmailAvailableOutput {
  __typename?: 'IsEmailAvailableOutput'
  /** Indicates whether the specified email address can be used to create a customer. */
  isEmailAvailable: Maybe<Scalars['Boolean']['output']>
}

/** The note object for quote line item. */
export interface ItemNote {
  __typename?: 'ItemNote'
  /** Timestamp that reflects note creation date. */
  createdAt: Maybe<Scalars['String']['output']>
  /** ID of the user who submitted a note. */
  creatorId: Maybe<Scalars['Int']['output']>
  /** Type of teh user who submitted a note. */
  creatorType: Maybe<Scalars['Int']['output']>
  /** The unique ID of a `CartItemInterface` object. */
  negotiableQuoteItemUid: Maybe<Scalars['ID']['output']>
  /** Note text. */
  note: Maybe<Scalars['String']['output']>
  /** The unique ID of a `ItemNote` object. */
  noteUid: Maybe<Scalars['ID']['output']>
}

/** A list of options of the selected bundle product. */
export interface ItemSelectedBundleOption {
  __typename?: 'ItemSelectedBundleOption'
  /**
   * The unique ID for a `ItemSelectedBundleOption` object.
   * @deprecated Use `uid` instead.
   */
  id: Scalars['ID']['output']
  /** The label of the option. */
  label: Scalars['String']['output']
  /** The unique ID for a `ItemSelectedBundleOption` object. */
  uid: Scalars['ID']['output']
  /** A list of products that represent the values of the parent option. */
  values: Maybe<Array<Maybe<ItemSelectedBundleOptionValue>>>
}

/** A list of values for the selected bundle product. */
export interface ItemSelectedBundleOptionValue {
  __typename?: 'ItemSelectedBundleOptionValue'
  /**
   * The unique ID for a `ItemSelectedBundleOptionValue` object.
   * @deprecated Use `uid` instead.
   */
  id: Scalars['ID']['output']
  /** The price of the child bundle product. */
  price: Money
  /** The name of the child bundle product. */
  productName: Scalars['String']['output']
  /** The SKU of the child bundle product. */
  productSku: Scalars['String']['output']
  /** The number of this bundle product that were ordered. */
  quantity: Scalars['Float']['output']
  /** The unique ID for a `ItemSelectedBundleOptionValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains a key-value pair. */
export interface KeyValue {
  __typename?: 'KeyValue'
  /** The name part of the key/value pair. */
  name: Maybe<Scalars['String']['output']>
  /** The value part of the key/value pair. */
  value: Maybe<Scalars['String']['output']>
}

/** Contains information for rendering layered navigation. */
export interface LayerFilter {
  __typename?: 'LayerFilter'
  /**
   * An array of filter items.
   * @deprecated Use `Aggregation.options` instead.
   */
  filterItems: Maybe<Array<Maybe<LayerFilterItemInterface>>>
  /**
   * The count of filter items in filter group.
   * @deprecated Use `Aggregation.count` instead.
   */
  filterItemsCount: Maybe<Scalars['Int']['output']>
  /**
   * The name of a layered navigation filter.
   * @deprecated Use `Aggregation.label` instead.
   */
  name: Maybe<Scalars['String']['output']>
  /**
   * The request variable name for a filter query.
   * @deprecated Use `Aggregation.attribute_code` instead.
   */
  requestVar: Maybe<Scalars['String']['output']>
}

export interface LayerFilterItem extends LayerFilterItemInterface {
  __typename?: 'LayerFilterItem'
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  itemsCount: Maybe<Scalars['Int']['output']>
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label: Maybe<Scalars['String']['output']>
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  valueString: Maybe<Scalars['String']['output']>
}

export interface LayerFilterItemInterface {
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  itemsCount: Maybe<Scalars['Int']['output']>
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label: Maybe<Scalars['String']['output']>
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  valueString: Maybe<Scalars['String']['output']>
}

/** Sets quote item note. */
export interface LineItemNoteInput {
  /** The note text to be added. */
  note?: InputMaybe<Scalars['String']['input']>
  /** The unique ID of a `CartLineItem` object. */
  quote_item_uid: Scalars['ID']['input']
  /** The unique ID of a `NegotiableQuote` object. */
  quote_uid: Scalars['ID']['input']
}

/** Defines characteristics about images and videos associated with a specific product. */
export interface MediaGalleryEntry {
  __typename?: 'MediaGalleryEntry'
  /** Details about the content of the media gallery item. */
  content: Maybe<ProductMediaGalleryEntriesContent>
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']['output']>
  /** The path of the image on the server. */
  file: Maybe<Scalars['String']['output']>
  /**
   * The identifier assigned to the object.
   * @deprecated Use `uid` instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** The alt text displayed on the storefront when the user points to the image. */
  label: Maybe<Scalars['String']['output']>
  /** Either `image` or `video`. */
  mediaType: Maybe<Scalars['String']['output']>
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']['output']>
  /** Array of image types. It can have the following values: image, small_image, thumbnail. */
  types: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The unique ID for a `MediaGalleryEntry` object. */
  uid: Scalars['ID']['output']
  /** Details about the content of a video item. */
  videoContent: Maybe<ProductMediaGalleryEntriesVideoContent>
}

/** Contains basic information about a product image or video. */
export interface MediaGalleryInterface {
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']['output']>
  /** The label of the product image or video. */
  label: Maybe<Scalars['String']['output']>
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']['output']>
  /** The URL of the product image or video. */
  url: Maybe<Scalars['String']['output']>
}

export interface MessageStyleLogo {
  __typename?: 'MessageStyleLogo'
  /** The type of logo for the PayPal Pay Later messaging */
  type: Maybe<Scalars['String']['output']>
}

export interface MessageStyles {
  __typename?: 'MessageStyles'
  /** The message layout */
  layout: Maybe<Scalars['String']['output']>
  /** The message logo */
  logo: Maybe<MessageStyleLogo>
}

export interface MollieApplePayValidationOutput {
  __typename?: 'MollieApplePayValidationOutput'
  response: Scalars['String']['output']
}

export interface MollieIssuer {
  __typename?: 'MollieIssuer'
  code: Maybe<Scalars['String']['output']>
  image: Maybe<Scalars['String']['output']>
  name: Maybe<Scalars['String']['output']>
  svg: Maybe<Scalars['String']['output']>
}

export interface MolliePaymentFee {
  __typename?: 'MolliePaymentFee'
  /** Base mollie payment fee */
  baseFee: Maybe<Money>
  /** Base mollie payment fee tax */
  baseFeeTax: Maybe<Money>
  /** Mollie payment fee */
  fee: Maybe<Money>
  /** Mollie payment fee tax */
  feeTax: Maybe<Money>
}

export interface MolliePaymentLinkRedirectOutput {
  __typename?: 'MolliePaymentLinkRedirectOutput'
  alreadyPaid: Scalars['Boolean']['output']
  isExpired: Scalars['Boolean']['output']
  redirectUrl: Maybe<Scalars['String']['output']>
}

export interface MolliePaymentMethod {
  __typename?: 'MolliePaymentMethod'
  code: Maybe<Scalars['String']['output']>
  image: Maybe<Scalars['String']['output']>
  name: Maybe<Scalars['String']['output']>
}

export interface MolliePaymentMethodMeta {
  __typename?: 'MolliePaymentMethodMeta'
  image: Maybe<Scalars['String']['output']>
}

export interface MolliePaymentMethodsInput {
  amount: Scalars['Float']['input']
  currency?: InputMaybe<Scalars['String']['input']>
}

export interface MolliePaymentMethodsOutput {
  __typename?: 'MolliePaymentMethodsOutput'
  methods: Maybe<Array<Maybe<MolliePaymentMethod>>>
}

export interface MollieProcessTransactionInput {
  /** The payment token returned from the PlaceOrder call/added to the return URL */
  payment_token: Scalars['String']['input']
}

export interface MollieProcessTransactionOutput {
  __typename?: 'MollieProcessTransactionOutput'
  /** The cart is only available when the payment status is failed, canceled or expired. */
  cart: Maybe<Cart>
  paymentStatus: Maybe<PaymentStatusEnum>
  /** Indicates if the customer should be redirected to the cart. */
  redirectToCart: Maybe<Scalars['Boolean']['output']>
  /** Indicates if the customer should be redirected to the success page. */
  redirectToSuccessPage: Maybe<Scalars['Boolean']['output']>
}

export interface MollieResetCartInput {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String']['input']
}

export interface MollieResetCartOutput {
  __typename?: 'MollieResetCartOutput'
  cart: Cart
}

export interface MollieStoreConfig {
  __typename?: 'MollieStoreConfig'
  /** Is Mollie running in live mode? */
  liveMode: Maybe<Scalars['Boolean']['output']>
  /** The profile ID used for this store */
  profileId: Maybe<Scalars['String']['output']>
}

export interface MollieTerminalOutput {
  __typename?: 'MollieTerminalOutput'
  brand: Scalars['String']['output']
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  model: Scalars['String']['output']
  serialNumber: Maybe<Scalars['String']['output']>
}

export interface MollieTransactionInput {
  issuer?: InputMaybe<Scalars['String']['input']>
  payment_token: Scalars['String']['input']
}

export interface MollieTransactionOutput {
  __typename?: 'MollieTransactionOutput'
  checkoutUrl: Maybe<Scalars['String']['output']>
}

/** Defines a monetary value, including a numeric value and a currency code. */
export interface Money {
  __typename?: 'Money'
  /** A three-letter currency code, such as USD or EUR. */
  currency: Maybe<CurrencyEnum>
  /** A number expressing a monetary value. */
  value: Maybe<Scalars['Float']['output']>
}

/** Contains the customer's gift registry and any errors encountered. */
export interface MoveCartItemsToGiftRegistryOutput
  extends GiftRegistryItemUserErrorInterface,
    GiftRegistryOutputInterface {
  __typename?: 'MoveCartItemsToGiftRegistryOutput'
  /** The gift registry. */
  giftRegistry: Maybe<GiftRegistry>
  /** Indicates whether the attempt to move the cart items to the gift registry was successful. */
  status: Scalars['Boolean']['output']
  /** An array of errors encountered while moving items from the cart to the gift registry. */
  userErrors: Array<Maybe<GiftRegistryItemsUserError>>
}

/** An input object that defines the items in a requisition list to be moved. */
export interface MoveItemsBetweenRequisitionListsInput {
  /** An array of IDs representing products moved from one requisition list to another. */
  requisitionListItemUids: Array<InputMaybe<Scalars['ID']['input']>>
}

/** Output of the request to move items to another requisition list. */
export interface MoveItemsBetweenRequisitionListsOutput {
  __typename?: 'MoveItemsBetweenRequisitionListsOutput'
  /** The destination requisition list after moving items. */
  destinationRequisitionList: Maybe<RequisitionList>
  /** The source requisition list after moving items. */
  sourceRequisitionList: Maybe<RequisitionList>
}

/** Contains the source and target wish lists after moving products. */
export interface MoveProductsBetweenWishlistsOutput {
  __typename?: 'MoveProductsBetweenWishlistsOutput'
  /** The destination wish list after receiving products moved from the source wish list. */
  destinationWishlist: Wishlist
  /** The source wish list after moving products from it. */
  sourceWishlist: Wishlist
  /** An array of errors encountered while moving products to a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>
}

export interface Mutation {
  __typename?: 'Mutation'
  /** Add one or more bundle products to the specified cart. We recommend using `addProductsToCart` instead. */
  addBundleProductsToCart: Maybe<AddBundleProductsToCartOutput>
  addCommentToPost: Maybe<AddCommentToPostOutput>
  /** Add one or more configurable products to the specified cart. We recommend using `addProductsToCart` instead. */
  addConfigurableProductsToCart: Maybe<AddConfigurableProductsToCartOutput>
  /** Add one or more downloadable products to the specified cart. We recommend using `addProductsToCart` instead. */
  addDownloadableProductsToCart: Maybe<AddDownloadableProductsToCartOutput>
  /** Add registrants to the specified gift registry. */
  addGiftRegistryRegistrants: Maybe<AddGiftRegistryRegistrantsOutput>
  /** Add any type of product to the cart. */
  addProductsToCart: Maybe<AddProductsToCartOutput>
  /** Add products to the specified compare list. */
  addProductsToCompareList: Maybe<CompareList>
  /** Add items to the specified requisition list. */
  addProductsToRequisitionList: Maybe<AddProductsToRequisitionListOutput>
  /** Add one or more products to the specified wish list. This mutation supports all product types. */
  addProductsToWishlist: Maybe<AddProductsToWishlistOutput>
  /** Add a comment to an existing purchase order. */
  addPurchaseOrderComment: Maybe<AddPurchaseOrderCommentOutput>
  /** Add purchase order items to the shopping cart. */
  addPurchaseOrderItemsToCart: Maybe<AddProductsToCartOutput>
  /** Add items in the requisition list to the customer's cart. */
  addRequisitionListItemsToCart: Maybe<AddRequisitionListItemsToCartOutput>
  /** Add a comment to an existing return. */
  addReturnComment: Maybe<AddReturnCommentOutput>
  /** Add tracking information to the return. */
  addReturnTracking: Maybe<AddReturnTrackingOutput>
  /** Add one or more simple products to the specified cart. We recommend using `addProductsToCart` instead. */
  addSimpleProductsToCart: Maybe<AddSimpleProductsToCartOutput>
  /** Add one or more virtual products to the specified cart. We recommend using `addProductsToCart` instead. */
  addVirtualProductsToCart: Maybe<AddVirtualProductsToCartOutput>
  /** Add items in the specified wishlist to the customer's cart. */
  addWishlistItemsToCart: Maybe<AddWishlistItemsToCartOutput>
  /** Apply a pre-defined coupon code to the specified cart. */
  applyCouponToCart: Maybe<ApplyCouponToCartOutput>
  /** Apply a pre-defined coupon code to the specified cart. */
  applyCouponsToCart: Maybe<ApplyCouponToCartOutput>
  /** Apply a pre-defined gift card code to the specified cart. */
  applyGiftCardToCart: Maybe<ApplyGiftCardToCartOutput>
  /** Apply all available points, up to the cart total. Partial redemption is not available. */
  applyRewardPointsToCart: Maybe<ApplyRewardPointsToCartOutput>
  /** Apply store credit to the specified cart. */
  applyStoreCreditToCart: Maybe<ApplyStoreCreditToCartOutput>
  /** Approve purchase orders. */
  approvePurchaseOrders: Maybe<PurchaseOrdersActionOutput>
  /** Assign the specified compare list to the logged in customer. */
  assignCompareListToCustomer: Maybe<AssignCompareListToCustomerOutput>
  /** Assign a logged-in customer to the specified guest shopping cart. */
  assignCustomerToGuestCart: Cart
  /** Cancel the specified customer order. */
  cancelOrder: Maybe<CancelOrderOutput>
  /** Cancel purchase orders. */
  cancelPurchaseOrders: Maybe<PurchaseOrdersActionOutput>
  /** Change the password for the logged-in customer. */
  changeCustomerPassword: Maybe<Customer>
  /** Remove all items from the specified cart. */
  clearCart: ClearCartOutput
  /** Remove all items from the specified cart. */
  clearCustomerCart: Maybe<ClearCustomerCartOutput>
  /** Mark a negotiable quote as closed. The negotiable quote is still visible on the storefront. */
  closeNegotiableQuotes: Maybe<CloseNegotiableQuotesOutput>
  /** Confirms the email address for a customer. */
  confirmEmail: Maybe<CustomerOutput>
  /** Send a 'Contact Us' email to the merchant. */
  contactUs: Maybe<ContactUsOutput>
  /** Copy items from one requisition list to another. */
  copyItemsBetweenRequisitionLists: Maybe<CopyItemsFromRequisitionListsOutput>
  /** Copy products from one wish list to another. The original wish list is unchanged. */
  copyProductsBetweenWishlists: Maybe<CopyProductsBetweenWishlistsOutput>
  /** Create a company at the request of either a customer or a guest. */
  createCompany: Maybe<CreateCompanyOutput>
  /** Create a new company role. */
  createCompanyRole: Maybe<CreateCompanyRoleOutput>
  /** Create a new team for the authenticated customer's company. */
  createCompanyTeam: Maybe<CreateCompanyTeamOutput>
  /** Create a new company user at the request of an existing customer. */
  createCompanyUser: Maybe<CreateCompanyUserOutput>
  /** Create a new compare list. The compare list is saved for logged in customers. */
  createCompareList: Maybe<CompareList>
  /** Use `createCustomerV2` instead. */
  createCustomer: Maybe<CustomerOutput>
  /** Create a billing or shipping address for a customer or guest. */
  createCustomerAddress: Maybe<CustomerAddress>
  /** Create a new customer configuration */
  createCustomerConfiguration: Maybe<CustomerConfiguration>
  /** Create a customer account. */
  createCustomerV2: Maybe<CustomerOutput>
  /**
   * Create an empty shopping cart for a guest or logged in user
   * @deprecated Use `Mutation.createGuestCart` or `Query.customerCart` for logged in customer
   */
  createEmptyCart: Maybe<Scalars['String']['output']>
  /** Create a gift registry on behalf of the customer. */
  createGiftRegistry: Maybe<CreateGiftRegistryOutput>
  /** Create a new shopping cart */
  createGuestCart: Maybe<CreateGuestCartOutput>
  /** @deprecated Using the Order.mollie_redirect_url attribuut */
  createMollieTransaction: Maybe<MollieTransactionOutput>
  /** Initiate a transaction and receive a token. Use this mutation for Payflow Pro and Payments Pro payment methods */
  createPayflowProToken: Maybe<CreatePayflowProTokenOutput>
  /** Creates a payment order for further payment processing */
  createPaymentOrder: Maybe<CreatePaymentOrderOutput>
  /** Initiate an Express Checkout transaction and receive a token. Use this mutation for Express Checkout and Payments Standard payment methods. */
  createPaypalExpressToken: Maybe<PaypalExpressTokenOutput>
  /** Create a product review for the specified product. */
  createProductReview: CreateProductReviewOutput
  /** Create a purchase order approval rule. */
  createPurchaseOrderApprovalRule: Maybe<PurchaseOrderApprovalRule>
  /** Create an empty requisition list. */
  createRequisitionList: Maybe<CreateRequisitionListOutput>
  /** Create a new wish list. */
  createWishlist: Maybe<CreateWishlistOutput>
  /** Delete the specified company role. */
  deleteCompanyRole: Maybe<DeleteCompanyRoleOutput>
  /** Delete the specified company team. */
  deleteCompanyTeam: Maybe<DeleteCompanyTeamOutput>
  /** Delete the specified company user. */
  deleteCompanyUser: Maybe<DeleteCompanyUserOutput>
  /** Delete the specified compare list. */
  deleteCompareList: Maybe<DeleteCompareListOutput>
  /** Delete customer account */
  deleteCustomer: Maybe<Scalars['Boolean']['output']>
  /** Delete the billing or shipping address of a customer. */
  deleteCustomerAddress: Maybe<Scalars['Boolean']['output']>
  /** Delete a negotiable quote. The negotiable quote will not be displayed on the storefront. */
  deleteNegotiableQuotes: Maybe<DeleteNegotiableQuotesOutput>
  /** Delete a customer's payment token. */
  deletePaymentToken: Maybe<DeletePaymentTokenOutput>
  /** Delete existing purchase order approval rules. */
  deletePurchaseOrderApprovalRule: Maybe<DeletePurchaseOrderApprovalRuleOutput>
  /** Delete a requisition list. */
  deleteRequisitionList: Maybe<DeleteRequisitionListOutput>
  /** Delete items from a requisition list. */
  deleteRequisitionListItems: Maybe<DeleteRequisitionListItemsOutput>
  /** Delete the specified wish list. You cannot delete the customer's default (first) wish list. */
  deleteWishlist: Maybe<DeleteWishlistOutput>
  /** Estimate shipping method(s) for cart based on address */
  estimateShippingMethods: Maybe<Array<Maybe<AvailableShippingMethod>>>
  /** Estimate totals for cart based on the address */
  estimateTotals: EstimateTotalsOutput
  /** Generate a token for specified customer. */
  generateCustomerToken: Maybe<CustomerToken>
  /** Request a customer token so that an administrator can perform remote shopping assistance. */
  generateCustomerTokenAsAdmin: Maybe<GenerateCustomerTokenAsAdminOutput>
  /** Handle a payment response and save the payment in Quote. Use this mutation for Payflow Pro and Payments Pro payment methods. */
  handlePayflowProResponse: Maybe<PayflowProResponseOutput>
  /** Transfer the contents of a guest cart into the cart of a logged-in customer. */
  mergeCarts: Cart
  mollieApplePayValidation: Maybe<MollieApplePayValidationOutput>
  molliePaymentLinkRedirect: Maybe<MolliePaymentLinkRedirectOutput>
  mollieProcessTransaction: Maybe<MollieProcessTransactionOutput>
  mollieRestoreCart: Maybe<MollieResetCartOutput>
  /** Move all items from the cart to a gift registry. */
  moveCartItemsToGiftRegistry: Maybe<MoveCartItemsToGiftRegistryOutput>
  /** Move Items from one requisition list to another. */
  moveItemsBetweenRequisitionLists: Maybe<MoveItemsBetweenRequisitionListsOutput>
  /** Move products from one wish list to another. */
  moveProductsBetweenWishlists: Maybe<MoveProductsBetweenWishlistsOutput>
  /** Convert a negotiable quote into an order. */
  placeNegotiableQuoteOrder: Maybe<PlaceNegotiableQuoteOrderOutput>
  /** Convert the quote into an order. */
  placeOrder: Maybe<PlaceOrderOutput>
  placeOrderAndGetPaymentInformation: Maybe<PlaceOrderAndGetPaymentInformationOutput>
  /** Convert the purchase order into an order. */
  placeOrderForPurchaseOrder: Maybe<PlaceOrderForPurchaseOrderOutput>
  /** Place a purchase order. */
  placePurchaseOrder: Maybe<PlacePurchaseOrderOutput>
  /** Redeem a gift card for store credit. */
  redeemGiftCardBalanceAsStoreCredit: Maybe<GiftCardAccount>
  /** Reject purchase orders. */
  rejectPurchaseOrders: Maybe<PurchaseOrdersActionOutput>
  /** Remove a previously-applied coupon from the cart. The cart must contain at least one item in order to remove the coupon. */
  removeCouponFromCart: Maybe<RemoveCouponFromCartOutput>
  /** Remove a previously-applied coupon from the cart. The cart must contain at least one item in order to remove the coupon. */
  removeCouponsFromCart: Maybe<RemoveCouponFromCartOutput>
  /** Removes a gift card from the cart. */
  removeGiftCardFromCart: Maybe<RemoveGiftCardFromCartOutput>
  /** Delete the specified gift registry. */
  removeGiftRegistry: Maybe<RemoveGiftRegistryOutput>
  /** Delete the specified items from a gift registry. */
  removeGiftRegistryItems: Maybe<RemoveGiftRegistryItemsOutput>
  /** Removes registrants from a gift registry. */
  removeGiftRegistryRegistrants: Maybe<RemoveGiftRegistryRegistrantsOutput>
  /** Delete the entire quantity of a specified item from the cart. If you remove all items from the cart, the cart continues to exist. */
  removeItemFromCart: Maybe<RemoveItemFromCartOutput>
  /** Remove one or more products from a negotiable quote. */
  removeNegotiableQuoteItems: Maybe<RemoveNegotiableQuoteItemsOutput>
  /** Remove products from the specified compare list. */
  removeProductsFromCompareList: Maybe<CompareList>
  /** Remove one or more products from the specified wish list. */
  removeProductsFromWishlist: Maybe<RemoveProductsFromWishlistOutput>
  /** Remove a tracked shipment from a return. */
  removeReturnTracking: Maybe<RemoveReturnTrackingOutput>
  /** Cancel the application of reward points to the cart. */
  removeRewardPointsFromCart: Maybe<RemoveRewardPointsFromCartOutput>
  /** Remove store credit that has been applied to the specified cart. */
  removeStoreCreditFromCart: Maybe<RemoveStoreCreditFromCartOutput>
  /** Add all products from a customer's previous order to the cart. */
  reorderItems: Maybe<ReorderItemsOutput>
  /** Request a new negotiable quote on behalf of the buyer. */
  requestNegotiableQuote: Maybe<RequestNegotiableQuoteOutput>
  /** Request an email with a reset password token for the registered customer identified by the specified email. */
  requestPasswordResetEmail: Maybe<Scalars['Boolean']['output']>
  /** Initiates a buyer's request to return items for replacement or refund. */
  requestReturn: Maybe<RequestReturnOutput>
  /** Reset a customer's password using the reset password token that the customer received in an email after requesting it using `requestPasswordResetEmail`. */
  resetPassword: Maybe<Scalars['Boolean']['output']>
  /** Revoke the customer token. */
  revokeCustomerToken: Maybe<RevokeCustomerTokenOutput>
  /** Send a message on behalf of a customer to the specified email addresses. */
  sendEmailToFriend: Maybe<SendEmailToFriendOutput>
  /** Send the negotiable quote to the seller for review. */
  sendNegotiableQuoteForReview: Maybe<SendNegotiableQuoteForReviewOutput>
  /** Set the billing address on a specific cart. */
  setBillingAddressOnCart: Maybe<SetBillingAddressOnCartOutput>
  /** An input object that defines a delivery data. */
  setDeliveryDataOnCart: Maybe<SetDeliveryDataOnCartOutput>
  /** An input object that defines a forklift available */
  setForkliftAvailableOnCart: Maybe<SetForkliftAvailableOnCartOutput>
  /** Set gift options, including gift messages, gift wrapping, gift receipts, and printed cards. */
  setGiftOptionsOnCart: Maybe<SetGiftOptionsOnCartOutput>
  /** Assign the email address of a guest to the cart. */
  setGuestEmailOnCart: Maybe<SetGuestEmailOnCartOutput>
  /** Add buyer's note to a negotiable quote item. */
  setLineItemNote: Maybe<SetLineItemNoteOutput>
  /** Assign a billing address to a negotiable quote. */
  setNegotiableQuoteBillingAddress: Maybe<SetNegotiableQuoteBillingAddressOutput>
  /** Set the payment method on a negotiable quote. */
  setNegotiableQuotePaymentMethod: Maybe<SetNegotiableQuotePaymentMethodOutput>
  /** Assign a previously-defined address as the shipping address for a negotiable quote. */
  setNegotiableQuoteShippingAddress: Maybe<SetNegotiableQuoteShippingAddressOutput>
  /** Assign the shipping methods on the negotiable quote. */
  setNegotiableQuoteShippingMethods: Maybe<SetNegotiableQuoteShippingMethodsOutput>
  /**
   * Set the cart payment method and convert the cart into an order.
   * @deprecated Should use setPaymentMethodOnCart and placeOrder mutations in single request.
   */
  setPaymentMethodAndPlaceOrder: Maybe<PlaceOrderOutput>
  /** Apply a payment method to the cart. */
  setPaymentMethodOnCart: Maybe<SetPaymentMethodOnCartOutput>
  /** An input object that defines a order reference */
  setReferenceOnCart: Maybe<SetReferenceOnCartOutput>
  /** Set one or more shipping addresses on a specific cart. */
  setShippingAddressesOnCart: Maybe<SetShippingAddressesOnCartOutput>
  /** Set one or more delivery methods on a cart. */
  setShippingMethodsOnCart: Maybe<SetShippingMethodsOnCartOutput>
  /** Send an email about the gift registry to a list of invitees. */
  shareGiftRegistry: Maybe<ShareGiftRegistryOutput>
  storeData: Scalars['String']['output']
  /** Subscribe the specified email to the store's newsletter. */
  subscribeEmailToNewsletter: Maybe<SubscribeEmailToNewsletterOutput>
  /** Synchronizes the payment order details for further payment processing */
  syncPaymentOrder: Maybe<Scalars['Boolean']['output']>
  /** Modify items in the cart. */
  updateCartItems: Maybe<UpdateCartItemsOutput>
  /** Update company information. */
  updateCompany: Maybe<UpdateCompanyOutput>
  /** Update company role information. */
  updateCompanyRole: Maybe<UpdateCompanyRoleOutput>
  /** Change the parent node of a company team. */
  updateCompanyStructure: Maybe<UpdateCompanyStructureOutput>
  /** Update company team data. */
  updateCompanyTeam: Maybe<UpdateCompanyTeamOutput>
  /** Update an existing company user. */
  updateCompanyUser: Maybe<UpdateCompanyUserOutput>
  /** Use `updateCustomerV2` instead. */
  updateCustomer: Maybe<CustomerOutput>
  /** Update the billing or shipping address of a customer or guest. */
  updateCustomerAddress: Maybe<CustomerAddress>
  /** Change the email address for the logged-in customer. */
  updateCustomerEmail: Maybe<CustomerOutput>
  /** Update the customer's personal information. */
  updateCustomerV2: Maybe<CustomerOutput>
  /** Update the specified gift registry. */
  updateGiftRegistry: Maybe<UpdateGiftRegistryOutput>
  /** Update the specified items in the gift registry. */
  updateGiftRegistryItems: Maybe<UpdateGiftRegistryItemsOutput>
  /** Modify the properties of one or more gift registry registrants. */
  updateGiftRegistryRegistrants: Maybe<UpdateGiftRegistryRegistrantsOutput>
  /** Change the quantity of one or more items in an existing negotiable quote. */
  updateNegotiableQuoteQuantities: Maybe<UpdateNegotiableQuoteItemsQuantityOutput>
  /** Update one or more products in the specified wish list. */
  updateProductsInWishlist: Maybe<UpdateProductsInWishlistOutput>
  /** Update existing purchase order approval rules. */
  updatePurchaseOrderApprovalRule: Maybe<PurchaseOrderApprovalRule>
  /** Rename a requisition list and change its description. */
  updateRequisitionList: Maybe<UpdateRequisitionListOutput>
  /** Update items in a requisition list. */
  updateRequisitionListItems: Maybe<UpdateRequisitionListItemsOutput>
  /** Change the name and visibility of the specified wish list. */
  updateWishlist: Maybe<UpdateWishlistOutput>
  /** Validate purchase orders. */
  validatePurchaseOrders: Maybe<ValidatePurchaseOrdersOutput>
}

export interface MutationAddBundleProductsToCartArgs {
  input: InputMaybe<AddBundleProductsToCartInput>
}

export interface MutationAddCommentToPostArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  input: InputMaybe<AddCommentToPostInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export interface MutationAddConfigurableProductsToCartArgs {
  input: InputMaybe<AddConfigurableProductsToCartInput>
}

export interface MutationAddDownloadableProductsToCartArgs {
  input: InputMaybe<AddDownloadableProductsToCartInput>
}

export interface MutationAddGiftRegistryRegistrantsArgs {
  giftRegistryUid: Scalars['ID']['input']
  registrants: Array<AddGiftRegistryRegistrantInput>
}

export interface MutationAddProductsToCartArgs {
  cartId: Scalars['String']['input']
  cartItems: Array<CartItemInput>
}

export interface MutationAddProductsToCompareListArgs {
  input: InputMaybe<AddProductsToCompareListInput>
}

export interface MutationAddProductsToRequisitionListArgs {
  requisitionListItems: Array<RequisitionListItemsInput>
  requisitionListUid: Scalars['ID']['input']
}

export interface MutationAddProductsToWishlistArgs {
  wishlistId: Scalars['ID']['input']
  wishlistItems: Array<WishlistItemInput>
}

export interface MutationAddPurchaseOrderCommentArgs {
  input: AddPurchaseOrderCommentInput
}

export interface MutationAddPurchaseOrderItemsToCartArgs {
  input: AddPurchaseOrderItemsToCartInput
}

export interface MutationAddRequisitionListItemsToCartArgs {
  requisitionListItemUids: InputMaybe<Array<Scalars['ID']['input']>>
  requisitionListUid: Scalars['ID']['input']
}

export interface MutationAddReturnCommentArgs {
  input: AddReturnCommentInput
}

export interface MutationAddReturnTrackingArgs {
  input: AddReturnTrackingInput
}

export interface MutationAddSimpleProductsToCartArgs {
  input: InputMaybe<AddSimpleProductsToCartInput>
}

export interface MutationAddVirtualProductsToCartArgs {
  input: InputMaybe<AddVirtualProductsToCartInput>
}

export interface MutationAddWishlistItemsToCartArgs {
  wishlistId: Scalars['ID']['input']
  wishlistItemIds: InputMaybe<Array<Scalars['ID']['input']>>
}

export interface MutationApplyCouponToCartArgs {
  input: InputMaybe<ApplyCouponToCartInput>
}

export interface MutationApplyCouponsToCartArgs {
  input: InputMaybe<ApplyCouponsToCartInput>
}

export interface MutationApplyGiftCardToCartArgs {
  input: InputMaybe<ApplyGiftCardToCartInput>
}

export interface MutationApplyRewardPointsToCartArgs {
  cartId: Scalars['ID']['input']
}

export interface MutationApplyStoreCreditToCartArgs {
  input: ApplyStoreCreditToCartInput
}

export interface MutationApprovePurchaseOrdersArgs {
  input: PurchaseOrdersActionInput
}

export interface MutationAssignCompareListToCustomerArgs {
  uid: Scalars['ID']['input']
}

export interface MutationAssignCustomerToGuestCartArgs {
  cart_id: Scalars['String']['input']
}

export interface MutationCancelOrderArgs {
  input: CancelOrderInput
}

export interface MutationCancelPurchaseOrdersArgs {
  input: PurchaseOrdersActionInput
}

export interface MutationChangeCustomerPasswordArgs {
  currentPassword: Scalars['String']['input']
  newPassword: Scalars['String']['input']
}

export interface MutationClearCartArgs {
  input: ClearCartInput
}

export interface MutationClearCustomerCartArgs {
  cartUid: Scalars['String']['input']
}

export interface MutationCloseNegotiableQuotesArgs {
  input: CloseNegotiableQuotesInput
}

export interface MutationConfirmEmailArgs {
  input: ConfirmEmailInput
}

export interface MutationContactUsArgs {
  input: ContactUsInput
}

export interface MutationCopyItemsBetweenRequisitionListsArgs {
  destinationRequisitionListUid: InputMaybe<Scalars['ID']['input']>
  requisitionListItem: InputMaybe<CopyItemsBetweenRequisitionListsInput>
  sourceRequisitionListUid: Scalars['ID']['input']
}

export interface MutationCopyProductsBetweenWishlistsArgs {
  destinationWishlistUid: Scalars['ID']['input']
  sourceWishlistUid: Scalars['ID']['input']
  wishlistItems: Array<WishlistItemCopyInput>
}

export interface MutationCreateCompanyArgs {
  input: CompanyCreateInput
}

export interface MutationCreateCompanyRoleArgs {
  input: CompanyRoleCreateInput
}

export interface MutationCreateCompanyTeamArgs {
  input: CompanyTeamCreateInput
}

export interface MutationCreateCompanyUserArgs {
  input: CompanyUserCreateInput
}

export interface MutationCreateCompareListArgs {
  input: InputMaybe<CreateCompareListInput>
}

export interface MutationCreateCustomerArgs {
  input: CustomerInput
}

export interface MutationCreateCustomerAddressArgs {
  input: CustomerAddressInput
}

export interface MutationCreateCustomerConfigurationArgs {
  input: CreateConfigurationInput
}

export interface MutationCreateCustomerV2Args {
  input: CustomerCreateInput
}

export interface MutationCreateEmptyCartArgs {
  input: InputMaybe<CreateEmptyCartInput>
}

export interface MutationCreateGiftRegistryArgs {
  giftRegistry: CreateGiftRegistryInput
}

export interface MutationCreateGuestCartArgs {
  input: InputMaybe<CreateGuestCartInput>
}

export interface MutationCreateMollieTransactionArgs {
  input: InputMaybe<MollieTransactionInput>
}

export interface MutationCreatePayflowProTokenArgs {
  input: PayflowProTokenInput
}

export interface MutationCreatePaymentOrderArgs {
  input: CreatePaymentOrderInput
}

export interface MutationCreatePaypalExpressTokenArgs {
  input: PaypalExpressTokenInput
}

export interface MutationCreateProductReviewArgs {
  input: CreateProductReviewInput
}

export interface MutationCreatePurchaseOrderApprovalRuleArgs {
  input: PurchaseOrderApprovalRuleInput
}

export interface MutationCreateRequisitionListArgs {
  input: InputMaybe<CreateRequisitionListInput>
}

export interface MutationCreateWishlistArgs {
  input: CreateWishlistInput
}

export interface MutationDeleteCompanyRoleArgs {
  id: Scalars['ID']['input']
}

export interface MutationDeleteCompanyTeamArgs {
  id: Scalars['ID']['input']
}

export interface MutationDeleteCompanyUserArgs {
  id: Scalars['ID']['input']
}

export interface MutationDeleteCompareListArgs {
  uid: Scalars['ID']['input']
}

export interface MutationDeleteCustomerAddressArgs {
  id: Scalars['Int']['input']
}

export interface MutationDeleteNegotiableQuotesArgs {
  input: DeleteNegotiableQuotesInput
}

export interface MutationDeletePaymentTokenArgs {
  public_hash: Scalars['String']['input']
}

export interface MutationDeletePurchaseOrderApprovalRuleArgs {
  input: DeletePurchaseOrderApprovalRuleInput
}

export interface MutationDeleteRequisitionListArgs {
  requisitionListUid: Scalars['ID']['input']
}

export interface MutationDeleteRequisitionListItemsArgs {
  requisitionListItemUids: Array<Scalars['ID']['input']>
  requisitionListUid: Scalars['ID']['input']
}

export interface MutationDeleteWishlistArgs {
  wishlistId: Scalars['ID']['input']
}

export interface MutationEstimateShippingMethodsArgs {
  input: EstimateTotalsInput
}

export interface MutationEstimateTotalsArgs {
  input: EstimateTotalsInput
}

export interface MutationGenerateCustomerTokenArgs {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export interface MutationGenerateCustomerTokenAsAdminArgs {
  input: GenerateCustomerTokenAsAdminInput
}

export interface MutationHandlePayflowProResponseArgs {
  input: PayflowProResponseInput
}

export interface MutationMergeCartsArgs {
  destination_cart_id: InputMaybe<Scalars['String']['input']>
  source_cart_id: Scalars['String']['input']
}

export interface MutationMollieApplePayValidationArgs {
  domain: InputMaybe<Scalars['String']['input']>
  validationUrl: Scalars['String']['input']
}

export interface MutationMolliePaymentLinkRedirectArgs {
  order: InputMaybe<Scalars['String']['input']>
}

export interface MutationMollieProcessTransactionArgs {
  input: InputMaybe<MollieProcessTransactionInput>
}

export interface MutationMollieRestoreCartArgs {
  input: InputMaybe<MollieResetCartInput>
}

export interface MutationMoveCartItemsToGiftRegistryArgs {
  cartUid: Scalars['ID']['input']
  giftRegistryUid: Scalars['ID']['input']
}

export interface MutationMoveItemsBetweenRequisitionListsArgs {
  destinationRequisitionListUid: InputMaybe<Scalars['ID']['input']>
  requisitionListItem: InputMaybe<MoveItemsBetweenRequisitionListsInput>
  sourceRequisitionListUid: Scalars['ID']['input']
}

export interface MutationMoveProductsBetweenWishlistsArgs {
  destinationWishlistUid: Scalars['ID']['input']
  sourceWishlistUid: Scalars['ID']['input']
  wishlistItems: Array<WishlistItemMoveInput>
}

export interface MutationPlaceNegotiableQuoteOrderArgs {
  input: PlaceNegotiableQuoteOrderInput
}

export interface MutationPlaceOrderArgs {
  input: InputMaybe<PlaceOrderInput>
}

export interface MutationPlaceOrderAndGetPaymentInformationArgs {
  input: InputMaybe<PlaceOrderAndGetPaymentInformationInput>
}

export interface MutationPlaceOrderForPurchaseOrderArgs {
  input: PlaceOrderForPurchaseOrderInput
}

export interface MutationPlacePurchaseOrderArgs {
  input: PlacePurchaseOrderInput
}

export interface MutationRedeemGiftCardBalanceAsStoreCreditArgs {
  input: GiftCardAccountInput
}

export interface MutationRejectPurchaseOrdersArgs {
  input: PurchaseOrdersActionInput
}

export interface MutationRemoveCouponFromCartArgs {
  input: InputMaybe<RemoveCouponFromCartInput>
}

export interface MutationRemoveCouponsFromCartArgs {
  input: InputMaybe<RemoveCouponsFromCartInput>
}

export interface MutationRemoveGiftCardFromCartArgs {
  input: InputMaybe<RemoveGiftCardFromCartInput>
}

export interface MutationRemoveGiftRegistryArgs {
  giftRegistryUid: Scalars['ID']['input']
}

export interface MutationRemoveGiftRegistryItemsArgs {
  giftRegistryUid: Scalars['ID']['input']
  itemsUid: Array<Scalars['ID']['input']>
}

export interface MutationRemoveGiftRegistryRegistrantsArgs {
  giftRegistryUid: Scalars['ID']['input']
  registrantsUid: Array<Scalars['ID']['input']>
}

export interface MutationRemoveItemFromCartArgs {
  input: InputMaybe<RemoveItemFromCartInput>
}

export interface MutationRemoveNegotiableQuoteItemsArgs {
  input: RemoveNegotiableQuoteItemsInput
}

export interface MutationRemoveProductsFromCompareListArgs {
  input: InputMaybe<RemoveProductsFromCompareListInput>
}

export interface MutationRemoveProductsFromWishlistArgs {
  wishlistId: Scalars['ID']['input']
  wishlistItemsIds: Array<Scalars['ID']['input']>
}

export interface MutationRemoveReturnTrackingArgs {
  input: RemoveReturnTrackingInput
}

export interface MutationRemoveRewardPointsFromCartArgs {
  cartId: Scalars['ID']['input']
}

export interface MutationRemoveStoreCreditFromCartArgs {
  input: RemoveStoreCreditFromCartInput
}

export interface MutationReorderItemsArgs {
  orderNumber: Scalars['String']['input']
}

export interface MutationRequestNegotiableQuoteArgs {
  input: RequestNegotiableQuoteInput
}

export interface MutationRequestPasswordResetEmailArgs {
  email: Scalars['String']['input']
}

export interface MutationRequestReturnArgs {
  input: RequestReturnInput
}

export interface MutationResetPasswordArgs {
  email: Scalars['String']['input']
  newPassword: Scalars['String']['input']
  resetPasswordToken: Scalars['String']['input']
}

export interface MutationSendEmailToFriendArgs {
  input: InputMaybe<SendEmailToFriendInput>
}

export interface MutationSendNegotiableQuoteForReviewArgs {
  input: SendNegotiableQuoteForReviewInput
}

export interface MutationSetBillingAddressOnCartArgs {
  input: InputMaybe<SetBillingAddressOnCartInput>
}

export interface MutationSetDeliveryDataOnCartArgs {
  input: InputMaybe<SetDeliveryDataOnCartInput>
}

export interface MutationSetForkliftAvailableOnCartArgs {
  input: InputMaybe<SetForkliftAvailableOnCartInput>
}

export interface MutationSetGiftOptionsOnCartArgs {
  input: InputMaybe<SetGiftOptionsOnCartInput>
}

export interface MutationSetGuestEmailOnCartArgs {
  input: InputMaybe<SetGuestEmailOnCartInput>
}

export interface MutationSetLineItemNoteArgs {
  input: LineItemNoteInput
}

export interface MutationSetNegotiableQuoteBillingAddressArgs {
  input: SetNegotiableQuoteBillingAddressInput
}

export interface MutationSetNegotiableQuotePaymentMethodArgs {
  input: SetNegotiableQuotePaymentMethodInput
}

export interface MutationSetNegotiableQuoteShippingAddressArgs {
  input: SetNegotiableQuoteShippingAddressInput
}

export interface MutationSetNegotiableQuoteShippingMethodsArgs {
  input: SetNegotiableQuoteShippingMethodsInput
}

export interface MutationSetPaymentMethodAndPlaceOrderArgs {
  input: InputMaybe<SetPaymentMethodAndPlaceOrderInput>
}

export interface MutationSetPaymentMethodOnCartArgs {
  input: InputMaybe<SetPaymentMethodOnCartInput>
}

export interface MutationSetReferenceOnCartArgs {
  input: InputMaybe<SetReferenceOnCartInput>
}

export interface MutationSetShippingAddressesOnCartArgs {
  input: InputMaybe<SetShippingAddressesOnCartInput>
}

export interface MutationSetShippingMethodsOnCartArgs {
  input: InputMaybe<SetShippingMethodsOnCartInput>
}

export interface MutationShareGiftRegistryArgs {
  giftRegistryUid: Scalars['ID']['input']
  invitees: Array<ShareGiftRegistryInviteeInput>
  sender: ShareGiftRegistrySenderInput
}

export interface MutationStoreDataArgs {
  data: Scalars['JSON']['input']
}

export interface MutationSubscribeEmailToNewsletterArgs {
  email: Scalars['String']['input']
}

export interface MutationSyncPaymentOrderArgs {
  input: InputMaybe<SyncPaymentOrderInput>
}

export interface MutationUpdateCartItemsArgs {
  input: InputMaybe<UpdateCartItemsInput>
}

export interface MutationUpdateCompanyArgs {
  input: CompanyUpdateInput
}

export interface MutationUpdateCompanyRoleArgs {
  input: CompanyRoleUpdateInput
}

export interface MutationUpdateCompanyStructureArgs {
  input: CompanyStructureUpdateInput
}

export interface MutationUpdateCompanyTeamArgs {
  input: CompanyTeamUpdateInput
}

export interface MutationUpdateCompanyUserArgs {
  input: CompanyUserUpdateInput
}

export interface MutationUpdateCustomerArgs {
  input: CustomerInput
}

export interface MutationUpdateCustomerAddressArgs {
  id: Scalars['Int']['input']
  input: InputMaybe<CustomerAddressInput>
}

export interface MutationUpdateCustomerEmailArgs {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export interface MutationUpdateCustomerV2Args {
  input: CustomerUpdateInput
}

export interface MutationUpdateGiftRegistryArgs {
  giftRegistry: UpdateGiftRegistryInput
  giftRegistryUid: Scalars['ID']['input']
}

export interface MutationUpdateGiftRegistryItemsArgs {
  giftRegistryUid: Scalars['ID']['input']
  items: Array<UpdateGiftRegistryItemInput>
}

export interface MutationUpdateGiftRegistryRegistrantsArgs {
  giftRegistryUid: Scalars['ID']['input']
  registrants: Array<UpdateGiftRegistryRegistrantInput>
}

export interface MutationUpdateNegotiableQuoteQuantitiesArgs {
  input: UpdateNegotiableQuoteQuantitiesInput
}

export interface MutationUpdateProductsInWishlistArgs {
  wishlistId: Scalars['ID']['input']
  wishlistItems: Array<WishlistItemUpdateInput>
}

export interface MutationUpdatePurchaseOrderApprovalRuleArgs {
  input: UpdatePurchaseOrderApprovalRuleInput
}

export interface MutationUpdateRequisitionListArgs {
  input: InputMaybe<UpdateRequisitionListInput>
  requisitionListUid: Scalars['ID']['input']
}

export interface MutationUpdateRequisitionListItemsArgs {
  requisitionListItems: Array<UpdateRequisitionListItemsInput>
  requisitionListUid: Scalars['ID']['input']
}

export interface MutationUpdateWishlistArgs {
  name: InputMaybe<Scalars['String']['input']>
  visibility: InputMaybe<WishlistVisibilityEnum>
  wishlistId: Scalars['ID']['input']
}

export interface MutationValidatePurchaseOrdersArgs {
  input: ValidatePurchaseOrdersInput
}

/** Contains details about a negotiable quote. */
export interface NegotiableQuote {
  __typename?: 'NegotiableQuote'
  /** An array of payment methods that can be applied to the negotiable quote. */
  availablePaymentMethods: Maybe<Array<Maybe<AvailablePaymentMethod>>>
  /** The billing address applied to the negotiable quote. */
  billingAddress: Maybe<NegotiableQuoteBillingAddress>
  /** The first and last name of the buyer. */
  buyer: NegotiableQuoteUser
  /** A list of comments made by the buyer and seller. */
  comments: Maybe<Array<Maybe<NegotiableQuoteComment>>>
  /** Timestamp indicating when the negotiable quote was created. */
  createdAt: Maybe<Scalars['String']['output']>
  /** The email address of the company user. */
  email: Maybe<Scalars['String']['output']>
  /** A list of status and price changes for the negotiable quote. */
  history: Maybe<Array<Maybe<NegotiableQuoteHistoryEntry>>>
  /** Indicates whether the negotiable quote contains only virtual products. */
  isVirtual: Scalars['Boolean']['output']
  /** The list of items in the negotiable quote. */
  items: Maybe<Array<Maybe<CartItemInterface>>>
  /** The title assigned to the negotiable quote. */
  name: Scalars['String']['output']
  /** A set of subtotals and totals applied to the negotiable quote. */
  prices: Maybe<CartPrices>
  /** The payment method that was applied to the negotiable quote. */
  selectedPaymentMethod: Maybe<SelectedPaymentMethod>
  /** A list of shipping addresses applied to the negotiable quote. */
  shippingAddresses: Array<Maybe<NegotiableQuoteShippingAddress>>
  /** The status of the negotiable quote. */
  status: NegotiableQuoteStatus
  /** The total number of items in the negotiable quote. */
  totalQuantity: Scalars['Float']['output']
  /** The unique ID of a `NegotiableQuote` object. */
  uid: Scalars['ID']['output']
  /** Timestamp indicating when the negotiable quote was updated. */
  updatedAt: Maybe<Scalars['String']['output']>
}

/** Defines the company's country. */
export interface NegotiableQuoteAddressCountry {
  __typename?: 'NegotiableQuoteAddressCountry'
  /** The address country code. */
  code: Scalars['String']['output']
  /** The display name of the region. */
  label: Scalars['String']['output']
}

/** Defines the billing or shipping address to be applied to the cart. */
export interface NegotiableQuoteAddressInput {
  /** The city specified for the billing or shipping address. */
  city: Scalars['String']['input']
  /** The company name. */
  company?: InputMaybe<Scalars['String']['input']>
  /** The country code and label for the billing or shipping address. */
  country_code: Scalars['String']['input']
  /** The first name of the company user. */
  firstname: Scalars['String']['input']
  /** The last name of the company user. */
  lastname: Scalars['String']['input']
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: InputMaybe<Scalars['String']['input']>
  /** A string that defines the state or province of the billing or shipping address. */
  region?: InputMaybe<Scalars['String']['input']>
  /** An integer that defines the state or province of the billing or shipping address. */
  region_id?: InputMaybe<Scalars['Int']['input']>
  /** Determines whether to save the address in the customer's address book. The default value is true. */
  save_in_address_book?: InputMaybe<Scalars['Boolean']['input']>
  /** An array containing the street for the billing or shipping address. */
  street: Array<InputMaybe<Scalars['String']['input']>>
  /** The telephone number for the billing or shipping address. */
  telephone?: InputMaybe<Scalars['String']['input']>
}

export interface NegotiableQuoteAddressInterface {
  /** The company's city or town. */
  city: Scalars['String']['output']
  /** The company name associated with the shipping/billing address. */
  company: Maybe<Scalars['String']['output']>
  /** The company's country. */
  country: NegotiableQuoteAddressCountry
  /** The first name of the company user. */
  firstname: Scalars['String']['output']
  /** The last name of the company user. */
  lastname: Scalars['String']['output']
  /** The company's ZIP or postal code. */
  postcode: Maybe<Scalars['String']['output']>
  /** An object containing the region name, region code, and region ID. */
  region: Maybe<NegotiableQuoteAddressRegion>
  /** An array of strings that define the street number and name. */
  street: Array<Maybe<Scalars['String']['output']>>
  /** The customer's telephone number. */
  telephone: Maybe<Scalars['String']['output']>
}

/** Defines the company's state or province. */
export interface NegotiableQuoteAddressRegion {
  __typename?: 'NegotiableQuoteAddressRegion'
  /** The address region code. */
  code: Maybe<Scalars['String']['output']>
  /** The display name of the region. */
  label: Maybe<Scalars['String']['output']>
  /** The unique ID for a pre-defined region. */
  regionId: Maybe<Scalars['Int']['output']>
}

export interface NegotiableQuoteBillingAddress
  extends NegotiableQuoteAddressInterface {
  __typename?: 'NegotiableQuoteBillingAddress'
  /** The company's city or town. */
  city: Scalars['String']['output']
  /** The company name associated with the shipping/billing address. */
  company: Maybe<Scalars['String']['output']>
  /** The company's country. */
  country: NegotiableQuoteAddressCountry
  /** The first name of the company user. */
  firstname: Scalars['String']['output']
  /** The last name of the company user. */
  lastname: Scalars['String']['output']
  /** The company's ZIP or postal code. */
  postcode: Maybe<Scalars['String']['output']>
  /** An object containing the region name, region code, and region ID. */
  region: Maybe<NegotiableQuoteAddressRegion>
  /** An array of strings that define the street number and name. */
  street: Array<Maybe<Scalars['String']['output']>>
  /** The customer's telephone number. */
  telephone: Maybe<Scalars['String']['output']>
}

/** Defines the billing address. */
export interface NegotiableQuoteBillingAddressInput {
  /** Defines a billing address. */
  address?: InputMaybe<NegotiableQuoteAddressInput>
  /** The unique ID of a `CustomerAddress` object. */
  customer_address_uid?: InputMaybe<Scalars['ID']['input']>
  /** Indicates whether to set the billing address to be the same as the existing shipping address on the negotiable quote. */
  same_as_shipping?: InputMaybe<Scalars['Boolean']['input']>
  /** Indicates whether to set the shipping address to be the same as this billing address. */
  use_for_shipping?: InputMaybe<Scalars['Boolean']['input']>
}

/** Contains a single plain text comment from either the buyer or seller. */
export interface NegotiableQuoteComment {
  __typename?: 'NegotiableQuoteComment'
  /** The first and last name of the commenter. */
  author: NegotiableQuoteUser
  /** Timestamp indicating when the comment was created. */
  createdAt: Scalars['String']['output']
  /** Indicates whether a buyer or seller commented. */
  creatorType: NegotiableQuoteCommentCreatorType
  /** The plain text comment. */
  text: Scalars['String']['output']
  /** The unique ID of a `NegotiableQuoteComment` object. */
  uid: Scalars['ID']['output']
}

export enum NegotiableQuoteCommentCreatorType {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

/** Contains the commend provided by the buyer. */
export interface NegotiableQuoteCommentInput {
  /** The comment provided by the buyer. */
  comment: Scalars['String']['input']
}

/** Contains custom log entries added by third-party extensions. */
export interface NegotiableQuoteCustomLogChange {
  __typename?: 'NegotiableQuoteCustomLogChange'
  /** The new entry content. */
  newValue: Scalars['String']['output']
  /** The previous entry in the custom log. */
  oldValue: Maybe<Scalars['String']['output']>
  /** The title of the custom log entry. */
  title: Scalars['String']['output']
}

/** Defines a filter to limit the negotiable quotes to return. */
export interface NegotiableQuoteFilterInput {
  /** Filter by the ID of one or more negotiable quotes. */
  ids?: InputMaybe<FilterEqualTypeInput>
  /** Filter by the negotiable quote name. */
  name?: InputMaybe<FilterMatchTypeInput>
}

/** Contains a list of changes to a negotiable quote. */
export interface NegotiableQuoteHistoryChanges {
  __typename?: 'NegotiableQuoteHistoryChanges'
  /** The comment provided with a change in the negotiable quote history. */
  commentAdded: Maybe<NegotiableQuoteHistoryCommentChange>
  /** Lists log entries added by third-party extensions. */
  customChanges: Maybe<NegotiableQuoteCustomLogChange>
  /** The expiration date of the negotiable quote before and after a change in the quote history. */
  expiration: Maybe<NegotiableQuoteHistoryExpirationChange>
  /** Lists products that were removed as a result of a change in the quote history. */
  productsRemoved: Maybe<NegotiableQuoteHistoryProductsRemovedChange>
  /** The status before and after a change in the negotiable quote history. */
  statuses: Maybe<NegotiableQuoteHistoryStatusesChange>
  /** The total amount of the negotiable quote before and after a change in the quote history. */
  total: Maybe<NegotiableQuoteHistoryTotalChange>
}

/** Contains a comment submitted by a seller or buyer. */
export interface NegotiableQuoteHistoryCommentChange {
  __typename?: 'NegotiableQuoteHistoryCommentChange'
  /** A plain text comment submitted by a seller or buyer. */
  comment: Scalars['String']['output']
}

/** Contains details about a change for a negotiable quote. */
export interface NegotiableQuoteHistoryEntry {
  __typename?: 'NegotiableQuoteHistoryEntry'
  /** The person who made a change in the status of the negotiable quote. */
  author: NegotiableQuoteUser
  /** An enum that describes the why the entry in the negotiable quote history changed status. */
  changeType: NegotiableQuoteHistoryEntryChangeType
  /** The set of changes in the negotiable quote. */
  changes: Maybe<NegotiableQuoteHistoryChanges>
  /** Timestamp indicating when the negotiable quote entry was created. */
  createdAt: Maybe<Scalars['String']['output']>
  /** The unique ID of a `NegotiableQuoteHistoryEntry` object. */
  uid: Scalars['ID']['output']
}

export enum NegotiableQuoteHistoryEntryChangeType {
  CLOSED = 'CLOSED',
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  UPDATED_BY_SYSTEM = 'UPDATED_BY_SYSTEM',
}

/** Contains a new expiration date and the previous date. */
export interface NegotiableQuoteHistoryExpirationChange {
  __typename?: 'NegotiableQuoteHistoryExpirationChange'
  /** The expiration date after the change. The value will be 'null' if not set. */
  newExpiration: Maybe<Scalars['String']['output']>
  /** The previous expiration date. The value will be 'null' if not previously set. */
  oldExpiration: Maybe<Scalars['String']['output']>
}

/** Contains lists of products that have been removed from the catalog and negotiable quote. */
export interface NegotiableQuoteHistoryProductsRemovedChange {
  __typename?: 'NegotiableQuoteHistoryProductsRemovedChange'
  /** A list of product IDs the seller removed from the catalog. */
  productsRemovedFromCatalog: Maybe<Array<Maybe<Scalars['ID']['output']>>>
  /** A list of products removed from the negotiable quote by either the buyer or the seller. */
  productsRemovedFromQuote: Maybe<Array<Maybe<ProductInterface>>>
}

/** Lists a new status change applied to a negotiable quote and the previous status. */
export interface NegotiableQuoteHistoryStatusChange {
  __typename?: 'NegotiableQuoteHistoryStatusChange'
  /** The updated status. */
  newStatus: NegotiableQuoteStatus
  /** The previous status. The value will be null for the first history entry in a negotiable quote. */
  oldStatus: Maybe<NegotiableQuoteStatus>
}

/** Contains a list of status changes that occurred for the negotiable quote. */
export interface NegotiableQuoteHistoryStatusesChange {
  __typename?: 'NegotiableQuoteHistoryStatusesChange'
  /** A list of status changes. */
  changes: Array<Maybe<NegotiableQuoteHistoryStatusChange>>
}

/** Contains a new price and the previous price. */
export interface NegotiableQuoteHistoryTotalChange {
  __typename?: 'NegotiableQuoteHistoryTotalChange'
  /** The total price as a result of the change. */
  newPrice: Maybe<Money>
  /** The previous total price on the negotiable quote. */
  oldPrice: Maybe<Money>
}

/** An error indicating that an operation was attempted on a negotiable quote in an invalid state. */
export interface NegotiableQuoteInvalidStateError extends ErrorInterface {
  __typename?: 'NegotiableQuoteInvalidStateError'
  /** The returned error message. */
  message: Scalars['String']['output']
}

/** Specifies the updated quantity of an item. */
export interface NegotiableQuoteItemQuantityInput {
  /** The new quantity of the negotiable quote item. */
  quantity: Scalars['Float']['input']
  /** The unique ID of a `CartItemInterface` object. */
  quote_item_uid: Scalars['ID']['input']
}

/** Defines the payment method to be applied to the negotiable quote. */
export interface NegotiableQuotePaymentMethodInput {
  /** Payment method code */
  code: Scalars['String']['input']
  /** The purchase order number. Optional for most payment methods. */
  purchase_order_number?: InputMaybe<Scalars['String']['input']>
}

export interface NegotiableQuoteShippingAddress
  extends NegotiableQuoteAddressInterface {
  __typename?: 'NegotiableQuoteShippingAddress'
  /** An array of shipping methods available to the buyer. */
  availableShippingMethods: Maybe<Array<Maybe<AvailableShippingMethod>>>
  /** The company's city or town. */
  city: Scalars['String']['output']
  /** The company name associated with the shipping/billing address. */
  company: Maybe<Scalars['String']['output']>
  /** The company's country. */
  country: NegotiableQuoteAddressCountry
  /** The first name of the company user. */
  firstname: Scalars['String']['output']
  /** The last name of the company user. */
  lastname: Scalars['String']['output']
  /** The company's ZIP or postal code. */
  postcode: Maybe<Scalars['String']['output']>
  /** An object containing the region name, region code, and region ID. */
  region: Maybe<NegotiableQuoteAddressRegion>
  /** The selected shipping method. */
  selectedShippingMethod: Maybe<SelectedShippingMethod>
  /** An array of strings that define the street number and name. */
  street: Array<Maybe<Scalars['String']['output']>>
  /** The customer's telephone number. */
  telephone: Maybe<Scalars['String']['output']>
}

/** Defines shipping addresses for the negotiable quote. */
export interface NegotiableQuoteShippingAddressInput {
  /** A shipping address. */
  address?: InputMaybe<NegotiableQuoteAddressInput>
  /** An ID from the company user's address book that uniquely identifies the address to be used for shipping. */
  customer_address_uid?: InputMaybe<Scalars['ID']['input']>
  /** Text provided by the company user. */
  customer_notes?: InputMaybe<Scalars['String']['input']>
}

/** Defines the field to use to sort a list of negotiable quotes. */
export interface NegotiableQuoteSortInput {
  /** Whether to return results in ascending or descending order. */
  sort_direction: SortEnum
  /** The specified sort field. */
  sort_field: NegotiableQuoteSortableField
}

export enum NegotiableQuoteSortableField {
  /** Sorts negotiable quotes by the dates they were created. */
  CREATED_AT = 'CREATED_AT',
  /** Sorts negotiable quotes by name. */
  QUOTE_NAME = 'QUOTE_NAME',
  /** Sorts negotiable quotes by the dates they were last modified. */
  UPDATED_AT = 'UPDATED_AT',
}

export enum NegotiableQuoteStatus {
  CLOSED = 'CLOSED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  OPEN = 'OPEN',
  ORDERED = 'ORDERED',
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
  UPDATED = 'UPDATED',
}

export interface NegotiableQuoteUidNonFatalResultInterface {
  /** The unique ID of a `NegotiableQuote` object. */
  quoteUid: Scalars['ID']['output']
}

/** Contains details about a successful operation on a negotiable quote. */
export interface NegotiableQuoteUidOperationSuccess
  extends NegotiableQuoteUidNonFatalResultInterface {
  __typename?: 'NegotiableQuoteUidOperationSuccess'
  /** The unique ID of a `NegotiableQuote` object. */
  quoteUid: Scalars['ID']['output']
}

/** A limited view of a Buyer or Seller in the negotiable quote process. */
export interface NegotiableQuoteUser {
  __typename?: 'NegotiableQuoteUser'
  /** The first name of the buyer or seller making a change. */
  firstname: Scalars['String']['output']
  /** The buyer's or seller's last name. */
  lastname: Scalars['String']['output']
}

/** Contains a list of negotiable that match the specified filter. */
export interface NegotiableQuotesOutput {
  __typename?: 'NegotiableQuotesOutput'
  /** A list of negotiable quotes */
  items: Array<Maybe<NegotiableQuote>>
  /** Contains pagination metadata */
  pageInfo: SearchResultPageInfo
  /** Contains the default sort field and all available sort fields. */
  sortFields: Maybe<SortFields>
  /** The number of negotiable quotes returned */
  totalCount: Scalars['Int']['output']
}

/** Contains an error message when an invalid UID was specified. */
export interface NoSuchEntityUidError extends ErrorInterface {
  __typename?: 'NoSuchEntityUidError'
  /** The returned error message. */
  message: Scalars['String']['output']
  /** The specified invalid unique ID of an object. */
  uid: Scalars['ID']['output']
}

/** Contains the order ID. */
export interface Order {
  __typename?: 'Order'
  maskedOrderId: Maybe<Scalars['String']['output']>
  molliePaymentToken: Maybe<Scalars['String']['output']>
  mollieRedirectUrl: Maybe<Scalars['String']['output']>
  /** @deprecated Use `order_number` instead. */
  orderId: Maybe<Scalars['String']['output']>
  /** The unique ID for an `Order` object. */
  orderNumber: Scalars['String']['output']
}

/** Contains detailed information about an order's billing and shipping addresses. */
export interface OrderAddress {
  __typename?: 'OrderAddress'
  /** The city or town. */
  city: Scalars['String']['output']
  /** The customer's company. */
  company: Maybe<Scalars['String']['output']>
  /** The customer's country. */
  countryCode: Maybe<CountryCodeEnum>
  /** The fax number. */
  fax: Maybe<Scalars['String']['output']>
  /** The first name of the person associated with the shipping/billing address. */
  firstname: Scalars['String']['output']
  /** The family name of the person associated with the shipping/billing address. */
  lastname: Scalars['String']['output']
  /** The middle name of the person associated with the shipping/billing address. */
  middlename: Maybe<Scalars['String']['output']>
  /** The customer's ZIP or postal code. */
  postcode: Maybe<Scalars['String']['output']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']['output']>
  /** The state or province name. */
  region: Maybe<Scalars['String']['output']>
  /** The unique ID for a `Region` object of a pre-defined region. */
  regionId: Maybe<Scalars['ID']['output']>
  /** An array of strings that define the street number and name. */
  street: Array<Maybe<Scalars['String']['output']>>
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']['output']>
  /** The telephone number. */
  telephone: Maybe<Scalars['String']['output']>
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  vatId: Maybe<Scalars['String']['output']>
}

/** Input to retrieve an order based on details. */
export interface OrderInformationInput {
  /** Order billing address email. */
  email: Scalars['String']['input']
  /** Order number. */
  number: Scalars['String']['input']
  /** Order billing address postcode. */
  postcode: Scalars['String']['input']
}

export interface OrderItem extends OrderItemInterface {
  __typename?: 'OrderItem'
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** Indicates whether the order item is eligible to be in a return request. */
  eligibleForReturn: Maybe<Scalars['Boolean']['output']>
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>
  /** The selected gift wrapping for the order item. */
  giftWrapping: Maybe<GiftWrapping>
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID']['output']
  /** The ProductInterface object, which contains details about the base product */
  product: Maybe<ProductInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']['output']>
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']['output']>
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']['output']>
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']['output']>
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']['output']>
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']['output']>
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The status of the order item. */
  status: Maybe<Scalars['String']['output']>
}

/** Order item details. */
export interface OrderItemInterface {
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** Indicates whether the order item is eligible to be in a return request. */
  eligibleForReturn: Maybe<Scalars['Boolean']['output']>
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>
  /** The selected gift wrapping for the order item. */
  giftWrapping: Maybe<GiftWrapping>
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID']['output']
  /** The ProductInterface object, which contains details about the base product */
  product: Maybe<ProductInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']['output']>
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']['output']>
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']['output']>
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']['output']>
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']['output']>
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']['output']>
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The status of the order item. */
  status: Maybe<Scalars['String']['output']>
}

/** Represents order item options like selected or entered. */
export interface OrderItemOption {
  __typename?: 'OrderItemOption'
  /** The name of the option. */
  label: Scalars['String']['output']
  /** The value of the option. */
  value: Scalars['String']['output']
}

/** Contains details about the payment method used to pay for the order. */
export interface OrderPaymentMethod {
  __typename?: 'OrderPaymentMethod'
  /** Additional data per payment method type. */
  additionalData: Maybe<Array<Maybe<KeyValue>>>
  /** The label that describes the payment method. */
  name: Scalars['String']['output']
  /** The payment method code that indicates how the order was paid for. */
  type: Scalars['String']['output']
}

/** Contains order shipment details. */
export interface OrderShipment {
  __typename?: 'OrderShipment'
  /** Comments added to the shipment. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>
  /** The unique ID for a `OrderShipment` object. */
  id: Scalars['ID']['output']
  /** An array of items included in the shipment. */
  items: Maybe<Array<Maybe<ShipmentItemInterface>>>
  /** The sequential credit shipment number. */
  number: Scalars['String']['output']
  /** An array of shipment tracking details. */
  tracking: Maybe<Array<Maybe<ShipmentTracking>>>
}

/** Input to retrieve an order based on token. */
export interface OrderTokenInput {
  /** Order token. */
  token: Scalars['String']['input']
}

/** Contains details about the sales total amounts used to calculate the final price. */
export interface OrderTotal {
  __typename?: 'OrderTotal'
  /** The final base grand total amount in the base currency. */
  baseGrandTotal: Money
  /** The applied discounts to the order. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The final total amount, including shipping, discounts, and taxes. */
  grandTotal: Money
  /** Details about the shipping and handling costs for the order. */
  shippingHandling: Maybe<ShippingHandling>
  /** The subtotal of the order, excluding shipping, discounts, and taxes. */
  subtotal: Money
  /** The order tax details. */
  taxes: Maybe<Array<Maybe<TaxItem>>>
  /** The gift card balance applied to the order. */
  totalGiftcard: Maybe<Money>
  /** The shipping amount for the order. */
  totalShipping: Money
  /** The amount of tax applied to the order. */
  totalTax: Money
}

/** Contains required input for Payflow Express Checkout payments. */
export interface PayflowExpressInput {
  /** The unique ID of the PayPal user. */
  payer_id: Scalars['String']['input']
  /** The token returned by the createPaypalExpressToken mutation. */
  token: Scalars['String']['input']
}

/** A set of relative URLs that PayPal uses in response to various actions during the authorization process. Adobe Commerce prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payflow Link and Payments Advanced payment methods. */
export interface PayflowLinkInput {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']['input']
  /** The relative URL of the transaction error page that PayPal redirects to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String']['input']
  /** The relative URL of the order confirmation page that PayPal redirects to when the payment is successful and additional confirmation is not needed. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']['input']
}

/** Indicates the mode for payment. Applies to the Payflow Link and Payments Advanced payment methods. */
export enum PayflowLinkMode {
  LIVE = 'LIVE',
  TEST = 'TEST',
}

/** Contains information used to generate PayPal iframe for transaction. Applies to Payflow Link and Payments Advanced payment methods. */
export interface PayflowLinkToken {
  __typename?: 'PayflowLinkToken'
  /** The mode for the Payflow transaction. */
  mode: Maybe<PayflowLinkMode>
  /** The PayPal URL used for requesting a Payflow form. */
  paypalUrl: Maybe<Scalars['String']['output']>
  /** The secure token generated by PayPal. */
  secureToken: Maybe<Scalars['String']['output']>
  /** The secure token ID generated by PayPal. */
  secureTokenId: Maybe<Scalars['String']['output']>
}

/** Contains information required to fetch payment token information for the Payflow Link and Payments Advanced payment methods. */
export interface PayflowLinkTokenInput {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String']['input']
}

/** Contains input for the Payflow Pro and Payments Pro payment methods. */
export interface PayflowProInput {
  /** Required input for credit card related information. */
  cc_details: CreditCardDetailsInput
  /** Indicates whether details about the shopper's credit/debit card should be tokenized for later usage. Required only if Vault is enabled for the PayPal Payflow Pro payment integration. */
  is_active_payment_token_enabler?: InputMaybe<Scalars['Boolean']['input']>
}

/** Input required to complete payment. Applies to Payflow Pro and Payments Pro payment methods. */
export interface PayflowProResponseInput {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String']['input']
  /** The payload returned from PayPal. */
  paypal_payload: Scalars['String']['input']
}

export interface PayflowProResponseOutput {
  __typename?: 'PayflowProResponseOutput'
  /** The cart with the updated selected payment method. */
  cart: Cart
}

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export interface PayflowProToken {
  __typename?: 'PayflowProToken'
  /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
  responseMessage: Scalars['String']['output']
  /** A non-zero value if any errors occurred. */
  result: Scalars['Int']['output']
  /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
  resultCode: Scalars['Int']['output']
  /** A secure token generated by PayPal. */
  secureToken: Scalars['String']['output']
  /** A secure token ID generated by PayPal. */
  secureTokenId: Scalars['String']['output']
}

/** Contains input required to fetch payment token information for the Payflow Pro and Payments Pro payment methods. */
export interface PayflowProTokenInput {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String']['input']
  /** A set of relative URLs that PayPal uses for callback. */
  urls: PayflowProUrlInput
}

/** Contains a set of relative URLs that PayPal uses in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for the Payflow Pro and Payment Pro payment methods. */
export interface PayflowProUrlInput {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']['input']
  /** The relative URL of the transaction error page that PayPal redirects to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String']['input']
  /** The relative URL of the final confirmation page that PayPal redirects to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']['input']
}

export interface PaymentCommonConfig extends PaymentConfigItem {
  __typename?: 'PaymentCommonConfig'
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>
}

/** Contains payment fields that are common to all types of payment methods. */
export interface PaymentConfigItem {
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>
}

/** Retrieves the payment configuration for a given location */
export interface PaymentConfigOutput {
  __typename?: 'PaymentConfigOutput'
  /** ApplePay payment method configuration */
  applePay: Maybe<ApplePayConfig>
  /** GooglePay payment method configuration */
  googlePay: Maybe<GooglePayConfig>
  /** Hosted fields payment method configuration */
  hostedFields: Maybe<HostedFieldsConfig>
  /** Smart Buttons payment method configuration */
  smartButtons: Maybe<SmartButtonsConfig>
}

export interface PaymentInformation {
  __typename?: 'PaymentInformation'
  gatewayRedirectFormAction: Maybe<Scalars['String']['output']>
  gatewayRedirectFormFields: Maybe<Array<Maybe<GatewayOrderResultFormField>>>
  gatewayRedirectUrl: Maybe<Scalars['String']['output']>
}

/** Defines the origin location for that payment request */
export enum PaymentLocation {
  ADMIN = 'ADMIN',
  CART = 'CART',
  CHECKOUT = 'CHECKOUT',
  MINICART = 'MINICART',
  PRODUCT_DETAIL = 'PRODUCT_DETAIL',
}

export interface PaymentMethod {
  __typename?: 'PaymentMethod'
  /** Available issuers for this payment method */
  mollieAvailableIssuers: Maybe<Array<Maybe<MollieIssuer>>>
  /** Retrieve meta information for this payment method (image) */
  mollieMeta: MolliePaymentMethodMeta
}

/** Defines the payment method. */
export interface PaymentMethodInput {
  additional_data?: InputMaybe<Array<InputMaybe<AdditionalDataField>>>
  /** The internal name for the payment method. */
  code: Scalars['String']['input']
  /** Required input for PayPal Hosted pro payments. */
  hosted_pro?: InputMaybe<HostedProInput>
  /** The Apple Pay payment token */
  mollie_applepay_payment_token?: InputMaybe<Scalars['String']['input']>
  /** The card token provided by Mollie Components */
  mollie_card_token?: InputMaybe<Scalars['String']['input']>
  /** Provided the issuer chosen by the end-user */
  mollie_selected_issuer?: InputMaybe<Scalars['String']['input']>
  /** Provided the terminal chosen */
  mollie_selected_terminal?: InputMaybe<Scalars['String']['input']>
  /** Required input for Payflow Express Checkout payments. */
  payflow_express?: InputMaybe<PayflowExpressInput>
  /** Required input for PayPal Payflow Link and Payments Advanced payments. */
  payflow_link?: InputMaybe<PayflowLinkInput>
  /** Required input for PayPal Payflow Pro and Payment Pro payments. */
  payflowpro?: InputMaybe<PayflowProInput>
  /** Required input for PayPal Payflow Pro vault payments. */
  payflowpro_cc_vault?: InputMaybe<VaultTokenInput>
  /** Required input for Apple Pay button */
  payment_services_paypal_apple_pay?: InputMaybe<ApplePayMethodInput>
  /** Required input for Google Pay button */
  payment_services_paypal_google_pay?: InputMaybe<GooglePayMethodInput>
  /** Required input for Hosted Fields */
  payment_services_paypal_hosted_fields?: InputMaybe<HostedFieldsInput>
  /** Required input for Smart buttons */
  payment_services_paypal_smart_buttons?: InputMaybe<SmartButtonMethodInput>
  /** Required input for vault */
  payment_services_paypal_vault?: InputMaybe<VaultMethodInput>
  /** Required input for Express Checkout and Payments Standard payments. */
  paypal_express?: InputMaybe<PaypalExpressInput>
  /** The purchase order number. Optional for most payment methods. */
  purchase_order_number?: InputMaybe<Scalars['String']['input']>
}

/** Contains the payment order details */
export interface PaymentOrderOutput {
  __typename?: 'PaymentOrderOutput'
  /** PayPal order ID */
  id: Maybe<Scalars['String']['output']>
  /** The order ID generated by Payment Services */
  mpOrderId: Maybe<Scalars['String']['output']>
  /** Details about the card used on the order */
  paymentSourceDetails: Maybe<PaymentSourceDetails>
  /** The status of the payment order */
  status: Maybe<Scalars['String']['output']>
}

export interface PaymentSdkParamsItem {
  __typename?: 'PaymentSdkParamsItem'
  /** The payment method code used in the order */
  code: Maybe<Scalars['String']['output']>
  /** The payment SDK parameters */
  params: Maybe<Array<Maybe<SdkParams>>>
}

export interface PaymentSourceDetails {
  __typename?: 'PaymentSourceDetails'
  /** Details about the card used on the order */
  card: Maybe<Card>
}

export enum PaymentStatusEnum {
  AUTHORIZED = 'AUTHORIZED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  ERROR = 'ERROR',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  OPEN = 'OPEN',
  PAID = 'PAID',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  SHIPPING = 'SHIPPING',
}

/** The stored payment method available to the customer. */
export interface PaymentToken {
  __typename?: 'PaymentToken'
  /** A description of the stored account details. */
  details: Maybe<Scalars['String']['output']>
  /** The payment method code associated with the token. */
  paymentMethodCode: Scalars['String']['output']
  /** The public hash of the token. */
  publicHash: Scalars['String']['output']
  /** Specifies the payment token type. */
  type: PaymentTokenTypeEnum
}

/** The list of available payment token types. */
export enum PaymentTokenTypeEnum {
  /** phpcs:ignore Magento2.GraphQL.ValidArgumentName */
  ACCOUNT = 'account',
  /** phpcs:ignore Magento2.GraphQL.ValidArgumentName */
  CARD = 'card',
}

/** Contains required input for Express Checkout and Payments Standard payments. */
export interface PaypalExpressInput {
  /** The unique ID of the PayPal user. */
  payer_id: Scalars['String']['input']
  /** The token returned by the `createPaypalExpressToken` mutation. */
  token: Scalars['String']['input']
}

/** Deprecated. Use `PaypalExpressTokenOutput` instead. */
export interface PaypalExpressToken {
  __typename?: 'PaypalExpressToken'
  /**
   * A set of URLs that allow the buyer to authorize payment and adjust checkout details.
   * @deprecated Use `PaypalExpressTokenOutput.paypal_urls` instead.
   */
  paypalUrls: Maybe<PaypalExpressUrlList>
  /**
   * The token returned by PayPal.
   * @deprecated Use `PaypalExpressTokenOutput.token` instead.
   */
  token: Maybe<Scalars['String']['output']>
}

/** Defines the attributes required to receive a payment token for Express Checkout and Payments Standard payment methods. */
export interface PaypalExpressTokenInput {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String']['input']
  /** The payment method code. */
  code: Scalars['String']['input']
  /** Indicates whether the buyer selected the quick checkout button. The default value is false. */
  express_button?: InputMaybe<Scalars['Boolean']['input']>
  /** A set of relative URLs that PayPal uses in response to various actions during the authorization process. */
  urls: PaypalExpressUrlsInput
  /** Indicates whether the buyer clicked the PayPal credit button. The default value is false. */
  use_paypal_credit?: InputMaybe<Scalars['Boolean']['input']>
}

/** Contains the token returned by PayPal and a set of URLs that allow the buyer to authorize payment and adjust checkout details. Applies to Express Checkout and Payments Standard payment methods. */
export interface PaypalExpressTokenOutput {
  __typename?: 'PaypalExpressTokenOutput'
  /** A set of URLs that allow the buyer to authorize payment and adjust checkout details. */
  paypalUrls: Maybe<PaypalExpressUrlList>
  /** The token returned by PayPal. */
  token: Maybe<Scalars['String']['output']>
}

/** Contains a set of URLs that allow the buyer to authorize payment and adjust checkout details for Express Checkout and Payments Standard transactions. */
export interface PaypalExpressUrlList {
  __typename?: 'PaypalExpressUrlList'
  /** The PayPal URL that allows the buyer to edit their checkout details. */
  edit: Maybe<Scalars['String']['output']>
  /** The URL to the PayPal login page. */
  start: Maybe<Scalars['String']['output']>
}

/** Contains a set of relative URLs that PayPal uses in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Express Checkout and Payments Standard payment methods. */
export interface PaypalExpressUrlsInput {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']['input']
  /** The relative URL of the page that PayPal redirects to when the payment has been put on hold for additional review. This condition mostly applies to ACH transactions, and is not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success_pending.html, the relative URL is paypal/action/success_pending.html. */
  pending_url?: InputMaybe<Scalars['String']['input']>
  /** The relative URL of the final confirmation page that PayPal redirects to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']['input']
  /** The relative URL of the order confirmation page that PayPal redirects to when the payment is successful and additional confirmation is not needed. Not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success.html, the relative URL is paypal/action/success.html. */
  success_url?: InputMaybe<Scalars['String']['input']>
}

/** Contains attributes specific to tangible products. */
export interface PhysicalProductInterface {
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>
}

export interface PimcoreAcMaterialResult {
  __typename?: 'PimcoreAcMaterialResult'
  /** AcMaterial product */
  product: Maybe<PimcoreObjectProduct>
  /** RecommendedQuantity of ACMaterial */
  recommendedQuantity: Maybe<Scalars['Int']['output']>
}

export interface PimcoreAcMaterialResults {
  __typename?: 'PimcoreAcMaterialResults'
  /** List of compatible materials based on the selected inverter */
  compatibleAcMaterials: Maybe<Array<Maybe<PimcoreAcMaterialResult>>>
}

export type PimcoreAnyDocumentTarget =
  | PimcoreDocumentEmail
  | PimcoreDocumentFolder
  | PimcoreDocumentHardlink
  | PimcoreDocumentLink
  | PimcoreDocumentPage
  | PimcoreDocumentSnippet

export type PimcoreAnyTarget =
  | PimcoreObjectBundleProduct
  | PimcoreObjectCategory
  | PimcoreObjectManufacturer
  | PimcoreObjectProduct
  | PimcoreObjectProductGroup
  | PimcoreObjectProductQuantity
  | PimcoreObjectSelectOption
  | PimcoreObjectStorageSystemConfiguration
  | PimcoreObjectStoreView
  | PimcoreObjectWebsite

export interface PimcoreAsset extends PimcoreElement {
  __typename?: 'PimcoreAsset'
  children: Maybe<Array<Maybe<PimcoreAssetTree>>>
  creationDate: Maybe<Scalars['Int']['output']>
  data: Maybe<Scalars['String']['output']>
  dimensions: Maybe<PimcoreDimensions>
  duration: Maybe<Scalars['Float']['output']>
  embeddedMetaInfo: Maybe<Array<Maybe<PimcoreAssetEmbeddedMetaInfoItem>>>
  filename: Maybe<Scalars['String']['output']>
  filesize: Maybe<Scalars['Int']['output']>
  fullpath: Maybe<Scalars['String']['output']>
  id: Maybe<Scalars['ID']['output']>
  metadata: Maybe<Array<Maybe<PimcoreAssetMetadataItem>>>
  mimetype: Maybe<Scalars['String']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  parent: Maybe<PimcoreAssetTree>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  resolutions: Maybe<Array<Maybe<PimcoreResolutions>>>
  siblings: Maybe<Array<Maybe<PimcoreAssetTree>>>
  srcset: Maybe<Array<Maybe<PimcoreSrcset>>>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  type: Maybe<Scalars['String']['output']>
  version: Maybe<Scalars['Int']['output']>
}

export interface PimcoreAssetDataArgs {
  format: InputMaybe<Scalars['String']['input']>
  thumbnail: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreAssetDimensionsArgs {
  format: InputMaybe<Scalars['String']['input']>
  thumbnail: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreAssetFullpathArgs {
  format: InputMaybe<Scalars['String']['input']>
  thumbnail: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreAssetMetadataArgs {
  ignore_language: InputMaybe<Scalars['Boolean']['input']>
  language: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreAssetPropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreAssetResolutionsArgs {
  format: InputMaybe<Scalars['String']['input']>
  thumbnail: Scalars['String']['input']
  types?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
}

export interface PimcoreAssetSrcsetArgs {
  format: InputMaybe<Scalars['String']['input']>
  thumbnail: Scalars['String']['input']
}

export interface PimcoreAssetTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreAssetEmbeddedMetaInfoItem {
  __typename?: 'PimcoreAssetEmbeddedMetaInfoItem'
  name: Maybe<Scalars['String']['output']>
  value: Maybe<Scalars['String']['output']>
}

export interface PimcoreAssetFolder {
  __typename?: 'PimcoreAssetFolder'
  children: Maybe<Array<Maybe<PimcoreAssetTree>>>
  creationDate: Maybe<Scalars['Int']['output']>
  filename: Maybe<Scalars['String']['output']>
  fullpath: Maybe<Scalars['String']['output']>
  id: Maybe<Scalars['ID']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  parent: Maybe<PimcoreAssetFolder>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  siblings: Maybe<Array<Maybe<PimcoreAssetTree>>>
}

export interface PimcoreAssetFolderFullpathArgs {
  thumbnail: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreAssetFolderPropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreAssetMetadataItem {
  __typename?: 'PimcoreAssetMetadataItem'
  data: Maybe<Scalars['String']['output']>
  language: Maybe<Scalars['String']['output']>
  name: Maybe<Scalars['String']['output']>
  type: Maybe<Scalars['String']['output']>
}

export type PimcoreAssetTree = PimcoreAsset | PimcoreAssetFolder

export interface PimcoreCategoryConnection {
  __typename?: 'PimcoreCategoryConnection'
  edges: Maybe<Array<Maybe<PimcoreCategoryEdge>>>
  /** The total count of all queryable objects for this schema listing */
  totalCount: Maybe<Scalars['Int']['output']>
}

export interface PimcoreCategoryEdge {
  __typename?: 'PimcoreCategoryEdge'
  cursor: Maybe<Scalars['String']['output']>
  node: Maybe<PimcoreObjectCategory>
}

export interface PimcoreDimensions {
  __typename?: 'PimcoreDimensions'
  height: Maybe<Scalars['Int']['output']>
  width: Maybe<Scalars['Int']['output']>
}

export type PimcoreDocument =
  | PimcoreDocumentEmail
  | PimcoreDocumentHardlink
  | PimcoreDocumentLink
  | PimcoreDocumentPage
  | PimcoreDocumentSnippet

export interface PimcoreDocumentEditableAreablock {
  __typename?: 'PimcoreDocumentEditableAreablock'
  data: Maybe<Array<Maybe<PimcoreDocumentEditableAreablockData>>>
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentEditableAreablockData {
  __typename?: 'PimcoreDocumentEditableAreablockData'
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  hidden: Maybe<Scalars['Boolean']['output']>
  key: Maybe<Scalars['String']['output']>
  type: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentEditableBlock {
  __typename?: 'PimcoreDocumentEditableBlock'
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  indices: Maybe<Array<Maybe<Scalars['Int']['output']>>>
}

export interface PimcoreDocumentEditableCheckbox {
  __typename?: 'PimcoreDocumentEditableCheckbox'
  checked: Maybe<Scalars['Boolean']['output']>
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentEditableDate {
  __typename?: 'PimcoreDocumentEditableDate'
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  formatted: Maybe<Scalars['String']['output']>
  timestamp: Maybe<Scalars['Int']['output']>
}

export interface PimcoreDocumentEditableDateFormattedArgs {
  format: Scalars['String']['input']
}

export interface PimcoreDocumentEditableEmbed {
  __typename?: 'PimcoreDocumentEditableEmbed'
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  url: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentEditableImage {
  __typename?: 'PimcoreDocumentEditableImage'
  alt: Maybe<Scalars['String']['output']>
  crop: Maybe<PimcoreHotspotCrop>
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  hotspots: Maybe<Array<Maybe<PimcoreHotspotHotspot>>>
  image: Maybe<PimcoreAsset>
  marker: Maybe<Array<Maybe<PimcoreHotspotMarker>>>
}

export interface PimcoreDocumentEditableInput {
  __typename?: 'PimcoreDocumentEditableInput'
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  text: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentEditableLink {
  __typename?: 'PimcoreDocumentEditableLink'
  data: Maybe<PimcoreDocumentEditableLinkData>
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentEditableLinkData {
  __typename?: 'PimcoreDocumentEditableLinkData'
  accesskey: Maybe<Scalars['String']['output']>
  anchor: Maybe<Scalars['String']['output']>
  attributes: Maybe<Scalars['String']['output']>
  class: Maybe<Scalars['String']['output']>
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  internal: Maybe<Scalars['Boolean']['output']>
  internalId: Maybe<Scalars['Int']['output']>
  internalType: Maybe<Scalars['String']['output']>
  parameters: Maybe<Scalars['String']['output']>
  path: Maybe<Scalars['String']['output']>
  relation: Maybe<Scalars['String']['output']>
  tabindex: Maybe<Scalars['String']['output']>
  target: Maybe<PimcoreAnyTarget>
  text: Maybe<Scalars['String']['output']>
  title: Maybe<Scalars['String']['output']>
  windowTarget: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentEditableMultiselect {
  __typename?: 'PimcoreDocumentEditableMultiselect'
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  selections: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export interface PimcoreDocumentEditableNumeric {
  __typename?: 'PimcoreDocumentEditableNumeric'
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  number: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentEditablePdf {
  __typename?: 'PimcoreDocumentEditablePdf'
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  pdf: Maybe<PimcoreAsset>
}

export interface PimcoreDocumentEditableRelation {
  __typename?: 'PimcoreDocumentEditableRelation'
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  id: Maybe<Scalars['Int']['output']>
  relation: Maybe<PimcoreAnyTarget>
  subtype: Maybe<Scalars['String']['output']>
  type: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentEditableRelations {
  __typename?: 'PimcoreDocumentEditableRelations'
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  relations: Maybe<Array<Maybe<PimcoreAnyTarget>>>
}

export interface PimcoreDocumentEditableScheduledblock {
  __typename?: 'PimcoreDocumentEditableScheduledblock'
  data: Maybe<Array<Maybe<PimcoreDocumentEditableScheduledblockData>>>
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentEditableScheduledblockData {
  __typename?: 'PimcoreDocumentEditableScheduledblockData'
  date: Maybe<Scalars['Int']['output']>
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  key: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentEditableSelect {
  __typename?: 'PimcoreDocumentEditableSelect'
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  text: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentEditableTable {
  __typename?: 'PimcoreDocumentEditableTable'
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  text: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentEditableTextarea {
  __typename?: 'PimcoreDocumentEditableTextarea'
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  text: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentEditableVideo {
  __typename?: 'PimcoreDocumentEditableVideo'
  description: Maybe<Scalars['String']['output']>
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  id: Maybe<Scalars['String']['output']>
  posterAsset: Maybe<PimcoreAsset>
  title: Maybe<Scalars['String']['output']>
  type: Maybe<Scalars['String']['output']>
  videoAsset: Maybe<PimcoreAsset>
}

export interface PimcoreDocumentEditableWysiwyg {
  __typename?: 'PimcoreDocumentEditableWysiwyg'
  editableName: Maybe<Scalars['String']['output']>
  editableType: Maybe<Scalars['String']['output']>
  frontend: Maybe<Scalars['String']['output']>
  text: Maybe<Scalars['String']['output']>
}

export type PimcoreDocumentElement =
  | PimcoreDocumentEditableAreablock
  | PimcoreDocumentEditableBlock
  | PimcoreDocumentEditableCheckbox
  | PimcoreDocumentEditableDate
  | PimcoreDocumentEditableEmbed
  | PimcoreDocumentEditableImage
  | PimcoreDocumentEditableInput
  | PimcoreDocumentEditableLink
  | PimcoreDocumentEditableMultiselect
  | PimcoreDocumentEditableNumeric
  | PimcoreDocumentEditablePdf
  | PimcoreDocumentEditableRelation
  | PimcoreDocumentEditableRelations
  | PimcoreDocumentEditableScheduledblock
  | PimcoreDocumentEditableSelect
  | PimcoreDocumentEditableTable
  | PimcoreDocumentEditableTextarea
  | PimcoreDocumentEditableVideo
  | PimcoreDocumentEditableWysiwyg

export interface PimcoreDocumentEmail extends PimcoreElement {
  __typename?: 'PimcoreDocumentEmail'
  action: Maybe<Scalars['String']['output']>
  bcc: Maybe<Scalars['String']['output']>
  cc: Maybe<Scalars['String']['output']>
  children: Maybe<Array<Maybe<PimcoreDocumentTree>>>
  controller: Maybe<Scalars['String']['output']>
  creationDate: Maybe<Scalars['Int']['output']>
  from: Maybe<Scalars['String']['output']>
  fullpath: Maybe<Scalars['String']['output']>
  id: Maybe<Scalars['ID']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  parent: Maybe<PimcoreDocumentTree>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  published: Maybe<Scalars['Boolean']['output']>
  replyTo: Maybe<Scalars['String']['output']>
  siblings: Maybe<Array<Maybe<PimcoreDocumentTree>>>
  subject: Maybe<Scalars['String']['output']>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  template: Maybe<Scalars['String']['output']>
  to: Maybe<Scalars['String']['output']>
  translations: Maybe<Array<Maybe<PimcoreDocumentTranslation>>>
  type: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentEmailPropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreDocumentEmailTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreDocumentEmailTranslationsArgs {
  defaultLanguage: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreDocumentFolder {
  __typename?: 'PimcoreDocumentFolder'
  children: Maybe<Array<Maybe<PimcoreDocumentTree>>>
  creationDate: Maybe<Scalars['Int']['output']>
  filename: Maybe<Scalars['String']['output']>
  fullpath: Maybe<Scalars['String']['output']>
  id: Maybe<Scalars['ID']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  parent: Maybe<PimcoreDocumentTree>
  siblings: Maybe<Array<Maybe<PimcoreDocumentTree>>>
  translations: Maybe<Array<Maybe<PimcoreDocumentTranslation>>>
}

export interface PimcoreDocumentFolderTranslationsArgs {
  defaultLanguage: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreDocumentHardlink extends PimcoreElement {
  __typename?: 'PimcoreDocumentHardlink'
  action: Maybe<Scalars['String']['output']>
  children: Maybe<Array<Maybe<PimcoreDocumentTree>>>
  childrenFromSource: Maybe<Scalars['Boolean']['output']>
  controller: Maybe<Scalars['String']['output']>
  creationDate: Maybe<Scalars['Int']['output']>
  fullpath: Maybe<Scalars['String']['output']>
  id: Maybe<Scalars['ID']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  parent: Maybe<PimcoreDocumentTree>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  propertiesFromSource: Maybe<Scalars['Boolean']['output']>
  published: Maybe<Scalars['Boolean']['output']>
  siblings: Maybe<Array<Maybe<PimcoreDocumentTree>>>
  sourceId: Maybe<Scalars['Int']['output']>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  target: Maybe<PimcoreAnyDocumentTarget>
  template: Maybe<Scalars['String']['output']>
  translations: Maybe<Array<Maybe<PimcoreDocumentTranslation>>>
  type: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentHardlinkPropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreDocumentHardlinkTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreDocumentHardlinkTranslationsArgs {
  defaultLanguage: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreDocumentLink extends PimcoreElement {
  __typename?: 'PimcoreDocumentLink'
  action: Maybe<Scalars['String']['output']>
  children: Maybe<Array<Maybe<PimcoreDocumentTree>>>
  controller: Maybe<Scalars['String']['output']>
  creationDate: Maybe<Scalars['Int']['output']>
  direct: Maybe<Scalars['String']['output']>
  fullpath: Maybe<Scalars['String']['output']>
  href: Maybe<Scalars['String']['output']>
  id: Maybe<Scalars['ID']['output']>
  internal: Maybe<Scalars['Int']['output']>
  internalType: Maybe<Scalars['String']['output']>
  linktype: Maybe<Scalars['String']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  object: Maybe<PimcoreAnyTarget>
  parent: Maybe<PimcoreDocumentTree>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  published: Maybe<Scalars['Boolean']['output']>
  siblings: Maybe<Array<Maybe<PimcoreDocumentTree>>>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  template: Maybe<Scalars['String']['output']>
  translations: Maybe<Array<Maybe<PimcoreDocumentTranslation>>>
  type: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentLinkPropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreDocumentLinkTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreDocumentLinkTranslationsArgs {
  defaultLanguage: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreDocumentPage extends PimcoreElement {
  __typename?: 'PimcoreDocumentPage'
  action: Maybe<Scalars['String']['output']>
  children: Maybe<Array<Maybe<PimcoreDocumentTree>>>
  controller: Maybe<Scalars['String']['output']>
  creationDate: Maybe<Scalars['Int']['output']>
  description: Maybe<Scalars['String']['output']>
  editables: Maybe<Array<Maybe<PimcoreDocumentElement>>>
  elements: Maybe<Array<Maybe<PimcoreDocumentElement>>>
  fullpath: Maybe<Scalars['String']['output']>
  id: Maybe<Scalars['ID']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  parent: Maybe<PimcoreDocumentTree>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  published: Maybe<Scalars['Boolean']['output']>
  rendered: Maybe<Scalars['String']['output']>
  siblings: Maybe<Array<Maybe<PimcoreDocumentTree>>>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  template: Maybe<Scalars['String']['output']>
  title: Maybe<Scalars['String']['output']>
  translations: Maybe<Array<Maybe<PimcoreDocumentTranslation>>>
  type: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentPageEditablesArgs {
  getInheritedValues?: InputMaybe<Scalars['Boolean']['input']>
}

export interface PimcoreDocumentPagePropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreDocumentPageRenderedArgs {
  attributes?: InputMaybe<Array<InputMaybe<PimcoreKeyValue>>>
  options?: InputMaybe<Array<InputMaybe<PimcoreKeyValue>>>
  query?: InputMaybe<Array<InputMaybe<PimcoreKeyValue>>>
  use_layout: InputMaybe<Scalars['Boolean']['input']>
}

export interface PimcoreDocumentPageTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreDocumentPageTranslationsArgs {
  defaultLanguage: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreDocumentSnippet extends PimcoreElement {
  __typename?: 'PimcoreDocumentSnippet'
  action: Maybe<Scalars['String']['output']>
  children: Maybe<Array<Maybe<PimcoreDocumentTree>>>
  controller: Maybe<Scalars['String']['output']>
  creationDate: Maybe<Scalars['Int']['output']>
  description: Maybe<Scalars['String']['output']>
  editables: Maybe<Array<Maybe<PimcoreDocumentElement>>>
  elements: Maybe<Array<Maybe<PimcoreDocumentElement>>>
  fullpath: Maybe<Scalars['String']['output']>
  id: Maybe<Scalars['ID']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  parent: Maybe<PimcoreDocumentTree>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  published: Maybe<Scalars['Boolean']['output']>
  rendered: Maybe<Scalars['String']['output']>
  siblings: Maybe<Array<Maybe<PimcoreDocumentTree>>>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  template: Maybe<Scalars['String']['output']>
  title: Maybe<Scalars['String']['output']>
  translations: Maybe<Array<Maybe<PimcoreDocumentTranslation>>>
  type: Maybe<Scalars['String']['output']>
}

export interface PimcoreDocumentSnippetEditablesArgs {
  getInheritedValues?: InputMaybe<Scalars['Boolean']['input']>
}

export interface PimcoreDocumentSnippetPropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreDocumentSnippetRenderedArgs {
  attributes?: InputMaybe<Array<InputMaybe<PimcoreKeyValue>>>
  options?: InputMaybe<Array<InputMaybe<PimcoreKeyValue>>>
  query?: InputMaybe<Array<InputMaybe<PimcoreKeyValue>>>
  use_layout: InputMaybe<Scalars['Boolean']['input']>
}

export interface PimcoreDocumentSnippetTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreDocumentSnippetTranslationsArgs {
  defaultLanguage: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreDocumentTranslation {
  __typename?: 'PimcoreDocumentTranslation'
  id: Maybe<Scalars['Int']['output']>
  language: Maybe<Scalars['String']['output']>
  target: Maybe<PimcoreDocumentTree>
}

export type PimcoreDocumentTree =
  | PimcoreDocumentEmail
  | PimcoreDocumentFolder
  | PimcoreDocumentHardlink
  | PimcoreDocumentLink
  | PimcoreDocumentPage
  | PimcoreDocumentSnippet

export interface PimcoreElement {
  id: Maybe<Scalars['ID']['output']>
}

export type PimcoreElementProperty =
  | PimcorePropertyCheckbox
  | PimcorePropertyObject
  | PimcorePropertySelect
  | PimcorePropertyText

export interface PimcoreElementTag {
  __typename?: 'PimcoreElementTag'
  id: Maybe<Scalars['ID']['output']>
  name: Maybe<Scalars['String']['output']>
  path: Maybe<Scalars['String']['output']>
}

export type PimcoreFieldType =
  | PimcoreSpecificationsObjectBrickAttributeFloat
  | PimcoreSpecificationsObjectBrickAttributeString
  | PimcoreSpecificationsObjectBrickAttributeStrings

export interface PimcoreHotspotCrop {
  __typename?: 'PimcoreHotspotCrop'
  cropHeight: Maybe<Scalars['Float']['output']>
  cropLeft: Maybe<Scalars['Float']['output']>
  cropPercent: Maybe<Scalars['Boolean']['output']>
  cropTop: Maybe<Scalars['Float']['output']>
  cropWidth: Maybe<Scalars['Float']['output']>
}

export interface PimcoreHotspotHotspot {
  __typename?: 'PimcoreHotspotHotspot'
  data: Maybe<Array<Maybe<PimcoreHotspotMetadata>>>
  height: Maybe<Scalars['Float']['output']>
  left: Maybe<Scalars['Float']['output']>
  name: Maybe<Scalars['String']['output']>
  top: Maybe<Scalars['Float']['output']>
  width: Maybe<Scalars['Float']['output']>
}

export interface PimcoreHotspotHotspotDataArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreHotspotMarker {
  __typename?: 'PimcoreHotspotMarker'
  data: Maybe<Array<Maybe<PimcoreHotspotMetadata>>>
  left: Maybe<Scalars['Float']['output']>
  name: Maybe<Scalars['String']['output']>
  top: Maybe<Scalars['Float']['output']>
}

export interface PimcoreHotspotMarkerDataArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type PimcoreHotspotMetadata =
  | PimcorePropertyAsset
  | PimcorePropertyCheckbox
  | PimcorePropertyDocument
  | PimcorePropertyObject
  | PimcorePropertyText
  | PimcorePropertyTextarea

export type PimcoreHotspotMetadataObject =
  | PimcoreObjectCategory
  | PimcoreObjectManufacturer
  | PimcoreObjectProduct
  | PimcoreObjectProductGroup

export interface PimcoreHotspotimage {
  __typename?: 'PimcoreHotspotimage'
  crop: Maybe<PimcoreHotspotCrop>
  hotspots: Maybe<Array<Maybe<PimcoreHotspotHotspot>>>
  image: Maybe<PimcoreAsset>
  marker: Maybe<Array<Maybe<PimcoreHotspotMarker>>>
}

export interface PimcoreKeyValue {
  key?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreManufacturerConnection {
  __typename?: 'PimcoreManufacturerConnection'
  edges: Maybe<Array<Maybe<PimcoreManufacturerEdge>>>
  /** The total count of all queryable objects for this schema listing */
  totalCount: Maybe<Scalars['Int']['output']>
}

export interface PimcoreManufacturerEdge {
  __typename?: 'PimcoreManufacturerEdge'
  cursor: Maybe<Scalars['String']['output']>
  node: Maybe<PimcoreObjectManufacturer>
}

export interface PimcoreObjectBundleProduct extends PimcoreElement {
  __typename?: 'PimcoreObjectBundleProduct'
  children: Maybe<Array<Maybe<PimcoreObjectTree>>>
  childrenSortBy: Maybe<Scalars['String']['output']>
  classname: Maybe<Scalars['String']['output']>
  creationDate: Maybe<Scalars['Int']['output']>
  id: Maybe<Scalars['ID']['output']>
  index: Maybe<Scalars['Int']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  objectType: Maybe<Scalars['String']['output']>
  parent: Maybe<PimcoreObjectTree>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  siblings: Maybe<Array<Maybe<PimcoreObjectTree>>>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  version: Maybe<Scalars['Int']['output']>
}

export interface PimcoreObjectBundleProductChildrenArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectBundleProductPropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectBundleProductSiblingsArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectBundleProductTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreObjectCategory extends PimcoreElement {
  __typename?: 'PimcoreObjectCategory'
  children: Maybe<Array<Maybe<PimcoreObjectTree>>>
  childrenSortBy: Maybe<Scalars['String']['output']>
  classname: Maybe<Scalars['String']['output']>
  creationDate: Maybe<Scalars['Int']['output']>
  id: Maybe<Scalars['ID']['output']>
  index: Maybe<Scalars['Int']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  name: Maybe<Scalars['String']['output']>
  objectType: Maybe<Scalars['String']['output']>
  parent: Maybe<PimcoreObjectTree>
  products: Maybe<Array<Maybe<PimcoreObjectProduct>>>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  siblings: Maybe<Array<Maybe<PimcoreObjectTree>>>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  version: Maybe<Scalars['Int']['output']>
}

export interface PimcoreObjectCategoryChildrenArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectCategoryNameArgs {
  language: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreObjectCategoryPropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectCategorySiblingsArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectCategoryTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreObjectManufacturer extends PimcoreElement {
  __typename?: 'PimcoreObjectManufacturer'
  children: Maybe<Array<Maybe<PimcoreObjectTree>>>
  childrenSortBy: Maybe<Scalars['String']['output']>
  classname: Maybe<Scalars['String']['output']>
  creationDate: Maybe<Scalars['Int']['output']>
  id: Maybe<Scalars['ID']['output']>
  index: Maybe<Scalars['Int']['output']>
  logo: Maybe<PimcoreAsset>
  modificationDate: Maybe<Scalars['Int']['output']>
  name: Maybe<Scalars['String']['output']>
  objectType: Maybe<Scalars['String']['output']>
  parent: Maybe<PimcoreObjectTree>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  siblings: Maybe<Array<Maybe<PimcoreObjectTree>>>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  version: Maybe<Scalars['Int']['output']>
}

export interface PimcoreObjectManufacturerChildrenArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectManufacturerNameArgs {
  language: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreObjectManufacturerPropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectManufacturerSiblingsArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectManufacturerTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreObjectProduct extends PimcoreElement {
  __typename?: 'PimcoreObjectProduct'
  articleNumber: Maybe<Scalars['String']['output']>
  bestChoice: Maybe<Scalars['Boolean']['output']>
  categories: Maybe<Array<Maybe<PimcoreObjectProductCategories>>>
  children: Maybe<Array<Maybe<PimcoreObjectTree>>>
  childrenSortBy: Maybe<Scalars['String']['output']>
  classname: Maybe<Scalars['String']['output']>
  creationDate: Maybe<Scalars['Int']['output']>
  description: Maybe<Scalars['String']['output']>
  ean: Maybe<Scalars['String']['output']>
  id: Maybe<Scalars['ID']['output']>
  imageGallery: Maybe<Array<Maybe<PimcoreHotspotimage>>>
  index: Maybe<Scalars['Int']['output']>
  manufacturer: Maybe<PimcoreObjectProductManufacturer>
  modificationDate: Maybe<Scalars['Int']['output']>
  name: Maybe<Scalars['String']['output']>
  objectType: Maybe<Scalars['String']['output']>
  parent: Maybe<PimcoreObjectTree>
  productGroup: Maybe<PimcoreObjectProductProductGroup>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  published: Maybe<Scalars['Boolean']['output']>
  showInConfigurator: Maybe<Scalars['String']['output']>
  siblings: Maybe<Array<Maybe<PimcoreObjectTree>>>
  sku: Maybe<Scalars['String']['output']>
  specifications: Maybe<Array<Maybe<PimcoreSpecificationsObjectBrickAttribute>>>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  usp: Maybe<Scalars['String']['output']>
  version: Maybe<Scalars['Int']['output']>
}

export interface PimcoreObjectProductChildrenArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectProductDescriptionArgs {
  language: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreObjectProductNameArgs {
  language: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreObjectProductPropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectProductSiblingsArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectProductSpecificationsArgs {
  excludeNullValues?: InputMaybe<Scalars['Boolean']['input']>
}

export interface PimcoreObjectProductTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreObjectProductUspArgs {
  language: InputMaybe<Scalars['String']['input']>
}

export type PimcoreObjectProductCategories = PimcoreObjectCategory

export interface PimcoreObjectProductGroup extends PimcoreElement {
  __typename?: 'PimcoreObjectProductGroup'
  children: Maybe<Array<Maybe<PimcoreObjectTree>>>
  childrenSortBy: Maybe<Scalars['String']['output']>
  classname: Maybe<Scalars['String']['output']>
  creationDate: Maybe<Scalars['Int']['output']>
  id: Maybe<Scalars['ID']['output']>
  index: Maybe<Scalars['Int']['output']>
  key: Maybe<Scalars['String']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  name: Maybe<Scalars['String']['output']>
  objectType: Maybe<Scalars['String']['output']>
  parent: Maybe<PimcoreObjectTree>
  products: Maybe<Array<Maybe<PimcoreObjectProduct>>>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  siblings: Maybe<Array<Maybe<PimcoreObjectTree>>>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  version: Maybe<Scalars['Int']['output']>
}

export interface PimcoreObjectProductGroupChildrenArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectProductGroupPropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectProductGroupSiblingsArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectProductGroupTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

/** pseudo class for field manufacturer */
export type PimcoreObjectProductManufacturer = PimcoreObjectManufacturer

/** pseudo class for field productGroup */
export type PimcoreObjectProductProductGroup = PimcoreObjectProductGroup

export interface PimcoreObjectProductQuantity extends PimcoreElement {
  __typename?: 'PimcoreObjectProductQuantity'
  children: Maybe<Array<Maybe<PimcoreObjectTree>>>
  childrenSortBy: Maybe<Scalars['String']['output']>
  classname: Maybe<Scalars['String']['output']>
  creationDate: Maybe<Scalars['Int']['output']>
  id: Maybe<Scalars['ID']['output']>
  index: Maybe<Scalars['Int']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  objectType: Maybe<Scalars['String']['output']>
  parent: Maybe<PimcoreObjectTree>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  siblings: Maybe<Array<Maybe<PimcoreObjectTree>>>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  version: Maybe<Scalars['Int']['output']>
}

export interface PimcoreObjectProductQuantityChildrenArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectProductQuantityPropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectProductQuantitySiblingsArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectProductQuantityTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreObjectSelectOption extends PimcoreElement {
  __typename?: 'PimcoreObjectSelectOption'
  children: Maybe<Array<Maybe<PimcoreObjectTree>>>
  childrenSortBy: Maybe<Scalars['String']['output']>
  classname: Maybe<Scalars['String']['output']>
  creationDate: Maybe<Scalars['Int']['output']>
  id: Maybe<Scalars['ID']['output']>
  index: Maybe<Scalars['Int']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  objectType: Maybe<Scalars['String']['output']>
  parent: Maybe<PimcoreObjectTree>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  siblings: Maybe<Array<Maybe<PimcoreObjectTree>>>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  version: Maybe<Scalars['Int']['output']>
}

export interface PimcoreObjectSelectOptionChildrenArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectSelectOptionPropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectSelectOptionSiblingsArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectSelectOptionTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreObjectStorageSystemConfiguration
  extends PimcoreElement {
  __typename?: 'PimcoreObjectStorageSystemConfiguration'
  children: Maybe<Array<Maybe<PimcoreObjectTree>>>
  childrenSortBy: Maybe<Scalars['String']['output']>
  classname: Maybe<Scalars['String']['output']>
  creationDate: Maybe<Scalars['Int']['output']>
  id: Maybe<Scalars['ID']['output']>
  index: Maybe<Scalars['Int']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  objectType: Maybe<Scalars['String']['output']>
  parent: Maybe<PimcoreObjectTree>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  siblings: Maybe<Array<Maybe<PimcoreObjectTree>>>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  version: Maybe<Scalars['Int']['output']>
}

export interface PimcoreObjectStorageSystemConfigurationChildrenArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectStorageSystemConfigurationPropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectStorageSystemConfigurationSiblingsArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectStorageSystemConfigurationTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreObjectStoreView extends PimcoreElement {
  __typename?: 'PimcoreObjectStoreView'
  children: Maybe<Array<Maybe<PimcoreObjectTree>>>
  childrenSortBy: Maybe<Scalars['String']['output']>
  classname: Maybe<Scalars['String']['output']>
  creationDate: Maybe<Scalars['Int']['output']>
  id: Maybe<Scalars['ID']['output']>
  index: Maybe<Scalars['Int']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  objectType: Maybe<Scalars['String']['output']>
  parent: Maybe<PimcoreObjectTree>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  siblings: Maybe<Array<Maybe<PimcoreObjectTree>>>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  version: Maybe<Scalars['Int']['output']>
}

export interface PimcoreObjectStoreViewChildrenArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectStoreViewPropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectStoreViewSiblingsArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectStoreViewTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

export type PimcoreObjectTree =
  | PimcoreObjectCategory
  | PimcoreObjectManufacturer
  | PimcoreObjectProduct
  | PimcoreObjectProductGroup

export interface PimcoreObjectWebsite extends PimcoreElement {
  __typename?: 'PimcoreObjectWebsite'
  children: Maybe<Array<Maybe<PimcoreObjectTree>>>
  childrenSortBy: Maybe<Scalars['String']['output']>
  classname: Maybe<Scalars['String']['output']>
  creationDate: Maybe<Scalars['Int']['output']>
  id: Maybe<Scalars['ID']['output']>
  index: Maybe<Scalars['Int']['output']>
  modificationDate: Maybe<Scalars['Int']['output']>
  objectType: Maybe<Scalars['String']['output']>
  parent: Maybe<PimcoreObjectTree>
  properties: Maybe<Array<Maybe<PimcoreElementProperty>>>
  siblings: Maybe<Array<Maybe<PimcoreObjectTree>>>
  tags: Maybe<Array<Maybe<PimcoreElementTag>>>
  version: Maybe<Scalars['Int']['output']>
}

export interface PimcoreObjectWebsiteChildrenArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectWebsitePropertiesArgs {
  keys: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectWebsiteSiblingsArgs {
  objectTypes: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface PimcoreObjectWebsiteTagsArgs {
  name: InputMaybe<Scalars['String']['input']>
}

export interface PimcoreProductConnection {
  __typename?: 'PimcoreProductConnection'
  edges: Maybe<Array<Maybe<PimcoreProductEdge>>>
  /** The total count of all queryable objects for this schema listing */
  totalCount: Maybe<Scalars['Int']['output']>
}

export interface PimcoreProductEdge {
  __typename?: 'PimcoreProductEdge'
  cursor: Maybe<Scalars['String']['output']>
  node: Maybe<PimcoreObjectProduct>
}

export interface PimcoreProductGroupConnection {
  __typename?: 'PimcoreProductGroupConnection'
  edges: Maybe<Array<Maybe<PimcoreProductGroupEdge>>>
  /** The total count of all queryable objects for this schema listing */
  totalCount: Maybe<Scalars['Int']['output']>
}

export interface PimcoreProductGroupEdge {
  __typename?: 'PimcoreProductGroupEdge'
  cursor: Maybe<Scalars['String']['output']>
  node: Maybe<PimcoreObjectProductGroup>
}

export interface PimcoreProducts {
  __typename?: 'PimcoreProducts'
  /** List of inverters compatible based on the solar panel's power and given configurations */
  compatibleInverters: Maybe<Array<Maybe<PimcoreObjectProduct>>>
  /** Information about the requested solar panel */
  solarPanel: Maybe<PimcoreObjectProduct>
  /** The total count of compatible inverters */
  totalCount: Maybe<Scalars['Int']['output']>
}

export interface PimcorePropertyAsset {
  __typename?: 'PimcorePropertyAsset'
  asset: Maybe<PimcoreAsset>
  name: Maybe<Scalars['String']['output']>
  type: Maybe<Scalars['String']['output']>
}

export interface PimcorePropertyCheckbox {
  __typename?: 'PimcorePropertyCheckbox'
  checked: Maybe<Scalars['Boolean']['output']>
  name: Maybe<Scalars['String']['output']>
  type: Maybe<Scalars['String']['output']>
}

export interface PimcorePropertyDocument {
  __typename?: 'PimcorePropertyDocument'
  document: Maybe<PimcoreDocument>
  name: Maybe<Scalars['String']['output']>
  type: Maybe<Scalars['String']['output']>
}

export interface PimcorePropertyObject {
  __typename?: 'PimcorePropertyObject'
  name: Maybe<Scalars['String']['output']>
  object: Maybe<PimcoreHotspotMetadataObject>
  type: Maybe<Scalars['String']['output']>
}

export interface PimcorePropertySelect {
  __typename?: 'PimcorePropertySelect'
  name: Maybe<Scalars['String']['output']>
  text: Maybe<Scalars['String']['output']>
  type: Maybe<Scalars['String']['output']>
}

export interface PimcorePropertyText {
  __typename?: 'PimcorePropertyText'
  name: Maybe<Scalars['String']['output']>
  text: Maybe<Scalars['String']['output']>
  type: Maybe<Scalars['String']['output']>
}

export interface PimcorePropertyTextarea {
  __typename?: 'PimcorePropertyTextarea'
  name: Maybe<Scalars['String']['output']>
  text: Maybe<Scalars['String']['output']>
  type: Maybe<Scalars['String']['output']>
}

export interface PimcoreResolutions {
  __typename?: 'PimcoreResolutions'
  resolution: Maybe<Scalars['Float']['output']>
  url: Maybe<Scalars['String']['output']>
}

export interface PimcoreSpecificationsObjectBrickAttribute {
  __typename?: 'PimcoreSpecificationsObjectBrickAttribute'
  field: Maybe<Scalars['String']['output']>
  fieldTitle: Maybe<Scalars['String']['output']>
  fieldTooltip: Maybe<Scalars['String']['output']>
  fieldType: Scalars['String']['output']
  objectBrick: Maybe<Scalars['String']['output']>
  sectionName: Maybe<Scalars['String']['output']>
  sectionTitle: Maybe<Scalars['String']['output']>
  value: Maybe<PimcoreFieldType>
}

export interface PimcoreSpecificationsObjectBrickAttributeFloat {
  __typename?: 'PimcoreSpecificationsObjectBrickAttributeFloat'
  displayValue: Maybe<Scalars['String']['output']>
  value: Maybe<Scalars['Float']['output']>
}

export interface PimcoreSpecificationsObjectBrickAttributeString {
  __typename?: 'PimcoreSpecificationsObjectBrickAttributeString'
  displayValue: Maybe<Scalars['String']['output']>
  value: Maybe<Scalars['String']['output']>
}

export interface PimcoreSpecificationsObjectBrickAttributeStrings {
  __typename?: 'PimcoreSpecificationsObjectBrickAttributeStrings'
  displayValue: Maybe<Scalars['String']['output']>
  displayValues: Maybe<Array<Maybe<Scalars['String']['output']>>>
  value: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export interface PimcoreSrcset {
  __typename?: 'PimcoreSrcset'
  descriptor: Maybe<Scalars['String']['output']>
  resolutions: Maybe<Array<Maybe<PimcoreResolutions>>>
  url: Maybe<Scalars['String']['output']>
}

export interface PimcoreSrcsetResolutionsArgs {
  types?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
}

export interface PimcoreStorageSystemProduct {
  __typename?: 'PimcoreStorageSystemProduct'
  /** Storage system product */
  product: Maybe<PimcoreObjectProduct>
}

export interface PimcoreStorageSystemResult {
  __typename?: 'PimcoreStorageSystemResult'
  /** List of storage systems products compatible based on the selected inverter */
  compatibleProducts: Maybe<Array<Maybe<PimcoreStorageSystemProduct>>>
  /** The total count of products */
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Specifies the negotiable quote to convert to an order. */
export interface PlaceNegotiableQuoteOrderInput {
  /** The unique ID of a `NegotiableQuote` object. */
  quote_uid: Scalars['ID']['input']
}

/** An output object that returns the generated order. */
export interface PlaceNegotiableQuoteOrderOutput {
  __typename?: 'PlaceNegotiableQuoteOrderOutput'
  /** Contains the generated order number. */
  order: Order
}

export interface PlaceOrderAndGetPaymentInformationInput {
  cart_id: Scalars['String']['input']
}

export interface PlaceOrderAndGetPaymentInformationOutput {
  __typename?: 'PlaceOrderAndGetPaymentInformationOutput'
  order: Order
  paymentInformation: PaymentInformation
}

/** An error encountered while placing an order. */
export interface PlaceOrderError {
  __typename?: 'PlaceOrderError'
  /** An error code that is specific to place order. */
  code: PlaceOrderErrorCodes
  /** A localized error message. */
  message: Scalars['String']['output']
}

export enum PlaceOrderErrorCodes {
  CART_NOT_ACTIVE = 'CART_NOT_ACTIVE',
  CART_NOT_FOUND = 'CART_NOT_FOUND',
  GUEST_EMAIL_MISSING = 'GUEST_EMAIL_MISSING',
  UNABLE_TO_PLACE_ORDER = 'UNABLE_TO_PLACE_ORDER',
  UNDEFINED = 'UNDEFINED',
}

/** Specifies the purchase order to convert to an order. */
export interface PlaceOrderForPurchaseOrderInput {
  /** The unique ID of a purchase order. */
  purchase_order_uid: Scalars['ID']['input']
}

/** Contains the results of the request to place an order. */
export interface PlaceOrderForPurchaseOrderOutput {
  __typename?: 'PlaceOrderForPurchaseOrderOutput'
  /** Placed order. */
  order: CustomerOrder
}

/** Specifies the quote to be converted to an order. */
export interface PlaceOrderInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** Optionally send a URL where the visitor is returned after completing the Mollie order */
  mollie_return_url?: InputMaybe<Scalars['String']['input']>
}

/** Contains the results of the request to place an order. */
export interface PlaceOrderOutput {
  __typename?: 'PlaceOrderOutput'
  /** An array of place order errors. */
  errors: Array<Maybe<PlaceOrderError>>
  /**
   * The ID of the order.
   * @deprecated Use `orderV2` instead.
   */
  order: Maybe<Order>
  /** Full order information. */
  orderV2: Maybe<CustomerOrder>
}

/** Specifies the quote to be converted to a purchase order. */
export interface PlacePurchaseOrderInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
}

/** Contains the results of the request to place a purchase order. */
export interface PlacePurchaseOrderOutput {
  __typename?: 'PlacePurchaseOrderOutput'
  /** Placed purchase order. */
  purchaseOrder: PurchaseOrder
}

/** Reponse for postcode lookup */
export interface PostcodeNlAddress {
  __typename?: 'PostcodeNLAddress'
  /** Type of this address. */
  addressType: Scalars['String']['output']
  /** Dutch term used in BAG: "adresseerbaar object id". Unique identification for objects which have 'building', 'house boat site', or 'mobile home site' as addressType. */
  bagAddressableObjectId: Maybe<Scalars['String']['output']>
  /** Dutch term used in BAG: "nummeraanduiding id". */
  bagNumberDesignationId: Maybe<Scalars['String']['output']>
  /**
   * Official city name in accordance with "BAG (Basisregistraties adressen en
   * gebouwen)". In capital and lowercase letters, including punctuation marks
   * and accents. This field is at most 80 characters in length.
   */
  city: Scalars['String']['output']
  /**
   * City name, shortened to fit a lower maximum length. In capital and lowercase
   * letters, including punctuation marks and accents. This field is at most 24
   * characters in length.
   */
  cityShort: Scalars['String']['output']
  /**
   * House number of a perceel. In case of a Postbus match the house number will
   * always be 0. Range: 0-99999
   */
  houseNumber: Scalars['Int']['output']
  /**
   * Addition of the house number to uniquely define a location. These additions
   * are officially recognized by the municipality. This field is at most
   * 6 characters in length and null if addition not found (see
   * houseNumberAdditions result field).
   */
  houseNumberAddition: Maybe<Scalars['String']['output']>
  /**
   * List of all house number additions having the postcode and houseNumber
   * which was input.
   */
  houseNumberAdditions: Array<Maybe<Scalars['String']['output']>>
  /** Latitude of address. Null for PO Boxes. */
  latitude: Maybe<Scalars['Float']['output']>
  /** Longitude of address. Null for PO Boxes. */
  longitude: Maybe<Scalars['Float']['output']>
  /**
   * Municipality name in accordance with "BAG (Basisregistraties adressen en
   * gebouwen)". In capital and lowercase letters, including punctuation marks
   * and accents. This field is at most 80 characters in length. Examples:
   * "Baarle-Nassau", "'s-Gravenhage", "Haarlemmerliede en Spaarnwoude".
   */
  municipality: Scalars['String']['output']
  /**
   * Municipality name, shortened to fit a lower maximum length. In capital and
   * lowercase letters, including punctuation marks and accents. This field is at
   * most 24 characters in length. Examples: "Baarle-Nassau", "'s-Gravenhage",
   * "Haarlemmerliede c.a.".
   */
  municipalityShort: Scalars['String']['output']
  /**
   * Four digit neighborhood code (first part of a postcode). Range: 1000-9999
   * plus two character letter combination (second part of a postcode).
   * Range: "AA"-"ZZ"
   */
  postcode: Scalars['String']['output']
  /** Official name of the province, correctly cased and with dashes where applicable. */
  province: Scalars['String']['output']
  /**
   * List of all purposes (Dutch: "gebruiksdoelen"). Null or an array of
   * text values.
   */
  purposes: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /**
   * X coordinate according to Dutch Rijksdriehoeksmeting "(EPSG)
   * 28992 Amersfoort / RD New". Values range from 0 to 300000 meters.
   * Null for PO Boxes.
   */
  rdX: Maybe<Scalars['Int']['output']>
  /**
   * Y coordinate according to Dutch Rijksdriehoeksmeting "(EPSG)
   * 28992 Amersfoort / RD New". Values range from 300000 to 620000 meters.
   * Null for PO Boxes.
   */
  rdY: Maybe<Scalars['Int']['output']>
  /**
   * Street name in accordance with "BAG (Basisregistraties adressen en
   * gebouwen)". In capital and lowercase letters, including punctuation marks
   * and accents. This field is at most 80 characters in length. Filled with
   * "Postbus" in case it is a range of PO boxes.
   */
  street: Scalars['String']['output']
  /**
   * Street name in NEN-5825 notation, which has a lower maximum length. In
   * capital and lowercase letters, including punctuation marks and accents.
   * This field is at most 24 characters in length. Filled with "Postbus" in case
   * it is a range of PO boxes.
   */
  streetNen: Scalars['String']['output']
  /** Surface in square meters. Null for PO Boxes. */
  surfaceArea: Maybe<Scalars['Float']['output']>
}

/** Deprecated. Use `ProductPrice` instead. Defines the price of a product as well as any tax-related adjustments. */
export interface Price {
  __typename?: 'Price'
  /**
   * An array that provides information about tax, weee, or weee_tax adjustments.
   * @deprecated Use `ProductPrice` instead.
   */
  adjustments: Maybe<Array<Maybe<PriceAdjustment>>>
  /**
   * The price of a product plus a three-letter currency code.
   * @deprecated Use `ProductPrice` instead.
   */
  amount: Maybe<Money>
}

/** Deprecated. Taxes will be included or excluded in the price. Defines the amount of money to apply as an adjustment, the type of adjustment to apply, and whether the item is included or excluded from the adjustment. */
export interface PriceAdjustment {
  __typename?: 'PriceAdjustment'
  /** The amount of the price adjustment and its currency code. */
  amount: Maybe<Money>
  /**
   * Indicates whether the adjustment involves tax, weee, or weee_tax.
   * @deprecated `PriceAdjustment` is deprecated.
   */
  code: Maybe<PriceAdjustmentCodesEnum>
  /**
   * Indicates whether the entity described by the code attribute is included or excluded from the adjustment.
   * @deprecated `PriceAdjustment` is deprecated.
   */
  description: Maybe<PriceAdjustmentDescriptionEnum>
}

/** `PriceAdjustment.code` is deprecated. */
export enum PriceAdjustmentCodesEnum {
  /** @deprecated `PriceAdjustmentCodesEnum` is deprecated. Tax is included or excluded in the price. Tax is not shown separately in Catalog. */
  TAX = 'TAX',
  /** @deprecated WEEE code is deprecated. Use `fixed_product_taxes.label` instead. */
  WEEE = 'WEEE',
  /** @deprecated Use `fixed_product_taxes` instead.  Tax is included or excluded in price. The tax is not shown separtely in Catalog. */
  WEEE_TAX = 'WEEE_TAX',
}

/** `PriceAdjustmentDescriptionEnum` is deprecated. States whether a price adjustment is included or excluded. */
export enum PriceAdjustmentDescriptionEnum {
  EXCLUDED = 'EXCLUDED',
  INCLUDED = 'INCLUDED',
}

/** Can be used to retrieve the main price details in case of bundle product */
export interface PriceDetails {
  __typename?: 'PriceDetails'
  /** The percentage of discount applied to the main product price */
  discountPercentage: Maybe<Scalars['Float']['output']>
  /** The final price after applying the discount to the main product */
  mainFinalPrice: Maybe<Scalars['Float']['output']>
  /** The regular price of the main product */
  mainPrice: Maybe<Scalars['Float']['output']>
}

/** Contains the price range for a product. If the product has a single price, the minimum and maximum price will be the same. */
export interface PriceRange {
  __typename?: 'PriceRange'
  /** The highest possible price for the product. */
  maximumPrice: Maybe<ProductPrice>
  /** The lowest possible price for the product. */
  minimumPrice: ProductPrice
}

/** Defines the price type. */
export enum PriceTypeEnum {
  DYNAMIC = 'DYNAMIC',
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
}

/** Defines whether a bundle product's price is displayed as the lowest possible value or as a range. */
export enum PriceViewEnum {
  AS_LOW_AS = 'AS_LOW_AS',
  PRICE_RANGE = 'PRICE_RANGE',
}

/** Contains a product attribute code and value. */
export interface ProductAttribute {
  __typename?: 'ProductAttribute'
  /** The unique identifier for a product attribute code. */
  code: Scalars['String']['output']
  /** The display value of the attribute. */
  value: Scalars['String']['output']
}

/** Defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export interface ProductAttributeFilterInput {
  /** Deprecated: use `category_uid` to filter product by category ID. */
  category_id?: InputMaybe<FilterEqualTypeInput>
  /** Filter product by the unique ID for a `CategoryInterface` object. */
  category_uid?: InputMaybe<FilterEqualTypeInput>
  /** Filter product by category URL path. */
  category_url_path?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Description */
  description?: InputMaybe<FilterMatchTypeInput>
  /** Attribute label: Product Name */
  name?: InputMaybe<FilterMatchTypeInput>
  /** Attribute label: Price */
  price?: InputMaybe<FilterRangeTypeInput>
  /** Attribute label: Short Description */
  short_description?: InputMaybe<FilterMatchTypeInput>
  /** Attribute label: SKU */
  sku?: InputMaybe<FilterEqualTypeInput>
  /** The part of the URL that identifies the product */
  url_key?: InputMaybe<FilterEqualTypeInput>
}

/** Specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. It's possible to sort products using searchable attributes with enabled 'Use in Filter Options' option */
export interface ProductAttributeSortInput {
  /** Attribute label: Product Name */
  name?: InputMaybe<SortEnum>
  /** Sort by the position assigned to each product. */
  position?: InputMaybe<SortEnum>
  /** Attribute label: Price */
  price?: InputMaybe<SortEnum>
  /** Sort by the search relevance score (default). */
  relevance?: InputMaybe<SortEnum>
}

/** Product custom attributes */
export interface ProductCustomAttributes {
  __typename?: 'ProductCustomAttributes'
  /** Errors when retrieving custom attributes metadata. */
  errors: Array<Maybe<AttributeMetadataError>>
  /** Requested custom attributes */
  items: Array<Maybe<AttributeValueInterface>>
}

/** Contains the discount applied to a product price. */
export interface ProductDiscount {
  __typename?: 'ProductDiscount'
  /** The actual value of the discount. */
  amountOff: Maybe<Scalars['Float']['output']>
  /** The discount expressed a percentage. */
  percentOff: Maybe<Scalars['Float']['output']>
}

/** ProductFilterInput is deprecated, use @ProductAttributeFilterInput instead. ProductFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export interface ProductFilterInput {
  /** The category ID the product belongs to. */
  category_id?: InputMaybe<FilterTypeInput>
  /** The product's country of origin. */
  country_of_manufacture?: InputMaybe<FilterTypeInput>
  /** The timestamp indicating when the product was created. */
  created_at?: InputMaybe<FilterTypeInput>
  /** The name of a custom layout. */
  custom_layout?: InputMaybe<FilterTypeInput>
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: InputMaybe<FilterTypeInput>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: InputMaybe<FilterTypeInput>
  /** Indicates whether a gift message is available. */
  gift_message_available?: InputMaybe<FilterTypeInput>
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: InputMaybe<FilterTypeInput>
  /** The relative path to the main image on the product page. */
  image?: InputMaybe<FilterTypeInput>
  /** The label assigned to a product image. */
  image_label?: InputMaybe<FilterTypeInput>
  /** Indicates whether the product can be returned. */
  is_returnable?: InputMaybe<FilterTypeInput>
  /** A number representing the product's manufacturer. */
  manufacturer?: InputMaybe<FilterTypeInput>
  /** The numeric maximal price of the product. Do not include the currency code. */
  max_price?: InputMaybe<FilterTypeInput>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: InputMaybe<FilterTypeInput>
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: InputMaybe<FilterTypeInput>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: InputMaybe<FilterTypeInput>
  /** The numeric minimal price of the product. Do not include the currency code. */
  min_price?: InputMaybe<FilterTypeInput>
  /** The product name. Customers use this name to identify the product. */
  name?: InputMaybe<FilterTypeInput>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: InputMaybe<FilterTypeInput>
  /** The end date for new product listings. */
  news_to_date?: InputMaybe<FilterTypeInput>
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: InputMaybe<FilterTypeInput>
  /** The keyword required to perform a logical OR comparison. */
  or?: InputMaybe<ProductFilterInput>
  /** The price of an item. */
  price?: InputMaybe<FilterTypeInput>
  /** Indicates whether the product has required options. */
  required_options?: InputMaybe<FilterTypeInput>
  /** A short description of the product. Its use depends on the theme. */
  short_description?: InputMaybe<FilterTypeInput>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: InputMaybe<FilterTypeInput>
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: InputMaybe<FilterTypeInput>
  /** The label assigned to a product's small image. */
  small_image_label?: InputMaybe<FilterTypeInput>
  /** The beginning date that a product has a special price. */
  special_from_date?: InputMaybe<FilterTypeInput>
  /** The discounted price of the product. Do not include the currency code. */
  special_price?: InputMaybe<FilterTypeInput>
  /** The end date that a product has a special price. */
  special_to_date?: InputMaybe<FilterTypeInput>
  /** The file name of a swatch image. */
  swatch_image?: InputMaybe<FilterTypeInput>
  /** The relative path to the product's thumbnail image. */
  thumbnail?: InputMaybe<FilterTypeInput>
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: InputMaybe<FilterTypeInput>
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: InputMaybe<FilterTypeInput>
  /** The timestamp indicating when the product was updated. */
  updated_at?: InputMaybe<FilterTypeInput>
  /** The part of the URL that identifies the product */
  url_key?: InputMaybe<FilterTypeInput>
  url_path?: InputMaybe<FilterTypeInput>
  /** The weight of the item, in units defined by the store. */
  weight?: InputMaybe<FilterTypeInput>
}

/** Contains product image information, including the image URL and label. */
export interface ProductImage extends MediaGalleryInterface {
  __typename?: 'ProductImage'
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']['output']>
  /** The label of the product image or video. */
  label: Maybe<Scalars['String']['output']>
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']['output']>
  /** The URL of the product image or video. */
  url: Maybe<Scalars['String']['output']>
}

/** Contains fields that are common to all types of products. */
export interface ProductInterface {
  /** @deprecated Use the `custom_attributes` field instead. */
  articleNumber: Maybe<Scalars['String']['output']>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  color: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  costPrice: Maybe<Scalars['String']['output']>
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** Product custom attributes. */
  customAttributesV2: Maybe<ProductCustomAttributes>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']['output']>
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>
  /** @deprecated Use the `custom_attributes` field instead. */
  isClonedForStore: Maybe<Scalars['String']['output']>
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']['output']>
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  manufacturerId: Maybe<Scalars['String']['output']>
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['String']['output']>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minimumQuantityAllowed: Maybe<Scalars['String']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  phasedOut: Maybe<Scalars['String']['output']>
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  productgroep: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  quantityIncrements: Maybe<Scalars['String']['output']>
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float']['output']
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalCrossection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDegreeofprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalEarthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalIdentificationcores: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalInnerdepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductorcrosssection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductordiameter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalinsulationthickness: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofcores: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofmodules: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofpoles: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofrows: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalOuterdiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalRatedvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalTrippingcharacteristic: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWithattachmentoption: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBundlesperpallet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingCabletype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingDiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingFireretardancy: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorBoxquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorCablediameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorSolarpanelcompatibility: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerConnector: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerPayload: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerRfidcardreader: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerSuitableforoutdoormounting: Maybe<
    Scalars['Int']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWirelessconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemRoofpitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWindstandard: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMaxsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMinsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringCommunicationtype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSeriecompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSignalrange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDaktype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHeightatthetop: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHoogtebovenzijde: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalPaletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperbox: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperpalet: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalRooftype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcdcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAfci: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerCompatiblestorage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcswitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerInvertertype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximuminputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMicropanelpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMinearthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMonitoringoptions: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMppvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerNumberofmpptrackers: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerPaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperbox: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperpalet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerRatedacpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerSolarpanels: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStartupvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStringspermpptracker: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerUsablebatterycapacity: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemBatvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCommunicationport: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxbatcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxoutputpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemOutputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemStoragecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemUsablecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBacksheetcolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBuild: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCelltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFramecolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelGuaranteedpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelJunctionboxprotectiondegree: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelMaxsystemvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelModuleefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelNumberofcells: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletsize: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPanelspercontainer: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPowerguarantee: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelSolarpaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelThickness: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWattagepersquaremeter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWindandsnowload: Maybe<Scalars['String']['output']>
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean']['output']
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  usp: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  voorraadstatus: Maybe<Scalars['String']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>
}

/** Contains fields that are common to all types of products. */
export interface ProductInterfaceCustomAttributesV2Args {
  filters: InputMaybe<AttributeFilterInput>
}

/** Contains fields that are common to all types of products. */
export interface ProductInterfaceReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** An implementation of `ProductLinksInterface`. */
export interface ProductLinks extends ProductLinksInterface {
  __typename?: 'ProductLinks'
  /** One of related, associated, upsell, or crosssell. */
  linkType: Maybe<Scalars['String']['output']>
  /** The SKU of the linked product. */
  linkedProductSku: Maybe<Scalars['String']['output']>
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linkedProductType: Maybe<Scalars['String']['output']>
  /** The position within the list of product links. */
  position: Maybe<Scalars['Int']['output']>
  /** The identifier of the linked product. */
  sku: Maybe<Scalars['String']['output']>
}

/** Contains information about linked products, including the link type and product type of each item. */
export interface ProductLinksInterface {
  /** One of related, associated, upsell, or crosssell. */
  linkType: Maybe<Scalars['String']['output']>
  /** The SKU of the linked product. */
  linkedProductSku: Maybe<Scalars['String']['output']>
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linkedProductType: Maybe<Scalars['String']['output']>
  /** The position within the list of product links. */
  position: Maybe<Scalars['Int']['output']>
  /** The identifier of the linked product. */
  sku: Maybe<Scalars['String']['output']>
}

/** Contains an image in base64 format and basic information about the image. */
export interface ProductMediaGalleryEntriesContent {
  __typename?: 'ProductMediaGalleryEntriesContent'
  /** The image in base64 format. */
  base64EncodedData: Maybe<Scalars['String']['output']>
  /** The file name of the image. */
  name: Maybe<Scalars['String']['output']>
  /** The MIME type of the file, such as image/png. */
  type: Maybe<Scalars['String']['output']>
}

/** Contains a link to a video file and basic information about the video. */
export interface ProductMediaGalleryEntriesVideoContent {
  __typename?: 'ProductMediaGalleryEntriesVideoContent'
  /** Must be external-video. */
  mediaType: Maybe<Scalars['String']['output']>
  /** A description of the video. */
  videoDescription: Maybe<Scalars['String']['output']>
  /** Optional data about the video. */
  videoMetadata: Maybe<Scalars['String']['output']>
  /** Describes the video source. */
  videoProvider: Maybe<Scalars['String']['output']>
  /** The title of the video. */
  videoTitle: Maybe<Scalars['String']['output']>
  /** The URL to the video. */
  videoUrl: Maybe<Scalars['String']['output']>
}

/** Represents a product price. */
export interface ProductPrice {
  __typename?: 'ProductPrice'
  /** The price discount. Represents the difference between the regular and final price. */
  discount: Maybe<ProductDiscount>
  /** The final price of the product after applying discounts. */
  finalPrice: Money
  /** An array of the multiple Fixed Product Taxes that can be applied to a product price. */
  fixedProductTaxes: Maybe<Array<Maybe<FixedProductTax>>>
  /** The regular price of the product. */
  regularPrice: Money
}

/** Deprecated. Use `PriceRange` instead. Contains the regular price of an item, as well as its minimum and maximum prices. Only composite products, which include bundle, configurable, and grouped products, can contain a minimum and maximum price. */
export interface ProductPrices {
  __typename?: 'ProductPrices'
  /**
   * The highest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the `to` value.
   * @deprecated Use `PriceRange.maximum_price` instead.
   */
  maximalPrice: Maybe<Price>
  /**
   * The lowest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the `from` value.
   * @deprecated Use `PriceRange.minimum_price` instead.
   */
  minimalPrice: Maybe<Price>
  /**
   * The base price of a product.
   * @deprecated Use `regular_price` from `PriceRange.minimum_price` or `PriceRange.maximum_price` instead.
   */
  regularPrice: Maybe<Price>
}

/** Contains details of a product review. */
export interface ProductReview {
  __typename?: 'ProductReview'
  /** The average of all ratings for this product. */
  averageRating: Scalars['Float']['output']
  /** The date the review was created. */
  createdAt: Scalars['String']['output']
  /** The customer's nickname. Defaults to the customer name, if logged in. */
  nickname: Scalars['String']['output']
  /** The reviewed product. */
  product: ProductInterface
  /** An array of ratings by rating category, such as quality, price, and value. */
  ratingsBreakdown: Array<Maybe<ProductReviewRating>>
  /** The summary (title) of the review. */
  summary: Scalars['String']['output']
  /** The review text. */
  text: Scalars['String']['output']
}

/** Contains data about a single aspect of a product review. */
export interface ProductReviewRating {
  __typename?: 'ProductReviewRating'
  /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
  name: Scalars['String']['output']
  /** The rating value given by customer. By default, possible values range from 1 to 5. */
  value: Scalars['String']['output']
}

/** Contains the reviewer's rating for a single aspect of a review. */
export interface ProductReviewRatingInput {
  /** An encoded rating ID. */
  id: Scalars['String']['input']
  /** An encoded rating value ID. */
  value_id: Scalars['String']['input']
}

/** Contains details about a single aspect of a product review. */
export interface ProductReviewRatingMetadata {
  __typename?: 'ProductReviewRatingMetadata'
  /** An encoded rating ID. */
  id: Scalars['String']['output']
  /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
  name: Scalars['String']['output']
  /** List of product review ratings sorted by position. */
  values: Array<Maybe<ProductReviewRatingValueMetadata>>
}

/** Contains details about a single value in a product review. */
export interface ProductReviewRatingValueMetadata {
  __typename?: 'ProductReviewRatingValueMetadata'
  /** A ratings scale, such as the number of stars awarded. */
  value: Scalars['String']['output']
  /** An encoded rating value ID. */
  valueId: Scalars['String']['output']
}

/** Contains an array of metadata about each aspect of a product review. */
export interface ProductReviewRatingsMetadata {
  __typename?: 'ProductReviewRatingsMetadata'
  /** An array of product reviews sorted by position. */
  items: Array<Maybe<ProductReviewRatingMetadata>>
}

/** Contains an array of product reviews. */
export interface ProductReviews {
  __typename?: 'ProductReviews'
  /** An array of product reviews. */
  items: Array<Maybe<ProductReview>>
  /** Metadata for pagination rendering. */
  pageInfo: SearchResultPageInfo
}

/** Deprecated. Use `ProductAttributeSortInput` instead. Specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export interface ProductSortInput {
  /** The product's country of origin. */
  country_of_manufacture?: InputMaybe<SortEnum>
  /** The timestamp indicating when the product was created. */
  created_at?: InputMaybe<SortEnum>
  /** The name of a custom layout. */
  custom_layout?: InputMaybe<SortEnum>
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: InputMaybe<SortEnum>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: InputMaybe<SortEnum>
  /** Indicates whether a gift message is available. */
  gift_message_available?: InputMaybe<SortEnum>
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: InputMaybe<SortEnum>
  /** The relative path to the main image on the product page. */
  image?: InputMaybe<SortEnum>
  /** The label assigned to a product image. */
  image_label?: InputMaybe<SortEnum>
  /** Indicates whether the product can be returned. */
  is_returnable?: InputMaybe<SortEnum>
  /** A number representing the product's manufacturer. */
  manufacturer?: InputMaybe<SortEnum>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: InputMaybe<SortEnum>
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: InputMaybe<SortEnum>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: InputMaybe<SortEnum>
  /** The product name. Customers use this name to identify the product. */
  name?: InputMaybe<SortEnum>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: InputMaybe<SortEnum>
  /** The end date for new product listings. */
  news_to_date?: InputMaybe<SortEnum>
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: InputMaybe<SortEnum>
  /** The price of the item. */
  price?: InputMaybe<SortEnum>
  /** Indicates whether the product has required options. */
  required_options?: InputMaybe<SortEnum>
  /** A short description of the product. Its use depends on the theme. */
  short_description?: InputMaybe<SortEnum>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: InputMaybe<SortEnum>
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: InputMaybe<SortEnum>
  /** The label assigned to a product's small image. */
  small_image_label?: InputMaybe<SortEnum>
  /** The beginning date that a product has a special price. */
  special_from_date?: InputMaybe<SortEnum>
  /** The discounted price of the product. */
  special_price?: InputMaybe<SortEnum>
  /** The end date that a product has a special price. */
  special_to_date?: InputMaybe<SortEnum>
  /** Indicates the criteria to sort swatches. */
  swatch_image?: InputMaybe<SortEnum>
  /** The relative path to the product's thumbnail image. */
  thumbnail?: InputMaybe<SortEnum>
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: InputMaybe<SortEnum>
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: InputMaybe<SortEnum>
  /** The timestamp indicating when the product was updated. */
  updated_at?: InputMaybe<SortEnum>
  /** The part of the URL that identifies the product */
  url_key?: InputMaybe<SortEnum>
  url_path?: InputMaybe<SortEnum>
  /** The weight of the item, in units defined by the store. */
  weight?: InputMaybe<SortEnum>
}

/** States whether a product stock status is in stock or out of stock. */
export enum ProductStockStatus {
  IN_STOCK = 'IN_STOCK',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
}

/** Deprecated. Use `TierPrice` instead. Defines a tier price, which is a quantity discount offered to a specific customer group. */
export interface ProductTierPrices {
  __typename?: 'ProductTierPrices'
  /**
   * The ID of the customer group.
   * @deprecated Not relevant for the storefront.
   */
  customerGroupId: Maybe<Scalars['String']['output']>
  /**
   * The percentage discount of the item.
   * @deprecated Use `TierPrice.discount` instead.
   */
  percentageValue: Maybe<Scalars['Float']['output']>
  /**
   * The number of items that must be purchased to qualify for tier pricing.
   * @deprecated Use `TierPrice.quantity` instead.
   */
  qty: Maybe<Scalars['Float']['output']>
  /**
   * The price of the fixed price item.
   * @deprecated Use `TierPrice.final_price` instead.
   */
  value: Maybe<Scalars['Float']['output']>
  /**
   * The ID assigned to the website.
   * @deprecated Not relevant for the storefront.
   */
  websiteId: Maybe<Scalars['Float']['output']>
}

/** Contains information about a product video. */
export interface ProductVideo extends MediaGalleryInterface {
  __typename?: 'ProductVideo'
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']['output']>
  /** The label of the product image or video. */
  label: Maybe<Scalars['String']['output']>
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']['output']>
  /** The URL of the product image or video. */
  url: Maybe<Scalars['String']['output']>
  /** Contains a `ProductMediaGalleryEntriesVideoContent` object. */
  videoContent: Maybe<ProductMediaGalleryEntriesVideoContent>
}

/** Contains the results of a `products` query. */
export interface Products {
  __typename?: 'Products'
  /** A bucket that contains the attribute code and label for each filterable option. */
  aggregations: Maybe<Array<Maybe<Aggregation>>>
  /**
   * Layered navigation filters array.
   * @deprecated Use `aggregations` instead.
   */
  filters: Maybe<Array<Maybe<LayerFilter>>>
  /** An array of products that match the specified search criteria. */
  items: Maybe<Array<Maybe<ProductInterface>>>
  /** An object that includes the page_info and currentPage values specified in the query. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** An object that includes the default sort field and all available sort fields. */
  sortFields: Maybe<SortFields>
  /** An array of search suggestions for case when search query have no results. */
  suggestions: Maybe<Array<Maybe<SearchSuggestion>>>
  /** The number of products that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Contains the results of a `products` query. */
export interface ProductsAggregationsArgs {
  filter: InputMaybe<AggregationsFilterInput>
}

/** Contains details about a purchase order. */
export interface PurchaseOrder {
  __typename?: 'PurchaseOrder'
  /** The approval flows for each applied rules. */
  approvalFlow: Array<Maybe<PurchaseOrderRuleApprovalFlow>>
  /** Purchase order actions available to the customer. Can be used to display action buttons on the client. */
  availableActions: Array<Maybe<PurchaseOrderAction>>
  /** The set of comments applied to the purchase order. */
  comments: Array<Maybe<PurchaseOrderComment>>
  /** The date the purchase order was created. */
  createdAt: Scalars['String']['output']
  /** The company user who created the purchase order. */
  createdBy: Maybe<Customer>
  /** The log of the events related to the purchase order. */
  historyLog: Array<Maybe<PurchaseOrderHistoryItem>>
  /** The purchase order number. */
  number: Scalars['String']['output']
  /** The reference to the order placed based on the purchase order. */
  order: Maybe<CustomerOrder>
  /** The quote related to the purchase order. */
  quote: Maybe<Cart>
  /** The current status of the purchase order. */
  status: PurchaseOrderStatus
  /** A unique identifier for the purchase order. */
  uid: Scalars['ID']['output']
  /** The date the purchase order was last updated. */
  updatedAt: Scalars['String']['output']
}

export enum PurchaseOrderAction {
  APPROVE = 'APPROVE',
  CANCEL = 'CANCEL',
  PLACE_ORDER = 'PLACE_ORDER',
  REJECT = 'REJECT',
  VALIDATE = 'VALIDATE',
}

/** Contains details about a failed action. */
export interface PurchaseOrderActionError {
  __typename?: 'PurchaseOrderActionError'
  /** The returned error message. */
  message: Scalars['String']['output']
  /** The error type. */
  type: PurchaseOrderErrorType
}

/** Contains details about a single event in the approval flow of the purchase order. */
export interface PurchaseOrderApprovalFlowEvent {
  __typename?: 'PurchaseOrderApprovalFlowEvent'
  /** A formatted message. */
  message: Maybe<Scalars['String']['output']>
  /** The approver name. */
  name: Maybe<Scalars['String']['output']>
  /** The approver role. */
  role: Maybe<Scalars['String']['output']>
  /** The status related to the event. */
  status: Maybe<PurchaseOrderApprovalFlowItemStatus>
  /** The date and time the event was updated. */
  updatedAt: Maybe<Scalars['String']['output']>
}

export enum PurchaseOrderApprovalFlowItemStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

/** Contains details about a purchase order approval rule. */
export interface PurchaseOrderApprovalRule {
  __typename?: 'PurchaseOrderApprovalRule'
  /** The name of the user(s) affected by the the purchase order approval rule. */
  appliesToRoles: Array<Maybe<CompanyRole>>
  /** The name of the user who needs to approve purchase orders that trigger the approval rule. */
  approverRoles: Array<Maybe<CompanyRole>>
  /** Condition which triggers the approval rule. */
  condition: Maybe<PurchaseOrderApprovalRuleConditionInterface>
  /** The date the purchase order rule was created. */
  createdAt: Scalars['String']['output']
  /** The name of the user who created the purchase order approval rule. */
  createdBy: Scalars['String']['output']
  /** Description of the purchase order approval rule. */
  description: Maybe<Scalars['String']['output']>
  /** The name of the purchase order approval rule. */
  name: Scalars['String']['output']
  /** The status of the purchase order approval rule. */
  status: PurchaseOrderApprovalRuleStatus
  /** The unique identifier for the purchase order approval rule. */
  uid: Scalars['ID']['output']
  /** The date the purchase order rule was last updated. */
  updatedAt: Scalars['String']['output']
}

/** Contains approval rule condition details, including the amount to be evaluated. */
export interface PurchaseOrderApprovalRuleConditionAmount
  extends PurchaseOrderApprovalRuleConditionInterface {
  __typename?: 'PurchaseOrderApprovalRuleConditionAmount'
  /** The amount to be be used for evaluation of the approval rule condition. */
  amount: Money
  /** The type of purchase order approval rule. */
  attribute: Maybe<PurchaseOrderApprovalRuleType>
  /** The operator to be used for evaluating the approval rule condition. */
  operator: Maybe<PurchaseOrderApprovalRuleConditionOperator>
}

/** Purchase order rule condition details. */
export interface PurchaseOrderApprovalRuleConditionInterface {
  /** The type of purchase order approval rule. */
  attribute: Maybe<PurchaseOrderApprovalRuleType>
  /** The operator to be used for evaluating the approval rule condition. */
  operator: Maybe<PurchaseOrderApprovalRuleConditionOperator>
}

export enum PurchaseOrderApprovalRuleConditionOperator {
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
  MORE_THAN = 'MORE_THAN',
  MORE_THAN_OR_EQUAL_TO = 'MORE_THAN_OR_EQUAL_TO',
}

/** Contains approval rule condition details, including the quantity to be evaluated. */
export interface PurchaseOrderApprovalRuleConditionQuantity
  extends PurchaseOrderApprovalRuleConditionInterface {
  __typename?: 'PurchaseOrderApprovalRuleConditionQuantity'
  /** The type of purchase order approval rule. */
  attribute: Maybe<PurchaseOrderApprovalRuleType>
  /** The operator to be used for evaluating the approval rule condition. */
  operator: Maybe<PurchaseOrderApprovalRuleConditionOperator>
  /** The quantity to be used for evaluation of the approval rule condition. */
  quantity: Maybe<Scalars['Int']['output']>
}

/** Defines a new purchase order approval rule. */
export interface PurchaseOrderApprovalRuleInput {
  /** A list of company user role IDs to which this purchase order approval rule should be applied. When an empty array is provided, the rule is applied to all user roles in the system, including those created in the future. */
  applies_to: Array<InputMaybe<Scalars['ID']['input']>>
  /** A list of B2B user roles that can approve this purchase order approval rule. */
  approvers: Array<InputMaybe<Scalars['ID']['input']>>
  /** The condition of the purchase order approval rule. */
  condition: CreatePurchaseOrderApprovalRuleConditionInput
  /** A summary of the purpose of the purchase order approval rule. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The purchase order approval rule name. */
  name: Scalars['String']['input']
  /** The status of the purchase order approval rule. */
  status: PurchaseOrderApprovalRuleStatus
}

/** Contains metadata that can be used to render rule edit forms. */
export interface PurchaseOrderApprovalRuleMetadata {
  __typename?: 'PurchaseOrderApprovalRuleMetadata'
  /** A list of B2B user roles that the rule can be applied to. */
  availableAppliesTo: Array<Maybe<CompanyRole>>
  /** A list of currencies that can be used to create approval rules based on amounts, for example shipping cost rules. */
  availableConditionCurrencies: Array<Maybe<AvailableCurrency>>
  /** A list of B2B user roles that can be specified as approvers for the approval rules. */
  availableRequiresApprovalFrom: Array<Maybe<CompanyRole>>
}

export enum PurchaseOrderApprovalRuleStatus {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export enum PurchaseOrderApprovalRuleType {
  GRAND_TOTAL = 'GRAND_TOTAL',
  NUMBER_OF_SKUS = 'NUMBER_OF_SKUS',
  SHIPPING_INCL_TAX = 'SHIPPING_INCL_TAX',
}

/** Contains the approval rules that the customer can see. */
export interface PurchaseOrderApprovalRules {
  __typename?: 'PurchaseOrderApprovalRules'
  /** A list of purchase order approval rules visible to the customer. */
  items: Array<Maybe<PurchaseOrderApprovalRule>>
  /** Result pagination details. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The total number of purchase order approval rules visible to the customer. */
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Contains details about a comment. */
export interface PurchaseOrderComment {
  __typename?: 'PurchaseOrderComment'
  /** The user who left the comment. */
  author: Maybe<Customer>
  /** The date and time when the comment was created. */
  createdAt: Scalars['String']['output']
  /** The text of the comment. */
  text: Scalars['String']['output']
  /** A unique identifier of the comment. */
  uid: Scalars['ID']['output']
}

export enum PurchaseOrderErrorType {
  COULD_NOT_SAVE = 'COULD_NOT_SAVE',
  NOT_FOUND = 'NOT_FOUND',
  NOT_VALID_DATA = 'NOT_VALID_DATA',
  OPERATION_NOT_APPLICABLE = 'OPERATION_NOT_APPLICABLE',
  UNDEFINED = 'UNDEFINED',
}

/** Contains details about a status change. */
export interface PurchaseOrderHistoryItem {
  __typename?: 'PurchaseOrderHistoryItem'
  /** The activity type of the event. */
  activity: Scalars['String']['output']
  /** The date and time when the event happened. */
  createdAt: Scalars['String']['output']
  /** The message representation of the event. */
  message: Scalars['String']['output']
  /** A unique identifier of the purchase order history item. */
  uid: Scalars['ID']['output']
}

/** Contains details about approval roles applied to the purchase order and status changes. */
export interface PurchaseOrderRuleApprovalFlow {
  __typename?: 'PurchaseOrderRuleApprovalFlow'
  /** The approval flow event related to the rule. */
  events: Array<Maybe<PurchaseOrderApprovalFlowEvent>>
  /** The name of the applied rule. */
  ruleName: Scalars['String']['output']
}

export enum PurchaseOrderStatus {
  APPROVAL_REQUIRED = 'APPROVAL_REQUIRED',
  APPROVED = 'APPROVED',
  APPROVED_PENDING_PAYMENT = 'APPROVED_PENDING_PAYMENT',
  CANCELED = 'CANCELED',
  ORDER_FAILED = 'ORDER_FAILED',
  ORDER_IN_PROGRESS = 'ORDER_IN_PROGRESS',
  ORDER_PLACED = 'ORDER_PLACED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

/** Contains a list of purchase orders. */
export interface PurchaseOrders {
  __typename?: 'PurchaseOrders'
  /** Purchase orders matching the search criteria. */
  items: Array<Maybe<PurchaseOrder>>
  /** Page information of search result's current page. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** Total number of purchase orders found matching the search criteria. */
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Defines which purchase orders to act on. */
export interface PurchaseOrdersActionInput {
  /** An array of purchase order UIDs. */
  purchase_order_uids: Array<InputMaybe<Scalars['ID']['input']>>
}

/** Returns a list of updated purchase orders and any error messages. */
export interface PurchaseOrdersActionOutput {
  __typename?: 'PurchaseOrdersActionOutput'
  /** An array of error messages encountered while performing the operation. */
  errors: Array<Maybe<PurchaseOrderActionError>>
  /** A list of purchase orders. */
  purchaseOrders: Array<Maybe<PurchaseOrder>>
}

/** Defines the criteria to use to filter the list of purchase orders. */
export interface PurchaseOrdersFilterInput {
  /** Include only purchase orders made by subordinate company users. */
  company_purchase_orders?: InputMaybe<Scalars['Boolean']['input']>
  /** Filter by the creation date of the purchase order. */
  created_date?: InputMaybe<FilterRangeTypeInput>
  /** Include only purchase orders that are waiting for the customer’s approval. */
  require_my_approval?: InputMaybe<Scalars['Boolean']['input']>
  /** Filter by the status of the purchase order. */
  status?: InputMaybe<PurchaseOrderStatus>
}

export interface Query {
  __typename?: 'Query'
  /**
   * Get an address based on its unique combination of postcode, house number and
   * house number addition.
   *
   * Coordinate and surfaceArea values are null values if no location is known,
   * i.e. if the address is a PO Box.
   *
   * You must always check the houseNumberAddition result field, as a successful
   * response is also returned if only the input addition isn't found.
   */
  address: Maybe<PostcodeNlAddress>
  /** Retrieve EAV attributes associated to a frontend form. Use countries query provided by DirectoryGraphQl module to retrieve region_id and country_id attribute options. */
  attributesForm: AttributesFormOutput
  /** Returns a list of attributes metadata for a given entity type. */
  attributesList: Maybe<AttributesMetadataOutput>
  /**
   * Get a list of autocomplete matches based on a single (partial) address term. Depending on the
   * input different types of matches may be returned, such as streets, postal codes or a specific address.
   *
   * This method is intended for interactive address entry where a user enters (part) of an address
   * and matching suggestions are shown. Depending on the matches a user may keep typing, or may
   * select a match after which additional input can still be entered.
   *
   * The matching algorithm takes into account possible typos as well as missing or irrelevant words.
   */
  autocompleteAddress: Maybe<AutocompleteAddressResult>
  autocompleteAddressDetails: Maybe<AutocompleteAddressDetailsResult>
  /** Return information about the cart delivery days */
  availableDeliveryDays: Maybe<Array<Maybe<DeliveryDay>>>
  /** Get a list of available store views and their config information. */
  availableStores: Maybe<Array<Maybe<StoreConfig>>>
  blogAuthor: Maybe<BlogAuthor>
  blogCategories: Maybe<BlogCategoriesOutput>
  blogCategory: Maybe<BlogCategory>
  /** The comments query searches for posts that match the criteria specified in the search and filter attributes */
  blogComments: Maybe<BlogCommentsOutput>
  blogPost: Maybe<BlogPost>
  /** The posts query searches for posts that match the criteria specified in the search and filter attributes */
  blogPosts: Maybe<BlogPostsOutput>
  blogTag: Maybe<BlogTag>
  blogTags: Maybe<BlogTagsOutput>
  calculateCompatibleAcMaterialsByInverterSku: Maybe<PimcoreAcMaterialResults>
  calculateCompatibleInvertersBySolarPanelSku: Maybe<PimcoreProducts>
  calculateCompatibleStorageSystemByInverterSku: Maybe<PimcoreStorageSystemResult>
  /** Return information about the specified shopping cart. */
  cart: Maybe<Cart>
  /** Return information about the possible delivery date for the cart */
  cartFirstDeliveryDate: Maybe<CartOrderDateResult>
  /** Return a list of categories that match the specified filter. */
  categories: Maybe<CategoryResult>
  /**
   * Search for categories that match the criteria specified in the `search` and `filter` attributes.
   * @deprecated Use `categories` instead.
   */
  category: Maybe<CategoryTree>
  /** Retrieve a category by UID */
  categoryByUid: Maybe<CategoryInterface>
  /**
   * Return an array of categories based on the specified filters.
   * @deprecated Use `categories` instead.
   */
  categoryList: Maybe<Array<Maybe<CategoryTree>>>
  /** Return Terms and Conditions configuration information. */
  checkoutAgreements: Maybe<Array<Maybe<CheckoutAgreement>>>
  /** Return information about CMS blocks. */
  cmsBlocks: Maybe<CmsBlocks>
  /** Return details about a CMS page. */
  cmsPage: Maybe<CmsPage>
  /** Return detailed information about the authenticated customer's company. */
  company: Maybe<Company>
  /** Return products that have been added to the specified compare list. */
  compareList: Maybe<CompareList>
  /** The countries query provides information for all countries. */
  countries: Maybe<Array<Maybe<Country>>>
  /** The countries query provides information for a single country. */
  country: Maybe<Country>
  /** Return information about the store's currency. */
  currency: Maybe<Currency>
  /**
   * Return the attribute type, given an attribute code and entity type.
   * @deprecated Use `customAttributeMetadataV2` query instead.
   */
  customAttributeMetadata: Maybe<CustomAttributeMetadata>
  /** Retrieve EAV attributes metadata. */
  customAttributeMetadataV2: AttributesMetadataOutput
  /** Return detailed information about a customer account. */
  customer: Maybe<Customer>
  /** Return information about the customer's shopping cart. */
  customerCart: Cart
  /** Get the customer Configuration by id */
  customerConfiguration: Maybe<CustomerConfiguration>
  /** Fetch customer credit. */
  customerCredit: Maybe<CustomerCreditResult>
  /** Return a list of downloadable products the customer has purchased. */
  customerDownloadableProducts: Maybe<CustomerDownloadableProducts>
  /** @deprecated Use the `customer` query instead. */
  customerOrders: Maybe<CustomerOrders>
  /** Return a list of customer payment tokens stored in the vault. */
  customerPaymentTokens: Maybe<CustomerPaymentTokens>
  /** Return information about the possible time frames for delivery */
  deliveryTimeFrames: Maybe<Array<Maybe<DeliveryTimeFrame>>>
  /** Return a list of dynamic blocks filtered by type, location, or UIDs. */
  dynamicBlocks: DynamicBlocks
  /** Returns Elektramat Configuration details */
  elektramatConfiguration: Maybe<ElektramatConfiguration>
  getCategory: Maybe<PimcoreObjectCategory>
  getCategoryListing: Maybe<PimcoreCategoryConnection>
  /** Retrieve the secure PayPal URL for a Payments Pro Hosted Solution transaction. */
  getHostedProUrl: Maybe<HostedProUrl>
  getManufacturer: Maybe<PimcoreObjectManufacturer>
  getManufacturerListing: Maybe<PimcoreManufacturerConnection>
  /** Retrieve payment credentials for a transaction. Use this query for Payflow Link and Payments Advanced payment methods. */
  getPayflowLinkToken: Maybe<PayflowLinkToken>
  /** Retrieves the payment configuration for a given location */
  getPaymentConfig: Maybe<PaymentConfigOutput>
  /** Retrieves the payment details for the order */
  getPaymentOrder: Maybe<PaymentOrderOutput>
  /** Gets the payment SDK urls and values */
  getPaymentSdk: Maybe<GetPaymentSdkOutput>
  getProduct: Maybe<PimcoreObjectProduct>
  getProductGroup: Maybe<PimcoreObjectProductGroup>
  getProductGroupListing: Maybe<PimcoreProductGroupConnection>
  getProductListing: Maybe<PimcoreProductConnection>
  /** Return details about a specific gift card. */
  giftCardAccount: Maybe<GiftCardAccount>
  /** Return the specified gift registry. Some details will not be available to guests. */
  giftRegistry: Maybe<GiftRegistry>
  /** Search for gift registries by specifying a registrant email address. */
  giftRegistryEmailSearch: Maybe<Array<Maybe<GiftRegistrySearchResult>>>
  /** Search for gift registries by specifying a registry URL key. */
  giftRegistryIdSearch: Maybe<Array<Maybe<GiftRegistrySearchResult>>>
  /** Search for gift registries by specifying the registrant name and registry type ID. */
  giftRegistryTypeSearch: Maybe<Array<Maybe<GiftRegistrySearchResult>>>
  /** Get a list of available gift registry types. */
  giftRegistryTypes: Maybe<Array<Maybe<GiftRegistryType>>>
  /** Retrieve guest order details based on number, email and postcode. */
  guestOrder: CustomerOrder
  /** Retrieve guest order details based on token. */
  guestOrderByToken: CustomerOrder
  /** Check whether the specified email can be used to register a company admin. */
  isCompanyAdminEmailAvailable: Maybe<IsCompanyAdminEmailAvailableOutput>
  /** Check whether the specified email can be used to register a new company. */
  isCompanyEmailAvailable: Maybe<IsCompanyEmailAvailableOutput>
  /** Check whether the specified role name is valid for the company. */
  isCompanyRoleNameAvailable: Maybe<IsCompanyRoleNameAvailableOutput>
  /** Check whether the specified email can be used to register a company user. */
  isCompanyUserEmailAvailable: Maybe<IsCompanyUserEmailAvailableOutput>
  /** Check whether the specified email has already been used to create a customer account. */
  isEmailAvailable: Maybe<IsEmailAvailableOutput>
  mollieCustomerOrder: Maybe<CustomerOrder>
  molliePaymentMethods: Maybe<MolliePaymentMethodsOutput>
  /** Retrieve the specified negotiable quote. */
  negotiableQuote: Maybe<NegotiableQuote>
  /** Return a list of negotiable quotes that can be viewed by the logged-in customer. */
  negotiableQuotes: Maybe<NegotiableQuotesOutput>
  order: Maybe<CustomerOrder>
  /** @deprecated id is deprecated, use productByUid */
  product: Maybe<ProductInterface>
  /** Retrieve a product by UID */
  productByUid: Maybe<ProductInterface>
  /** Return the active ratings attributes and the values each rating can have. */
  productReviewRatingsMetadata: ProductReviewRatingsMetadata
  /** Search for products that match the criteria specified in the `search` and `filter` attributes. */
  products: Maybe<Products>
  /** Returns details about Google reCAPTCHA V3-Invisible configuration. */
  recaptchaV3Config: Maybe<ReCaptchaConfigurationV3>
  resolveDataHash: Maybe<Scalars['JSON']['output']>
  /** Return the full details for a specified product, category, or CMS page. */
  route: Maybe<RoutableInterface>
  /** Return details about the store's configuration. */
  storeConfig: Maybe<StoreConfig>
  /**
   * Return the relative URL for a specified product, category or CMS page.
   * @deprecated Use the `route` query instead.
   */
  urlResolver: Maybe<EntityUrl>
  /**
   * Return the contents of a customer's wish list.
   * @deprecated Moved under `Customer.wishlist`.
   */
  wishlist: Maybe<WishlistOutput>
}

export interface QueryAddressArgs {
  houseNumber: Scalars['Int']['input']
  houseNumberAddition: InputMaybe<Scalars['String']['input']>
  postcode: Scalars['String']['input']
}

export interface QueryAttributesFormArgs {
  formCode: Scalars['String']['input']
}

export interface QueryAttributesListArgs {
  entityType: AttributeEntityTypeEnum
  filters: InputMaybe<AttributeFilterInput>
}

export interface QueryAutocompleteAddressArgs {
  context: Scalars['String']['input']
  language: InputMaybe<AutocompleteAddressLanguages>
  session: Scalars['String']['input']
  term: Scalars['String']['input']
}

export interface QueryAutocompleteAddressDetailsArgs {
  context: Scalars['String']['input']
  dispatchCountry: InputMaybe<Scalars['String']['input']>
  session: Scalars['String']['input']
}

export interface QueryAvailableDeliveryDaysArgs {
  cart_id: Scalars['String']['input']
  month: InputMaybe<Scalars['Int']['input']>
  year: InputMaybe<Scalars['Int']['input']>
}

export interface QueryAvailableStoresArgs {
  useCurrentGroup: InputMaybe<Scalars['Boolean']['input']>
}

export interface QueryBlogAuthorArgs {
  id: InputMaybe<Scalars['String']['input']>
}

export interface QueryBlogCategoryArgs {
  id: InputMaybe<Scalars['String']['input']>
}

export interface QueryBlogCommentsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter: InputMaybe<BlogCommentsFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export interface QueryBlogPostArgs {
  id: InputMaybe<Scalars['String']['input']>
}

export interface QueryBlogPostsArgs {
  allPosts: InputMaybe<Scalars['Boolean']['input']>
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter: InputMaybe<BlogPostsFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sortFiled?: InputMaybe<Scalars['String']['input']>
}

export interface QueryBlogTagArgs {
  id: InputMaybe<Scalars['String']['input']>
}

export interface QueryCalculateCompatibleAcMaterialsByInverterSkuArgs {
  defaultLanguage: InputMaybe<Scalars['String']['input']>
  quantity: Scalars['String']['input']
  sku: Scalars['String']['input']
}

export interface QueryCalculateCompatibleInvertersBySolarPanelSkuArgs {
  configurations: Scalars['String']['input']
  defaultLanguage: InputMaybe<Scalars['String']['input']>
  sku: Scalars['String']['input']
}

export interface QueryCalculateCompatibleStorageSystemByInverterSkuArgs {
  sku: Scalars['String']['input']
  storeCode: Scalars['String']['input']
}

export interface QueryCartArgs {
  cart_id: Scalars['String']['input']
}

export interface QueryCartFirstDeliveryDateArgs {
  cart_id: Scalars['String']['input']
}

export interface QueryCategoriesArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filters: InputMaybe<CategoryFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export interface QueryCategoryArgs {
  id: InputMaybe<Scalars['Int']['input']>
}

export interface QueryCategoryByUidArgs {
  uid: Scalars['ID']['input']
}

export interface QueryCategoryListArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filters: InputMaybe<CategoryFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export interface QueryCmsBlocksArgs {
  identifiers: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface QueryCmsPageArgs {
  id: InputMaybe<Scalars['Int']['input']>
  identifier: InputMaybe<Scalars['String']['input']>
}

export interface QueryCompareListArgs {
  uid: Scalars['ID']['input']
}

export interface QueryCountryArgs {
  id: InputMaybe<Scalars['String']['input']>
}

export interface QueryCustomAttributeMetadataArgs {
  attributes: Array<AttributeInput>
}

export interface QueryCustomAttributeMetadataV2Args {
  attributes: InputMaybe<Array<AttributeInput>>
}

export interface QueryCustomerConfigurationArgs {
  id: InputMaybe<Scalars['Int']['input']>
}

export interface QueryDynamicBlocksArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  input: InputMaybe<DynamicBlocksFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export interface QueryElektramatConfigurationArgs {
  build_sku: Scalars['String']['input']
}

export interface QueryGetCategoryArgs {
  defaultLanguage: InputMaybe<Scalars['String']['input']>
  fullpath: InputMaybe<Scalars['String']['input']>
  id: InputMaybe<Scalars['Int']['input']>
}

export interface QueryGetCategoryListingArgs {
  after: InputMaybe<Scalars['Int']['input']>
  defaultLanguage: InputMaybe<Scalars['String']['input']>
  filter: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  fullpaths: InputMaybe<Scalars['String']['input']>
  ids: InputMaybe<Scalars['String']['input']>
  published: InputMaybe<Scalars['Boolean']['input']>
  sortBy: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sortOrder: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  tags: InputMaybe<Scalars['String']['input']>
}

export interface QueryGetHostedProUrlArgs {
  input: HostedProUrlInput
}

export interface QueryGetManufacturerArgs {
  defaultLanguage: InputMaybe<Scalars['String']['input']>
  fullpath: InputMaybe<Scalars['String']['input']>
  id: InputMaybe<Scalars['Int']['input']>
}

export interface QueryGetManufacturerListingArgs {
  after: InputMaybe<Scalars['Int']['input']>
  defaultLanguage: InputMaybe<Scalars['String']['input']>
  filter: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  fullpaths: InputMaybe<Scalars['String']['input']>
  ids: InputMaybe<Scalars['String']['input']>
  published: InputMaybe<Scalars['Boolean']['input']>
  sortBy: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sortOrder: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  tags: InputMaybe<Scalars['String']['input']>
}

export interface QueryGetPayflowLinkTokenArgs {
  input: PayflowLinkTokenInput
}

export interface QueryGetPaymentConfigArgs {
  location: PaymentLocation
}

export interface QueryGetPaymentOrderArgs {
  cartId: Scalars['String']['input']
  id: Scalars['String']['input']
}

export interface QueryGetPaymentSdkArgs {
  location: PaymentLocation
}

export interface QueryGetProductArgs {
  defaultLanguage: InputMaybe<Scalars['String']['input']>
  fullpath: InputMaybe<Scalars['String']['input']>
  id: InputMaybe<Scalars['Int']['input']>
}

export interface QueryGetProductGroupArgs {
  defaultLanguage: InputMaybe<Scalars['String']['input']>
  fullpath: InputMaybe<Scalars['String']['input']>
  id: InputMaybe<Scalars['Int']['input']>
}

export interface QueryGetProductGroupListingArgs {
  after: InputMaybe<Scalars['Int']['input']>
  defaultLanguage: InputMaybe<Scalars['String']['input']>
  filter: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  fullpaths: InputMaybe<Scalars['String']['input']>
  ids: InputMaybe<Scalars['String']['input']>
  published: InputMaybe<Scalars['Boolean']['input']>
  sortBy: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sortOrder: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  tags: InputMaybe<Scalars['String']['input']>
}

export interface QueryGetProductListingArgs {
  after: InputMaybe<Scalars['Int']['input']>
  defaultLanguage: InputMaybe<Scalars['String']['input']>
  filter: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  fullpaths: InputMaybe<Scalars['String']['input']>
  ids: InputMaybe<Scalars['String']['input']>
  published: InputMaybe<Scalars['Boolean']['input']>
  sortBy: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sortOrder: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  tags: InputMaybe<Scalars['String']['input']>
}

export interface QueryGiftCardAccountArgs {
  input: GiftCardAccountInput
}

export interface QueryGiftRegistryArgs {
  giftRegistryUid: Scalars['ID']['input']
}

export interface QueryGiftRegistryEmailSearchArgs {
  email: Scalars['String']['input']
}

export interface QueryGiftRegistryIdSearchArgs {
  giftRegistryUid: Scalars['ID']['input']
}

export interface QueryGiftRegistryTypeSearchArgs {
  firstName: Scalars['String']['input']
  giftRegistryTypeUid: InputMaybe<Scalars['ID']['input']>
  lastName: Scalars['String']['input']
}

export interface QueryGuestOrderArgs {
  input: OrderInformationInput
}

export interface QueryGuestOrderByTokenArgs {
  input: OrderTokenInput
}

export interface QueryIsCompanyAdminEmailAvailableArgs {
  email: Scalars['String']['input']
}

export interface QueryIsCompanyEmailAvailableArgs {
  email: Scalars['String']['input']
}

export interface QueryIsCompanyRoleNameAvailableArgs {
  name: Scalars['String']['input']
}

export interface QueryIsCompanyUserEmailAvailableArgs {
  email: Scalars['String']['input']
}

export interface QueryIsEmailAvailableArgs {
  email: Scalars['String']['input']
}

export interface QueryMollieCustomerOrderArgs {
  hash: InputMaybe<Scalars['String']['input']>
}

export interface QueryMolliePaymentMethodsArgs {
  input: InputMaybe<MolliePaymentMethodsInput>
}

export interface QueryNegotiableQuoteArgs {
  uid: Scalars['ID']['input']
}

export interface QueryNegotiableQuotesArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter: InputMaybe<NegotiableQuoteFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort: InputMaybe<NegotiableQuoteSortInput>
}

export interface QueryOrderArgs {
  orderMaskId: Scalars['String']['input']
}

export interface QueryProductArgs {
  id: Scalars['Int']['input']
}

export interface QueryProductByUidArgs {
  uid: Scalars['ID']['input']
}

export interface QueryProductsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter: InputMaybe<ProductAttributeFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  search: InputMaybe<Scalars['String']['input']>
  sort: InputMaybe<ProductAttributeSortInput>
}

export interface QueryResolveDataHashArgs {
  hash: Scalars['String']['input']
}

export interface QueryRouteArgs {
  url: Scalars['String']['input']
}

export interface QueryUrlResolverArgs {
  url: Scalars['String']['input']
}

/** Specifies the field to use for sorting quote items */
export interface QuoteItemsSortInput {
  /** Specifies the quote items field to sort by */
  field: SortQuoteItemsEnum
  /** Specifies the order of quote items' sorting */
  order: SortEnum
}

/** Contains reCAPTCHA V3-Invisible configuration details. */
export interface ReCaptchaConfigurationV3 {
  __typename?: 'ReCaptchaConfigurationV3'
  /** The position of the invisible reCAPTCHA badge on each page. */
  badgePosition: Scalars['String']['output']
  /** The message that appears to the user if validation fails. */
  failureMessage: Scalars['String']['output']
  /** A list of forms on the storefront that have been configured to use reCAPTCHA V3. */
  forms: Array<Maybe<ReCaptchaFormEnum>>
  /** Return whether recaptcha is enabled or not */
  isEnabled: Scalars['Boolean']['output']
  /** A two-character code that specifies the language that is used for Google reCAPTCHA text and messaging. */
  languageCode: Maybe<Scalars['String']['output']>
  /** The minimum score that identifies a user interaction as a potential risk. */
  minimumScore: Scalars['Float']['output']
  /** The website key generated when the Google reCAPTCHA account was registered. */
  websiteKey: Scalars['String']['output']
}

export enum ReCaptchaFormEnum {
  BRAINTREE = 'BRAINTREE',
  CONTACT = 'CONTACT',
  CUSTOMER_CREATE = 'CUSTOMER_CREATE',
  CUSTOMER_EDIT = 'CUSTOMER_EDIT',
  CUSTOMER_FORGOT_PASSWORD = 'CUSTOMER_FORGOT_PASSWORD',
  CUSTOMER_LOGIN = 'CUSTOMER_LOGIN',
  NEWSLETTER = 'NEWSLETTER',
  PLACE_ORDER = 'PLACE_ORDER',
  PRODUCT_REVIEW = 'PRODUCT_REVIEW',
  SENDFRIEND = 'SENDFRIEND',
}

export interface Region {
  __typename?: 'Region'
  /** The two-letter code for the region, such as TX for Texas. */
  code: Maybe<Scalars['String']['output']>
  /** The unique ID for a `Region` object. */
  id: Maybe<Scalars['Int']['output']>
  /** The name of the region, such as Texas. */
  name: Maybe<Scalars['String']['output']>
}

/** Specifies the cart from which to remove a coupon. */
export interface RemoveCouponFromCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
}

/** Contains details about the cart after removing a coupon. */
export interface RemoveCouponFromCartOutput {
  __typename?: 'RemoveCouponFromCartOutput'
  /** The cart after removing a coupon. */
  cart: Maybe<Cart>
}

/** Remove coupons from the cart. */
export interface RemoveCouponsFromCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** An array of coupon codes to be removed from the quote. If coupon_codes is empty all coupons will be removed from the quote. */
  coupon_codes: Array<InputMaybe<Scalars['String']['input']>>
}

/** Defines the input required to run the `removeGiftCardFromCart` mutation. */
export interface RemoveGiftCardFromCartInput {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String']['input']
  /** The gift card code to be removed to the cart. */
  gift_card_code: Scalars['String']['input']
}

/** Defines the possible output for the `removeGiftCardFromCart` mutation. */
export interface RemoveGiftCardFromCartOutput {
  __typename?: 'RemoveGiftCardFromCartOutput'
  /** The contents of the specified shopping cart. */
  cart: Cart
}

/** Contains the results of a request to remove an item from a gift registry. */
export interface RemoveGiftRegistryItemsOutput {
  __typename?: 'RemoveGiftRegistryItemsOutput'
  /** The gift registry after removing items. */
  giftRegistry: Maybe<GiftRegistry>
}

/** Contains the results of a request to delete a gift registry. */
export interface RemoveGiftRegistryOutput {
  __typename?: 'RemoveGiftRegistryOutput'
  /** Indicates whether the gift registry was successfully deleted. */
  success: Scalars['Boolean']['output']
}

/** Contains the results of a request to delete a registrant. */
export interface RemoveGiftRegistryRegistrantsOutput {
  __typename?: 'RemoveGiftRegistryRegistrantsOutput'
  /** The gift registry after deleting registrants. */
  giftRegistry: Maybe<GiftRegistry>
}

/** Specifies which items to remove from the cart. */
export interface RemoveItemFromCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: InputMaybe<Scalars['Int']['input']>
  /** Required field. The unique ID for a `CartItemInterface` object. */
  cart_item_uid?: InputMaybe<Scalars['ID']['input']>
}

/** Contains details about the cart after removing an item. */
export interface RemoveItemFromCartOutput {
  __typename?: 'RemoveItemFromCartOutput'
  /** The cart after removing an item. */
  cart: Cart
}

/** Defines the items to remove from the specified negotiable quote. */
export interface RemoveNegotiableQuoteItemsInput {
  /** An array of IDs indicating which items to remove from the negotiable quote. */
  quote_item_uids: Array<InputMaybe<Scalars['ID']['input']>>
  /** The unique ID of a `NegotiableQuote` object. */
  quote_uid: Scalars['ID']['input']
}

/** Contains the negotiable quote. */
export interface RemoveNegotiableQuoteItemsOutput {
  __typename?: 'RemoveNegotiableQuoteItemsOutput'
  /** The negotiable quote after removing items. */
  quote: Maybe<NegotiableQuote>
}

/** Defines which products to remove from a compare list. */
export interface RemoveProductsFromCompareListInput {
  /** An array of product IDs to remove from the compare list. */
  products: Array<InputMaybe<Scalars['ID']['input']>>
  /** The unique identifier of the compare list to modify. */
  uid: Scalars['ID']['input']
}

/** Contains the customer's wish list and any errors encountered. */
export interface RemoveProductsFromWishlistOutput {
  __typename?: 'RemoveProductsFromWishlistOutput'
  /** An array of errors encountered while deleting products from a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>
  /** Contains the wish list with after items were successfully deleted. */
  wishlist: Wishlist
}

/** Defines the tracking information to delete. */
export interface RemoveReturnTrackingInput {
  /** The unique ID for a `ReturnShippingTracking` object. */
  return_shipping_tracking_uid: Scalars['ID']['input']
}

/** Contains the response after deleting tracking information. */
export interface RemoveReturnTrackingOutput {
  __typename?: 'RemoveReturnTrackingOutput'
  /** Contains details about the modified return. */
  return: Maybe<Return>
}

/** Contains the customer cart. */
export interface RemoveRewardPointsFromCartOutput {
  __typename?: 'RemoveRewardPointsFromCartOutput'
  /** The customer cart after reward points are removed. */
  cart: Cart
}

/** Defines the input required to run the `removeStoreCreditFromCart` mutation. */
export interface RemoveStoreCreditFromCartInput {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String']['input']
}

/** Defines the possible output for the `removeStoreCreditFromCart` mutation. */
export interface RemoveStoreCreditFromCartOutput {
  __typename?: 'RemoveStoreCreditFromCartOutput'
  /** The contents of the specified shopping cart. */
  cart: Cart
}

/** Contains the cart and any errors after adding products. */
export interface ReorderItemsOutput {
  __typename?: 'ReorderItemsOutput'
  /** Detailed information about the customer's cart. */
  cart: Cart
  /** An array of reordering errors. */
  userInputErrors: Array<Maybe<CheckoutUserInputError>>
}

/** Defines properties of a negotiable quote request. */
export interface RequestNegotiableQuoteInput {
  /** The cart ID of the buyer requesting a new negotiable quote. */
  cart_id: Scalars['ID']['input']
  /** Comments the buyer entered to describe the request. */
  comment: NegotiableQuoteCommentInput
  /** The name the buyer assigned to the negotiable quote request. */
  quote_name: Scalars['String']['input']
}

/** Contains the `NegotiableQuote` object generated when a buyer requests a negotiable quote. */
export interface RequestNegotiableQuoteOutput {
  __typename?: 'RequestNegotiableQuoteOutput'
  /** Details about the negotiable quote. */
  quote: Maybe<NegotiableQuote>
}

/** Contains information needed to start a return request. */
export interface RequestReturnInput {
  /** Text the buyer entered that describes the reason for the refund request. */
  comment_text?: InputMaybe<Scalars['String']['input']>
  /** The email address the buyer enters to receive notifications about the status of the return. */
  contact_email?: InputMaybe<Scalars['String']['input']>
  /** An array of items to be returned. */
  items: Array<InputMaybe<RequestReturnItemInput>>
  /** The unique ID for a `Order` object. */
  order_uid: Scalars['ID']['input']
}

/** Contains details about an item to be returned. */
export interface RequestReturnItemInput {
  /** Details about a custom attribute that was entered. */
  entered_custom_attributes?: InputMaybe<
    Array<InputMaybe<EnteredCustomAttributeInput>>
  >
  /** The unique ID for a `OrderItemInterface` object. */
  order_item_uid: Scalars['ID']['input']
  /** The quantity of the item to be returned. */
  quantity_to_return: Scalars['Float']['input']
  /** An array of selected custom option IDs associated with the item to be returned. For example, the IDs for the selected color and size of a configurable product. */
  selected_custom_attributes?: InputMaybe<
    Array<InputMaybe<SelectedCustomAttributeInput>>
  >
}

/** Contains the response to a return request. */
export interface RequestReturnOutput {
  __typename?: 'RequestReturnOutput'
  /** Details about a single return request. */
  return: Maybe<Return>
  /** An array of return requests. */
  returns: Maybe<Returns>
}

/** Contains the response to a return request. */
export interface RequestReturnOutputReturnsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines the contents of a requisition list. */
export interface RequisitionList {
  __typename?: 'RequisitionList'
  /** Optional text that describes the requisition list. */
  description: Maybe<Scalars['String']['output']>
  /** An array of products added to the requisition list. */
  items: Maybe<RequistionListItems>
  /** The number of items in the list. */
  itemsCount: Scalars['Int']['output']
  /** The requisition list name. */
  name: Scalars['String']['output']
  /** The unique requisition list ID. */
  uid: Scalars['ID']['output']
  /** The time of the last modification of the requisition list. */
  updatedAt: Maybe<Scalars['String']['output']>
}

/** Defines the contents of a requisition list. */
export interface RequisitionListItemsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines requisition list filters. */
export interface RequisitionListFilterInput {
  /** Filter by the display name of the requisition list. */
  name?: InputMaybe<FilterMatchTypeInput>
  /** Filter requisition lists by one or more requisition list IDs. */
  uids?: InputMaybe<FilterEqualTypeInput>
}

/** The interface for requisition list items. */
export interface RequisitionListItemInterface {
  /** Selected custom options for an item in the requisition list. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** Details about a requisition list item. */
  product: ProductInterface
  /** The amount added. */
  quantity: Scalars['Float']['output']
  /** The unique ID for the requisition list item. */
  uid: Scalars['ID']['output']
}

/** Defines the items to add. */
export interface RequisitionListItemsInput {
  /** Entered option IDs. */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>
  /** For configurable products, the SKU of the parent product. */
  parent_sku?: InputMaybe<Scalars['String']['input']>
  /** The quantity of the product to add. */
  quantity?: InputMaybe<Scalars['Float']['input']>
  /** Selected option IDs. */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The product SKU. */
  sku: Scalars['String']['input']
}

/** Defines customer requisition lists. */
export interface RequisitionLists {
  __typename?: 'RequisitionLists'
  /** An array of requisition lists. */
  items: Maybe<Array<Maybe<RequisitionList>>>
  /** Pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The number of returned requisition lists. */
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Contains an array of items added to a requisition list. */
export interface RequistionListItems {
  __typename?: 'RequistionListItems'
  /** An array of items in the requisition list. */
  items: Array<Maybe<RequisitionListItemInterface>>
  /** Pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The number of pages returned. */
  totalPages: Scalars['Int']['output']
}

/** Contains details about a return. */
export interface Return {
  __typename?: 'Return'
  /** A list of shipping carriers available for returns. */
  availableShippingCarriers: Maybe<Array<Maybe<ReturnShippingCarrier>>>
  /** A list of comments posted for the return request. */
  comments: Maybe<Array<Maybe<ReturnComment>>>
  /** The date the return was requested. */
  createdAt: Scalars['String']['output']
  /** Data from the customer who created the return request. */
  customer: ReturnCustomer
  /** A list of items being returned. */
  items: Maybe<Array<Maybe<ReturnItem>>>
  /** A human-readable return number. */
  number: Scalars['String']['output']
  /** The order associated with the return. */
  order: Maybe<CustomerOrder>
  /** Shipping information for the return. */
  shipping: Maybe<ReturnShipping>
  /** The status of the return request. */
  status: Maybe<ReturnStatus>
  /** The unique ID for a `Return` object. */
  uid: Scalars['ID']['output']
}

/** Contains details about a return comment. */
export interface ReturnComment {
  __typename?: 'ReturnComment'
  /** The name or author who posted the comment. */
  authorName: Scalars['String']['output']
  /** The date and time the comment was posted. */
  createdAt: Scalars['String']['output']
  /** The contents of the comment. */
  text: Scalars['String']['output']
  /** The unique ID for a `ReturnComment` object. */
  uid: Scalars['ID']['output']
}

/** Contains details about a `ReturnCustomerAttribute` object. */
export interface ReturnCustomAttribute {
  __typename?: 'ReturnCustomAttribute'
  /** A description of the attribute. */
  label: Scalars['String']['output']
  /** The unique ID for a `ReturnCustomAttribute` object. */
  uid: Scalars['ID']['output']
  /** A JSON-encoded value of the attribute. */
  value: Scalars['String']['output']
}

/** The customer information for the return. */
export interface ReturnCustomer {
  __typename?: 'ReturnCustomer'
  /** The email address of the customer. */
  email: Scalars['String']['output']
  /** The first name of the customer. */
  firstname: Maybe<Scalars['String']['output']>
  /** The last name of the customer. */
  lastname: Maybe<Scalars['String']['output']>
}

/** Contains details about a product being returned. */
export interface ReturnItem {
  __typename?: 'ReturnItem'
  /**
   * Return item custom attributes that are visible on the storefront.
   * @deprecated Use custom_attributesV2 instead.
   */
  customAttributes: Maybe<Array<Maybe<ReturnCustomAttribute>>>
  /** Custom attributes that are visible on the storefront. */
  customAttributesV2: Maybe<Array<Maybe<AttributeValueInterface>>>
  /** Provides access to the product being returned, including information about selected and entered options. */
  orderItem: OrderItemInterface
  /** The quantity of the item the merchant authorized to be returned. */
  quantity: Scalars['Float']['output']
  /** The quantity of the item requested to be returned. */
  requestQuantity: Scalars['Float']['output']
  /** The return status of the item. */
  status: ReturnItemStatus
  /** The unique ID for a `ReturnItem` object. */
  uid: Scalars['ID']['output']
}

/** Return Item attribute metadata. */
export interface ReturnItemAttributeMetadata
  extends CustomAttributeMetadataInterface {
  __typename?: 'ReturnItemAttributeMetadata'
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  code: Scalars['ID']['output']
  /** Default attribute value. */
  defaultValue: Maybe<Scalars['String']['output']>
  /** The type of entity that defines the attribute. */
  entityType: AttributeEntityTypeEnum
  /** The frontend class of the attribute. */
  frontendClass: Maybe<Scalars['String']['output']>
  /** The frontend input type of the attribute. */
  frontendInput: Maybe<AttributeFrontendInputEnum>
  /** The template used for the input of the attribute (e.g., 'date'). */
  inputFilter: Maybe<InputFilterEnum>
  /** Whether the attribute value is required. */
  isRequired: Scalars['Boolean']['output']
  /** Whether the attribute value must be unique. */
  isUnique: Scalars['Boolean']['output']
  /** The label assigned to the attribute. */
  label: Maybe<Scalars['String']['output']>
  /** The number of lines of the attribute value. */
  multilineCount: Maybe<Scalars['Int']['output']>
  /** Attribute options. */
  options: Array<Maybe<CustomAttributeOptionInterface>>
  /** The position of the attribute in the form. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The validation rules of the attribute value. */
  validateRules: Maybe<Array<Maybe<ValidationRule>>>
}

export enum ReturnItemStatus {
  APPROVED = 'APPROVED',
  AUTHORIZED = 'AUTHORIZED',
  DENIED = 'DENIED',
  PENDING = 'PENDING',
  RECEIVED = 'RECEIVED',
  REJECTED = 'REJECTED',
}

/** Contains details about the return shipping address. */
export interface ReturnShipping {
  __typename?: 'ReturnShipping'
  /** The merchant-defined return shipping address. */
  address: Maybe<ReturnShippingAddress>
  /** The unique ID for a `ReturnShippingTracking` object. If a single UID is specified, the array contains a single tracking record. Otherwise, array contains all tracking information. */
  tracking: Maybe<Array<Maybe<ReturnShippingTracking>>>
}

/** Contains details about the return shipping address. */
export interface ReturnShippingTrackingArgs {
  uid: InputMaybe<Scalars['ID']['input']>
}

/** Contains details about the shipping address used for receiving returned items. */
export interface ReturnShippingAddress {
  __typename?: 'ReturnShippingAddress'
  /** The city for product returns. */
  city: Scalars['String']['output']
  /** The merchant's contact person. */
  contactName: Maybe<Scalars['String']['output']>
  /** An object that defines the country for product returns. */
  country: Country
  /** The postal code for product returns. */
  postcode: Scalars['String']['output']
  /** An object that defines the state or province for product returns. */
  region: Region
  /** The street address for product returns. */
  street: Array<Maybe<Scalars['String']['output']>>
  /** The telephone number for product returns. */
  telephone: Maybe<Scalars['String']['output']>
}

/** Contains details about the carrier on a return. */
export interface ReturnShippingCarrier {
  __typename?: 'ReturnShippingCarrier'
  /** A description of the shipping carrier. */
  label: Scalars['String']['output']
  /** The unique ID for a `ReturnShippingCarrier` object assigned to the shipping carrier. */
  uid: Scalars['ID']['output']
}

/** Contains shipping and tracking details. */
export interface ReturnShippingTracking {
  __typename?: 'ReturnShippingTracking'
  /** Contains details of a shipping carrier. */
  carrier: ReturnShippingCarrier
  /** Details about the status of a shipment. */
  status: Maybe<ReturnShippingTrackingStatus>
  /** A tracking number assigned by the carrier. */
  trackingNumber: Scalars['String']['output']
  /** The unique ID for a `ReturnShippingTracking` object assigned to the tracking item. */
  uid: Scalars['ID']['output']
}

/** Contains the status of a shipment. */
export interface ReturnShippingTrackingStatus {
  __typename?: 'ReturnShippingTrackingStatus'
  /** Text that describes the status. */
  text: Scalars['String']['output']
  /** Indicates whether the status type is informational or an error. */
  type: ReturnShippingTrackingStatusType
}

export enum ReturnShippingTrackingStatusType {
  ERROR = 'ERROR',
  INFORMATION = 'INFORMATION',
}

export enum ReturnStatus {
  APPROVED = 'APPROVED',
  AUTHORIZED = 'AUTHORIZED',
  CLOSED = 'CLOSED',
  DENIED = 'DENIED',
  PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
  PARTIALLY_AUTHORIZED = 'PARTIALLY_AUTHORIZED',
  PARTIALLY_RECEIVED = 'PARTIALLY_RECEIVED',
  PARTIALLY_REJECTED = 'PARTIALLY_REJECTED',
  PENDING = 'PENDING',
  PROCESSED_AND_CLOSED = 'PROCESSED_AND_CLOSED',
  RECEIVED = 'RECEIVED',
  REJECTED = 'REJECTED',
}

/** Contains a list of customer return requests. */
export interface Returns {
  __typename?: 'Returns'
  /** A list of return requests. */
  items: Maybe<Array<Maybe<Return>>>
  /** Pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The total number of return requests. */
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Contains the result of a request to revoke a customer token. */
export interface RevokeCustomerTokenOutput {
  __typename?: 'RevokeCustomerTokenOutput'
  /** The result of a request to revoke a customer token. */
  result: Scalars['Boolean']['output']
}

/** Contains details about a customer's reward points. */
export interface RewardPoints {
  __typename?: 'RewardPoints'
  /** The current balance of reward points. */
  balance: Maybe<RewardPointsAmount>
  /** The balance history of reward points. If the ability for customers to view the balance history has been disabled in the Admin, this field will be set to null. */
  balanceHistory: Maybe<Array<Maybe<RewardPointsBalanceHistoryItem>>>
  /** The current exchange rates for reward points. */
  exchangeRates: Maybe<RewardPointsExchangeRates>
  /** The subscription status of emails related to reward points. */
  subscriptionStatus: Maybe<RewardPointsSubscriptionStatus>
}

export interface RewardPointsAmount {
  __typename?: 'RewardPointsAmount'
  /** The reward points amount in store currency. */
  money: Money
  /** The reward points amount in points. */
  points: Scalars['Float']['output']
}

/** Contain details about the reward points transaction. */
export interface RewardPointsBalanceHistoryItem {
  __typename?: 'RewardPointsBalanceHistoryItem'
  /** The award points balance after the completion of the transaction. */
  balance: Maybe<RewardPointsAmount>
  /** The reason the balance changed. */
  changeReason: Scalars['String']['output']
  /** The date of the transaction. */
  date: Scalars['String']['output']
  /** The number of points added or deducted in the transaction. */
  pointsChange: Scalars['Float']['output']
}

/** Lists the reward points exchange rates. The values depend on the customer group. */
export interface RewardPointsExchangeRates {
  __typename?: 'RewardPointsExchangeRates'
  /** How many points are earned for a given amount spent. */
  earning: Maybe<RewardPointsRate>
  /** How many points must be redeemed to get a given amount of currency discount at the checkout. */
  redemption: Maybe<RewardPointsRate>
}

/** Contains details about customer's reward points rate. */
export interface RewardPointsRate {
  __typename?: 'RewardPointsRate'
  /** The money value for the exchange rate. For earnings, this is the amount spent to earn the specified points. For redemption, this is the amount of money the number of points represents. */
  currencyAmount: Scalars['Float']['output']
  /** The number of points for an exchange rate. For earnings, this is the number of points earned. For redemption, this is the number of points needed for redemption. */
  points: Scalars['Float']['output']
}

/** Indicates whether the customer subscribes to reward points emails. */
export interface RewardPointsSubscriptionStatus {
  __typename?: 'RewardPointsSubscriptionStatus'
  /** Indicates whether the customer subscribes to 'Reward points balance updates' emails. */
  balanceUpdates: RewardPointsSubscriptionStatusesEnum
  /** Indicates whether the customer subscribes to 'Reward points expiration notifications' emails. */
  pointsExpirationNotifications: RewardPointsSubscriptionStatusesEnum
}

export enum RewardPointsSubscriptionStatusesEnum {
  NOT_SUBSCRIBED = 'NOT_SUBSCRIBED',
  SUBSCRIBED = 'SUBSCRIBED',
}

/** Routable entities serve as the model for a rendered page. */
export interface RoutableInterface {
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
}

/** Default implementation of RoutableInterface. This type is returned when the URL is not linked to an entity. */
export interface RoutableUrl extends RoutableInterface {
  __typename?: 'RoutableUrl'
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
}

/** Contains details about a comment. */
export interface SalesCommentItem {
  __typename?: 'SalesCommentItem'
  /** The text of the message. */
  message: Scalars['String']['output']
  /** The timestamp of the comment. */
  timestamp: Scalars['String']['output']
}

export interface SalesItemInterface {
  __typename?: 'SalesItemInterface'
  /** The entered gift message for the order item */
  giftMessage: Maybe<GiftMessage>
}

/** This enumeration defines the scope type for customer orders. */
export enum ScopeTypeEnum {
  GLOBAL = 'GLOBAL',
  STORE = 'STORE',
  WEBSITE = 'WEBSITE',
}

/** Defines the name and value of a SDK parameter */
export interface SdkParams {
  __typename?: 'SdkParams'
  /** The name of the SDK parameter */
  name: Maybe<Scalars['String']['output']>
  /** The value of the SDK parameter */
  value: Maybe<Scalars['String']['output']>
}

/** Provides navigation for the query response. */
export interface SearchResultPageInfo {
  __typename?: 'SearchResultPageInfo'
  /** The specific page to return. */
  currentPage: Maybe<Scalars['Int']['output']>
  /** The maximum number of items to return per page of results. */
  pageSize: Maybe<Scalars['Int']['output']>
  /** The total number of pages in the response. */
  totalPages: Maybe<Scalars['Int']['output']>
}

/** A string that contains search suggestion */
export interface SearchSuggestion {
  __typename?: 'SearchSuggestion'
  /** The search suggestion of existing product. */
  search: Scalars['String']['output']
}

/** Contains details about a selected bundle option. */
export interface SelectedBundleOption {
  __typename?: 'SelectedBundleOption'
  /** @deprecated Use `uid` instead */
  id: Scalars['Int']['output']
  /** The display name of the selected bundle product option. */
  label: Scalars['String']['output']
  /** The type of selected bundle product option. */
  type: Scalars['String']['output']
  /** The unique ID for a `SelectedBundleOption` object */
  uid: Scalars['ID']['output']
  /** An array of selected bundle option values. */
  values: Array<Maybe<SelectedBundleOptionValue>>
}

/** Contains details about a value for a selected bundle option. */
export interface SelectedBundleOptionValue {
  __typename?: 'SelectedBundleOptionValue'
  /** Use `uid` instead */
  id: Scalars['Int']['output']
  /** The display name of the value for the selected bundle product option. */
  label: Scalars['String']['output']
  /** The price of the value for the selected bundle product option. */
  price: Scalars['Float']['output']
  /** The quantity of the value for the selected bundle product option. */
  quantity: Scalars['Float']['output']
  /** The unique ID for a `SelectedBundleOptionValue` object */
  uid: Scalars['ID']['output']
}

/** Contains details about a selected configurable option. */
export interface SelectedConfigurableOption {
  __typename?: 'SelectedConfigurableOption'
  /** The unique ID for a `ConfigurableProductOptions` object. */
  configurableProductOptionUid: Scalars['ID']['output']
  /** The unique ID for a `ConfigurableProductOptionsValues` object. */
  configurableProductOptionValueUid: Scalars['ID']['output']
  /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_uid` instead. */
  id: Scalars['Int']['output']
  /** The display text for the option. */
  optionLabel: Scalars['String']['output']
  /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_value_uid` instead. */
  valueId: Scalars['Int']['output']
  /** The display name of the selected configurable option. */
  valueLabel: Scalars['String']['output']
}

/** Contains details about an attribute the buyer selected. */
export interface SelectedCustomAttributeInput {
  /** A string that identifies the selected attribute. */
  attribute_code: Scalars['String']['input']
  /** The unique ID for a `CustomAttribute` object of a selected custom attribute. */
  value: Scalars['ID']['input']
}

/** Identifies a customized product that has been placed in a cart. */
export interface SelectedCustomizableOption {
  __typename?: 'SelectedCustomizableOption'
  /** The unique ID for a specific `CustomizableOptionInterface` object, such as a `CustomizableFieldOption`, `CustomizableFileOption`, or `CustomizableAreaOption` object. */
  customizableOptionUid: Scalars['ID']['output']
  /** @deprecated Use `SelectedCustomizableOption.customizable_option_uid` instead. */
  id: Scalars['Int']['output']
  /** Indicates whether the customizable option is required. */
  isRequired: Scalars['Boolean']['output']
  /** The display name of the selected customizable option. */
  label: Scalars['String']['output']
  /** A value indicating the order to display this option. */
  sortOrder: Scalars['Int']['output']
  /** The type of `CustomizableOptionInterface` object. */
  type: Scalars['String']['output']
  /** An array of selectable values. */
  values: Array<Maybe<SelectedCustomizableOptionValue>>
}

/** Identifies the value of the selected customized option. */
export interface SelectedCustomizableOptionValue {
  __typename?: 'SelectedCustomizableOptionValue'
  /** The unique ID for a value object that corresponds to the object represented by the `customizable_option_uid` attribute. */
  customizableOptionValueUid: Scalars['ID']['output']
  /** @deprecated Use `SelectedCustomizableOptionValue.customizable_option_value_uid` instead. */
  id: Scalars['Int']['output']
  /** The display name of the selected value. */
  label: Scalars['String']['output']
  /** The price of the selected customizable value. */
  price: CartItemSelectedOptionValuePrice
  /** The text identifying the selected value. */
  value: Scalars['String']['output']
}

/** Describes the payment method the shopper selected. */
export interface SelectedPaymentMethod {
  __typename?: 'SelectedPaymentMethod'
  /** The payment method code. */
  code: Scalars['String']['output']
  /** Retrieve meta information for this payment method (image) */
  mollieMeta: MolliePaymentMethodMeta
  /** The purchase order number. */
  purchaseOrderNumber: Maybe<Scalars['String']['output']>
  /** The payment method title. */
  title: Scalars['String']['output']
}

/** Contains details about the selected shipping method and carrier. */
export interface SelectedShippingMethod {
  __typename?: 'SelectedShippingMethod'
  /** The cost of shipping using this shipping method. */
  amount: Money
  /** @deprecated The field should not be used on the storefront. */
  baseAmount: Maybe<Money>
  /** A string that identifies a commercial carrier or an offline shipping method. */
  carrierCode: Scalars['String']['output']
  /** The label for the carrier code. */
  carrierTitle: Scalars['String']['output']
  /** A shipping method code associated with a carrier. */
  methodCode: Scalars['String']['output']
  /** The label for the method code. */
  methodTitle: Scalars['String']['output']
  /** The cost of shipping using this shipping method, excluding tax. */
  priceExclTax: Money
  /** The cost of shipping using this shipping method, including tax. */
  priceInclTax: Money
}

/** Defines the referenced product and the email sender and recipients. */
export interface SendEmailToFriendInput {
  /** The ID of the product that the sender is referencing. */
  product_id: Scalars['Int']['input']
  /** An array containing information about each recipient. */
  recipients: Array<InputMaybe<SendEmailToFriendRecipientInput>>
  /** Information about the customer and the content of the message. */
  sender: SendEmailToFriendSenderInput
}

/** Contains information about the sender and recipients. */
export interface SendEmailToFriendOutput {
  __typename?: 'SendEmailToFriendOutput'
  /** An array containing information about each recipient. */
  recipients: Maybe<Array<Maybe<SendEmailToFriendRecipient>>>
  /** Information about the customer and the content of the message. */
  sender: Maybe<SendEmailToFriendSender>
}

/** An output object that contains information about the recipient. */
export interface SendEmailToFriendRecipient {
  __typename?: 'SendEmailToFriendRecipient'
  /** The email address of the recipient. */
  email: Scalars['String']['output']
  /** The name of the recipient. */
  name: Scalars['String']['output']
}

/** Contains details about a recipient. */
export interface SendEmailToFriendRecipientInput {
  /** The email address of the recipient. */
  email: Scalars['String']['input']
  /** The name of the recipient. */
  name: Scalars['String']['input']
}

/** An output object that contains information about the sender. */
export interface SendEmailToFriendSender {
  __typename?: 'SendEmailToFriendSender'
  /** The email address of the sender. */
  email: Scalars['String']['output']
  /** The text of the message to be sent. */
  message: Scalars['String']['output']
  /** The name of the sender. */
  name: Scalars['String']['output']
}

/** Contains details about the sender. */
export interface SendEmailToFriendSenderInput {
  /** The email address of the sender. */
  email: Scalars['String']['input']
  /** The text of the message to be sent. */
  message: Scalars['String']['input']
  /** The name of the sender. */
  name: Scalars['String']['input']
}

/** Contains details about the configuration of the Email to a Friend feature. */
export interface SendFriendConfiguration {
  __typename?: 'SendFriendConfiguration'
  /** Indicates whether the Email to a Friend feature is enabled. */
  enabledForCustomers: Scalars['Boolean']['output']
  /** Indicates whether the Email to a Friend feature is enabled for guests. */
  enabledForGuests: Scalars['Boolean']['output']
}

/** Specifies which negotiable quote to send for review. */
export interface SendNegotiableQuoteForReviewInput {
  /** A comment for the seller to review. */
  comment?: InputMaybe<NegotiableQuoteCommentInput>
  /** The unique ID of a `NegotiableQuote` object. */
  quote_uid: Scalars['ID']['input']
}

/** Contains the negotiable quote. */
export interface SendNegotiableQuoteForReviewOutput {
  __typename?: 'SendNegotiableQuoteForReviewOutput'
  /** The negotiable quote after sending for seller review. */
  quote: Maybe<NegotiableQuote>
}

/** Sets the billing address. */
export interface SetBillingAddressOnCartInput {
  /** The billing address. */
  billing_address: BillingAddressInput
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
}

/** Contains details about the cart after setting the billing address. */
export interface SetBillingAddressOnCartOutput {
  __typename?: 'SetBillingAddressOnCartOutput'
  /** The cart after setting the billing address. */
  cart: Cart
}

export interface SetDeliveryDataOnCartInput {
  cart_id: Scalars['String']['input']
  delivery_date: Scalars['String']['input']
  delivery_time: Scalars['String']['input']
}

export interface SetDeliveryDataOnCartOutput {
  __typename?: 'SetDeliveryDataOnCartOutput'
  cart: Cart
}

export interface SetForkliftAvailableOnCartInput {
  cart_id: Scalars['String']['input']
  forklift_available: Scalars['Boolean']['input']
}

export interface SetForkliftAvailableOnCartOutput {
  __typename?: 'SetForkliftAvailableOnCartOutput'
  cart: Cart
}

/** Defines the gift options applied to the cart. */
export interface SetGiftOptionsOnCartInput {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String']['input']
  /** Gift message details for the cart. */
  gift_message?: InputMaybe<GiftMessageInput>
  /** Whether customer requested gift receipt for the cart. */
  gift_receipt_included: Scalars['Boolean']['input']
  /** The unique ID for a `GiftWrapping` object to be used for the cart. */
  gift_wrapping_id?: InputMaybe<Scalars['ID']['input']>
  /** Whether customer requested printed card for the cart. */
  printed_card_included: Scalars['Boolean']['input']
}

/** Contains the cart after gift options have been applied. */
export interface SetGiftOptionsOnCartOutput {
  __typename?: 'SetGiftOptionsOnCartOutput'
  /** The modified cart object. */
  cart: Cart
}

/** Defines the guest email and cart. */
export interface SetGuestEmailOnCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** The email address of the guest. */
  email: Scalars['String']['input']
}

/** Contains details about the cart after setting the email of a guest. */
export interface SetGuestEmailOnCartOutput {
  __typename?: 'SetGuestEmailOnCartOutput'
  /** The cart after setting the guest email. */
  cart: Cart
}

/** Contains the updated negotiable quote. */
export interface SetLineItemNoteOutput {
  __typename?: 'SetLineItemNoteOutput'
  /** The negotiable quote after sending for seller review. */
  quote: Maybe<NegotiableQuote>
}

/** Sets the billing address. */
export interface SetNegotiableQuoteBillingAddressInput {
  /** The billing address to be added. */
  billing_address: NegotiableQuoteBillingAddressInput
  /** The unique ID of a `NegotiableQuote` object. */
  quote_uid: Scalars['ID']['input']
}

/** Contains the negotiable quote. */
export interface SetNegotiableQuoteBillingAddressOutput {
  __typename?: 'SetNegotiableQuoteBillingAddressOutput'
  /** The negotiable quote after assigning a billing address. */
  quote: Maybe<NegotiableQuote>
}

/** Defines the payment method of the specified negotiable quote. */
export interface SetNegotiableQuotePaymentMethodInput {
  /** The payment method to be assigned to the negotiable quote. */
  payment_method: NegotiableQuotePaymentMethodInput
  /** The unique ID of a `NegotiableQuote` object. */
  quote_uid: Scalars['ID']['input']
}

/** Contains details about the negotiable quote after setting the payment method. */
export interface SetNegotiableQuotePaymentMethodOutput {
  __typename?: 'SetNegotiableQuotePaymentMethodOutput'
  /** The updated negotiable quote. */
  quote: Maybe<NegotiableQuote>
}

/** Defines the shipping address to assign to the negotiable quote. */
export interface SetNegotiableQuoteShippingAddressInput {
  /** The unique ID of a `CustomerAddress` object. */
  customer_address_id?: InputMaybe<Scalars['ID']['input']>
  /** The unique ID of a `NegotiableQuote` object. */
  quote_uid: Scalars['ID']['input']
  /** An array of shipping addresses to apply to the negotiable quote. */
  shipping_addresses?: InputMaybe<
    Array<InputMaybe<NegotiableQuoteShippingAddressInput>>
  >
}

/** Contains the negotiable quote. */
export interface SetNegotiableQuoteShippingAddressOutput {
  __typename?: 'SetNegotiableQuoteShippingAddressOutput'
  /** The negotiable quote after assigning a shipping address. */
  quote: Maybe<NegotiableQuote>
}

/** Defines the shipping method to apply to the negotiable quote. */
export interface SetNegotiableQuoteShippingMethodsInput {
  /** The unique ID of a `NegotiableQuote` object. */
  quote_uid: Scalars['ID']['input']
  /** An array of shipping methods to apply to the negotiable quote. */
  shipping_methods: Array<InputMaybe<ShippingMethodInput>>
}

/** Contains the negotiable quote. */
export interface SetNegotiableQuoteShippingMethodsOutput {
  __typename?: 'SetNegotiableQuoteShippingMethodsOutput'
  /** The negotiable quote after applying shipping methods. */
  quote: Maybe<NegotiableQuote>
}

/** Applies a payment method to the quote. */
export interface SetPaymentMethodAndPlaceOrderInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** The payment method data to apply to the cart. */
  payment_method: PaymentMethodInput
}

/** Applies a payment method to the cart. */
export interface SetPaymentMethodOnCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** The payment method data to apply to the cart. */
  payment_method: PaymentMethodInput
}

/** Contains details about the cart after setting the payment method. */
export interface SetPaymentMethodOnCartOutput {
  __typename?: 'SetPaymentMethodOnCartOutput'
  /** The cart after setting the payment method. */
  cart: Cart
}

export interface SetReferenceOnCartInput {
  cart_id: Scalars['String']['input']
  reference: Scalars['String']['input']
}

export interface SetReferenceOnCartOutput {
  __typename?: 'SetReferenceOnCartOutput'
  cart: Cart
}

/** Specifies an array of addresses to use for shipping. */
export interface SetShippingAddressesOnCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** An array of shipping addresses. */
  shipping_addresses: Array<InputMaybe<ShippingAddressInput>>
}

/** Contains details about the cart after setting the shipping addresses. */
export interface SetShippingAddressesOnCartOutput {
  __typename?: 'SetShippingAddressesOnCartOutput'
  /** The cart after setting the shipping addresses. */
  cart: Cart
}

/** Applies one or shipping methods to the cart. */
export interface SetShippingMethodsOnCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** An array of shipping methods. */
  shipping_methods: Array<InputMaybe<ShippingMethodInput>>
}

/** Contains details about the cart after setting the shipping methods. */
export interface SetShippingMethodsOnCartOutput {
  __typename?: 'SetShippingMethodsOnCartOutput'
  /** The cart after setting the shipping methods. */
  cart: Cart
}

/** Defines a gift registry invitee. */
export interface ShareGiftRegistryInviteeInput {
  /** The email address of the gift registry invitee. */
  email: Scalars['String']['input']
  /** The name of the gift registry invitee. */
  name: Scalars['String']['input']
}

/** Contains the results of a request to share a gift registry. */
export interface ShareGiftRegistryOutput {
  __typename?: 'ShareGiftRegistryOutput'
  /** Indicates whether the gift registry was successfully shared. */
  isShared: Scalars['Boolean']['output']
}

/** Defines the sender of an invitation to view a gift registry. */
export interface ShareGiftRegistrySenderInput {
  /** A brief message from the sender. */
  message: Scalars['String']['input']
  /** The sender of the gift registry invitation. */
  name: Scalars['String']['input']
}

/** Defines whether bundle items must be shipped together. */
export enum ShipBundleItemsEnum {
  SEPARATELY = 'SEPARATELY',
  TOGETHER = 'TOGETHER',
}

export interface ShipmentItem extends ShipmentItemInterface {
  __typename?: 'ShipmentItem'
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item associated with the shipment item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of shipped items. */
  quantityShipped: Scalars['Float']['output']
}

/** Order shipment item details. */
export interface ShipmentItemInterface {
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item associated with the shipment item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of shipped items. */
  quantityShipped: Scalars['Float']['output']
}

/** Contains order shipment tracking details. */
export interface ShipmentTracking {
  __typename?: 'ShipmentTracking'
  /** The shipping carrier for the order delivery. */
  carrier: Scalars['String']['output']
  /** The tracking number of the order shipment. */
  number: Maybe<Scalars['String']['output']>
  /** The shipment tracking title. */
  title: Scalars['String']['output']
}

/** Defines a single shipping address. */
export interface ShippingAddressInput {
  /** Defines a shipping address. */
  address?: InputMaybe<CartAddressInput>
  /** An ID from the customer's address book that uniquely identifies the address to be used for shipping. */
  customer_address_id?: InputMaybe<Scalars['Int']['input']>
  /** Text provided by the shopper. */
  customer_notes?: InputMaybe<Scalars['String']['input']>
}

/** Contains shipping addresses and methods. */
export interface ShippingCartAddress extends CartAddressInterface {
  __typename?: 'ShippingCartAddress'
  /** An array that lists the shipping methods that can be applied to the cart. */
  availableShippingMethods: Maybe<Array<Maybe<AvailableShippingMethod>>>
  /** @deprecated Use `cart_items_v2` instead. */
  cartItems: Maybe<Array<Maybe<CartItemQuantity>>>
  /** An array that lists the items in the cart. */
  cartItemsV2: Maybe<Array<Maybe<CartItemInterface>>>
  /** The city specified for the billing or shipping address. */
  city: Scalars['String']['output']
  /** The company specified for the billing or shipping address. */
  company: Maybe<Scalars['String']['output']>
  /** An object containing the country label and code. */
  country: CartAddressCountry
  /** The custom attribute values of the billing or shipping address. */
  customAttributes: Array<Maybe<AttributeValueInterface>>
  /** Text provided by the shopper. */
  customerNotes: Maybe<Scalars['String']['output']>
  /** The customer's fax number. */
  fax: Maybe<Scalars['String']['output']>
  /** The first name of the customer or guest. */
  firstname: Scalars['String']['output']
  /** @deprecated This information should not be exposed on the frontend. */
  itemsWeight: Maybe<Scalars['Float']['output']>
  /** The last name of the customer or guest. */
  lastname: Scalars['String']['output']
  /** The middle name of the person associated with the billing/shipping address. */
  middlename: Maybe<Scalars['String']['output']>
  /** The ZIP or postal code of the billing or shipping address. */
  postcode: Maybe<Scalars['String']['output']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']['output']>
  /** An object containing the region label and code. */
  region: Maybe<CartAddressRegion>
  /** An object that describes the selected shipping method. */
  selectedShippingMethod: Maybe<SelectedShippingMethod>
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']['output']>>
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']['output']>
  /** The telephone number for the billing or shipping address. */
  telephone: Maybe<Scalars['String']['output']>
  /** The unique id of the customer address. */
  uid: Scalars['String']['output']
  /** The VAT company number for billing or shipping address. */
  vatId: Maybe<Scalars['String']['output']>
}

/** Defines an individual shipping discount. This discount can be applied to shipping. */
export interface ShippingDiscount {
  __typename?: 'ShippingDiscount'
  /** The amount of the discount. */
  amount: Money
}

/** Contains details about shipping and handling costs. */
export interface ShippingHandling {
  __typename?: 'ShippingHandling'
  /** The shipping amount, excluding tax. */
  amountExcludingTax: Maybe<Money>
  /** The shipping amount, including tax. */
  amountIncludingTax: Maybe<Money>
  /** The applied discounts to the shipping. */
  discounts: Maybe<Array<Maybe<ShippingDiscount>>>
  /** Details about taxes applied for shipping. */
  taxes: Maybe<Array<Maybe<TaxItem>>>
  /** The total amount for shipping. */
  totalAmount: Money
}

/** Defines the shipping carrier and method. */
export interface ShippingMethodInput {
  /** A string that identifies a commercial carrier or an offline delivery method. */
  carrier_code: Scalars['String']['input']
  /** A string that indicates which service a commercial carrier will use to ship items. For offline delivery methods, this value is similar to the label displayed on the checkout page. */
  method_code: Scalars['String']['input']
}

/** An implementation for simple product cart items. */
export interface SimpleCartItem extends CartItemInterface {
  __typename?: 'SimpleCartItem'
  /** The list of available gift wrapping options for the cart item. */
  availableGiftWrapping: Array<Maybe<GiftWrapping>>
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** Delivery date of the cart item (Y-m-d) */
  deliveryDate: Maybe<Scalars['String']['output']>
  /** Contains discount for quote line item. */
  discount: Maybe<Array<Maybe<Discount>>>
  /** Elektramat configuration packslip */
  elektramatPackslip: Maybe<Scalars['String']['output']>
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>
  /** The entered gift message for the cart item */
  giftMessage: Maybe<GiftMessage>
  /** The selected gift wrapping for the cart item. */
  giftWrapping: Maybe<GiftWrapping>
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output']
  /** True if requested quantity is less than available stock, false otherwise. */
  isAvailable: Scalars['Boolean']['output']
  /** The buyer's quote line item note. */
  noteFromBuyer: Maybe<Array<Maybe<ItemNote>>>
  /** The seller's quote line item note. */
  noteFromSeller: Maybe<Array<Maybe<ItemNote>>>
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>
  /** Details about an item in the cart. */
  product: ProductInterface
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output']
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output']
}

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export interface SimpleProduct
  extends CustomizableProductInterface,
    PhysicalProductInterface,
    ProductInterface,
    RoutableInterface {
  __typename?: 'SimpleProduct'
  /** @deprecated Use the `custom_attributes` field instead. */
  articleNumber: Maybe<Scalars['String']['output']>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  color: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  costPrice: Maybe<Scalars['String']['output']>
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** Product custom attributes. */
  customAttributesV2: Maybe<ProductCustomAttributes>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']['output']>
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>
  /** @deprecated Use the `custom_attributes` field instead. */
  isClonedForStore: Maybe<Scalars['String']['output']>
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']['output']>
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  manufacturerId: Maybe<Scalars['String']['output']>
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['String']['output']>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minimumQuantityAllowed: Maybe<Scalars['String']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  phasedOut: Maybe<Scalars['String']['output']>
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  productgroep: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  quantityIncrements: Maybe<Scalars['String']['output']>
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float']['output']
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalCrossection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDegreeofprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalEarthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalIdentificationcores: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalInnerdepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductorcrosssection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductordiameter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalinsulationthickness: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofcores: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofmodules: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofpoles: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofrows: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalOuterdiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalRatedvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalTrippingcharacteristic: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWithattachmentoption: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBundlesperpallet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingCabletype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingDiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingFireretardancy: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorBoxquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorCablediameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorSolarpanelcompatibility: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerConnector: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerPayload: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerRfidcardreader: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerSuitableforoutdoormounting: Maybe<
    Scalars['Int']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWirelessconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemRoofpitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWindstandard: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMaxsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMinsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringCommunicationtype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSeriecompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSignalrange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDaktype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHeightatthetop: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHoogtebovenzijde: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalPaletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperbox: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperpalet: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalRooftype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcdcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAfci: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerCompatiblestorage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcswitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerInvertertype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximuminputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMicropanelpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMinearthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMonitoringoptions: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMppvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerNumberofmpptrackers: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerPaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperbox: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperpalet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerRatedacpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerSolarpanels: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStartupvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStringspermpptracker: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerUsablebatterycapacity: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemBatvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCommunicationport: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxbatcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxoutputpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemOutputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemStoragecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemUsablecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBacksheetcolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBuild: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCelltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFramecolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelGuaranteedpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelJunctionboxprotectiondegree: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelMaxsystemvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelModuleefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelNumberofcells: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletsize: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPanelspercontainer: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPowerguarantee: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelSolarpaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelThickness: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWattagepersquaremeter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWindandsnowload: Maybe<Scalars['String']['output']>
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean']['output']
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  usp: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  voorraadstatus: Maybe<Scalars['String']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>
}

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export interface SimpleProductCustomAttributesV2Args {
  filters: InputMaybe<AttributeFilterInput>
}

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export interface SimpleProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines a single product to add to the cart. */
export interface SimpleProductCartItemInput {
  /** An array that defines customizable options for the product. */
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
  data: CartItemInput
}

/** Contains details about simple products added to a requisition list. */
export interface SimpleRequisitionListItem
  extends RequisitionListItemInterface {
  __typename?: 'SimpleRequisitionListItem'
  /** Selected custom options for an item in the requisition list. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** Details about a requisition list item. */
  product: ProductInterface
  /** The amount added. */
  quantity: Scalars['Float']['output']
  /** The unique ID for the requisition list item. */
  uid: Scalars['ID']['output']
}

/** Contains a simple product wish list item. */
export interface SimpleWishlistItem extends WishlistItemInterface {
  __typename?: 'SimpleWishlistItem'
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output']
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output']
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output']
}

/** Smart button payment inputs */
export interface SmartButtonMethodInput {
  /** The payment source for the payment method */
  payment_source?: InputMaybe<Scalars['String']['input']>
  /** The payment services order ID */
  payments_order_id?: InputMaybe<Scalars['String']['input']>
  /** PayPal order ID */
  paypal_order_id?: InputMaybe<Scalars['String']['input']>
}

export interface SmartButtonsConfig extends PaymentConfigItem {
  __typename?: 'SmartButtonsConfig'
  /** The styles for the PayPal Smart Button configuration */
  buttonStyles: Maybe<ButtonStyles>
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>
  /** Indicates whether to display the PayPal Pay Later message */
  displayMessage: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether to display Venmo */
  displayVenmo: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>
  /** Contains details about the styles for the PayPal Pay Later message */
  messageStyles: Maybe<MessageStyles>
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>
}

/** Indicates whether to return results in ascending or descending order. */
export enum SortEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

/** Defines a possible sort field. */
export interface SortField {
  __typename?: 'SortField'
  /** The label of the sort field. */
  label: Maybe<Scalars['String']['output']>
  /** The attribute code of the sort field. */
  value: Maybe<Scalars['String']['output']>
}

/** Contains a default value for sort fields and all available sort fields. */
export interface SortFields {
  __typename?: 'SortFields'
  /** The default sort field value. */
  default: Maybe<Scalars['String']['output']>
  /** An array of possible sort fields. */
  options: Maybe<Array<Maybe<SortField>>>
}

/** Specifies the field to use for sorting quote items */
export enum SortQuoteItemsEnum {
  BASE_DISCOUNT_AMOUNT = 'BASE_DISCOUNT_AMOUNT',
  BASE_DISCOUNT_TAX_COMPENSATION_AMOUNT = 'BASE_DISCOUNT_TAX_COMPENSATION_AMOUNT',
  BASE_PRICE = 'BASE_PRICE',
  BASE_PRICE_INC_TAX = 'BASE_PRICE_INC_TAX',
  BASE_ROW_TOTAL = 'BASE_ROW_TOTAL',
  BASE_ROW_TOTAL_INC_TAX = 'BASE_ROW_TOTAL_INC_TAX',
  BASE_TAX_AMOUNT = 'BASE_TAX_AMOUNT',
  BASE_TAX_BEFORE_DISCOUNT = 'BASE_TAX_BEFORE_DISCOUNT',
  CREATED_AT = 'CREATED_AT',
  CUSTOM_PRICE = 'CUSTOM_PRICE',
  DESCRIPTION = 'DESCRIPTION',
  DISCOUNT_AMOUNT = 'DISCOUNT_AMOUNT',
  DISCOUNT_PERCENT = 'DISCOUNT_PERCENT',
  DISCOUNT_TAX_COMPENSATION_AMOUNT = 'DISCOUNT_TAX_COMPENSATION_AMOUNT',
  FREE_SHIPPING = 'FREE_SHIPPING',
  ITEM_ID = 'ITEM_ID',
  NAME = 'NAME',
  ORIGINAL_CUSTOM_PRICE = 'ORIGINAL_CUSTOM_PRICE',
  PRICE = 'PRICE',
  PRICE_INC_TAX = 'PRICE_INC_TAX',
  PRODUCT_ID = 'PRODUCT_ID',
  PRODUCT_TYPE = 'PRODUCT_TYPE',
  QTY = 'QTY',
  ROW_TOTAL = 'ROW_TOTAL',
  ROW_TOTAL_INC_TAX = 'ROW_TOTAL_INC_TAX',
  ROW_TOTAL_WITH_DISCOUNT = 'ROW_TOTAL_WITH_DISCOUNT',
  ROW_WEIGHT = 'ROW_WEIGHT',
  SKU = 'SKU',
  TAX_AMOUNT = 'TAX_AMOUNT',
  TAX_BEFORE_DISCOUNT = 'TAX_BEFORE_DISCOUNT',
  TAX_PERCENT = 'TAX_PERCENT',
  UPDATED_AT = 'UPDATED_AT',
  WEIGHT = 'WEIGHT',
}

/** Contains information about a store's configuration. */
export interface StoreConfig {
  __typename?: 'StoreConfig'
  /** Contains scripts that must be included in the HTML before the closing `<body>` tag. */
  absoluteFooter: Maybe<Scalars['String']['output']>
  /** Indicates if the gift sender has the option to send a gift receipt. Possible values: 1 (Yes) and 0 (No). */
  allowGiftReceipt: Maybe<Scalars['String']['output']>
  /** Indicates whether gift wrapping can be added for the entire order. Possible values: 1 (Yes) and 0 (No). */
  allowGiftWrappingOnOrder: Maybe<Scalars['String']['output']>
  /** Indicates whether gift wrapping can be added for individual order items. Possible values: 1 (Yes) and 0 (No). */
  allowGiftWrappingOnOrderItems: Maybe<Scalars['String']['output']>
  /** Indicates whether guest users can write product reviews. Possible values: 1 (Yes) and 0 (No). */
  allowGuestsToWriteProductReviews: Maybe<Scalars['String']['output']>
  /** The value of the Allow Gift Messages for Order Items option */
  allowItems: Maybe<Scalars['String']['output']>
  /** The value of the Allow Gift Messages on Order Level option */
  allowOrder: Maybe<Scalars['String']['output']>
  /** Indicates if a printed card can accompany an order. Possible values: 1 (Yes) and 0 (No). */
  allowPrintedCard: Maybe<Scalars['String']['output']>
  /** Indicates whether to enable autocomplete on login and forgot password forms. */
  autocompleteOnStorefront: Maybe<Scalars['Boolean']['output']>
  /** The base currency code. */
  baseCurrencyCode: Maybe<Scalars['String']['output']>
  /** A fully-qualified URL that is used to create relative links to the `base_url`. */
  baseLinkUrl: Maybe<Scalars['String']['output']>
  /** The fully-qualified URL that specifies the location of media files. */
  baseMediaUrl: Maybe<Scalars['String']['output']>
  /** The fully-qualified URL that specifies the location of static view files. */
  baseStaticUrl: Maybe<Scalars['String']['output']>
  /** The store’s fully-qualified base URL. */
  baseUrl: Maybe<Scalars['String']['output']>
  /** Extended Config Data - checkout/cart/delete_quote_after */
  cartExpiresInDays: Maybe<Scalars['Int']['output']>
  /** Indicates if gift wrapping prices are displayed on the Shopping Cart page. Possible values: 1 (Yes) and 0 (No). */
  cartGiftWrapping: Maybe<Scalars['String']['output']>
  /** Indicates if printed card prices are displayed on the Shopping Cart page. Possible values: 1 (Yes) and 0 (No). */
  cartPrintedCard: Maybe<Scalars['String']['output']>
  /** Extended Config Data - checkout/cart_link/use_qty */
  cartSummaryDisplayQuantity: Maybe<Scalars['Int']['output']>
  /** The default sort order of the search results list. */
  catalogDefaultSortBy: Maybe<Scalars['String']['output']>
  /** Corresponds to the 'Display Prices In Product Lists' field in the Admin. It indicates how FPT information is displayed on category pages. */
  categoryFixedProductTaxDisplaySetting: Maybe<FixedProductTaxDisplaySettings>
  /** The suffix applied to category pages, such as `.htm` or `.html`. */
  categoryUrlSuffix: Maybe<Scalars['String']['output']>
  /** Indicates whether only specific countries can use this payment method. */
  checkMoneyOrderEnableForSpecificCountries: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the Check/Money Order payment method is enabled. */
  checkMoneyOrderEnabled: Maybe<Scalars['Boolean']['output']>
  /** The name of the party to whom the check must be payable. */
  checkMoneyOrderMakeCheckPayableTo: Maybe<Scalars['String']['output']>
  /** The maximum order amount required to qualify for the Check/Money Order payment method. */
  checkMoneyOrderMaxOrderTotal: Maybe<Scalars['String']['output']>
  /** The minimum order amount required to qualify for the Check/Money Order payment method. */
  checkMoneyOrderMinOrderTotal: Maybe<Scalars['String']['output']>
  /** The status of new orders placed using the Check/Money Order payment method. */
  checkMoneyOrderNewOrderStatus: Maybe<Scalars['String']['output']>
  /** A comma-separated list of specific countries allowed to use the Check/Money Order payment method. */
  checkMoneyOrderPaymentFromSpecificCountries: Maybe<
    Scalars['String']['output']
  >
  /** The full street address or PO Box where the checks are mailed. */
  checkMoneyOrderSendCheckTo: Maybe<Scalars['String']['output']>
  /** A number indicating the position of the Check/Money Order payment method in the list of available payment methods during checkout. */
  checkMoneyOrderSortOrder: Maybe<Scalars['Int']['output']>
  /** The title of the Check/Money Order payment method displayed on the storefront. */
  checkMoneyOrderTitle: Maybe<Scalars['String']['output']>
  /** The name of the CMS page that identifies the home page for the store. */
  cmsHomePage: Maybe<Scalars['String']['output']>
  /** A specific CMS page that displays when cookies are not enabled for the browser. */
  cmsNoCookies: Maybe<Scalars['String']['output']>
  /** A specific CMS page that displays when a 404 'Page Not Found' error occurs. */
  cmsNoRoute: Maybe<Scalars['String']['output']>
  /**
   * A code assigned to the store to identify it.
   * @deprecated Use `store_code` instead.
   */
  code: Maybe<Scalars['String']['output']>
  /** Indicates whether the `parent` or child (`itself`) thumbnail should be used in the cart for configurable products. */
  configurableThumbnailSource: Maybe<Scalars['String']['output']>
  /** Indicates whether the Contact Us form in enabled. */
  contactEnabled: Scalars['Boolean']['output']
  /** The copyright statement that appears at the bottom of each page. */
  copyright: Maybe<Scalars['String']['output']>
  /** Extended Config Data - general/region/state_required */
  countriesWithRequiredRegion: Maybe<Scalars['String']['output']>
  /** Indicates if the new accounts need confirmation. */
  createAccountConfirmation: Maybe<Scalars['Boolean']['output']>
  /** Customer access token lifetime. */
  customerAccessTokenLifetime: Maybe<Scalars['Float']['output']>
  /** Extended Config Data - general/country/default */
  defaultCountry: Maybe<Scalars['String']['output']>
  defaultCountryId: Maybe<Scalars['String']['output']>
  /** The description that provides a summary of your site for search engine listings. It should not be more than 160 characters in length. */
  defaultDescription: Maybe<Scalars['String']['output']>
  /** The default display currency code. */
  defaultDisplayCurrencyCode: Maybe<Scalars['String']['output']>
  /** A series of keywords that describe your store, each separated by a comma. */
  defaultKeywords: Maybe<Scalars['String']['output']>
  /** The title that appears at the title bar of each page when viewed in a browser. */
  defaultTitle: Maybe<Scalars['String']['output']>
  /** Controls the display of the demo store notice at the top of the page. Options: 0 (No) or 1 (Yes). */
  demonotice: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - general/region/display_all */
  displayStateIfOptional: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether customers can have multiple wish lists. Possible values: 1 (Yes) and 0 (No). */
  enableMultipleWishlists: Maybe<Scalars['String']['output']>
  /** Category AC material to enable or disable */
  fourblueConfiguratorCategoryAcEnabled: Maybe<Scalars['Boolean']['output']>
  /** Elektramat to enable or disable */
  fourblueConfiguratorElektramatEnabled: Maybe<Scalars['Boolean']['output']>
  /** Esdec to enable or disable */
  fourblueConfiguratorEsdecEnabled: Maybe<Scalars['Boolean']['output']>
  /** Panelclaw to enable or disable */
  fourblueConfiguratorPanelclawEnabled: Maybe<Scalars['Boolean']['output']>
  /** Client Id for Esdec */
  fourblueEsdecClientId: Maybe<Scalars['String']['output']>
  /** Client Id for PanelClaw */
  fourbluePanelClawClientId: Maybe<Scalars['String']['output']>
  /** The landing page that is associated with the base URL. */
  front: Maybe<Scalars['String']['output']>
  /** The default number of products per page in Grid View. */
  gridPerPage: Maybe<Scalars['Int']['output']>
  /** A list of numbers that define how many products can be displayed in Grid View. */
  gridPerPageValues: Maybe<Scalars['String']['output']>
  /** GTM Container IDs, optional a comma separated list of Container IDs */
  gtmContainerIds: Maybe<Scalars['String']['output']>
  /** Scripts that must be included in the HTML before the closing `<head>` tag. */
  headIncludes: Maybe<Scalars['String']['output']>
  /** The small graphic image (favicon) that appears in the address bar and tab of the browser. */
  headShortcutIcon: Maybe<Scalars['String']['output']>
  /** The path to the logo that appears in the header. */
  headerLogoSrc: Maybe<Scalars['String']['output']>
  /**
   * The ID number assigned to the store.
   * @deprecated Use `store_code` instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** Indicates whether the store view has been designated as the default within the store group. */
  isDefaultStore: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the store group has been designated as the default within the website. */
  isDefaultStoreGroup: Maybe<Scalars['Boolean']['output']>
  /** Extended Config Data - checkout/options/guest_checkout */
  isGuestCheckoutEnabled: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether negotiable quote functionality is enabled. */
  isNegotiableQuoteActive: Maybe<Scalars['Boolean']['output']>
  /** Extended Config Data - checkout/options/onepage_checkout_enabled */
  isOnePageCheckoutEnabled: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether requisition lists are enabled. Possible values: 1 (Yes) and 0 (No). */
  isRequisitionListActive: Maybe<Scalars['String']['output']>
  /** The format of the search results list. */
  listMode: Maybe<Scalars['String']['output']>
  /** The default number of products per page in List View. */
  listPerPage: Maybe<Scalars['Int']['output']>
  /** A list of numbers that define how many products can be displayed in List View. */
  listPerPageValues: Maybe<Scalars['String']['output']>
  /** The store locale. */
  locale: Maybe<Scalars['String']['output']>
  /** The Alt text that is associated with the logo. */
  logoAlt: Maybe<Scalars['String']['output']>
  /** The height of the logo image, in pixels. */
  logoHeight: Maybe<Scalars['Int']['output']>
  /** The width of the logo image, in pixels. */
  logoWidth: Maybe<Scalars['Int']['output']>
  /** Indicates whether reward points functionality is enabled. Possible values: 1 (Enabled) and 0 (Disabled). */
  magentoRewardGeneralIsEnabled: Maybe<Scalars['String']['output']>
  /** Indicates whether reward points functionality is enabled on the storefront. Possible values: 1 (Enabled) and 0 (Disabled). */
  magentoRewardGeneralIsEnabledOnFront: Maybe<Scalars['String']['output']>
  /** The minimum point balance customers must have before they can redeem them. A null value indicates no minimum. */
  magentoRewardGeneralMinPointsBalance: Maybe<Scalars['String']['output']>
  /** When enabled, customers can see a detailed history of their reward points. Possible values: 1 (Enabled) and 0 (Disabled). */
  magentoRewardGeneralPublishHistory: Maybe<Scalars['String']['output']>
  /** The number of points for a referral when an invitee registers on the site. */
  magentoRewardPointsInvitationCustomer: Maybe<Scalars['String']['output']>
  /** The maximum number of registration referrals that will qualify for rewards. A null value indicates no limit. */
  magentoRewardPointsInvitationCustomerLimit: Maybe<Scalars['String']['output']>
  /** The number of points for a referral, when an invitee places their first order on the site. */
  magentoRewardPointsInvitationOrder: Maybe<Scalars['String']['output']>
  /** The number of order conversions that can earn points for the customer who sends the invitation. A null value indicates no limit. */
  magentoRewardPointsInvitationOrderLimit: Maybe<Scalars['String']['output']>
  /** The number of points earned by registered customers who subscribe to a newsletter. */
  magentoRewardPointsNewsletter: Maybe<Scalars['String']['output']>
  /** Indicates customers earn points for shopping according to the reward point exchange rate. In Luma, this also controls whether to show a message in the shopping cart about the rewards points earned for the purchase, as well as the customer’s current reward point balance. */
  magentoRewardPointsOrder: Maybe<Scalars['String']['output']>
  /** The number of points customer gets for registering. */
  magentoRewardPointsRegister: Maybe<Scalars['String']['output']>
  /** The number of points for writing a review. */
  magentoRewardPointsReview: Maybe<Scalars['String']['output']>
  /** The maximum number of reviews that will qualify for the rewards. A null value indicates no limit. */
  magentoRewardPointsReviewLimit: Maybe<Scalars['String']['output']>
  /** Indicates whether wishlists are enabled (1) or disabled (0). */
  magentoWishlistGeneralIsEnabled: Maybe<Scalars['String']['output']>
  /** Extended Config Data - checkout/options/max_items_display_count */
  maxItemsInOrderSummary: Maybe<Scalars['Int']['output']>
  /** If multiple wish lists are enabled, the maximum number of wish lists the customer can have. */
  maximumNumberOfWishlists: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/advanced_permalink/archive_path_schema */
  mfblogAdvancedPermalinkArchivePathSchema: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/advanced_permalink/blog_route */
  mfblogAdvancedPermalinkBlogRoute: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/advanced_permalink/enabled */
  mfblogAdvancedPermalinkEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/advanced_permalink/redirect_to_no_slash */
  mfblogAdvancedPermalinkRedirectToNoSlash: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/advanced_permalink/search_path_schema */
  mfblogAdvancedPermalinkSearchPathSchema: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/author/enabled */
  mfblogAuthorEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/author/page_enabled */
  mfblogAuthorPageEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/author/robots */
  mfblogAuthorRobots: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/design/format_date */
  mfblogDesignFormatDate: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/design/publication_date */
  mfblogDesignPublicationDate: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/index_page/meta_description */
  mfblogIndexPageMetaDescription: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/index_page/meta_keywords */
  mfblogIndexPageMetaKeywords: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/index_page/meta_title */
  mfblogIndexPageMetaTitle: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/index_page/posts_sort_by */
  mfblogIndexPagePostsSortBy: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/index_page/title */
  mfblogIndexPageTitle: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/permalink/archive_route */
  mfblogPermalinkArchiveRoute: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/permalink/author_route */
  mfblogPermalinkAuthorRoute: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/permalink/author_sufix */
  mfblogPermalinkAuthorSufix: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/permalink/category_route */
  mfblogPermalinkCategoryRoute: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/permalink/category_sufix */
  mfblogPermalinkCategorySufix: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/permalink/category_use_categories */
  mfblogPermalinkCategoryUseCategories: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/permalink/post_route */
  mfblogPermalinkPostRoute: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/permalink/post_sufix */
  mfblogPermalinkPostSufix: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/permalink/post_use_categories */
  mfblogPermalinkPostUseCategories: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/permalink/redirect_to_no_slash */
  mfblogPermalinkRedirectToNoSlash: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/permalink/route */
  mfblogPermalinkRoute: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/permalink/search_route */
  mfblogPermalinkSearchRoute: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/permalink/tag_route */
  mfblogPermalinkTagRoute: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/permalink/tag_sufix */
  mfblogPermalinkTagSufix: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/permalink/type */
  mfblogPermalinkType: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/post_list/posts_per_page */
  mfblogPostListPostsPerPage: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/post_view/comments/default_status */
  mfblogPostViewCommentsDefaultStatus: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/post_view/comments/display_privacy_policy_checkbox */
  mfblogPostViewCommentsDisplayPrivacyPolicyCheckbox: Maybe<
    Scalars['Int']['output']
  >
  /** Extended Config Data - mfblog/post_view/comments/disqus_forum_shortname */
  mfblogPostViewCommentsDisqusForumShortname: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/post_view/comments/fb_app_id */
  mfblogPostViewCommentsFbAppId: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/post_view/comments/fb_app_id_header */
  mfblogPostViewCommentsFbAppIdHeader: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/post_view/comments/format_date */
  mfblogPostViewCommentsFormatDate: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/post_view/comments/guest_comments */
  mfblogPostViewCommentsGuestComments: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/post_view/comments/number_of_comments */
  mfblogPostViewCommentsNumberOfComments: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/post_view/comments/number_of_replies */
  mfblogPostViewCommentsNumberOfReplies: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/post_view/comments/type */
  mfblogPostViewCommentsType: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/post_view/nextprev/enabled */
  mfblogPostViewNextprevEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/post_view/related_posts/enabled */
  mfblogPostViewRelatedPostsEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/post_view/related_posts/number_of_posts */
  mfblogPostViewRelatedPostsNumberOfPosts: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/post_view/related_products/enabled */
  mfblogPostViewRelatedProductsEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/post_view/related_products/number_of_products */
  mfblogPostViewRelatedProductsNumberOfProducts: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/product_page/number_of_related_posts */
  mfblogProductPageNumberOfRelatedPosts: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/product_page/related_posts_enabled */
  mfblogProductPageRelatedPostsEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/search/robots */
  mfblogSearchRobots: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/seo/use_canonical_meta_tag_for */
  mfblogSeoUseCanonicalMetaTagFor: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/sidebar/archive/enabled */
  mfblogSidebarArchiveEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/archive/format_date */
  mfblogSidebarArchiveFormatDate: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/sidebar/archive/sort_order */
  mfblogSidebarArchiveSortOrder: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/categories/enabled */
  mfblogSidebarCategoriesEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/categories/max_depth */
  mfblogSidebarCategoriesMaxDepth: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/categories/show_posts_count */
  mfblogSidebarCategoriesShowPostsCount: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/categories/sort_order */
  mfblogSidebarCategoriesSortOrder: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/custom2/enabled */
  mfblogSidebarCustom2Enabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/custom2/html */
  mfblogSidebarCustom2Html: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/sidebar/custom2/sort_order */
  mfblogSidebarCustom2SortOrder: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/custom/enabled */
  mfblogSidebarCustomEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/custom/html */
  mfblogSidebarCustomHtml: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/sidebar/custom/sort_order */
  mfblogSidebarCustomSortOrder: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/featured_posts/enabled */
  mfblogSidebarFeaturedPostsEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/featured_posts/posts_ids */
  mfblogSidebarFeaturedPostsPostsIds: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/sidebar/featured_posts/sort_order */
  mfblogSidebarFeaturedPostsSortOrder: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/popular_posts/enabled */
  mfblogSidebarPopularPostsEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/popular_posts/posts_per_page */
  mfblogSidebarPopularPostsPostsPerPage: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/popular_posts/sort_order */
  mfblogSidebarPopularPostsSortOrder: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/recent_posts/enabled */
  mfblogSidebarRecentPostsEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/recent_posts/posts_per_page */
  mfblogSidebarRecentPostsPostsPerPage: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/recent_posts/sort_order */
  mfblogSidebarRecentPostsSortOrdere: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/rss_feed/description */
  mfblogSidebarRssFeedDescription: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/sidebar/rss_feed/enabled */
  mfblogSidebarRssFeedEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/rss_feed/sort_order */
  mfblogSidebarRssFeedSortOrder: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/rss_feed/title */
  mfblogSidebarRssFeedTitle: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/sidebar/search/enabled */
  mfblogSidebarSearchEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/search/sort_order */
  mfblogSidebarSearchSortOrder: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/tag_claud/enabled */
  mfblogSidebarTagClaudEnabled: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/tag_claud/sort_order */
  mfblogSidebarTagClaudSortOrder: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/sidebar/tag_claud/tag_count */
  mfblogSidebarTagClaudTagCount: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/social/add_this_enabled */
  mfblogSocialAddThisEnabled: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/social/add_this_language */
  mfblogSocialAddThisLanguage: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/social/add_this_pubid */
  mfblogSocialAddThisPubid: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/tag/robots */
  mfblogTagRobots: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/top_menu/include_categories */
  mfblogTopMenuIncludeCategories: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/top_menu/item_text */
  mfblogTopMenuItemText: Maybe<Scalars['String']['output']>
  /** Extended Config Data - mfblog/top_menu/max_depth */
  mfblogTopMenuMaxDepth: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - mfblog/top_menu/show_item */
  mfblogTopMenuShowItem: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - checkout/sidebar/display */
  minicartDisplay: Maybe<Scalars['Boolean']['output']>
  /** Extended Config Data - checkout/sidebar/count */
  minicartMaxItems: Maybe<Scalars['Int']['output']>
  /** The minimum number of characters required for a valid password. */
  minimumPasswordLength: Maybe<Scalars['String']['output']>
  /** Mollie store config */
  mollie: Maybe<MollieStoreConfig>
  /** Indicates whether newsletters are enabled. */
  newsletterEnabled: Scalars['Boolean']['output']
  /** The default page that displays when a 404 'Page not Found' error occurs. */
  noRoute: Maybe<Scalars['String']['output']>
  /** Extended Config Data - general/country/optional_zip_countries */
  optionalZipCountries: Maybe<Scalars['String']['output']>
  /** Indicates whether orders can be cancelled by customers or not. */
  orderCancellationEnabled: Scalars['Boolean']['output']
  /** An array containing available cancellation reasons. */
  orderCancellationReasons: Array<Maybe<CancellationReason>>
  /** Payflow Pro vault status. */
  paymentPayflowproCcVaultActive: Maybe<Scalars['String']['output']>
  /** The default price of a printed card that accompanies an order. */
  printedCardPrice: Maybe<Scalars['String']['output']>
  /** Corresponds to the 'Display Prices On Product View Page' field in the Admin. It indicates how FPT information is displayed on product pages. */
  productFixedProductTaxDisplaySetting: Maybe<FixedProductTaxDisplaySettings>
  /** Indicates whether product reviews are enabled. Possible values: 1 (Yes) and 0 (No). */
  productReviewsEnabled: Maybe<Scalars['String']['output']>
  /** The suffix applied to product pages, such as `.htm` or `.html`. */
  productUrlSuffix: Maybe<Scalars['String']['output']>
  /** Indicates whether quick order functionality is enabled. */
  quickorderActive: Scalars['Boolean']['output']
  /** The number of different character classes (lowercase, uppercase, digits, special characters) required in a password. */
  requiredCharacterClassesNumber: Maybe<Scalars['String']['output']>
  /** Indicates whether RMA is enabled on the storefront. Possible values: enabled/disabled. */
  returnsEnabled: Scalars['String']['output']
  /** Magento 2 Theme Robots.txt value */
  robotsTxt: Maybe<Scalars['String']['output']>
  /**
   * The ID of the root category.
   * @deprecated Use `root_category_uid` instead.
   */
  rootCategoryId: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `CategoryInterface` object. */
  rootCategoryUid: Maybe<Scalars['ID']['output']>
  /** Corresponds to the 'Display Prices In Sales Modules' field in the Admin. It indicates how FPT information is displayed on cart, checkout, and order pages. */
  salesFixedProductTaxDisplaySetting: Maybe<FixedProductTaxDisplaySettings>
  /** Indicates if gift wrapping prices are displayed on the Orders page. Possible values: 1 (Yes) and 0 (No). */
  salesGiftWrapping: Maybe<Scalars['String']['output']>
  /** Indicates if printed card prices are displayed on the Orders page. Possible values: 1 (Yes) and 0 (No). */
  salesPrintedCard: Maybe<Scalars['String']['output']>
  /** Search engine robot values. 'INDEX, FOLLOW', 'NOINDEX, FOLLOW' etc. */
  searchEngineRobots: Maybe<Scalars['String']['output']>
  /** A secure fully-qualified URL that is used to create relative links to the `base_url`. */
  secureBaseLinkUrl: Maybe<Scalars['String']['output']>
  /** The secure fully-qualified URL that specifies the location of media files. */
  secureBaseMediaUrl: Maybe<Scalars['String']['output']>
  /** The secure fully-qualified URL that specifies the location of static view files. */
  secureBaseStaticUrl: Maybe<Scalars['String']['output']>
  /** The store’s fully-qualified secure base URL. */
  secureBaseUrl: Maybe<Scalars['String']['output']>
  /** Email to a Friend configuration. */
  sendFriend: Maybe<SendFriendConfiguration>
  /** Extended Config Data - tax/cart_display/full_summary */
  shoppingCartDisplayFullSummary: Maybe<Scalars['Boolean']['output']>
  /** Extended Config Data - tax/cart_display/grandtotal */
  shoppingCartDisplayGrandTotal: Maybe<Scalars['Boolean']['output']>
  /** Extended Config Data - tax/cart_display/price */
  shoppingCartDisplayPrice: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - tax/cart_display/shipping */
  shoppingCartDisplayShipping: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - tax/cart_display/subtotal */
  shoppingCartDisplaySubtotal: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - tax/cart_display/gift_wrapping */
  shoppingCartDisplayTaxGiftWrapping: Maybe<TaxWrappingEnum>
  /** Extended Config Data - tax/cart_display/zero_tax */
  shoppingCartDisplayZeroTax: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether a breadcrumb trail appears on all CMS pages in the catalog. 0 (No) or 1 (Yes). */
  showCmsBreadcrumbs: Maybe<Scalars['Int']['output']>
  socialTrustpilotBusinessUnitId: Maybe<Scalars['String']['output']>
  storeCity: Maybe<Scalars['String']['output']>
  /** The unique ID of the store view. In the Admin, this is called the Store View Code. When making a GraphQL call, assign this value to the `Store` header to provide the scope. */
  storeCode: Maybe<Scalars['ID']['output']>
  storeCountryId: Maybe<Scalars['String']['output']>
  /** The unique ID assigned to the store group. In the Admin, this is called the Store Name. */
  storeGroupCode: Maybe<Scalars['ID']['output']>
  /** The label assigned to the store group. */
  storeGroupName: Maybe<Scalars['String']['output']>
  storeHours: Maybe<Scalars['String']['output']>
  storeMerchantVatNumber: Maybe<Scalars['String']['output']>
  /** The label assigned to the store view. */
  storeName: Maybe<Scalars['String']['output']>
  storePhone: Maybe<Scalars['String']['output']>
  storePostcode: Maybe<Scalars['String']['output']>
  storeRegionId: Maybe<Scalars['String']['output']>
  /** The store view sort order. */
  storeSortOrder: Maybe<Scalars['Int']['output']>
  storeStreetLine1: Maybe<Scalars['String']['output']>
  storeStreetLine2: Maybe<Scalars['String']['output']>
  /** The time zone of the store. */
  timezone: Maybe<Scalars['String']['output']>
  /** A prefix that appears before the title to create a two- or three-part title. */
  titlePrefix: Maybe<Scalars['String']['output']>
  /** The character that separates the category name and subcategory in the browser title bar. */
  titleSeparator: Maybe<Scalars['String']['output']>
  /** A suffix that appears after the title to create a two- or three-part title. */
  titleSuffix: Maybe<Scalars['String']['output']>
  /** Indicates whether the store code should be used in the URL. */
  useStoreInUrl: Maybe<Scalars['Boolean']['output']>
  /** The unique ID for the website. */
  websiteCode: Maybe<Scalars['ID']['output']>
  /**
   * The ID number assigned to the website store.
   * @deprecated The field should not be used on the storefront.
   */
  websiteId: Maybe<Scalars['Int']['output']>
  /** The label assigned to the website. */
  websiteName: Maybe<Scalars['String']['output']>
  /** The unit of weight. */
  weightUnit: Maybe<Scalars['String']['output']>
  /** Text that appears in the header of the page and includes the name of the logged in customer. */
  welcome: Maybe<Scalars['String']['output']>
  /** Indicates whether only specific countries can use this payment method. */
  zeroSubtotalEnableForSpecificCountries: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the Zero Subtotal payment method is enabled. */
  zeroSubtotalEnabled: Maybe<Scalars['Boolean']['output']>
  /** The status of new orders placed using the Zero Subtotal payment method. */
  zeroSubtotalNewOrderStatus: Maybe<Scalars['String']['output']>
  /** When the new order status is 'Processing', this can be set to `authorize_capture` to automatically invoice all items that have a zero balance. */
  zeroSubtotalPaymentAction: Maybe<Scalars['String']['output']>
  /** A comma-separated list of specific countries allowed to use the Zero Subtotal payment method. */
  zeroSubtotalPaymentFromSpecificCountries: Maybe<Scalars['String']['output']>
  /** A number indicating the position of the Zero Subtotal payment method in the list of available payment methods during checkout. */
  zeroSubtotalSortOrder: Maybe<Scalars['Int']['output']>
  /** The title of the Zero Subtotal payment method displayed on the storefront. */
  zeroSubtotalTitle: Maybe<Scalars['String']['output']>
}

/** Indicates where an attribute can be displayed. */
export interface StorefrontProperties {
  __typename?: 'StorefrontProperties'
  /** The relative position of the attribute in the layered navigation block. */
  position: Maybe<Scalars['Int']['output']>
  /** Indicates whether the attribute is filterable with results, without results, or not at all. */
  useInLayeredNavigation: Maybe<UseInLayeredNavigationOptions>
  /** Indicates whether the attribute is displayed in product listings. */
  useInProductListing: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the attribute can be used in layered navigation on search results pages. */
  useInSearchResultsLayeredNavigation: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the attribute is displayed on product pages. */
  visibleOnCatalogPages: Maybe<Scalars['Boolean']['output']>
}

/** Contains the result of the `subscribeEmailToNewsletter` operation. */
export interface SubscribeEmailToNewsletterOutput {
  __typename?: 'SubscribeEmailToNewsletterOutput'
  /** The status of the subscription request. */
  status: Maybe<SubscriptionStatusesEnum>
}

/** Indicates the status of the request. */
export enum SubscriptionStatusesEnum {
  NOT_ACTIVE = 'NOT_ACTIVE',
  SUBSCRIBED = 'SUBSCRIBED',
  UNCONFIRMED = 'UNCONFIRMED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
}

/** Describes the swatch type and a value. */
export interface SwatchData {
  __typename?: 'SwatchData'
  /** The type of swatch filter item: 1 - text; 2 - image. */
  type: Maybe<Scalars['String']['output']>
  /** The value for the swatch item. It could be text or an image link. */
  value: Maybe<Scalars['String']['output']>
}

export interface SwatchDataInterface {
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']['output']>
}

/** Swatch attribute metadata input types. */
export enum SwatchInputTypeEnum {
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  DROPDOWN = 'DROPDOWN',
  FILE = 'FILE',
  GALLERY = 'GALLERY',
  HIDDEN = 'HIDDEN',
  IMAGE = 'IMAGE',
  MEDIA_IMAGE = 'MEDIA_IMAGE',
  MULTILINE = 'MULTILINE',
  MULTISELECT = 'MULTISELECT',
  PRICE = 'PRICE',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  UNDEFINED = 'UNDEFINED',
  VISUAL = 'VISUAL',
  WEIGHT = 'WEIGHT',
}

export interface SwatchLayerFilterItem
  extends LayerFilterItemInterface,
    SwatchLayerFilterItemInterface {
  __typename?: 'SwatchLayerFilterItem'
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  itemsCount: Maybe<Scalars['Int']['output']>
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label: Maybe<Scalars['String']['output']>
  /** Data required to render a swatch filter item. */
  swatchData: Maybe<SwatchData>
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  valueString: Maybe<Scalars['String']['output']>
}

export interface SwatchLayerFilterItemInterface {
  /** Data required to render a swatch filter item. */
  swatchData: Maybe<SwatchData>
}

/** Synchronizes the payment order details */
export interface SyncPaymentOrderInput {
  /** The customer cart ID */
  cartId: Scalars['String']['input']
  /** PayPal order ID */
  id: Scalars['String']['input']
}

/** Contains tax item details. */
export interface TaxItem {
  __typename?: 'TaxItem'
  /** The amount of tax applied to the item. */
  amount: Money
  /** The rate used to calculate the tax. */
  rate: Scalars['Float']['output']
  /** A title that describes the tax. */
  title: Scalars['String']['output']
}

export enum TaxWrappingEnum {
  DISPLAY_EXCLUDING_TAX = 'DISPLAY_EXCLUDING_TAX',
  DISPLAY_INCLUDING_TAX = 'DISPLAY_INCLUDING_TAX',
  DISPLAY_TYPE_BOTH = 'DISPLAY_TYPE_BOTH',
}

export interface TextSwatchData extends SwatchDataInterface {
  __typename?: 'TextSwatchData'
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']['output']>
}

/** Defines a price based on the quantity purchased. */
export interface TierPrice {
  __typename?: 'TierPrice'
  /** The price discount that this tier represents. */
  discount: Maybe<ProductDiscount>
  /** The price of the product at this tier. */
  finalPrice: Maybe<Money>
  /** The minimum number of items that must be purchased to qualify for this price tier. */
  quantity: Maybe<Scalars['Float']['output']>
}

/** Modifies the specified items in the cart. */
export interface UpdateCartItemsInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** An array of items to be updated. */
  cart_items: Array<InputMaybe<CartItemUpdateInput>>
}

/** Contains details about the cart after updating items. */
export interface UpdateCartItemsOutput {
  __typename?: 'UpdateCartItemsOutput'
  /** The cart after updating products. */
  cart: Cart
}

/** Contains the response to the request to update the company. */
export interface UpdateCompanyOutput {
  __typename?: 'UpdateCompanyOutput'
  /** The updated company instance. */
  company: Company
}

/** Contains the response to the request to update the company role. */
export interface UpdateCompanyRoleOutput {
  __typename?: 'UpdateCompanyRoleOutput'
  /** The updated company role instance. */
  role: CompanyRole
}

/** Contains the response to the request to update the company structure. */
export interface UpdateCompanyStructureOutput {
  __typename?: 'UpdateCompanyStructureOutput'
  /** The updated company instance. */
  company: Company
}

/** Contains the response to the request to update a company team. */
export interface UpdateCompanyTeamOutput {
  __typename?: 'UpdateCompanyTeamOutput'
  /** The updated company team instance. */
  team: CompanyTeam
}

/** Contains the response to the request to update the company user. */
export interface UpdateCompanyUserOutput {
  __typename?: 'UpdateCompanyUserOutput'
  /** The updated company user instance. */
  user: Customer
}

/** Defines updates to a `GiftRegistry` object. */
export interface UpdateGiftRegistryInput {
  /** Additional attributes specified as a code-value pair. Unspecified dynamic attributes are not changed. */
  dynamic_attributes?: InputMaybe<
    Array<InputMaybe<GiftRegistryDynamicAttributeInput>>
  >
  /** The updated name of the event. */
  event_name?: InputMaybe<Scalars['String']['input']>
  /** The updated message describing the event. */
  message?: InputMaybe<Scalars['String']['input']>
  /** Indicates whether the gift registry is PRIVATE or PUBLIC. */
  privacy_settings?: InputMaybe<GiftRegistryPrivacySettings>
  /** The updated shipping address for all gift registry items. */
  shipping_address?: InputMaybe<GiftRegistryShippingAddressInput>
  /** Indicates whether the gift registry is ACTIVE or INACTIVE. */
  status?: InputMaybe<GiftRegistryStatus>
}

/** Defines updates to an item in a gift registry. */
export interface UpdateGiftRegistryItemInput {
  /** The unique ID of a `giftRegistryItem` object. */
  gift_registry_item_uid: Scalars['ID']['input']
  /** The updated description of the item. */
  note?: InputMaybe<Scalars['String']['input']>
  /** The updated quantity of the gift registry item. */
  quantity: Scalars['Float']['input']
}

/** Contains the results of a request to update gift registry items. */
export interface UpdateGiftRegistryItemsOutput {
  __typename?: 'UpdateGiftRegistryItemsOutput'
  /** The gift registry after updating updating items. */
  giftRegistry: Maybe<GiftRegistry>
}

/** Contains the results of a request to update a gift registry. */
export interface UpdateGiftRegistryOutput {
  __typename?: 'UpdateGiftRegistryOutput'
  /** The updated gift registry. */
  giftRegistry: Maybe<GiftRegistry>
}

/** Defines updates to an existing registrant. */
export interface UpdateGiftRegistryRegistrantInput {
  /** As a result of the update, only the values of provided attributes will be affected. If the attribute is missing in the request, its value will not be changed. */
  dynamic_attributes?: InputMaybe<
    Array<InputMaybe<GiftRegistryDynamicAttributeInput>>
  >
  /** The updated email address of the registrant. */
  email?: InputMaybe<Scalars['String']['input']>
  /** The updated first name of the registrant. */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** The unique ID of a `giftRegistryRegistrant` object. */
  gift_registry_registrant_uid: Scalars['ID']['input']
  /** The updated last name of the registrant. */
  lastname?: InputMaybe<Scalars['String']['input']>
}

/** Contains the results a request to update registrants. */
export interface UpdateGiftRegistryRegistrantsOutput {
  __typename?: 'UpdateGiftRegistryRegistrantsOutput'
  /** The gift registry after updating registrants. */
  giftRegistry: Maybe<GiftRegistry>
}

/** Contains the updated negotiable quote. */
export interface UpdateNegotiableQuoteItemsQuantityOutput {
  __typename?: 'UpdateNegotiableQuoteItemsQuantityOutput'
  /** The updated negotiable quote. */
  quote: Maybe<NegotiableQuote>
}

/** Specifies the items to update. */
export interface UpdateNegotiableQuoteQuantitiesInput {
  /** An array of items to update. */
  items: Array<InputMaybe<NegotiableQuoteItemQuantityInput>>
  /** The unique ID of a `NegotiableQuote` object. */
  quote_uid: Scalars['ID']['input']
}

/** Contains the customer's wish list and any errors encountered. */
export interface UpdateProductsInWishlistOutput {
  __typename?: 'UpdateProductsInWishlistOutput'
  /** An array of errors encountered while updating products in a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>
  /** Contains the wish list with all items that were successfully updated. */
  wishlist: Wishlist
}

/** Defines the changes to be made to an approval rule. */
export interface UpdatePurchaseOrderApprovalRuleInput {
  /** An updated list of company user role IDs to which this purchase order approval rule should be applied. When an empty array is provided, the rule is applied to all user roles in the system, including those created in the future. */
  applies_to?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** An updated list of B2B user roles that can approve this purchase order approval rule. */
  approvers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The updated condition of the purchase order approval rule. */
  condition?: InputMaybe<CreatePurchaseOrderApprovalRuleConditionInput>
  /** The updated approval rule description. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The updated approval rule name. */
  name?: InputMaybe<Scalars['String']['input']>
  /** The updated status of the purchase order approval rule. */
  status?: InputMaybe<PurchaseOrderApprovalRuleStatus>
  /** Unique identifier for the purchase order approval rule. */
  uid: Scalars['ID']['input']
}

/** An input object that defines which requistion list characteristics to update. */
export interface UpdateRequisitionListInput {
  /** The updated description of the requisition list. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The new name of the requisition list. */
  name: Scalars['String']['input']
}

/** Defines which items in a requisition list to update. */
export interface UpdateRequisitionListItemsInput {
  /** An array of customer-entered options. */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>
  /** The ID of the requisition list item to update. */
  item_id: Scalars['ID']['input']
  /** The new quantity of the item. */
  quantity?: InputMaybe<Scalars['Float']['input']>
  /** An array of selected option IDs. */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Output of the request to update items in the specified requisition list. */
export interface UpdateRequisitionListItemsOutput {
  __typename?: 'UpdateRequisitionListItemsOutput'
  /** The requisition list after updating items. */
  requisitionList: Maybe<RequisitionList>
}

/** Output of the request to rename the requisition list. */
export interface UpdateRequisitionListOutput {
  __typename?: 'UpdateRequisitionListOutput'
  /** The renamed requisition list. */
  requisitionList: Maybe<RequisitionList>
}

/** Contains the name and visibility of an updated wish list. */
export interface UpdateWishlistOutput {
  __typename?: 'UpdateWishlistOutput'
  /** The wish list name. */
  name: Scalars['String']['output']
  /** The unique ID of a `Wishlist` object. */
  uid: Scalars['ID']['output']
  /** Indicates whether the wish list is public or private. */
  visibility: WishlistVisibilityEnum
}

/** Contains URL rewrite details. */
export interface UrlRewrite {
  __typename?: 'UrlRewrite'
  /** An array of request parameters. */
  parameters: Maybe<Array<Maybe<HttpQueryParameter>>>
  /** The request URL. */
  url: Maybe<Scalars['String']['output']>
}

/** This enumeration defines the entity type. */
export enum UrlRewriteEntityTypeEnum {
  CATEGORY = 'CATEGORY',
  CMS_PAGE = 'CMS_PAGE',
  MF_BLOG_ARCHIVE = 'MF_BLOG_ARCHIVE',
  MF_BLOG_AUTHOR = 'MF_BLOG_AUTHOR',
  MF_BLOG_CATEGORY = 'MF_BLOG_CATEGORY',
  MF_BLOG_COMMENT = 'MF_BLOG_COMMENT',
  MF_BLOG_INDEX = 'MF_BLOG_INDEX',
  MF_BLOG_POST = 'MF_BLOG_POST',
  MF_BLOG_SEARCH = 'MF_BLOG_SEARCH',
  MF_BLOG_TAG = 'MF_BLOG_TAG',
  PRODUCT = 'PRODUCT',
}

/** Defines whether the attribute is filterable in layered navigation. */
export enum UseInLayeredNavigationOptions {
  FILTERABLE_NO_RESULT = 'FILTERABLE_NO_RESULT',
  FILTERABLE_WITH_RESULTS = 'FILTERABLE_WITH_RESULTS',
  NO = 'NO',
}

/** Contains details about a failed validation attempt. */
export interface ValidatePurchaseOrderError {
  __typename?: 'ValidatePurchaseOrderError'
  /** The returned error message. */
  message: Scalars['String']['output']
  /** Error type. */
  type: ValidatePurchaseOrderErrorType
}

export enum ValidatePurchaseOrderErrorType {
  COULD_NOT_SAVE = 'COULD_NOT_SAVE',
  NOT_FOUND = 'NOT_FOUND',
  NOT_VALID_DATA = 'NOT_VALID_DATA',
  OPERATION_NOT_APPLICABLE = 'OPERATION_NOT_APPLICABLE',
  UNDEFINED = 'UNDEFINED',
}

/** Defines the purchase orders to be validated. */
export interface ValidatePurchaseOrdersInput {
  /** An array of the purchase order IDs. */
  purchase_order_uids: Array<InputMaybe<Scalars['ID']['input']>>
}

/** Contains the results of validation attempts. */
export interface ValidatePurchaseOrdersOutput {
  __typename?: 'ValidatePurchaseOrdersOutput'
  /** An array of error messages encountered while performing the operation. */
  errors: Array<Maybe<ValidatePurchaseOrderError>>
  /** An array of the purchase orders in the request. */
  purchaseOrders: Array<Maybe<PurchaseOrder>>
}

/** Defines a customer attribute validation rule. */
export interface ValidationRule {
  __typename?: 'ValidationRule'
  /** Validation rule name applied to a customer attribute. */
  name: Maybe<ValidationRuleEnum>
  /** Validation rule value. */
  value: Maybe<Scalars['String']['output']>
}

/** List of validation rule names applied to a customer attribute. */
export enum ValidationRuleEnum {
  DATE_RANGE_MAX = 'DATE_RANGE_MAX',
  DATE_RANGE_MIN = 'DATE_RANGE_MIN',
  FILE_EXTENSIONS = 'FILE_EXTENSIONS',
  INPUT_VALIDATION = 'INPUT_VALIDATION',
  MAX_FILE_SIZE = 'MAX_FILE_SIZE',
  MAX_IMAGE_HEIGHT = 'MAX_IMAGE_HEIGHT',
  MAX_IMAGE_WIDTH = 'MAX_IMAGE_WIDTH',
  MAX_TEXT_LENGTH = 'MAX_TEXT_LENGTH',
  MIN_TEXT_LENGTH = 'MIN_TEXT_LENGTH',
}

/** Vault payment inputs */
export interface VaultMethodInput {
  /** The payment source for the payment method */
  payment_source?: InputMaybe<Scalars['String']['input']>
  /** The payment services order ID */
  payments_order_id?: InputMaybe<Scalars['String']['input']>
  /** PayPal order ID */
  paypal_order_id?: InputMaybe<Scalars['String']['input']>
  /** The public hash of the token. */
  public_hash?: InputMaybe<Scalars['String']['input']>
}

/** Contains required input for payment methods with Vault support. */
export interface VaultTokenInput {
  /** The public hash of the payment token. */
  public_hash: Scalars['String']['input']
}

/** An implementation for virtual product cart items. */
export interface VirtualCartItem extends CartItemInterface {
  __typename?: 'VirtualCartItem'
  /** An array containing customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** Delivery date of the cart item (Y-m-d) */
  deliveryDate: Maybe<Scalars['String']['output']>
  /** Contains discount for quote line item. */
  discount: Maybe<Array<Maybe<Discount>>>
  /** Elektramat configuration packslip */
  elektramatPackslip: Maybe<Scalars['String']['output']>
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output']
  /** True if requested quantity is less than available stock, false otherwise. */
  isAvailable: Scalars['Boolean']['output']
  /** The buyer's quote line item note. */
  noteFromBuyer: Maybe<Array<Maybe<ItemNote>>>
  /** The seller's quote line item note. */
  noteFromSeller: Maybe<Array<Maybe<ItemNote>>>
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>
  /** Details about an item in the cart. */
  product: ProductInterface
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output']
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output']
}

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export interface VirtualProduct
  extends CustomizableProductInterface,
    ProductInterface,
    RoutableInterface {
  __typename?: 'VirtualProduct'
  /** @deprecated Use the `custom_attributes` field instead. */
  articleNumber: Maybe<Scalars['String']['output']>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  color: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  costPrice: Maybe<Scalars['String']['output']>
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** Product custom attributes. */
  customAttributesV2: Maybe<ProductCustomAttributes>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']['output']>
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>
  /** @deprecated Use the `custom_attributes` field instead. */
  isClonedForStore: Maybe<Scalars['String']['output']>
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']['output']>
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  manufacturerId: Maybe<Scalars['String']['output']>
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['String']['output']>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minimumQuantityAllowed: Maybe<Scalars['String']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  phasedOut: Maybe<Scalars['String']['output']>
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  productgroep: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  quantityIncrements: Maybe<Scalars['String']['output']>
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float']['output']
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalCrossection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDegreeofprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalEarthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalIdentificationcores: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalInnerdepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductorcrosssection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalconductordiameter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNominalinsulationthickness: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofcores: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofmodules: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofpoles: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalNumberofrows: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalOuterdiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalRatedvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalTrippingcharacteristic: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsAcmateriaalWithattachmentoption: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingBundlesperpallet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingCabletype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingDiameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingFireretardancy: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsBekabelingWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorApproval: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorBoxquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorCablediameter: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorSolarpanelcompatibility: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorStandards: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsConnectorWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerConnector: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerPayload: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerRfidcardreader: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerSuitableforoutdoormounting: Maybe<
    Scalars['Int']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsEvladerWirelessconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemColor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemRoofpitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsIndaksysteemWindstandard: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMaxsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMicroomvormerMinsolarpanelpower: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringCommunicationtype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSeriecompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringSignalrange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMonitoringWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalBoxweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDaktype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHeightatthetop: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalHoogtebovenzijde: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalMaterial: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalPaletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalProductline: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperbox: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalQuantityperpalet: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalRooftype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalUnitweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsMontagemateriaalWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcconnection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcdcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerAfci: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerCompatiblestorage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcsurgeprotection: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDcswitch: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerInvertertype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximuminputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaximumvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMaxoutputcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMicropanelpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMinearthleakageprotection: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMonitoringoptions: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerMppvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerNumberofmpptrackers: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerPaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperbox: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerQuantityperpalet: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerRatedacpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerSolarpanels: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStartupvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerStringspermpptracker: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerUsablebatterycapacity: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOmvormerWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemBatvoltagerange: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCommunicationport: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemCompatibility: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemDepth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemHeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxbatcurrent: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemMaxoutputpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemOutputvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemProtectionrate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemStoragecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemTypeofcooling: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemUsablecapacity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsOpslagsysteemWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBacksheetcolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelBuild: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCablelength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelCelltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelConnectortype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFireclass: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelFramecolor: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelGuaranteedpower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelJunctionboxprotectiondegree: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelLength: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelMaxsystemvoltage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelModuleefficiency: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelNumberofcells: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletquantity: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletsize: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPalletweight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPanelspercontainer: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPower: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelPowerguarantee: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelSolarpaneltype: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelThickness: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWarranty: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWattagepersquaremeter: Maybe<
    Scalars['String']['output']
  >
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWeight: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWidth: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  specificationsZonnepaneelWindandsnowload: Maybe<Scalars['String']['output']>
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean']['output']
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  usp: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  voorraadstatus: Maybe<Scalars['String']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>
}

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export interface VirtualProductCustomAttributesV2Args {
  filters: InputMaybe<AttributeFilterInput>
}

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export interface VirtualProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines a single product to add to the cart. */
export interface VirtualProductCartItemInput {
  /** An array that defines customizable options for the product. */
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
  data: CartItemInput
}

/** Contains details about virtual products added to a requisition list. */
export interface VirtualRequisitionListItem
  extends RequisitionListItemInterface {
  __typename?: 'VirtualRequisitionListItem'
  /** Selected custom options for an item in the requisition list. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** Details about a requisition list item. */
  product: ProductInterface
  /** The amount added. */
  quantity: Scalars['Float']['output']
  /** The unique ID for the requisition list item. */
  uid: Scalars['ID']['output']
}

/** Contains a virtual product wish list item. */
export interface VirtualWishlistItem extends WishlistItemInterface {
  __typename?: 'VirtualWishlistItem'
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output']
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output']
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output']
}

/** Deprecated. It should not be used on the storefront. Contains information about a website. */
export interface Website {
  __typename?: 'Website'
  /**
   * A code assigned to the website to identify it.
   * @deprecated The field should not be used on the storefront.
   */
  code: Maybe<Scalars['String']['output']>
  /**
   * The default group ID of the website.
   * @deprecated The field should not be used on the storefront.
   */
  defaultGroupId: Maybe<Scalars['String']['output']>
  /**
   * The ID number assigned to the website.
   * @deprecated The field should not be used on the storefront.
   */
  id: Maybe<Scalars['Int']['output']>
  /**
   * Indicates whether this is the default website.
   * @deprecated The field should not be used on the storefront.
   */
  isDefault: Maybe<Scalars['Boolean']['output']>
  /**
   * The website name. Websites use this name to identify it easier.
   * @deprecated The field should not be used on the storefront.
   */
  name: Maybe<Scalars['String']['output']>
  /**
   * The attribute to use for sorting websites.
   * @deprecated The field should not be used on the storefront.
   */
  sortOrder: Maybe<Scalars['Int']['output']>
}

/** An error encountered while performing operations with WishList. */
export interface WishListUserInputError {
  __typename?: 'WishListUserInputError'
  /** A wish list-specific error code. */
  code: WishListUserInputErrorType
  /** A localized error message. */
  message: Scalars['String']['output']
}

/** A list of possible error types. */
export enum WishListUserInputErrorType {
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  UNDEFINED = 'UNDEFINED',
}

/** Contains a customer wish list. */
export interface Wishlist {
  __typename?: 'Wishlist'
  /** The unique ID for a `Wishlist` object. */
  id: Maybe<Scalars['ID']['output']>
  /** @deprecated Use the `items_v2` field instead. */
  items: Maybe<Array<Maybe<WishlistItem>>>
  /** The number of items in the wish list. */
  itemsCount: Maybe<Scalars['Int']['output']>
  /** An array of items in the customer's wish list. */
  itemsV2: Maybe<WishlistItems>
  /** The name of the wish list. */
  name: Maybe<Scalars['String']['output']>
  /** An encrypted code that Magento uses to link to the wish list. */
  sharingCode: Maybe<Scalars['String']['output']>
  /** The time of the last modification to the wish list. */
  updatedAt: Maybe<Scalars['String']['output']>
  /** Indicates whether the wish list is public or private. */
  visibility: WishlistVisibilityEnum
}

/** Contains a customer wish list. */
export interface WishlistItemsV2Args {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Contains details about errors encountered when a customer added wish list items to the cart. */
export interface WishlistCartUserInputError {
  __typename?: 'WishlistCartUserInputError'
  /** An error code that describes the error encountered. */
  code: WishlistCartUserInputErrorType
  /** A localized error message. */
  message: Scalars['String']['output']
  /** The unique ID of the `Wishlist` object containing an error. */
  wishlistId: Scalars['ID']['output']
  /** The unique ID of the wish list item containing an error. */
  wishlistItemId: Scalars['ID']['output']
}

/** A list of possible error types. */
export enum WishlistCartUserInputErrorType {
  INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
  NOT_SALABLE = 'NOT_SALABLE',
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  UNDEFINED = 'UNDEFINED',
}

/** Contains details about a wish list item. */
export interface WishlistItem {
  __typename?: 'WishlistItem'
  /** The time when the customer added the item to the wish list. */
  addedAt: Maybe<Scalars['String']['output']>
  /** The customer's comment about this item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItem` object. */
  id: Maybe<Scalars['Int']['output']>
  /** Details about the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item */
  qty: Maybe<Scalars['Float']['output']>
}

/** Specifies the IDs of items to copy and their quantities. */
export interface WishlistItemCopyInput {
  /** The quantity of this item to copy to the destination wish list. This value can't be greater than the quantity in the source wish list. */
  quantity?: InputMaybe<Scalars['Float']['input']>
  /** The unique ID of the `WishlistItemInterface` object to be copied. */
  wishlist_item_id: Scalars['ID']['input']
}

/** Defines the items to add to a wish list. */
export interface WishlistItemInput {
  /** An array of options that the customer entered. */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>
  /** For complex product types, the SKU of the parent product. */
  parent_sku?: InputMaybe<Scalars['String']['input']>
  /** The amount or number of items to add. */
  quantity: Scalars['Float']['input']
  /** An array of strings corresponding to options the customer selected. */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The SKU of the product to add. For complex product types, specify the child product SKU. */
  sku: Scalars['String']['input']
}

/** The interface for wish list items. */
export interface WishlistItemInterface {
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output']
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output']
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output']
}

/** Specifies the IDs of the items to move and their quantities. */
export interface WishlistItemMoveInput {
  /** The quantity of this item to move to the destination wish list. This value can't be greater than the quantity in the source wish list. */
  quantity?: InputMaybe<Scalars['Float']['input']>
  /** The unique ID of the `WishlistItemInterface` object to be moved. */
  wishlist_item_id: Scalars['ID']['input']
}

/** Defines updates to items in a wish list. */
export interface WishlistItemUpdateInput {
  /** Customer-entered comments about the item. */
  description?: InputMaybe<Scalars['String']['input']>
  /** An array of options that the customer entered. */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>
  /** The new amount or number of this item. */
  quantity?: InputMaybe<Scalars['Float']['input']>
  /** An array of strings corresponding to options the customer selected. */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The unique ID for a `WishlistItemInterface` object. */
  wishlist_item_id: Scalars['ID']['input']
}

/** Contains an array of items in a wish list. */
export interface WishlistItems {
  __typename?: 'WishlistItems'
  /** A list of items in the wish list. */
  items: Array<Maybe<WishlistItemInterface>>
  /** Contains pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>
}

/** Deprecated: Use the `Wishlist` type instead. */
export interface WishlistOutput {
  __typename?: 'WishlistOutput'
  /**
   * An array of items in the customer's wish list
   * @deprecated Use the `Wishlist.items` field instead.
   */
  items: Maybe<Array<Maybe<WishlistItem>>>
  /**
   * The number of items in the wish list.
   * @deprecated Use the `Wishlist.items_count` field instead.
   */
  itemsCount: Maybe<Scalars['Int']['output']>
  /**
   * When multiple wish lists are enabled, the name the customer assigns to the wishlist.
   * @deprecated This field is related to Commerce functionality and is always `null` in Open Source.
   */
  name: Maybe<Scalars['String']['output']>
  /**
   * An encrypted code that links to the wish list.
   * @deprecated Use the `Wishlist.sharing_code` field instead.
   */
  sharingCode: Maybe<Scalars['String']['output']>
  /**
   * The time of the last modification to the wish list.
   * @deprecated Use the `Wishlist.updated_at` field instead.
   */
  updatedAt: Maybe<Scalars['String']['output']>
}

/** Defines the wish list visibility types. */
export enum WishlistVisibilityEnum {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}
