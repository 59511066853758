import defaultsToVars, { defaultsToCss } from './cssVarsUtils'

const sizesPrefix = 'sizes'

const sizesDefaults = {
  inputHeight: '50px',
  containerWidth: '1440px',
}

const sizes = defaultsToVars(sizesDefaults, sizesPrefix)

export const sizesCss = [...defaultsToCss(sizesDefaults, sizesPrefix)]

export default {
  sizes,
}
