/* eslint-disable no-console */
import { useCartId, useCartIdContext } from '@emico-hooks/cart-id'
import { useLoginTokenContext } from '@emico-hooks/login-token'
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { getMagentoSession } from './get'
import loginTokenVar from '../../lib/loginTokenVar'
import { useDebug } from '../debug/useDebug'

export interface MagentoSession {
  baseURL: string
  storeCode: string
  storeLocale: string
  cartId: string
  customerId: string
  customerToken: string
  guest: number
  sessionId: string
}

interface ContextInterface {
  session?: MagentoSession | null
  refreshSession?: () => Promise<void>
}

const Context = React.createContext<ContextInterface>({})

interface Props {
  children: ReactNode
}

export const MagentoSessionProvider = ({ children }: Props) => {
  const [session, setSession] = useState<MagentoSession>()
  const isDebug = useDebug()
  const loginTokenContext = useLoginTokenContext()
  const cartId = useCartId()
  const cartIdVar = useCartIdContext()

  useEffect(() => {
    setSession((session) => {
      if (!session || !cartId) {
        return session
      }

      if (session.cartId) {
        return session
      }

      return {
        ...session,
        cartId,
      }
    })
  }, [cartId])

  const refreshSession = useCallback(async () => {
    let newSession: MagentoSession | undefined
    const isDev = process.env.REACT_APP_ENV === 'dev'

    if (!isDev) {
      newSession = await getMagentoSession()
    } else {
      newSession = {
        baseURL: 'https:test.4blue.nl',
        storeCode: 'default',
        storeLocale: 'nl_NL',
        cartId: 'Lisu8YbQTXKXXwpg1vh41ClJDlSbx9rH',
        customerId: '4803',
        customerToken:
          'eyJraWQiOiIxIiwiYWxnIjoiSFMyNTYifQ.eyJ1aWQiOjQ4MDMsInV0eXBpZCI6MywiaWF0IjoxNzQxOTQxNTc5LCJleHAiOjE3NDE5NDUxNzl9.WFkwubw-GBSnA0pmcu9rQf97o_yCh2y_-Fi62elGxaQ',
        guest: 0,
        sessionId: '714f5f6e414d9dc1c7bdd84e2f99e663',
      }
      /**
       * DE local testing
       */
      // newSession = {
      //   baseURL: 'https://test.4blue.de/',
      //   storeCode: 'de',
      //   storeLocale: 'de_DE',
      //   cartId: 'DSnALNDbCEybJYtdzxsOmTObGs9k6ENu',
      //   customerId: '6761',
      //   customerToken:
      //     'eyJraWQiOiIxIiwiYWxnIjoiSFMyNTYifQ.eyJ1aWQiOjY3NjEsInV0eXBpZCI6MywiaWF0IjoxNzM4OTE3MTk1LCJleHAiOjE3Mzg5MjA3OTV9.1fl8hxO3NA3VLRq64unPvWDkvHG8yGnH4KK-5nr_tWc',
      //   guest: 0,
      //   sessionId: '0c69cf0289ffc89e03d3a95f3d686191',
      // }
    }

    if (isDebug) {
      console.debug('SessionContext - session')
      console.table(newSession)
    }

    if (!newSession) {
      return
    }

    setSession(newSession)

    loginTokenContext(newSession.customerToken)
    loginTokenVar(newSession.customerToken)

    cartIdVar(newSession.cartId)

    return
  }, [cartIdVar, isDebug, loginTokenContext])

  useEffect(() => {
    refreshSession()
  }, [refreshSession, session?.customerToken])

  return (
    <Context.Provider value={{ session, refreshSession }}>
      {children}
    </Context.Provider>
  )
}

export function useMagentoSession() {
  return useContext(Context)
}
