import { SVGProps } from 'react'

export const BackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 17"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M8.271 16.764L1.068 9.072a.858.858 0 01-.007-1.144L8.265.236a.718.718 0 011.064 0l.444.475a.843.843 0 010 1.137L3.546 8.503l6.233 6.65a.843.843 0 010 1.136l-.444.475a.718.718 0 01-1.064 0z"
      fill="currentColor"
    />
  </svg>
)
