import { RadioCustomVisibleButton as BaseRadioCustomVisibleButton } from '@emico-react/input-radio'
import styled from '@emotion/styled'

import theme from '../theme'

const StyledRadioCustomVisibleButton = styled(
  BaseRadioCustomVisibleButton.original,
)`
  flex-shrink: 0;
  --emico-radio-custom-visible-button-size: 24px;
  --emico-radio-custom-visible-button-active-color: ${theme.colors.accentGreen};
  --emico-radio-custom-visible-button-focus-color: ${theme.colors.accentGreen};
  --emico-radio-custom-visible-button-active-color-disabled: ${theme.colors
    .mediumGray};
  --emico-radio-custom-visible-button-border-disabled: 2px solid
    ${theme.colors.mediumGray};

  &::before {
    display: block;
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
    transition-property: ${theme.transition.properties.common};
    transition-duration: ${theme.transition.durations.normal};
    transition-timing-function: ${theme.transition.timingFunctions
      .cubicBezierSmooth};
  }
`

BaseRadioCustomVisibleButton.override(
  StyledRadioCustomVisibleButton as typeof BaseRadioCustomVisibleButton.original,
)
