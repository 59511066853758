import { css, Global } from '@emotion/react'

import { minWidth, overrideTheme } from '@emico/styles'

import { bordersCss } from './borders'
import { colorsCss } from './colors'
import theme from './index'
import { sizesCss } from './sizes'
import { spacingCss, mdGrid } from './spacing'
import { transitionCss } from './transitions'
import { typographyCss, getTypography } from './typography'

function GlobalStyles() {
  overrideTheme({
    breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1310,
    },
  })

  const allVars = [
    ...bordersCss,
    ...colorsCss,
    ...spacingCss,
    ...sizesCss,
    ...typographyCss,
    ...transitionCss,
  ].join('')

  return (
    <Global
      styles={css`
        :root {
          ${allVars}
        }

        @media ${minWidth('md')} {
          :root {
            ${mdGrid.join('')}
          }
        }

        @media (prefers-reduced-motion) {
          --var-transition-ease-in: none;
        }

        body {
          ${getTypography('bodyProxima', 14)}
          background-color: ${theme.colors.white};
          color: ${theme.colors.black};
        }

        p {
          margin-bottom: ${theme.spacing.x2};
        }

        a {
          color: ${theme.colors.primary};
          text-decoration: none;
        }

        html,
        body {
          height: 100%;
          padding: 0;
          margin: 0;
        }

        * {
          box-sizing: border-box;
        }

        #__next {
          height: 100%;
        }
      `}
    />
  )
}

export default GlobalStyles
