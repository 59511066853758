import styled from '@emotion/styled'
import { useCallback, useEffect, useState } from 'react'

import { maxWidth, minWidth } from '@emico/styles'

import theme from '../../theme'
import { Product } from '../product/product'

const ImageWrapper = styled.div`
  position: relative;
  max-height: 436px;
  padding-bottom: ${theme.spacing.x3};
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  @media ${maxWidth('md')} {
    max-height: 230px;
  }
`

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const ThumbnailsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  padding: ${theme.spacing.x05};

  @media ${minWidth('lg')} {
    gap: ${theme.spacing.x3};
  }
`

const Thumbnail = styled.img`
  object-fit: contain;
  height: 45px;
  width: 45px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  padding: ${theme.spacing.x1};

  @media ${minWidth('lg')} {
    height: 77px;
    width: 79px;
  }

  &:hover {
    cursor: pointer;
  }
`

interface ImageInfo {
  index: number
  imageUrl: string
}

interface Props {
  product: Product
}

export const MediaGallery = ({ product }: Props) => {
  const [activeImage, setActiveImage] = useState<ImageInfo>()
  const [images, setImages] = useState<ImageInfo[]>()

  const pimUrl = process.env.NEXT_PUBLIC_PIM_URL

  const handleImageUrls = useCallback(
    (product: Product) => {
      const imageArr: ImageInfo[] = []

      if (
        product &&
        product.imageGallery &&
        product.imageGallery.length > 0 &&
        pimUrl
      ) {
        product?.imageGallery.map((image, index) =>
          imageArr.push({
            index: index,
            imageUrl: pimUrl + image?.image?.fullpath,
          }),
        )
      }

      setActiveImage(imageArr[0])

      return imageArr
    },
    [pimUrl],
  )

  const handleOnClickThumbnail = (image: ImageInfo) => {
    setActiveImage(image)
  }

  useEffect(() => {
    if (images === undefined) {
      setImages(handleImageUrls(product))
    }
  }, [images, product, handleImageUrls])

  return (
    <ImageWrapper>
      <Image src={activeImage?.imageUrl} alt={product.name ?? ''} />

      <ThumbnailsWrapper>
        {images?.map(
          (image) =>
            activeImage?.index !== image.index && (
              <Thumbnail
                key={image.index}
                src={image.imageUrl}
                onClick={() => handleOnClickThumbnail(image)}
              />
            ),
        )}
      </ThumbnailsWrapper>
    </ImageWrapper>
  )
}
