import { TypePolicies } from '@apollo/client'

const typePolicies: TypePolicies = {
  Cart: {
    fields: {
      items: {
        merge(_, incoming) {
          return incoming
        },
      },
    },
  },
}

export default typePolicies
