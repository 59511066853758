import {
  PimcoreObjectCategory,
  PimcoreSpecificationsObjectBrickAttribute,
} from '@emico/graphql-schema-types/src'

import { getPriceWithCurrency } from '../../lib/getPriceWithCurrency'
import { Product } from '../product/product'

export const getImage = (item: Product): string => {
  const pimUrl = process.env.NEXT_PUBLIC_PIM_URL

  if (!item.imageGallery || item.imageGallery.length === 0) {
    return 'https://via.placeholder.com/250x250?text=4BLUE+ZONNEPANELEN'
  }

  if (item.imageGallery[0]?.image?.fullpath) {
    return pimUrl + item.imageGallery[0]?.image?.fullpath
  }

  return 'https://via.placeholder.com/250x250?text=4BLUE+ZONNEPANELEN'
}

export const getPrice = (
  item: Product,
  currency: string | null | undefined,
): string => {
  if (!item.price) {
    return ''
  }

  return `${getPriceWithCurrency(item.price, currency)} p/st`
}

export const getSurplusPrice = (
  item: Product,
  currency: string | null | undefined,
): string => {
  if (!item.price) {
    return ''
  }

  return `+ ${getPriceWithCurrency(item.price, currency)}`
}

export const getSpecifications = (
  specifications: PimcoreSpecificationsObjectBrickAttribute[],
  categories: PimcoreObjectCategory[],
  isAccessoires?: boolean,
) => {
  const length = specifications.find((spec) => spec.field === 'length')
  const width = specifications.find((spec) => spec.field === 'width')
  const warranty = specifications.find((spec) => spec.field === 'warranty')
  const warrantyText = warranty?.value?.displayValue
    ? `${warranty.fieldTitle}: ${warranty?.value?.displayValue} |`
    : ''

  const connectorType = specifications.find(
    (spec) => spec.field === 'connectorType',
  )

  const connectorTypeText = connectorType?.value?.displayValue
    ? `${connectorType.fieldTitle}: ${connectorType?.value?.displayValue}`
    : ''

  if (categories && categories.find((cat) => cat.name === 'Zonnepanelen')) {
    const cellType = specifications.find((spec) => spec.field === 'cellType')
    const cellTypeText = cellType?.value?.displayValue
      ? `${cellType.fieldTitle}: ${cellType?.value?.displayValue} |`
      : ''
    const thickness = specifications.find((spec) => spec.field === 'thickness')
    const lengthWidthThicknessText =
      length?.value?.displayValue &&
      width?.value?.displayValue &&
      thickness?.value?.displayValue
        ? `${length?.value?.displayValue} x ${width?.value?.displayValue} x ${thickness?.value?.displayValue}mm |`
        : ''

    return `${lengthWidthThicknessText} ${cellTypeText} ${warrantyText} ${connectorTypeText}`
  }
  if (categories && categories.find((cat) => cat.name === 'Monitoring')) {
    const communicationType = specifications.find(
      (spec) => spec.field === 'communicationType',
    )

    const communicationTypeText = communicationType?.value?.displayValue
      ? `${communicationType.fieldTitle}: ${communicationType?.value?.displayValue} |`
      : ''

    const serieCompatibility = specifications.find(
      (spec) => spec.field === 'serieCompatibility',
    )

    const serieCompatibilityText = serieCompatibility?.value?.displayValue
      ? `${serieCompatibility.fieldTitle}: ${serieCompatibility?.value?.displayValue}`
      : ''

    return `${communicationTypeText} ${serieCompatibilityText}`
  }
  if (
    categories &&
    categories.find((cat) => cat.name === 'Omvormers') &&
    !isAccessoires
  ) {
    const mppTrackers = specifications.find(
      (spec) => spec.field === 'numberOfMppTrackers',
    )

    const stringsPerMppTracker = specifications.find(
      (spec) => spec.field === 'stringsPerMppTracker',
    )

    const vlamboogDetectie = specifications.find(
      (spec) => spec.field === 'afci',
    )

    const ratedACPower = specifications.find(
      (spec) => spec.field === 'ratedACPower',
    )

    const mppTrackersText = mppTrackers?.value?.displayValue
      ? `${mppTrackers.fieldTitle}: ${mppTrackers?.value?.displayValue} |`
      : ''

    const stringsPerMppTrackerText = stringsPerMppTracker?.value?.displayValue
      ? `${stringsPerMppTracker.fieldTitle}: ${stringsPerMppTracker?.value?.displayValue} |`
      : ''

    const vlamboogDetectieText = vlamboogDetectie?.value?.displayValue
      ? `${vlamboogDetectie.fieldTitle}: ${vlamboogDetectie?.value?.displayValue} |`
      : ''

    const ratedACPowerText = ratedACPower?.value?.displayValue
      ? `${ratedACPower.fieldTitle}: ${ratedACPower.value.displayValue} |`
      : ''

    const quantityPerBox = specifications.find(
      (spec) => spec.field === 'quantityPerBox',
    )

    const quantityPerPalet = specifications.find(
      (spec) => spec.field === 'quantityPerPalet',
    )

    const quantityPerBoxText = quantityPerBox?.value?.displayValue
      ? `${quantityPerBox.fieldTitle}: ${quantityPerBox?.value?.displayValue} |`
      : ''

    const quantityPerPaletText = quantityPerPalet?.value?.displayValue
      ? `${quantityPerPalet.fieldTitle}: ${quantityPerPalet.value.displayValue} |`
      : ''

    return `${ratedACPowerText} ${mppTrackersText} ${stringsPerMppTrackerText} ${vlamboogDetectieText} ${warrantyText} ${quantityPerBoxText} ${quantityPerPaletText} ${connectorTypeText}`
  }
  if (
    categories &&
    categories.find(
      (cat) =>
        cat.name === 'Groepenkast componenten' ||
        cat.name === 'Werkschakelaars',
    )
  ) {
    const nominalCurrent = specifications.find(
      (spec) => spec.field === 'nominalCurrent',
    )

    const numberOfPoles = specifications.find(
      (spec) => spec.field === 'numberOfPoles',
    )

    const degreeOfProtection = specifications.find(
      (spec) => spec.field === 'degreeOfProtection',
    )

    const trippingCharacteristic = specifications.find(
      (spec) => spec.field === 'trippingCharacteristic',
    )

    const nominalCurrentText = nominalCurrent?.value?.displayValue
      ? `${nominalCurrent.fieldTitle}: ${nominalCurrent.value.displayValue} |`
      : ''

    const numberOfPolesText = numberOfPoles?.value?.displayValue
      ? `${numberOfPoles.fieldTitle}: ${numberOfPoles.value.displayValue} |`
      : ''

    const degreeOfProtectionText = degreeOfProtection?.value?.displayValue
      ? `${degreeOfProtection.fieldTitle}: ${degreeOfProtection.value.displayValue}`
      : ''

    const trippingCharacteristicText = trippingCharacteristic?.value
      ?.displayValue
      ? `${trippingCharacteristic.fieldTitle}: ${trippingCharacteristic.value.displayValue} |`
      : ''

    return `${nominalCurrentText} ${numberOfPolesText} ${trippingCharacteristicText} ${degreeOfProtectionText}`
  }

  if (
    categories &&
    categories.find((cat) => cat.name === 'Installatiekabels') &&
    categories.find((cat) => cat.name === 'AC Materiaal')
  ) {
    const numberOfCores = specifications.find(
      (spec) => spec.field === 'numberOfCores',
    )

    const numberOfCoresText = numberOfCores?.value?.displayValue
      ? `${numberOfCores.fieldTitle}: ${numberOfCores.value.displayValue} |`
      : ''

    const nominalInsulationThickness = specifications.find(
      (spec) => spec.field === 'nominalInsulationThickness',
    )

    const nominalInsulationThicknessText = nominalInsulationThickness?.value
      ?.displayValue
      ? `${nominalInsulationThickness.fieldTitle}: ${nominalInsulationThickness.value.displayValue} |`
      : ''

    const outerDiamater = specifications.find(
      (spec) => spec.field === 'outerDiameter',
    )

    const outerDiamaterText = outerDiamater?.value?.displayValue
      ? `${outerDiamater.fieldTitle}: ${outerDiamater.value.displayValue} |`
      : ''

    const nominalConductorCrossSection = specifications.find(
      (spec) => spec.field === 'nominalConductorCrossSection',
    )

    const nominalConductorCrossSectionText = nominalConductorCrossSection?.value
      ?.displayValue
      ? `${nominalConductorCrossSection.fieldTitle}: ${nominalConductorCrossSection.value.displayValue} |`
      : ''

    return `${numberOfCoresText} ${nominalInsulationThicknessText} ${outerDiamaterText} ${nominalConductorCrossSectionText}`
  }

  if (
    categories &&
    categories.find(
      (cat) =>
        cat.name === 'PV-verdelers' || cat.name === 'Installatiematerialen',
    )
  ) {
    const degreeOfProtection = specifications.find(
      (spec) => spec.field === 'degreeOfProtection',
    )

    const degreeOfProtectionText = degreeOfProtection?.value?.displayValue
      ? `${degreeOfProtection.fieldTitle}: ${degreeOfProtection.value.displayValue} |`
      : ''
    const height = specifications.find((spec) => spec.field === 'height')
    const heightText = height?.value?.displayValue
      ? `${height.fieldTitle}: ${height.value.displayValue} |`
      : ''
    const width = specifications.find((spec) => spec.field === 'width')
    const widthText = width?.value?.displayValue
      ? `${width.fieldTitle}: ${width.value.displayValue} |`
      : ''
    const depth = specifications.find((spec) => spec.field === 'width')
    const depthText = depth?.value?.displayValue
      ? `${depth.fieldTitle}: ${depth.value.displayValue}`
      : ''

    return `${degreeOfProtectionText} ${heightText} ${widthText} ${depthText}`
  }

  if (categories && categories.find((cat) => cat.name === 'Connectoren')) {
    const solarPanelCompatibility = specifications.find(
      (spec) => spec.field === 'solarPanelCompatibility',
    )

    const solarPanelCompatibilityText = solarPanelCompatibility?.value
      ?.displayValue
      ? `${solarPanelCompatibility.fieldTitle}: ${solarPanelCompatibility.value.displayValue}`
      : ''

    return `${solarPanelCompatibilityText}`
  }
  if (categories && categories.find((cat) => cat.name === 'Solarkabels')) {
    const diamater = specifications.find((spec) => spec.field === 'diamater')
    const diamaterText = diamater?.value?.displayValue
      ? `${diamater.fieldTitle}: ${diamater.value.displayValue} |`
      : ''

    const lengthText = length?.value?.displayValue
      ? `${length.fieldTitle}: ${length.value.displayValue} |`
      : ''

    const maximumVoltage = specifications.find(
      (spec) => spec.field === 'maximumVoltage',
    )

    const maximumVoltageText = maximumVoltage?.value?.displayValue
      ? `${maximumVoltage.fieldTitle}: ${maximumVoltage.value.displayValue} |`
      : ''
    const color = specifications.find((spec) => spec.field === 'color')
    const colorText = color?.value?.displayValue
      ? `${color.fieldTitle}: ${color.value.displayValue}`
      : ''

    return `${diamaterText} ${lengthText} ${maximumVoltageText} ${warrantyText} ${colorText}`
  }

  return ''
}
