import { Trans } from '@lingui/macro'

export function getPriceWithCurrency(
  price: number | null | undefined,
  currency: string | null | undefined,
  digits: number = 2,
  showFree: boolean = true,
) {
  return price || (!price && !showFree) ? (
    new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: currency ?? 'EUR',
      minimumFractionDigits: digits,
    }).format(price ?? 0)
  ) : (
    <Trans>Free</Trans>
  )
}
