import { globalWindow } from '@emico-utils/ssr-utils'

export const detectCurrentBrowser = () => {
  if (typeof globalWindow !== 'undefined' && navigator) {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) !== -1
    ) {
      return 'Opera'
    } else if (navigator.userAgent.indexOf('Edg') !== -1) {
      return 'Edge'
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'Chrome'
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'Safari'
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'Firefox'
    } else if (
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      Boolean(globalWindow.document.DOCUMENT_NODE)
    ) {
      //IF IE > 10
      return 'IE'
    } else {
      return 'unknown'
    }
  } else {
    return 'unknown'
  }
}
