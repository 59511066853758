import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
  colorTheme?: 'default' | 'white'
}

const Logo = ({ colorTheme = 'default', ...other }: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 111 46"
    fill="none"
    aria-hidden="true"
    {...other}
  >
    <path
      d="M19.573 36.44c-.346 0-.696-.098-1.002-.305a1.82 1.82 0 01-.503-2.515l6.28-9.43h-4.96a1.807 1.807 0 01-1.805-1.81v-5.498a1.807 1.807 0 113.612 0v3.688h6.531a1.81 1.81 0 011.505 2.817l-8.158 12.25a1.796 1.796 0 01-1.5.804z"
      fill={colorTheme === 'white' ? '#ffffff' : '#61B1E7'}
    />
    <path
      d="M39.891 9.77v6.097c1.32-.575 3.374-1.128 5.31-1.128 5.072 0 8.01 2.988 8.427 7.893.117 1.424.117 4.196 0 5.58-.395 4.869-3.611 8.148-8.962 8.148-3.75 0-6.926-1.72-7.973-2.47-.611-.396-.768-.81-.768-1.505V9.77c0-1.146.867-2.058 1.994-2.058 1.083 0 1.972.912 1.972 2.058zm0 10.05v11.159c.786.615 2.979 1.464 4.7 1.464 3.117 0 4.833-1.604 5.107-4.667.099-1.25.058-3.603 0-4.654-.216-2.93-1.698-4.569-4.735-4.569-2.152.005-4.24.755-5.072 1.267zM62.748 9.77v24.358c0 1.127-.85 2.039-1.977 2.039-1.083 0-1.972-.912-1.972-2.04V9.772c0-1.146.867-2.075 1.972-2.075 1.087 0 1.977.93 1.977 2.075zm23.143 7.206v15.57c0 .71-.193 1.146-.746 1.519-1.203.83-3.97 2.295-8.112 2.295-5.27 0-8.643-2.987-8.643-8.31V17.012c0-1.186.75-2.098 1.954-2.098 1.105 0 1.99.912 1.99 2.098v10.943c0 2.83 1.82 4.488 4.757 4.488 2.21 0 3.81-.674 4.838-1.325V16.976c0-1.15.845-2.057 1.972-2.057 1.105-.005 1.99.907 1.99 2.057zm22.816 6.505v1.19c0 1.442-.768 2.43-2.547 2.43H94.813v.594c0 3.284 2.309 5.044 5.507 5.044 1.757 0 3.095-.291 4.164-.79.553-.256.966-.396 1.362-.396.947 0 1.756.791 1.756 1.784 0 .651-.337 1.303-1.262 1.76-1.384.67-3.513 1.263-6.141 1.263-5.485 0-8.8-3.481-9.155-8.503-.1-1.488-.081-3.644.018-4.888.395-5.085 3.966-8.23 8.944-8.23 5.269 0 8.701 3.522 8.701 8.742zm-13.894.615h9.689c.355 0 .495-.139.512-.534.221-3.243-1.832-5.382-4.99-5.382-3.554 0-5.27 2.273-5.211 5.916zM13.158 45.602a1.808 1.808 0 01-1.81-1.81V27.945H3.45c-.665 0-1.28-.368-1.595-.961a1.819 1.819 0 01.09-1.86l15.727-23.61a1.803 1.803 0 012.507-.5 1.818 1.818 0 01.498 2.516L6.833 24.317h6.325c.998 0 1.806.808 1.806 1.81V43.79c0 1.002-.809 1.81-1.806 1.81z"
      fill={colorTheme === 'white' ? '#ffffff' : '#000351'}
    />
  </svg>
)

export default Logo
