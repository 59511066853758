import { RadioCustom as BaseRadioCustom } from '@emico-react/input-radio'
import { StyledComponent } from '@emotion/styled'
import { ForwardRefExoticComponent, RefAttributes } from 'react'

import RadioCustom from '../components/RadioCustom'

BaseRadioCustom.override(
  RadioCustom as ForwardRefExoticComponent<RefAttributes<HTMLInputElement>> &
    StyledComponent<RefAttributes<HTMLInputElement>>,
)
