const zIndices = {
  loader: 97,
  modalBackground: 98,
  modal: 99,
  below: -1,
  editorBackground: 1,
  editor: 2,
  productInfo: 3,
  buttonsAndToolbars: 4,
  sideMenu: 4,
  header: 5,
  eyeDropper: 10,
  notifications: 9999,
}

export default zIndices
