import { defaultDataIdFromObject, IdGetter, StoreObject } from '@apollo/client'

import { EntityUrl } from '@emico/graphql-schema-types/src'

// TODO: re-implement cart dataIdFromObject
// import { dataIdFromObject as cartDataIdFromObject } from '@emico/cart'
// import {
//   EntityUrl,
//   SelectedConfigurableOption,
// } from '@emico/graphql-schema-types'

/**
 * Generate a unique ID for the Apollo cache.
 * @param object
 * @returns
 */
const dataIdFromObject: IdGetter = (object) => {
  // const cartDataId = cartDataIdFromObject(object)
  // if (cartDataId !== undefined) {
  //   return cartDataId
  // }

  switch (object.__typename) {
    case 'Customer':
    case 'Cart':
      return `${object.__typename}`
    case 'StoreConfig':
      return 'StoreConfig'
    case 'EntityUrl': {
      const entityUrl = object as unknown as EntityUrl

      return `${object.__typename}:${entityUrl.type}:${entityUrl.relativeUrl}`
    }
    // case 'SelectedConfigurableOption': {
    //   const option = object as unknown as SelectedConfigurableOption
    //   return `${option.__typename}:${option.configurableProductOptionUid}:${option.configurableProductOptionValueUid}`
    // }
    default:
      return defaultDataIdFromObject(object as Readonly<StoreObject>)
  }
}

export default dataIdFromObject
