import styled, { StyledComponent } from '@emotion/styled'
import { ForwardRefExoticComponent, RefAttributes } from 'react'

import { Input as BaseInput } from '@emico/input'

import theme from '../theme'

const StyledInput = styled(BaseInput.original)`
  width: 100%;
  padding: ${theme.spacing.x1};
  border: ${theme.borderStyles.thinDark};

  &:disabled {
    background-color: ${theme.colors.backgroundLight};
    cursor: not-allowed;
    color: ${theme.colors.textMuted};
  }

  &:focus {
    box-shadow: none;
    outline: 0;
  }
`

BaseInput.override(
  StyledInput as ForwardRefExoticComponent<RefAttributes<HTMLInputElement>> &
    StyledComponent<RefAttributes<HTMLInputElement>>,
)
