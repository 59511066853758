import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { globalWindow } from '@emico-utils/ssr-utils'
import merge from 'deepmerge'
import isEqual from 'lodash.isequal'
import { GetStaticPropsResult, PreviewData } from 'next'
import { useMemo } from 'react'

import { makeHttpLinkUri } from '@emico/apollo'
import { fragmentTypes } from '@emico/graphql-schema-types'

import getCraftPreviewHeaders from './craftPreviewHeaders'
import dataIdFromObject from './dataIdFromObject'
import typePolicies from './typePolicies'
import useLocale from '../../lib/useLocale'
import storeConfigs from '../../storeConfig.json'

const graphqlUri =
  String(
    globalWindow
      ? process.env.REACT_APP_APOLLO_URL
      : process.env.REACT_APP_SERVER_URL,
  ) + String(process.env.REACT_APP_APOLLO_GRAPHQL_URL)

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

export function createApolloClient(
  storeCode: string,
  previewData?: PreviewData,
) {
  const headers: Record<string, string> = {
    Store: storeCode,
    ...getCraftPreviewHeaders(previewData),
  }

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, stack }) => {
        console.error(
          `GraphQL error in operation ${operation.operationName} to ${graphqlUri}:`,
          operation.variables,
          message,
        )
      })
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`)
    }
  })

  const link = new HttpLink({
    uri: makeHttpLinkUri(graphqlUri),
    headers,
  })

  const cache = new InMemoryCache({
    dataIdFromObject,
    typePolicies,
    possibleTypes: fragmentTypes.possibleTypes,
  })

  return new ApolloClient({
    ssrMode: typeof globalWindow === 'undefined',
    link: errorLink.concat(link),
    cache,
  })
}

let apolloClient: ApolloClient<NormalizedCacheObject>

export function initializeApollo(
  locale: string,
  initialState: NormalizedCacheObject | null = null,
  previewData?: PreviewData,
) {
  const storeConfig = storeConfigs.storeViews.find((o) => o.locale === locale)

  const _apolloClient =
    apolloClient && !previewData
      ? apolloClient
      : createApolloClient(storeConfig?.code ?? 'default', previewData)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    const existingCache = _apolloClient.extract()

    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    })

    _apolloClient.cache.restore(data)
  }
  // For Preview's, SSG and SSR always create a new Apollo Client
  if (typeof globalWindow === 'undefined' || previewData) {
    return _apolloClient
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient
  }

  return _apolloClient
}

type PageProps<P> = GetStaticPropsResult<P> & {
  props: {
    [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject | null | undefined
    previewData?: PreviewData
  }
}

export function addApolloState<P>(
  client: ApolloClient<NormalizedCacheObject | null | undefined>,
  pageProps: PageProps<P>,
) {
  if (pageProps.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo<P>(pageProps: PageProps<P>['props']) {
  const state = pageProps?.[APOLLO_STATE_PROP_NAME]
  const locale = useLocale()

  return useMemo(
    () => initializeApollo(locale, state, pageProps.previewData),
    [locale, state, pageProps.previewData],
  )
}
