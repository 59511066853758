/* eslint-disable import/no-duplicates */

import '@emico/styles/reboot.css'
import '../lib/setupLegacyStoreViews'
import '../overrides'
import '../styles/fonts.css'
import '../styles/globals.css'

import { ApolloProvider } from '@apollo/client'
import getCatalog from '@catalogs'
import { CartIdProvider } from '@emico-hooks/cart-id'
import { LoginTokenProvider } from '@emico-hooks/login-token'
import { ActiveStoreViewProvider } from '@emico-hooks/use-active-storeview'
import { BreakpointsProvider as BreakpointsProviderV2 } from '@emico-react/breakpoints'
import { globalWindow } from '@emico-utils/ssr-utils'
import { getStoreViewByLocale } from '@emico-utils/storeview-manager'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { PortalTarget } from '@emico/portal'
import { BreakpointsProvider } from '@emico/ui'

import Meta from '../components/Meta'
import MetaOgDefaults from '../components/MetaOgDefaults'
import { getLinguiLocale } from '../lib/getLinguiLocale'
import getSeoPageDescription from '../lib/getSeoPageDescription'
import getSeoPageTitle from '../lib/getSeoPageTitle'
import { defaultStoreConfig } from '../lib/setupLegacyStoreViews'
import { useUpdateCatalogs } from '../lib/useUpdateCatalogs'
import { useApollo } from '../shared/apollo/apolloClient'
import { useDebugListener } from '../shared/debug/useDebugListener'
import { Modal } from '../shared/modal/Modal'
import { getMagentoSession } from '../shared/session/get'
import { MagentoSessionProvider } from '../shared/session/SessionContext'
import GlobalStyles from '../theme/GlobalStyles'

const App = ({ Component, pageProps }: AppProps) => {
  const apolloClient = useApollo(pageProps)

  useDebugListener()

  /**
   * Load and activate the pre-rendered catalog
   */
  const { defaultLocale = '', locale = defaultLocale } = useRouter()
  const linguiLocale = getLinguiLocale(locale)

  const catalog = getCatalog(linguiLocale)

  if (i18n.locale !== locale) {
    i18n.load({
      [linguiLocale]: catalog.messages,
    })
    i18n.activate(linguiLocale)
  }

  useUpdateCatalogs()

  const handleGuests = useCallback(async () => {
    const session = await getMagentoSession()
    const isDev = process.env.REACT_APP_ENV === 'dev'

    if (session) {
      if (session.guest === 1 && !isDev) {
        if (
          globalWindow &&
          globalWindow.location.pathname !== '/login' &&
          !globalWindow.location.pathname.includes('debug')
        ) {
          // globalWindow.location.replace('/customer/account/login/')
        }
      }
    }
  }, [])

  handleGuests()

  return (
    <ActiveStoreViewProvider
      storeView={getStoreViewByLocale(locale) ?? defaultStoreConfig}
      // storeView={defaultStoreConfig} // DE local testing
    >
      <PortalTarget>
        <I18nProvider i18n={i18n}>
          <GlobalStyles />

          <ApolloProvider client={apolloClient}>
            <Head>
              <meta
                content="width=device-width, initial-scale=1.0"
                name="viewport"
              />

              <link rel="shortcut icon" href="/app/favicon.png" />
            </Head>

            <Meta
              metaTitle={getSeoPageTitle()}
              metaDescription={getSeoPageDescription()}
            />
            <MetaOgDefaults />

            <BreakpointsProvider>
              <BreakpointsProviderV2>
                <CartIdProvider>
                  <LoginTokenProvider>
                    <MagentoSessionProvider>
                      <Modal />

                      <Component {...pageProps} />
                    </MagentoSessionProvider>
                  </LoginTokenProvider>
                </CartIdProvider>
              </BreakpointsProviderV2>
            </BreakpointsProvider>
          </ApolloProvider>
        </I18nProvider>
      </PortalTarget>
    </ActiveStoreViewProvider>
  )
}

export default App
