import { t } from '@lingui/macro'

export default function getSeoPageTitle(
  pageTitle?: string,
  pageTitleType: 'default' | 'website' = 'default',
) {
  let defaultTitle

  switch (pageTitleType) {
    case 'website':
      defaultTitle = t({ message: '4blue.nl' })
      break
    default:
      defaultTitle = t({ message: '4blue | Together, filled with energy' })
  }

  return pageTitle ? `${pageTitle} | ${defaultTitle}` : defaultTitle
}
