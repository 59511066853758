import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'

import theme from '../theme'

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => !['size'].includes(prop.toString()),
})<{ size?: 'regular' | 'large' }>`
  @keyframes spinner {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  position: relative;
  width: ${({ size }) => (size == 'large' ? '40px' : '20px')};
  height: ${({ size }) => (size == 'large' ? '40px' : '20px')};
  margin: 0 auto;
  border: 4px solid ${theme.colors.primary};
  border-left-color: transparent;
  border-radius: 50%;
  animation: spinner 0.65s linear infinite;
`

interface Props extends HTMLAttributes<HTMLDivElement> {
  size?: 'regular' | 'large'
}

const Loader = ({ size, ...others }: Props) => (
  <Wrapper size={size} {...others} data-testid="loader" />
)

export default Loader
