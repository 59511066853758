import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import React from 'react'

import { CrossIcon } from '@emico/icons'
import { maxWidth, minWidth } from '@emico/styles'
import { ButtonIcon, H1 } from '@emico/ui'

import theme, { getTypography } from '../../theme'
import { getImage } from '../card/utils'
import { BackIcon } from '../icon/BackIcon'
import { Product } from '../product/product'

const Wrapper = styled('div', {
  shouldForwardProp: (prop) =>
    !['isSafari', 'isBasic'].includes(prop.toString()),
})<{ isSafari?: boolean; isBasic: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.gray};
  background-color: ${({ isBasic }) =>
    isBasic ? theme.colors.background : theme.colors.backgroundLight};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  @media ${minWidth('lg')} {
    padding-left: ${({ isSafari }) => isSafari && `${theme.spacing.x3}`};
  }
`

const Title = styled(H1, {
  shouldForwardProp: (prop) => !['isBasic'].includes(prop.toString()),
})<{ isBasic: boolean }>`
  ${({ isBasic }) =>
    getTypography(isBasic ? 'headingRubik' : 'headingGalano', 15, 700)};

  @media ${minWidth('lg')} {
    font-size: ${theme.fontSizes[18]};
  }
`

const StyledButtonIconDesktop = styled(ButtonIcon, {
  shouldForwardProp: (prop) => !['isBasic'].includes(prop.toString()),
})<{ isBasic: boolean }>`
  height: 100%;
  border-left: 1px solid ${theme.colors.gray};
  padding: ${theme.spacing.x3} ${theme.spacing.x4};

  @media ${maxWidth('md')} {
    display: none;
  }

  &:focus {
    color: ${theme.colors.black};
  }

  ${'span'} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const StyledCrossIcon = styled(CrossIcon)`
  height: 14px;
  width: 14px;
`

const StyledBackIcon = styled(BackIcon)`
  color: ${theme.colors.black};
  height: 17px;
  width: 17px;
  margin-top: -2px;
`

const StyledButtonIconMobile = styled(ButtonIcon)`
  height: 100%;
  padding: ${theme.spacing.x3} ${theme.spacing.x15};

  > span {
    display: flex;
    align-items: center;
  }

  &:focus {
    color: ${theme.colors.black};
  }

  @media ${minWidth('lg')} {
    display: none;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing.x2};
  padding-right: ${theme.spacing.x2};
  width: 100%;

  @media ${minWidth('lg')} {
    padding: 0 ${theme.spacing.x4};
  }
`

const ProductImage = styled.img`
  width: auto;
  height: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const ImageWrapper = styled.div`
  height: 60px;
  width: 63px;
  min-height: 60px;
  min-width: 63px;
  border: 1px solid ${theme.colors.gray};
  padding: ${theme.spacing.x05};
`

interface ModalHeaderProps {
  product?: Product
  children?: React.ReactChild
  close: () => void
  isSafari?: boolean
  isBasic?: boolean
}

export const ModalHeader = ({
  product,
  children,
  close,
  isSafari,
  isBasic = false,
}: ModalHeaderProps) => (
  <Wrapper isSafari={isSafari} isBasic={isBasic}>
    <StyledButtonIconMobile
      label={t({ message: 'Close menu' })}
      analyticsContext="shopping-menu"
      analyticsName="close-menu"
      onClick={close}
    >
      <StyledBackIcon />
    </StyledButtonIconMobile>

    <ContentWrapper>
      {product && (
        <ImageWrapper>
          <ProductImage src={getImage(product)} />
        </ImageWrapper>
      )}

      <Title isBasic={isBasic}>{children}</Title>
    </ContentWrapper>

    <StyledButtonIconDesktop
      label={t({ message: 'Close menu' })}
      analyticsName="close-menu"
      analyticsContext="shopping-menu"
      onClick={close}
      isBasic={isBasic}
    >
      <StyledCrossIcon />
    </StyledButtonIconDesktop>
  </Wrapper>
)
