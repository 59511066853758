import { setupStoreViews } from '@emico-utils/storeview-manager'

import { setStoreViews, StoreView } from '@emico/storeviews'

import storeConfig from '../storeConfig.json'

const mapUrl = (url: string) =>
  new URL(url, storeConfig.storeViews[0].basename).toString()

export const defaultStoreConfig =
  storeConfig.storeViews.find((storeView) => storeView.code === 'nl_nl') ??
  storeConfig.storeViews[0]

/**
 * DE local testing
 */
// export const defaultStoreConfig =
//   storeConfig.storeViews.find((storeView) => storeView.code === 'de_DE') ??
//   storeConfig.storeViews[0]

setupStoreViews(storeConfig.storeViews, 'nl_NL')

/**
 * Backward compatible
 * Dependencies expect `@emico/storeviews` `setStoreViews`
 */
const storeViewOld: StoreView = {
  ...defaultStoreConfig,
  basename: '',
  location: /\//,
  makeUrl: mapUrl,
  name: { message: 'Shop', id: 'shop' },
  default: true,
}

setStoreViews([storeViewOld], storeViewOld)

export {}
