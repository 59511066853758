import styled from '@emotion/styled'
import { ReactNode } from 'react'

import BodyScrollLock from '@emico/body-scroll-lock'
import { Modal } from '@emico/modal'
import ModalBackdrop from '@emico/modal-backdrop'
import { ModalSegueRight } from '@emico/modal-segue'
import { minWidth } from '@emico/styles'

const Overlay = styled.div`
  position: relative;
  z-index: 98;
`

const StyledBodyScrollLock = styled(BodyScrollLock)`
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  width: 100%;

  @media ${minWidth('lg')} {
    max-width: 800px;
  }
`

interface Props {
  children: ReactNode
  open: boolean
  onClose: () => void
  className?: string
  noOffset?: boolean
  showCloseButton?: boolean
}
export const ModalSlideRight = ({
  children,
  open,
  onClose,
  className,
}: Props) => (
  <Overlay className={className}>
    <ModalBackdrop show={open} onClick={onClose} />

    <ModalSegueRight show={open}>
      <Modal close={onClose} show={open} hasFocusTrap={false}>
        <StyledBodyScrollLock lock={open}>{children}</StyledBodyScrollLock>
      </Modal>
    </ModalSegueRight>
  </Overlay>
)
