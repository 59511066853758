import { CheckboxCustomVisibleButton as BaseCheckboxCustomVisibleButton } from '@emico-react/input-checkbox'
import styled from '@emotion/styled'

import theme from '../theme'

const StyledCheckboxCustomVisibleButton = styled(
  BaseCheckboxCustomVisibleButton.original,
)`
  --emico-checkbox-custom-visible-button-active-color: ${theme.colors.darkGray};
  --emico-checkbox-custom-visible-button-focus-color: ${theme.colors.darkGray};
  --emico-checkbox-custom-visible-button-active-color-disabled: ${theme.colors
    .textMuted};
  --emico-checkbox-custom-visible-button-border: 1px solid
    ${theme.colors.darkGray};
  --emico-checkbox-custom-visible-button-border-selected: ${theme.colors
    .accentGreen};
  --emico-checkbox-custom-visible-button-border-disabled: 2px solid
    ${theme.colors.textMuted};
  --emico-checkbox-custom-visible-button-background: ${theme.colors
    .accentGreen};
  --emico-checkbox-custom-visible-button-checkmark-color: ${theme.colors.white};
  --emico-checkbox-custom-visible-button-border-radius: 0;
  margin-right: ${theme.spacing.x1};
`

BaseCheckboxCustomVisibleButton.override(
  StyledCheckboxCustomVisibleButton as typeof BaseCheckboxCustomVisibleButton.original,
)
