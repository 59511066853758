export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AggregationOptionInterface: ['AggregationOption'],
    AttributeSelectedOptionInterface: ['AttributeSelectedOption'],
    AttributeValueInterface: ['AttributeSelectedOptions', 'AttributeValue'],
    CartAddressInterface: ['BillingCartAddress', 'ShippingCartAddress'],
    CartItemInterface: [
      'BundleCartItem',
      'ConfigurableCartItem',
      'DownloadableCartItem',
      'GiftCardCartItem',
      'SimpleCartItem',
      'VirtualCartItem',
    ],
    CategoryInterface: ['CategoryTree'],
    CloseNegotiableQuoteError: [
      'InternalError',
      'NegotiableQuoteInvalidStateError',
      'NoSuchEntityUidError',
    ],
    CloseNegotiableQuoteOperationResult: [
      'CloseNegotiableQuoteOperationFailure',
      'NegotiableQuoteUidOperationSuccess',
    ],
    CompanyStructureEntity: ['CompanyTeam', 'Customer'],
    CreditMemoItemInterface: [
      'BundleCreditMemoItem',
      'CreditMemoItem',
      'DownloadableCreditMemoItem',
      'GiftCardCreditMemoItem',
    ],
    CustomAttributeMetadataInterface: [
      'AttributeMetadata',
      'CatalogAttributeMetadata',
      'CustomerAttributeMetadata',
      'ReturnItemAttributeMetadata',
    ],
    CustomAttributeOptionInterface: ['AttributeOptionMetadata'],
    CustomizableOptionInterface: [
      'CustomizableAreaOption',
      'CustomizableCheckboxOption',
      'CustomizableDateOption',
      'CustomizableDropDownOption',
      'CustomizableFieldOption',
      'CustomizableFileOption',
      'CustomizableMultipleOption',
      'CustomizableRadioOption',
    ],
    CustomizableProductInterface: [
      'BundleProduct',
      'ConfigurableProduct',
      'DownloadableProduct',
      'GiftCardProduct',
      'SimpleProduct',
      'VirtualProduct',
    ],
    DeleteNegotiableQuoteError: [
      'InternalError',
      'NegotiableQuoteInvalidStateError',
      'NoSuchEntityUidError',
    ],
    DeleteNegotiableQuoteOperationResult: [
      'DeleteNegotiableQuoteOperationFailure',
      'NegotiableQuoteUidOperationSuccess',
    ],
    ErrorInterface: [
      'InternalError',
      'NegotiableQuoteInvalidStateError',
      'NoSuchEntityUidError',
    ],
    GiftRegistryDynamicAttributeInterface: [
      'GiftRegistryDynamicAttribute',
      'GiftRegistryRegistrantDynamicAttribute',
    ],
    GiftRegistryDynamicAttributeMetadataInterface: [
      'GiftRegistryDynamicAttributeMetadata',
    ],
    GiftRegistryItemInterface: ['GiftRegistryItem'],
    GiftRegistryItemUserErrorInterface: [
      'GiftRegistryItemUserErrors',
      'MoveCartItemsToGiftRegistryOutput',
    ],
    GiftRegistryOutputInterface: [
      'GiftRegistryOutput',
      'MoveCartItemsToGiftRegistryOutput',
    ],
    InvoiceItemInterface: [
      'BundleInvoiceItem',
      'DownloadableInvoiceItem',
      'GiftCardInvoiceItem',
      'InvoiceItem',
    ],
    LayerFilterItemInterface: ['LayerFilterItem', 'SwatchLayerFilterItem'],
    MediaGalleryInterface: ['ProductImage', 'ProductVideo'],
    NegotiableQuoteAddressInterface: [
      'NegotiableQuoteBillingAddress',
      'NegotiableQuoteShippingAddress',
    ],
    NegotiableQuoteUidNonFatalResultInterface: [
      'NegotiableQuoteUidOperationSuccess',
    ],
    OrderItemInterface: [
      'BundleOrderItem',
      'DownloadableOrderItem',
      'GiftCardOrderItem',
      'OrderItem',
    ],
    PaymentConfigItem: [
      'ApplePayConfig',
      'GooglePayConfig',
      'HostedFieldsConfig',
      'PaymentCommonConfig',
      'SmartButtonsConfig',
    ],
    PhysicalProductInterface: [
      'BundleProduct',
      'ConfigurableProduct',
      'GiftCardProduct',
      'GroupedProduct',
      'SimpleProduct',
    ],
    PimcoreAnyDocumentTarget: [
      'PimcoreDocumentEmail',
      'PimcoreDocumentFolder',
      'PimcoreDocumentHardlink',
      'PimcoreDocumentLink',
      'PimcoreDocumentPage',
      'PimcoreDocumentSnippet',
    ],
    PimcoreAnyTarget: [
      'PimcoreObjectBundleProduct',
      'PimcoreObjectCategory',
      'PimcoreObjectManufacturer',
      'PimcoreObjectProduct',
      'PimcoreObjectProductGroup',
      'PimcoreObjectProductQuantity',
      'PimcoreObjectSelectOption',
      'PimcoreObjectStorageSystemConfiguration',
      'PimcoreObjectStoreView',
      'PimcoreObjectWebsite',
    ],
    PimcoreAssetTree: ['PimcoreAsset', 'PimcoreAssetFolder'],
    PimcoreDocument: [
      'PimcoreDocumentEmail',
      'PimcoreDocumentHardlink',
      'PimcoreDocumentLink',
      'PimcoreDocumentPage',
      'PimcoreDocumentSnippet',
    ],
    PimcoreDocumentElement: [
      'PimcoreDocumentEditableAreablock',
      'PimcoreDocumentEditableBlock',
      'PimcoreDocumentEditableCheckbox',
      'PimcoreDocumentEditableDate',
      'PimcoreDocumentEditableEmbed',
      'PimcoreDocumentEditableImage',
      'PimcoreDocumentEditableInput',
      'PimcoreDocumentEditableLink',
      'PimcoreDocumentEditableMultiselect',
      'PimcoreDocumentEditableNumeric',
      'PimcoreDocumentEditablePdf',
      'PimcoreDocumentEditableRelation',
      'PimcoreDocumentEditableRelations',
      'PimcoreDocumentEditableScheduledblock',
      'PimcoreDocumentEditableSelect',
      'PimcoreDocumentEditableTable',
      'PimcoreDocumentEditableTextarea',
      'PimcoreDocumentEditableVideo',
      'PimcoreDocumentEditableWysiwyg',
    ],
    PimcoreDocumentTree: [
      'PimcoreDocumentEmail',
      'PimcoreDocumentFolder',
      'PimcoreDocumentHardlink',
      'PimcoreDocumentLink',
      'PimcoreDocumentPage',
      'PimcoreDocumentSnippet',
    ],
    PimcoreElement: [
      'PimcoreAsset',
      'PimcoreDocumentEmail',
      'PimcoreDocumentHardlink',
      'PimcoreDocumentLink',
      'PimcoreDocumentPage',
      'PimcoreDocumentSnippet',
      'PimcoreObjectBundleProduct',
      'PimcoreObjectCategory',
      'PimcoreObjectManufacturer',
      'PimcoreObjectProduct',
      'PimcoreObjectProductGroup',
      'PimcoreObjectProductQuantity',
      'PimcoreObjectSelectOption',
      'PimcoreObjectStorageSystemConfiguration',
      'PimcoreObjectStoreView',
      'PimcoreObjectWebsite',
    ],
    PimcoreElementProperty: [
      'PimcorePropertyCheckbox',
      'PimcorePropertyObject',
      'PimcorePropertySelect',
      'PimcorePropertyText',
    ],
    PimcoreFieldType: [
      'PimcoreSpecificationsObjectBrickAttributeFloat',
      'PimcoreSpecificationsObjectBrickAttributeString',
      'PimcoreSpecificationsObjectBrickAttributeStrings',
    ],
    PimcoreHotspotMetadata: [
      'PimcorePropertyAsset',
      'PimcorePropertyCheckbox',
      'PimcorePropertyDocument',
      'PimcorePropertyObject',
      'PimcorePropertyText',
      'PimcorePropertyTextarea',
    ],
    PimcoreHotspotMetadataObject: [
      'PimcoreObjectCategory',
      'PimcoreObjectManufacturer',
      'PimcoreObjectProduct',
      'PimcoreObjectProductGroup',
    ],
    PimcoreObjectProductCategories: ['PimcoreObjectCategory'],
    PimcoreObjectProductManufacturer: ['PimcoreObjectManufacturer'],
    PimcoreObjectProductProductGroup: ['PimcoreObjectProductGroup'],
    PimcoreObjectTree: [
      'PimcoreObjectCategory',
      'PimcoreObjectManufacturer',
      'PimcoreObjectProduct',
      'PimcoreObjectProductGroup',
    ],
    ProductInterface: [
      'BundleProduct',
      'ConfigurableProduct',
      'DownloadableProduct',
      'GiftCardProduct',
      'GroupedProduct',
      'SimpleProduct',
      'VirtualProduct',
    ],
    ProductLinksInterface: ['ProductLinks'],
    PurchaseOrderApprovalRuleConditionInterface: [
      'PurchaseOrderApprovalRuleConditionAmount',
      'PurchaseOrderApprovalRuleConditionQuantity',
    ],
    RequisitionListItemInterface: [
      'BundleRequisitionListItem',
      'ConfigurableRequisitionListItem',
      'DownloadableRequisitionListItem',
      'GiftCardRequisitionListItem',
      'SimpleRequisitionListItem',
      'VirtualRequisitionListItem',
    ],
    RoutableInterface: [
      'BlogAuthor',
      'BlogCategory',
      'BlogCommentsOutput',
      'BlogPost',
      'BlogPostsOutput',
      'BlogTag',
      'BundleProduct',
      'CategoryTree',
      'CmsPage',
      'ConfigurableProduct',
      'DownloadableProduct',
      'GiftCardProduct',
      'GroupedProduct',
      'RoutableUrl',
      'SimpleProduct',
      'VirtualProduct',
    ],
    ShipmentItemInterface: [
      'BundleShipmentItem',
      'GiftCardShipmentItem',
      'ShipmentItem',
    ],
    SwatchDataInterface: [
      'ColorSwatchData',
      'ImageSwatchData',
      'TextSwatchData',
    ],
    SwatchLayerFilterItemInterface: ['SwatchLayerFilterItem'],
    WishlistItemInterface: [
      'BundleWishlistItem',
      'ConfigurableWishlistItem',
      'DownloadableWishlistItem',
      'GiftCardWishlistItem',
      'GroupedProductWishlistItem',
      'SimpleWishlistItem',
      'VirtualWishlistItem',
    ],
  },
}
export default result
