import { TypedDocumentNode, gql } from '@apollo/client'

import { productFragment } from './fragments'
import {
  GetProductQuery,
  GetProductQueryVariables,
} from './getProduct.generated'

export const getProductQuery = gql`
  query GetProduct($id: Int!, $defaultLanguage: String) {
    getProduct(id: $id, defaultLanguage: $defaultLanguage) {
      ...ProductFragment
    }
  }
  ${productFragment}
` as TypedDocumentNode<GetProductQuery, GetProductQueryVariables>
