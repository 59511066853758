import { parseHTML } from '@4blue-html-parser'
import { HTMLContentBase } from '@emico-react/html-content'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'

import { H2, H3, H4, H5, H6 } from '@emico/ui'

import NextLinkPrimary from './NextLinkPrimary'
import theme from '../theme'

const listStyling = css`
  margin-bottom: ${theme.spacing.x25};
  list-style: none;

  &:last-child {
    margin-bottom: 0;
  }
`

const Paragraph = styled.p`
  margin-bottom: ${theme.spacing.x2};

  &:last-child {
    margin-bottom: 0;
  }
`

const ListItem = styled.li`
  display: flex;
  margin-bottom: ${theme.spacing.x05};

  &:last-child {
    margin-bottom: 0;
  }
`

const UnorderedList = styled.ul`
  ${listStyling};
  list-style: circle;
`

const OrderedList = styled.ol`
  ${listStyling};
  counter-reset: item;

  ${ListItem} {
    counter-increment: item;

    &::before {
      content: counter(item) '.';
      margin-right: ${theme.spacing.x1};
    }
  }
`

const headerStyling = css`
  margin-bottom: ${theme.spacing.x1};
`

const StyledH2 = styled(H2)`
  ${headerStyling}
`

const StyledH3 = styled(H3)`
  ${headerStyling}

  font-weight: ${theme.fontWeights[700]};
`

const StyledH4 = styled(H4)`
  ${headerStyling}
`

const StyledH5 = styled(H5)`
  ${headerStyling}
`

const StyledH6 = styled(H6)`
  ${headerStyling}
`

const HtmlContent = ({
  allowStyles = false,
  html,
  removeSingleParagraphWrapper,
  ...rest
}: Omit<
  ComponentProps<typeof HTMLContentBase>,
  'componentMapping' | 'parser'
>) => (
  <HTMLContentBase
    html={html}
    parser={parseHTML}
    componentMapping={{
      A: (node, index, children) => {
        const a = node as HTMLAnchorElement

        return (
          <NextLinkPrimary
            key={`${index}%%${a.href}`}
            href={a.getAttribute('href') ?? ''}
            analyticsContext=""
            analyticsName=""
            target={a.getAttribute('target') ?? undefined}
          >
            {children}
          </NextLinkPrimary>
        )
      },
      H2: (_node, index, children) => (
        <StyledH2 key={index}>{children}</StyledH2>
      ),
      H3: (_node, index, children) => (
        <StyledH3 key={index}>{children}</StyledH3>
      ),
      H4: (_node, index, children) => (
        <StyledH4 key={index}>{children}</StyledH4>
      ),
      H5: (_node, index, children) => (
        <StyledH5 key={index}>{children}</StyledH5>
      ),
      H6: (_node, index, children) => (
        <StyledH6 key={index}>{children}</StyledH6>
      ),
      P: (_node, index, children) => (
        <Paragraph key={index}>{children}</Paragraph>
      ),
      UL: (_node, index, children) => (
        <UnorderedList key={index}>{children}</UnorderedList>
      ),
      OL: (_node, index, children) => (
        <OrderedList key={index}>{children}</OrderedList>
      ),
      LI: (node, index, children) => (
        <ListItem key={index}>
          <div>{children}</div>
        </ListItem>
      ),
    }}
    removeSingleParagraphWrapper={removeSingleParagraphWrapper}
    allowStyles={allowStyles}
    {...rest}
  />
)

export default HtmlContent
