import { RadioCustom as BaseRadioCustom } from '@emico-react/input-radio'
import styled from '@emotion/styled'
import { ComponentProps, Ref, forwardRef, useState } from 'react'

import theme from '../theme'

const StyledRadioCustom = styled(BaseRadioCustom.original, {
  shouldForwardProp: (prop) =>
    ![
      'hasCustomSelectState',
      'customSelectStyle',
      'isSelected',
      'hasDisabledState',
    ].includes(prop.toString()),
})<{
  hasCustomSelectState?: boolean
  customSelectStyle?: 'default' | 'simple'
  isSelected?: boolean
  hasDisabledState?: boolean
}>`
  display: flex;
  align-items: flex-start;
  cursor: ${({ isSelected, hasDisabledState }) =>
    isSelected || hasDisabledState ? 'default' : 'pointer'};
  gap: ${({ hasCustomSelectState }) =>
    hasCustomSelectState && theme.spacing.x25};
  position: relative;
  padding: ${theme.spacing.x3};
  width: 100%;
  border: ${({ hasCustomSelectState }) =>
    hasCustomSelectState && theme.borderStyles.thinDark};
  border-color: ${({ hasCustomSelectState, customSelectStyle, isSelected }) =>
    hasCustomSelectState && customSelectStyle === 'simple' && isSelected
      ? theme.colors.accentGreen
      : theme.colors.gray};
  box-shadow: ${({ hasCustomSelectState, customSelectStyle, isSelected }) =>
    hasCustomSelectState &&
    `0px 0px 20px 0px rgba(0, 0, 0, 0.05) ${
      isSelected && customSelectStyle === 'default'
        ? `, inset 3px 0 0 0 ${theme.colors.accentGreen}`
        : `, inset 0 0 0 0 ${theme.colors.accentGreen}`
    }`};
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};

  > * {
    opacity: ${({ hasDisabledState }) => hasDisabledState && '0.3'};
  }
`

interface Props extends ComponentProps<typeof BaseRadioCustom> {
  hasCustomSelectState?: boolean
  customSelectStyle?: 'default' | 'simple'
  handleCustomRadioState?(isSelected: boolean): void
}

const RadioCustom = (
  {
    hasCustomSelectState = false,
    customSelectStyle = 'default',
    handleCustomRadioState,
    children,
    isDisabled,
    ...other
  }: Props,
  ref: Ref<HTMLInputElement>,
) => {
  const [isSelected, setIsSelected] = useState<boolean>(false)

  const handleStateChange = (isSelected: boolean) => {
    setIsSelected(isSelected)

    if (handleCustomRadioState) {
      handleCustomRadioState(isSelected)
    }
  }

  return (
    <StyledRadioCustom
      ref={ref}
      handleStateChange={handleStateChange}
      isSelected={isSelected}
      isDisabled={isDisabled}
      hasDisabledState={isDisabled}
      hasCustomSelectState={hasCustomSelectState}
      customSelectStyle={customSelectStyle}
      {...other}
    >
      {children}
    </StyledRadioCustom>
  )
}

RadioCustom.displayName = 'RadioCustom'

export default forwardRef(RadioCustom) as typeof RadioCustom
