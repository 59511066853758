import { css } from '@emotion/react'

import defaultsToVars, { defaultsToCss } from './cssVarsUtils'

export const fonts = {
  headingRubik: "'Rubik', sans-serif",
  bodyRubik: "'Rubik', sans-serif",
  headingGalano: "'GalanoGrotesque', serif",
  bodyGalano: "'GalanoGrotesque', sans-serif",
  headingProxima: "'ProximaNova', serif",
  bodyProxima: "'ProximaNova', sans-serif",
}

export const fontWeights = {
  400: 400,
  500: 500,
  600: 600,
  700: 700,
}

const fontSizes = {
  7: '7px',
  10: '10px',
  12: '12px',
  13: '13px',
  14: '14px',
  15: '15px',
  16: '16px',
  18: '18px',
  20: '20px',
  24: '24px',
  32: '32px',
  forms: '16px',
  body: '14px',
}

type BodyFontSizes = Omit<typeof fontSizes, 18 | 20 | 24 | 32>

export const lineHeightsBody: Record<keyof BodyFontSizes, string> = {
  7: '7px',
  10: '21px',
  12: '18px',
  13: '24px',
  14: '21px',
  15: '24px',
  16: '24px',
  forms: '20px',
  body: '21px',
}

type HeaderFontSizes = Omit<typeof fontSizes, 7 | 10 | 12 | 13 | 15>

export const lineHeightsHeader: Record<keyof HeaderFontSizes, string> = {
  14: '21px',
  16: '24px',
  18: '22px',
  20: '28px',
  24: '36px',
  32: '42px',
  forms: '22px',
  body: '21px',
}

export const typographyCss = [
  ...defaultsToCss(fonts, 'font'),
  ...defaultsToCss(fontWeights, 'font-weight'),
  ...defaultsToCss(fontSizes, 'font-size'),
  ...defaultsToCss(lineHeightsBody, 'line-height-body'),
  ...defaultsToCss(lineHeightsHeader, 'line-height-heading'),
]

const fontVars = defaultsToVars(fonts, 'font')
const fontSizesVars = defaultsToVars(fontSizes, 'font-size')
const fontWeightVars = defaultsToVars(fontWeights, 'font-weight')
const lineHeightHeadingVars = defaultsToVars(fontSizes, 'line-height-heading')
const lineHeightBodyVars = defaultsToVars(fontSizes, 'line-height-body')

export const getTypography = (
  family: keyof typeof fonts,
  size: keyof typeof fontSizes,
  fontWeight?: keyof typeof fontWeights,
) => css`
  font-family: ${fontVars[family]};
  font-size: ${fontSizesVars[size]};
  line-height: ${family === ('bodyGalano' || 'bodyProxima')
    ? lineHeightBodyVars[size]
    : lineHeightHeadingVars[size]};
  ${fontWeight === undefined
    ? `font-weight: ${fontWeights[400]}`
    : `font-weight: ${fontWeight && fontWeight}`};
`

export default {
  fonts: fontVars,
  fontWeights: fontWeightVars,
  lineHeightsBody: lineHeightBodyVars,
  lineHeightsHeader: lineHeightHeadingVars,
  fontSizes: fontSizesVars,
}
