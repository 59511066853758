import { globalWindow } from '@emico-utils/ssr-utils'
import { useState, useEffect, useCallback } from 'react'

export const useDebugListener = () => {
  const [keys, setKeys] = useState<string[]>([])

  const isKonamiCode =
    keys.join(' ') === 'up up down down left right left right B A'

  const toggleDebug = useCallback(() => {
    if (globalWindow) {
      const currentDebug = globalWindow.localStorage.getItem('debug')

      if (currentDebug) {
        globalWindow.localStorage.removeItem('debug')
      } else {
        globalWindow.localStorage.setItem('debug', '1')
      }
    }
  }, [])

  useEffect(() => {
    if (globalWindow) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let timeout: any

      globalWindow.document.onkeydown = (e) => {
        const keyName = getKeyName(e.keyCode)

        if (keyName) {
          setKeys((currentKeys) => [...currentKeys, keyName])
        }

        clearTimeout(timeout)

        timeout = setTimeout(() => setKeys([]), 5000)
      }
    }
  }, [])

  useEffect(() => {
    if (isKonamiCode) {
      toggleDebug()
      setKeys([])
    }
  }, [isKonamiCode, toggleDebug])
}

const getKeyName = (keyCode: number) =>
  ({
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down',
    65: 'A',
    66: 'B',
  })[keyCode]
