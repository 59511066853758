import { CheckboxCustom as BaseCheckboxCustom } from '@emico-react/input-checkbox'
import styled from '@emotion/styled'
import { ChangeEvent, ComponentProps, forwardRef, useState } from 'react'

import { minWidth } from '@emico/styles'

import CheckmarkIcon from '../shared/icon/CheckmarkIcon'
import theme from '../theme'

const StyledCheckboxCustom = styled(BaseCheckboxCustom.original, {
  shouldForwardProp: (prop) =>
    !['hasCustomSelectState', 'isSelected'].includes(prop.toString()),
})<{ hasCustomSelectState?: boolean; isSelected?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  box-shadow: ${({ hasCustomSelectState, isSelected }) =>
    hasCustomSelectState &&
    (isSelected
      ? `0 0 0 2px ${theme.colors.darkGray}`
      : `0 0 0 1px ${theme.colors.gray}`)};
`

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: ${theme.colors.darkGray};
  color: ${theme.colors.white};
  border-radius: 50%;
  padding: 7px;

  @media ${minWidth('lg')} {
    font-size: 28px;
  }
`

interface Props extends ComponentProps<typeof BaseCheckboxCustom> {
  /**
   * Should the checkbox have a predefined custom select state, like a checkbox 'card'?
   */
  hasCustomSelectState?: boolean
  /**
   * Should the checkbox have a controlled selected value?
   * Only use when the checkbox interaction should be dependent on another factor,
   * e.g. a dynamic value or pressing another button should change the checkbox selected state.
   */
  isSelectedControlled?: boolean
  /**
   * Function to pass isSelected CheckboxCustom state to parent in order to change parent based on checkbox isSelected state.
   * This can also be helpful to prevent a delay in the visually communicated checked/unchecked state when checkbox is controlled.
   */
  handleStateChange?(isSelected: boolean): void
  onChange(e: ChangeEvent): void
}

const CheckboxCustom = forwardRef<HTMLInputElement, Props>(
  (
    {
      hasCustomSelectState = false,
      isSelectedControlled,
      children,
      handleStateChange,
      onChange,
      ...other
    }: Props,
    ref,
  ) => {
    const [isSelected, setIsSelected] = useState<boolean>(false)

    // Necessary to communicate the current checkbox state to parent and within component for custom state-dependant markup and styling
    const handleChange = (e: ChangeEvent) => {
      if ('checked' in e.currentTarget) {
        const checked = e.currentTarget.checked as boolean

        setIsSelected(checked)
        handleStateChange?.(checked)
      }
    }

    const onChangeFiltered = (e: true | ChangeEvent) =>
      typeof e === 'object' && 'target' in e ? onChange(e) : void 0

    return (
      <StyledCheckboxCustom
        ref={ref}
        isSelected={isSelected}
        hasCustomSelectState={hasCustomSelectState}
        checked={isSelectedControlled}
        onChange={(e) => {
          handleChange(e)
          onChangeFiltered(e)
        }}
        {...other}
      >
        {children}

        {isSelected && hasCustomSelectState && <StyledCheckmarkIcon />}
      </StyledCheckboxCustom>
    )
  },
)

CheckboxCustom.displayName = 'CheckboxCustom'

export default CheckboxCustom
