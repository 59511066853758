import { PreviewData } from 'next'

type PreviewDataFields = {
  previewId?: string
  previewToken?: string
}

export default function getCraftPreviewHeaders(previewData?: PreviewData) {
  if (!previewData || typeof previewData === 'string') {
    return
  }
  const previewId = (previewData as PreviewDataFields).previewId
  const previewToken = (previewData as PreviewDataFields).previewToken

  return {
    'x-craft-live-preview': previewId,
    'x-craft-token': previewToken,
  }
}
