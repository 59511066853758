import styled from '@emotion/styled'
import { ComponentProps } from 'react'

import NextLink from './NextLink'
import theme from '../theme'

const StyledNextLink = styled(NextLink)`
  color: var(--next-link-basic-color, ${theme.colors.primary});
  text-decoration: underline;

  &:hover {
    color: var(--next-link-basic-color, ${theme.colors.primary});
    text-decoration: underline;
  }
`

type Props = ComponentProps<typeof NextLink>

const NextLinkPrimary = (props: Props) => <StyledNextLink {...props} />

export default NextLinkPrimary
