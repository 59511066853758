import colors from './colors'
import defaultsToVars, { defaultsToCss } from './cssVarsUtils'

const borderPrefix = 'border'
const borderRadiusPrefix = 'border-radius'
const borderStylePrefix = 'border-style'

const borderDefaults = {
  none: 0,
  sm: '1px',
  md: '2px',
  lg: '4px',
  xl: '5px',
}

const borderRadiusDefaults = {
  none: 0,
  sm: '3px',
  md: '4px',
  lg: '8px',
}

const borderStyleDefaults = {
  default: `1px solid ${colors.lightGray}`,
  thick: `3px solid ${colors.lightGray}`,
  thinDark: `1px solid ${colors.gray}`,
  thickDark: `3px solid ${colors.gray}`,
}

const borders = defaultsToVars(borderDefaults, borderPrefix)
const borderRadius = defaultsToVars(borderRadiusDefaults, borderRadiusPrefix)
const borderStyles = defaultsToVars(borderStyleDefaults, borderStylePrefix)

export const bordersCss = [
  ...defaultsToCss(borderDefaults, borderPrefix),
  ...defaultsToCss(borderRadiusDefaults, borderRadiusPrefix),
  ...defaultsToCss(borderStyleDefaults, borderStylePrefix),
]

export default {
  borders,
  borderRadius,
  borderStyles,
}
