import styled from '@emotion/styled'

import { H1, H2, H3, H4, H5, H6 } from '@emico/ui'

import theme, { getTypography } from '../theme'

H1.override(styled.h1`
  ${getTypography('headingRubik', 32, 500)};
  color: var(--color-h1, ${theme.colors.textPrimary});
  margin: 0;
`)

H2.override(styled.h2`
  ${getTypography('headingRubik', 24, 500)};
  color: var(--color-h2, ${theme.colors.textPrimary});
  margin: 0;
`)

H3.override(styled.h3`
  ${getTypography('headingRubik', 24, 500)};
  color: var(--color-h3, ${theme.colors.textPrimary});
  margin: 0;
`)

H4.override(styled.h4`
  ${getTypography('headingRubik', 16, 400)};
  color: var(--color-h4, ${theme.colors.textPrimary});
  margin: 0;
`)

H5.override(styled.h5`
  ${getTypography('headingRubik', 16, 400)};
  color: var(--color-h5, ${theme.colors.textPrimary});
  margin: 0;
`)

H6.override(styled.h6`
  ${getTypography('headingRubik', 16, 400)};
  color: var(--color-h6, ${theme.colors.textPrimary});
  margin: 0;
`)
