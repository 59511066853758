import { decamelize } from 'humps'

function dashes(str: string) {
  return decamelize(str, { separator: '-' })
}

function constructCssKey(...parts: Array<string | undefined>) {
  return parts.filter(Boolean).join('-')
}

function cvar(name: string, defaultValue?: string) {
  if (defaultValue) {
    return `var(${name}, ${defaultValue})`
  }

  return `var(${name})`
}

/**
 * @deprecated Import from @emico-utils/css-var-utils
 */
export function defaultsToCss<T extends { [s: string]: unknown }>(
  defaults: T,
  prefix?: string,
) {
  return Object.entries(defaults).map(([key, value]) => {
    const cssKey = constructCssKey('-', prefix, dashes(key))

    return `${cssKey}: ${String(value)};`
  })
}

/**
 * @deprecated Import from @emico-utils/css-var-utils
 */
export default function defaultsToVars<T extends { [s: string]: unknown }>(
  defaults: T,
  prefix?: string,
) {
  const keys = Object.entries(defaults).map(([key]) => [
    key,
    cvar(constructCssKey('-', prefix, dashes(key))),
  ])

  return Object.fromEntries(keys) as Record<keyof T, string>
}
