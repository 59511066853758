import Head from 'next/head'

import useLocale from '../lib/useLocale'

/**
 * Component to add default og meta data in <head>
 */
const MetaOgDefaults = () => {
  const locale = useLocale()

  return (
    <Head>
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="4blue" />
      <meta property="og:locale" content={locale ? locale : 'nl'} />
    </Head>
  )
}

export default MetaOgDefaults
