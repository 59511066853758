import { gql } from '@apollo/client'

export const productFragment = gql`
  fragment ProductFragment on PimcoreObjectProduct {
    published
    articleNumber
    description
    ean
    id
    name
    objectType
    sku
    usp
    bestChoice
    imageGallery {
      image {
        fullpath
      }
    }
    manufacturer {
      ... on PimcoreObjectManufacturer {
        id
        name
      }
    }
    categories {
      ... on PimcoreObjectCategory {
        id
        name
      }
    }
    specifications {
      ... on PimcoreSpecificationsObjectBrickAttribute {
        field
        fieldType
        fieldTitle
        fieldTooltip
        objectBrick
        sectionName
        sectionTitle
        value {
          ... on PimcoreSpecificationsObjectBrickAttributeStrings {
            stringsValue: value
            value
            displayValue
            displayValues
          }
          ... on PimcoreSpecificationsObjectBrickAttributeString {
            displayValue
            stringValue: value
          }
          ... on PimcoreSpecificationsObjectBrickAttributeFloat {
            displayValue
            floatValue: value
          }
        }
      }
    }
  }
`
