import { isServerSide } from '@emico-utils/ssr-utils'

import { MagentoSession } from './SessionContext'

let cached: Promise<MagentoSession>

export const getMagentoSession = async () => {
  if (isServerSide() || process.env.REACT_APP_ENV === 'dev') {
    return
  }
  if (cached && (await cached).customerId !== '') {
    return cached
  }
  cached = fetch(`/react/index/getsession/`).then((res) => res.json())

  return cached
}
