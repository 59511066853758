import { pushLinkClick, testId } from '@emico-utils/datalayer'
import Link from 'next/link'
import { ComponentProps } from 'react'

const isAnalyticsEnabled =
  process.env.REACT_APP_DISABLE_ANALYTICS_LINK_CLICK !== 'true'

interface Props extends ComponentProps<typeof Link> {
  ariaLabel?: string
  rel?: string

  // GTM
  /** Tracking event context. Use a visual breadcrumb id, e.g. header.subMenu.category */
  analyticsContext?: string
  /** Tracking event name */
  analyticsName?: string
}

const NextLink = ({
  ariaLabel,
  rel,
  target,
  onClick,
  analyticsContext,
  analyticsName,
  ...other
}: Props) => (
  <Link
    rel={rel ?? target === '_blank' ? `noopener noreferrer` : undefined}
    aria-label={ariaLabel}
    data-testid={testId(analyticsName, analyticsContext)}
    onClick={
      isAnalyticsEnabled
        ? (e) => {
            onClick?.(e)

            pushLinkClick(analyticsName, analyticsContext)
          }
        : undefined
    }
    {...other}
  />
)

export default NextLink
