import { useApolloClient } from '@apollo/client'
import { useAuthorizationContext } from '@emico-hooks/login-token'

import { getProductQuery } from './getProduct'
import { GetProductQueryVariables } from './getProduct.generated'

/**
 * @returns async function to fire a query to get a product
 */
export const useGetProduct = () => {
  const client = useApolloClient()
  const authorizationContext = useAuthorizationContext()

  return async (variables: GetProductQueryVariables) => {
    const { data, ...others } = await client.query({
      query: getProductQuery,
      variables,
      errorPolicy: 'all',
      context: authorizationContext,
    })

    return {
      product: data?.getProduct ? data.getProduct : null,
      ...others,
    }
  }
}
