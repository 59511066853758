import { CheckboxCustom as BaseCheckboxCustom } from '@emico-react/input-checkbox'
import { StyledComponent } from '@emotion/styled'
import { ForwardRefExoticComponent, RefAttributes } from 'react'

import CheckboxCustom from '../components/CheckboxCustom'

BaseCheckboxCustom.override(
  CheckboxCustom as ForwardRefExoticComponent<RefAttributes<HTMLInputElement>> &
    StyledComponent<RefAttributes<HTMLInputElement>>,
)
