import defaultsToVars, { defaultsToCss } from './cssVarsUtils'

const spacingPrefix = 'spacing'

const spacingDefaults = {
  x05: '4px',
  x1: '8px',
  x15: '12px',
  x2: '16px',
  x25: '20px',
  x3: '24px',
  x4: '32px',
  x5: '40px',
  x6: '48px',
  x7: '56px',
  x8: '64px',
}

export const spacing = defaultsToVars(spacingDefaults, spacingPrefix)

const defaults = {
  containerPadding: spacing.x2,
  pagePaddingSm: spacing.x3,
  pagePadding: spacing.x5,
}

const grid = {
  gridY: spacing.x1,
  gridX: spacing.x15,
  sectionY: spacing.x3,
  gridColumns: 8,
  titleY: spacing.x05,
  containerWidth: '930px',
  containerPadding: spacing.x15,
  // |<----->|  CONTAINER WIDTH  |<----->|
  //     ^--- the "auto" padding the container would have if it is centered.
  centerContainerPadding: `max(
      ${spacing.x1},
      calc(
        (
          100% - var(--spacing-container-width)
            + ${spacing.x1} + ${spacing.x1}
        ) / 2
      )
    )`,
}

/**
 * Only add vars here that differ from the default (mobile) values.
 */
export const mdGrid = defaultsToCss(
  {
    gridY: spacing.x3,
    gridX: '30px',
    sectionY: spacing.x7,
    gridColumns: 12,
    titleY: spacing.x05,
  },
  spacingPrefix,
)

// /**
//  * @deprecated Switch to spacing semantics and spacing8
//  */
// export const mdSpacing = defaultsToCss<typeof spacing>(
//   {
//     sm: '5px',
//     md: '8px',
//     lg: '12.5px',
//     xl: '16px',
//     xxl: '22px',
//   },
//   spacingPrefix,
// )

// /**
//  * @deprecated Switch to spacing semantics and spacing8
//  */
// const spacingDefaults = {
//   sm: '3px',
//   md: '6px',
//   lg: '9px',
//   xl: '15px',
//   xxl: '24px',
// }

// /**
//  * @deprecated Switch to spacing semantics and spacing8
//  */
// const spacing = defaultsToVars(spacingDefaults, spacingPrefix)

export const spacingCss = [
  // ...defaultsToCss(spacingDefaults, spacingPrefix),
  ...defaultsToCss(spacingDefaults, spacingPrefix),
  ...defaultsToCss(defaults, spacingPrefix),
  ...defaultsToCss(grid, spacingPrefix),
]

export default {
  // ...spacing,
  spacing,
  ...defaultsToVars(defaults, spacingPrefix),
  ...defaultsToVars(grid, spacingPrefix),
}
