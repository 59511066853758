import animations from './animations'
import borders from './borders'
import colors from './colors'
import sizes from './sizes'
import spacing from './spacing'
import transitions from './transitions'
import typography, { getTypography } from './typography'
import zIndices from './zIndices'

const theme = {
  colors,
  ...borders,
  ...spacing,
  ...sizes,
  ...typography,
  zIndices,
  transition: transitions,
  animation: animations,
  getTypography,
}

export { getTypography }

export default theme
