import defaultsToVars, { defaultsToCss } from './cssVarsUtils'

const colorPrefix = 'color'

export const paletteDefaults = {
  black: '#0D0022',
  blue: '#4D4F7E',
  darkBlue: '#000351',
  orange: '#FD5E08',
  superLightGray: '#F3F2F4',
  lightGray: '#F8F7F9',
  mediumGray: '#D9D7D7',
  gray: '#E4E4E4',
  darkGray: '#5F6275',
  green: '#0DC072',
  fadedGreen: '#00da7a10',
  white: '#FFFFFF',
  darkWhite: '#F2F2F2',
  lightBlue: '#181B61',
  inactive: '#9F9F9F',
  accentGreen: '#00DA7A',
  accentBlue: '#60B1E7',
  red: '#F74343',
  fadedRed: '#FD5E0810',
}

export const palette = defaultsToVars(paletteDefaults, colorPrefix)

const defaults = {
  primary: paletteDefaults.darkBlue,
  secondary: paletteDefaults.lightBlue,
  text: paletteDefaults.black,
  textPrimary: paletteDefaults.darkBlue,
  textLight: paletteDefaults.white,
  textMuted: palette.mediumGray,
  background: paletteDefaults.white,
  backgroundLight: paletteDefaults.lightGray,
  success: paletteDefaults.green,
  error: paletteDefaults.red,
  orange: paletteDefaults.orange,
  lightGray: paletteDefaults.lightGray,
  gray: paletteDefaults.gray,
  darkGray: paletteDefaults.darkGray,
  green: paletteDefaults.green,
  white: paletteDefaults.white,
  darkWhite: paletteDefaults.darkWhite,
  blue: paletteDefaults.blue,
  lightBlue: paletteDefaults.lightBlue,
  mediumGray: paletteDefaults.mediumGray,
  inactive: paletteDefaults.inactive,
}

export const colorsCss = [
  ...defaultsToCss(paletteDefaults, colorPrefix),
  ...defaultsToCss(defaults, colorPrefix),
]

export const semantics = defaultsToVars(defaults, colorPrefix)

const colors = {
  ...palette,
  ...semantics,
}

export default colors
