import { makeVar } from '@apollo/client'
import { syncReactiveVar } from '@emico-utils/reactive-var-utils'

export const localStorageKey = 'customer/token'
export const defaultValue = undefined

const loginTokenVar = syncReactiveVar(
  makeVar<string | undefined>(defaultValue),
  localStorageKey,
)

export default loginTokenVar
