import { makeVar, useReactiveVar } from '@apollo/client'
import { ButtonUnstyled } from '@emico-react/buttons'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useEffect, useState } from 'react'

import ProductSpecificationsTable, {
  Specification,
} from '@emico/product-specifications-table-pimcore'
import { maxWidth, minWidth } from '@emico/styles'

import { detectCurrentBrowser } from './detectCurrentBrowser'
import { ModalHeader } from './ModalHeader'
import { ModalSlideRight } from './ModalSlideRight'
import HtmlContent from '../../components/HtmlContent'
import Loader from '../../components/Loader'
import useLocale from '../../lib/useLocale'
import theme, { getTypography } from '../../theme'
import { MediaGallery } from '../media-gallery/MediaGallery'
import { Product } from '../product/product'
import { useGetProduct } from '../product/useGetProduct'

interface ShowProductModal {
  open: boolean
  id: string | undefined
}

export const showProductModal = makeVar<ShowProductModal>({
  open: false,
  id: undefined,
})

export const MenuContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSafari',
})<{ isSafari?: boolean }>`
  background-color: ${theme.colors.white};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  right: 0;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;

  @media ${minWidth('lg')} {
    height: ${({ isSafari }) => (isSafari ? '100%' : '100vh')};
    overflow-y: ${({ isSafari }) => (isSafari ? 'none ' : 'auto')};
  }
`

export const MenuButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: ${theme.spacing.x3};
  gap: ${theme.spacing.x1};
`

export const ModalContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSafari',
})<{ isSafari?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${theme.spacing.x3};
  overflow-y: auto;
  padding-bottom: ${({ isSafari }) => isSafari && theme.spacing.x5};
  @media ${maxWidth('md')} {
    padding: ${theme.spacing.x4} ${theme.spacing.x2};
  }
`

const Usp = styled.h5`
  ${getTypography('headingGalano', 16, 700)}
  margin: 0;
  padding-bottom: ${theme.spacing.x3};
`

const TabsWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.colors.superLightGray};
  z-index: 100;
  white-space: nowrap;
  margin-bottom: ${theme.spacing.x1};

  &:not(:first-of-type) {
    border-left: 1px solid ${theme.colors.superLightGray};
  }

  @media ${minWidth('lg')} {
    margin-bottom: ${theme.spacing.x2};
  }
`

const StyledButtonUnstyled = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>`
  ${getTypography('bodyGalano', 14, 700)}
  padding: ${theme.spacing.x2} ${theme.spacing.x3};
  border-bottom: ${({ active }) => active && `2px solid ${theme.colors.black}`};

  @media ${minWidth('lg')} {
    border-top: 1px solid ${theme.colors.superLightGray};
    border-right: 1px solid ${theme.colors.superLightGray};
  }
`

const Title = styled.h4`
  ${getTypography('headingGalano', 18, 700)}
  @media ${maxWidth('md')} {
    margin-top: ${theme.spacing.x3};
    margin-bottom: ${theme.spacing.x1};
  }
`

const MobileTitle = styled.h1`
  @media ${maxWidth('md')} {
    display: block;
    padding-bottom: ${theme.spacing.x3};
    margin: 0;
    ${getTypography('headingGalano', 18, 700)}
  }
  display: none;
`

export const kf = keyframes`
  from {
    transform: translateX(75%);
  }
  to {
    transform: translateX(0);
  }
`

export const Animation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  flex: 1;
  width: 100%;
  background: ${theme.colors.background};

  &:last-of-type:not(:only-child) {
    animation: ${kf} 0.25s ease;
  }
`

export const LoaderWrapper = styled.div`
  margin: auto;
`

const GalleryWrapper = styled.div`
  padding-bottom: ${theme.spacing.x3};
`

export const Modal = () => {
  const locale = useLocale()

  const showProductModalData = useReactiveVar(showProductModal)
  const show = showProductModalData.open
  const productId = showProductModalData.id
  const isSafari = detectCurrentBrowser() === 'Safari'
  const queryGetProduct = useGetProduct()
  const [product, setProduct] = useState<Product | undefined>(undefined)
  const [showSpecs, setShowSpecs] = useState<boolean>(false)

  useEffect(() => {
    if (productId) {
      queryGetProduct({
        id: parseInt(productId),
        defaultLanguage: locale,
      }).then(({ product }) => {
        if (product) {
          setProduct(product)
        }
      })
    }
  })

  return (
    <ModalSlideRight
      open={show}
      onClose={() =>
        showProductModal({ open: false, id: showProductModalData.id })
      }
    >
      <Animation>
        <MenuContainer isSafari={isSafari}>
          {!product ||
            (productId !== product.id ? (
              <LoaderWrapper>
                <Loader size="large" />
              </LoaderWrapper>
            ) : (
              <>
                <ModalHeader
                  product={product}
                  isSafari={isSafari}
                  close={() =>
                    showProductModal({
                      open: false,
                      id: showProductModalData.id,
                    })
                  }
                >
                  {product && product.name ? product.name : ''}
                </ModalHeader>

                <ModalContent isSafari={isSafari}>
                  <MobileTitle>
                    {product && product.name ? product.name : ''}
                  </MobileTitle>

                  <GalleryWrapper>
                    <MediaGallery product={product} />
                  </GalleryWrapper>

                  {product.usp && <Usp>{product.usp}</Usp>}

                  <TabsWrapper>
                    <StyledButtonUnstyled
                      onPress={() => setShowSpecs(false)}
                      analyticsContext="modal"
                      analyticsName="description-tab-button"
                      active={!showSpecs}
                    >
                      <Trans>Description</Trans>
                    </StyledButtonUnstyled>

                    <StyledButtonUnstyled
                      onPress={() => setShowSpecs(true)}
                      analyticsContext="modal"
                      analyticsName="specifications-tab-button"
                      active={showSpecs}
                    >
                      <Trans>Specifications</Trans>
                    </StyledButtonUnstyled>
                  </TabsWrapper>

                  {!showSpecs ? (
                    <>
                      <Title>{product.name}</Title>

                      <HtmlContent
                        removeSingleParagraphWrapper
                        html={product.description ?? ''}
                      />
                    </>
                  ) : (
                    <>
                      <Title>{product.name}</Title>

                      <ProductSpecificationsTable
                        specifications={
                          product.specifications as Specification[]
                        }
                      />
                    </>
                  )}
                </ModalContent>
              </>
            ))}
        </MenuContainer>
      </Animation>
    </ModalSlideRight>
  )
}
